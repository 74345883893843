<header>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-6">
        <div class="logo">
          <img src="assets/images/logo2.jpg">
        </div>
      </div>
      <div class="col-lg-6 col-6 ">
        <div class="head-title">
          <h2><a href="#"><span class="website-link">GO TO OUR WEB SITE</span><img src="assets/images/title.png"></a>
          </h2>
        </div>
      </div>

    </div>

  </div>
</header>
<section>
  <div class="main-banner">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="ban-cap">
            <h3>KEAM Entrance Examination  Coaching</h3>
            <h3>KMAT Mock - Try your Kmat Score</h3>
            <!-- <h5>Upgrade yourself as a hireable IT Professional. </h5> -->
            <!-- <h5>Now @ <span class="cross">5000 Rs</span> 2500 Rs</h5> -->
            <div class="sub-btn mt-5 mb-5" style=" margin: auto;">
              <button class="btn btn-primary" data-toggle="modal" data-target="#myModal" id="form-btn"
                type="submit">Enquery Now</button>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="ban-img">
            <img src="assets/images/itp/Asset 1@2x.png">
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="numb">
      <p style="padding-top: 15px;">OFFER VALID TILL</p>
      <div class="circle">
        <div class="row">
          <div class="box">
            <div class="col-lg">
              <div class="numberCircle">13</div>
              <p>DAY(S)</p>
            </div>
          </div>
          <div class="box">
            <div class="col-lg">
              <div class="numberCircle">09</div>
              <P>HOUR</P>
            </div>
          </div>
          <div class="box">
            <div class="col-lg">
              <div class="numberCircle">27</div>
              <P>MINUTE</P>
            </div>
          </div>
          <div class="box">
            <div class="col-lg">
              <div class="numberCircle">41</div>
              <P>SECOND</P>
            </div>
          </div>
        </div>
      </div>

    </div>-->
  </div>
</section>
<div class="container">

  <div class="modal fade" id="myModal" role="dialog">
    <div class="modal-dialog">

      <div class="modal-content">
        <div class="modal-header">
          <h5></h5>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body">

          <div class="contact-form section-padding-0-100">
            <div class="container">
              <div class="row">
                <div class="col-12">
                  <div class="section-heading">
                    <h2>Application Details </h2>

                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="contact-form-area">
                    <form action="#" method="post">
                      <div class="row">
                        <div class="col-12 col-lg-6">
                          <div class="form-group">
                            <label for="contact-email">Have you Appeared for*:</label>
                            <select name="" class="form-control " style="height: 50px;">
                              <option value="volvo">KEAM </option>
                              <option value="saab">CMAT</option>
                              <option value="opel">NEET</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-12 col-lg-6">
                          <div class="form-group">
                            <label for="contact-name">Name*:</label>
                            <input type="text" class="form-control" id="contact-name" placeholder="Name">
                          </div>
                        </div>
                        <div class="col-12 col-lg-6">
                          <div class="form-group">
                            <label for="contact-name">City*:</label>
                            <input type="text" class="form-control" id="contact-city" placeholder="City">
                          </div>
                        </div>
                        <div class="col-12 col-lg-6">
                          <div class="form-group">
                            <label for="contact-name">Pin-code*:</label>
                            <input type="text" class="form-control" id="contact-pin" placeholder="Pin-code">
                          </div>
                        </div>
                        <div class="col-12 col-lg-6">
                          <div class="form-group">
                            <label for="contact-name">DOB*:</label>
                            <input type="text" class="form-control" id="contact-dob" placeholder="DOB">
                          </div>
                        </div>
                        <div class="col-12 col-lg-6">
                          <div class="form-group">
                            <label for="contact-email">Religion*:</label>
                            <input type="text" class="form-control" id="contact-address" placeholder="Religion">
                          </div>
                        </div>
                        <div class="col-12 col-lg-6">
                          <div class="form-group">
                            <label for="contact-name">Category*:</label>
                            <input type="text" class="form-control" id="contact-category" placeholder="Category">
                          </div>
                        </div>
                        <div class="col-12 col-lg-6">
                          <div class="form-group">
                            <label for="contact-name">Whatsapp number*:</label>
                            <input type="text" class="form-control" id="contact-name" placeholder="number">
                          </div>
                        </div>
                        <div class="col-12 col-lg-6">
                          <div class="form-group">
                            <label for="contact-name">Class*:</label>
                            <input type="text" class="form-control" id="contact-name" placeholder="class">
                          </div>
                        </div>

                        <div class="col-12">
                          <div class="form-group">
                            <label for="message">Address for communication*:</label>
                            <textarea class="form-control" name="address" id="address" cols="30" rows="10"
                              placeholder="Address"></textarea>
                          </div>
                        </div>
                        <div class="col-12 col-lg-6">
                          <div class="form-group">
                            <label for="contact-name">State*:</label>
                            <input type="text" class="form-control" id="contact-name" placeholder="State">
                          </div>
                        </div>
                        <div class="col-12 col-lg-6">
                          <div class="form-group">
                            <label for="contact-email">Gender*:</label>
                            <select name="" class="form-control " style="height: 50px;">
                              <option value="volvo">Male</option>
                              <option value="saab">Female</option>

                            </select>
                          </div>
                        </div>
                        <div class="col-12 col-lg-6">
                          <div class="form-group">
                            <label for="contact-name">Name of School Studying / Last Stidied *:</label>
                            <input type="text" class="form-control" id="contact-name"
                              placeholder="Name of School Studying / Last Stidied ">
                          </div>
                        </div>

                        <div class="col-12 col-lg-6">
                          <div class="form-group">
                            <label for="contact-email">How did you know about Jaibharath skill Academy ?*:</label>
                            <select name="" class="form-control " style="height: 50px;">
                              <option value="volvo">Website</option>
                              <option value="saab">Newpaper </option>
                              <option value="saab">School</option>
                              <option value="saab">Social Media </option>
                              <option value="saab">Former Student</option>

                            </select>
                          </div>
                        </div>

                        <div class="col-12 text-center">
                          <button type="submit" class="btn crose-btn mt-15">Submit Now</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="advantage">
    <div class="container">
      <div class="advant-content1">

        <div class="row">
          <div class="col-md-3">
            <div class="advant-box">
              <div class="inr-icons">
                <span class="lnr lnr-users"></span>
              </div>
              <h2>Students Jointed-750</h2>
              <!-- <p class="advant">
              An expert driven industry mentorships supplemented with job fitment analysis and also enable for resume building using AI mode.
            </p>-->
            </div>
          </div>
          <div class="col-md-3">
            <div class="advant-box">
              <div class="inr-icons">
                <span class="lnr lnr-layers"></span>
              </div>
              <h2>Class Completed-3000</h2>
              <!--   <p class="advant">
              Our financial aid helps the students and their families by
covering higher education expenses, tie up with banks and also provide various scholarship
schemes.
            </p>-->
            </div>
          </div>
          <div class="col-md-3">
            <div class="advant-box">
              <div class="inr-icons">
                <span class="lnr lnr-highlight"></span>
              </div>
              <h2>Certified Teachers - 50</h2>
              <!-- <p class="advant">
              The Jai Bharath placement portal serves you access to
extensive job leads and reference of leading firms.
            </p>-->
            </div>
          </div>

          <div class="col-md-3">
            <div class="advant-box">
              <div class="inr-icons">
                <span class="lnr lnr-earth"></span>
              </div>
              <h2>********</h2>
              <!--  <p class="advant">
            We at Jai Bharath take care of all the environment friendly factors to contribute in making an
eco-friendly campus
          </p>-->
            </div>
          </div>
        </div>
      </div>
    </div>



    <section>
      <div class="certificate highlights">
        <div class="container">
          <h2><b>Our Highlights</b></h2>
          <br><br>
          <div class="row">
            <div class="col-lg-6">
              <h5><b>Are you seeking the best Preparation plan and Preparation course for KEAM
                  Entrance Exam? Here it is the tailor-made course for your Keam entrance.</b></h5>
            </div>
            <div class="col-lg-6">

              <div class="side-space">
                <ul>
                  <h6 class="benefit"><b>Highlights</b></h6>
                  <li><img src="assets/images/Asset 6@2x.png"></li>
                  <h5><b>100% Result Oriented Coaching</b> </h5>
                  <li><img src="assets/images/Asset 6@2x.png"></li>
                  <h5><b>Highly Qualified, eminent and expertise faculties in the respective areas conduct the
                      lectures</b></h5>
                  <li><img src="assets/images/Asset 6@2x.png"></li>
                  <h5><b>Module wise study materials are provided for each subject</b></h5>
                  <li><img src="assets/images/Asset 6@2x.png"></li>
                  <h5><b>Individual care and guidance are given to required students</b></h5>
                  <li><img src="assets/images/Asset 6@2x.png"></li>
                  <h5><b>Hostel facilities for available for girls and boys</b></h5>
                  <li><img src="assets/images/Asset 6@2x.png"></li>
                  <h5><b>A healthy parent teacher communication, and monthly parents meeting</b></h5>

                </ul>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="container">
        <p class="keam-cap">Keam Entrance Adding points
          Led by a group of Philanthropists with an excellent track record
          Based on an internationally accepted ‘Learn - Exercise - Memorize - Output - Best result’ concept
          Well experienced & Professionally Qualified Faculty with proven records
          Highly competent teachers with professional skills and practices
          Well disciplined environment and experienced administration
          Internationally reputed Strategic Advisors & Educational Consultants
          Study centre situates with frequent transport services.
          Dedicated Time Span of coaching
          Individual attention to every student. One to one counseling for individual success
          National level study materials
          Helping the students to submit entrance application form without any processing fees
          Training covers many national level tests too.
          Many model / mock entrance tests / Revision classes / Evaluation / Grading / Ranking
          Timely progress and activity reporting to the parents</p>


      </div>
      <div class="certificate">
        <div class="container">


          <div class="row justify-content-center">
            <div class="col-12 col-lg-10 col-md-11 text-center wow fadeInUp margin-two-bottom">
              <h2 class="text-extra-dark-gray font-weight-700"><b>Benefits</b> </h2>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3 why-unique text-center wow fadeInUp">
              <img class="img-fluid" src="./assets/images/Unique_1.png">
              <h6>Strategy</h6>
              <p>Objective Study , Time Management , Negative Mark Elimination</p>
            </div>
            <div class="col-md-3 why-unique text-center wow fadeInUp" data-wow-delay="0.6s">
              <img class="img-fluid" src="./assets/images/Unique_2.png">
              <h6>Study Plan</h6>
              <p> An Effective Study Calendar</p>
            </div>
            <div class="col-md-3 why-unique text-center wow fadeInUp" data-wow-delay="0.4s">
              <img class="img-fluid" src="./assets/images/Unique_3.png">
              <h6>Learning Materials</h6>
              <p>Module-Wise Theory , Video Lectures , MCQ Video Lecture , MCQ Solution Ebook</p>
            </div>
            <div class="col-md-3 why-unique text-center wow fadeInUp" data-wow-delay="0.4s">
              <img class="img-fluid" src="./assets/images/Unique_4.png">
              <h6>Face the Exams </h6>
              <p>OMR MCQ Exam Series-Solution , Model Exams</p>
            </div>


          </div>

        </div>
      </div>
    </section>

    <section>
      <div class="demo-class">
        <h2><b>Our Demo Classes</b></h2>
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="class-box">
                <a href="https://youtu.be/R79KkXZ4sNc" target="_blank">
                  <img src="https://img.youtube.com/vi/R79KkXZ4sNc/0.jpg" />
                </a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="class-box">
                <a href="https://youtu.be/8PNghqtlL1A" target="_blank">
                  <img src="https://img.youtube.com/vi/8PNghqtlL1A/0.jpg" />
                </a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="class-box">
                <a href="https://youtu.be/cY5atwSf7y4" target="_blank">
                  <img src="https://img.youtube.com/vi/cY5atwSf7y4/0.jpg" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div class="process certificate">
        <h2 class="admin"><b>Admission Procedure</b></h2>
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="process-img">
                <img src="assets/images/admision.png" />
              </div>
            </div>
            <div class="col-lg-8">
              <div class="side-space">
                <ul>
                  <li><img src="assets/images/Asset 6@2x.png"></li>
                  <h5><b>Students with 70% or above marks in their 10 th or 12 th Board Exam can Apply</b> </h5>
                  <li><img src="assets/images/Asset 6@2x.png"></li>
                  <h5><b>Students with 60% or Above marks are also eligible for Admission based on certain criteria</b>
                  </h5>
                  <li><img src="assets/images/Asset 6@2x.png"></li>
                  <h5><b>Jobfitment Analysis Program</b></h5>
                  <li><img src="assets/images/Asset 6@2x.png"></li>
                  <h5><b>Engineering Foundation Course</b></h5>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="sub-btn mt-5 mb-5 text-center">
          <button class="btn btn-primary" type="submit" routerLink="./online-admission">Apply Now</button>
        </div>
      </div>
    </section>


    <section>
      <div class="main-title">
        <h3>Confused about joining??</h3>
        <h4>Register for our free webinar and get details of the program</h4>
        <h3>and learn how to uplift your career</h3>
        <br>
        <div class="row">
          <div class="col-md-12 frm-lyt">
            <div class="form-group mb-2">

              <input type="text" name="name" class="form-control-1" placeholder="Your Name" required>
            </div>
          </div>
          <div class="col-md-12 frm-lyt">
            <div class="form-group mb-2">

              <input type="text" name="name" class="form-control-1" placeholder=" Phone number" required>
            </div>
          </div>
          <div class="col-md-12 frm-lyt">
            <div class="form-group mb-2">

              <input type="text" name="name" class="form-control-1" placeholder="Your Email id" required>
            </div>
          </div>
          <div class=" sub-btn mt-2 mb-5" style=" margin: auto;">
            <button class="btn btn-primary" id="form-btn" type="submit">Sign up</button>
          </div>
        </div>
      </div>
    </section>


    <div class="container">
      <div id="accordion">
        <div class="row">
          <div class="col-md-7">
            <h4><b>Frequently Asked Questions (FAQ)</b></h4>
            <br>
            <div id="accordion">
              <div class="card">
                <div class="card-header-style">
                  <a class="card-link" data-toggle="collapse" href="#collapseOne">
                    What are the procedures for applying online? <svg width="2em" height="2em" viewBox="0 0 16 16"
                      class="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path fill-rule="evenodd"
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                  </a>
                </div>
                <div id="collapseOne" class="collapse" data-parent="#accordion">
                  <div class="card-body">
                    There are 5 steps in the online application submission.

                    The candidate should complete all the steps for applying online before the last date specified.
<ul>
  <li class="step">  Step 1: Registration</li>
  <li class="step"> Step 2: Fill Application</li>
  <li class="step">   Step 3: Pay Application Fee</li>
  <li class="step">Step 4: Upload Images and Certificates</li>
  <li class="step">  Step 5: Print Confirmation Page for more details, refer to ‘How to apply?</li>
  
</ul> </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header-style">
                  <a class="collapsed card-link" data-toggle="collapse" href="#collapseTwo">

                    Which are the Courses allotted by CEE in KEAM? <svg width="2em" height="2em" viewBox="0 0 16 16"
                      class="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path fill-rule="evenodd"
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                  </a>
                </div>
                <div id="collapseTwo" class="collapse" data-parent="#accordion">
                  <div class="card-body">
                    CEE is conducting allotment to the following courses in KEAM: Engineering, Architecture, MBBS, BDS,
                    Ayurveda, Homoeo, Siddha, Unani, Agriculture, Forestry, Veterinary, Fisheries, and Pharmacy
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header-style">
                  <a class="collapsed card-link" data-toggle="collapse" href="#collapseThree">

                    I wish to apply for the Architecture course.<br /> Which entrance exam shall I need to qualify?<svg
                      width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-plus-circle1" fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path fill-rule="evenodd"
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                  </a>
                </div>
                <div id="collapseThree" class="collapse" data-parent="#accordion">
                  <div class="card-body">

                    If candidate wants to be considered for allotment in Architecture course, the qualifying in NATA
                    (National Aptitude Test) conducted by the Council of Architecture is required. The candidate should
                    also apply for KEAM also.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header-style">
                  <a class="collapsed card-link" data-toggle="collapse" href="#collapsefour">

                    Is it compulsory to apply KEAM and NEET simultaneously for considering allotment in the Medical
                    courses of Kerala? <svg width="2em" height="2em" viewBox="0 0 16 16" class="bi bi-plus-circle1"
                      fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path fill-rule="evenodd"
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                  </a>
                </div>
                <div id="collapsefour" class="collapse" data-parent="#accordion">
                  <div class="card-body">
                    Definitely Yes. For medical courses, the candidate has to apply for NEET conducted by NTA and
                    qualify in NEET. Moreover, he/she must be an applicant of KEAM also.

                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header-style">
                  <a class="collapsed card-link" data-toggle="collapse" href="#collapsefive">
                    What should I prepare for Jobfitness? <svg width="2em" height="2em" viewBox="0 0 16 16"
                      class="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path fill-rule="evenodd"
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                  </a>
                </div>
                <div id="collapsefive" class="collapse" data-parent="#accordion">
                  <div class="card-body">
                    Jobfitment Analysis checks your skills on Culture Fitness, Abilities, Situational Analysis on your
                    domain (like Computer Science, Management,, Finance etc.).
                  </div>
                </div>
              </div>
              <div class="card mar">
                <div class="card-header-style">
                  <a class="collapsed card-link" data-toggle="collapse" href="#collapsesix">
                    Will I get placement assistance after Jobfitment?<svg width="2em" height="2em" viewBox="0 0 16 16"
                      class="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd"
                        d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path fill-rule="evenodd"
                        d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                  </a>
                </div>
                <div id="collapsesix" class="collapse" data-parent="#accordion">
                  <div class="card-body">
                    Yes, placement assistance will be provided to Jobfitment analysis assessed candidates through the
                    Dedicated Dashboard
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <img src="assets/images/faq.png" class="jobfit">
          </div>
        </div>
      </div>
    </div>


    <!--<div class="testiminial-main">
  <div class="container">
    <h4>TESTIMONIALS</h4>
    <div class="testimonial">
      <ul id="testimonial">
        <li>
          <div class="testimonial-item">
              <div class="tstm-img">
                  <img src="https://itpwcs.in/uploads/testimonials/taxscan.png" data-no-retina="">
              </div>
              <p>I believe that the strength of a company is the employees. So each and every recruitment is a great task for me but it's a time killing process.  But still, I am able to recruit the students without any task and that's because of you.</p>
              <div class="testm-info">
              <h5>Abdullah Karuthedakam</h5>
              <p>CEO Taxscan Edutech PVT LTD</p>
              </div>
          </div>
        </li>
        <li>
          <div class="testimonial-item">
              <div class="tstm-img">
                  <img src="https://itpwcs.in/uploads/testimonials/taxscan.png" data-no-retina="">
              </div>
              <p>I believe that the strength of a company is the employees. So each and every recruitment is a great task for me but it's a time killing process.  But still, I am able to recruit the students without any task and that's because of you.</p>
              <div class="testm-info">
              <h5>Abdullah Karuthedakam</h5>
              <p>CEO Taxscan Edutech PVT LTD</p>
              </div>
          </div>
        </li>
                  
      </ul>

      <div class="lSAction advant-controls">
        <a class="lSPrev" id="advantPrev"><img src="./assets/images/arrow-left.png" /></a>
        <a class="lSNext" id="advantNext"><img src="./assets/images/arrow-right.png" /></a>
    </div>
  </div>
</div>
</div>-->