import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { KmatMockRegistrationPopupComponent } from '../kmat-mock-registration-popup/kmat-mock-registration-popup.component';

@Component({
  selector: 'app-scholarship-homepopup',
  templateUrl: './scholarship-homepopup.component.html',
  styleUrls: ['./scholarship-homepopup.component.css']
})
export class ScholarshipHomepopupComponent implements OnInit {

  constructor(
    private matDialog: MatDialog
    ) { }

  ngOnInit(): void {
  }

  changeForm:boolean=false;
  RegistrationForm(){
    this.changeForm=true;
  }

}
