<div class="col-md-12" mat-dialog-title>
    <div class="main-head">
        <h1>Enquiry Form
            <span style="
            float: right;
        " mat-button [mat-dialog-close]="false" class="pull-right close-popup"><i class="fa fa-times"></i></span>
        </h1>
    </div>
</div>
<div class="container">
    <div class="enquir-form">
        <div class="row">
            <div class="col-md-12">
                <div class="form-img">
                    <img src="assets/images/syllabus.png" />
                </div>
            </div>

        </div>

        <form [formGroup]="enquiryForm" (ngSubmit)="onSubmit()" autocomplete="off">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <input type="text" formControlName="Name"
                        [ngClass]="{ 'is-invalid': submitted && formControl.Name.errors }" class="form-control control-box" placeholder="Enter your name" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <input type="text" formControlName="Phone"
                        [ngClass]="{ 'is-invalid': submitted && formControl.Name.errors }" class="form-control control-box"
                            placeholder="Enter your phone number" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <input type="email" formControlName="Email"
                        [ngClass]="{ 'is-invalid': submitted && formControl.Name.errors }" class="form-control control-box"
                            placeholder="Enter your email Id" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <input type="text" formControlName="State"
                        [ngClass]="{ 'is-invalid': submitted && formControl.State.errors }" class="form-control control-box"
                            placeholder="Enter your state" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <input type="text" formControlName="City"
                        [ngClass]="{ 'is-invalid': submitted && formControl.City.errors }" class="form-control control-box"
                            placeholder="Enter your city" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="submit-btn">
                        <button class="btn btn-primary">Send</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>