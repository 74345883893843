<div class="main-head">
    <div class="head-line">
        <h1>Online Admission</h1>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="manage-tbl">
                <p class="text-center" *ngIf="!AllOnlineAdmission.length">No data found</p>
                <table mat-table [dataSource]="AllOnlineAdmission" class="mat-elevation-z8" width="100%"
                    *ngIf="AllOnlineAdmission.length">

                    <ng-container matColumnDef="SortId">
                        <th mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element"> - </td>
                    </ng-container>
                    <!-- <ng-container matColumnDef="ApplicationDate">
                        <th mat-header-cell *matHeaderCellDef> Application Date </th>
                        <td mat-cell *matCellDef="let element"> {{element.ApplicationDate}} </td>
                    </ng-container> -->
                    <ng-container matColumnDef="CandidateName">
                        <th mat-header-cell *matHeaderCellDef> Candidate Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.CandidateName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Email">
                        <th mat-header-cell *matHeaderCellDef> Email Id </th>
                        <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Phone">
                        <th mat-header-cell *matHeaderCellDef> Contact No. </th>
                        <td mat-cell *matCellDef="let element"> {{element.PhoneNo}} </td>
                    </ng-container>


                    <ng-container matColumnDef="PostedDate">
                        <th mat-header-cell *matHeaderCellDef> Posted On </th>
                        <td mat-cell *matCellDef="let element"> {{element.PostedOn | date :'dd MMM yyyy @ h:mm:ss a'}}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Operation">
                        <th mat-header-cell *matHeaderCellDef> Operation</th>
                        <td mat-cell *matCellDef="let element" width="50px">
                            <a [routerLink]="['/view-details',element.OnlineAdmissionId]" class="sm" mat-raised-button
                                color="primary">
                                <i class="fa fa-eye" aria-hidden="true"></i>
                            </a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="EnquiriesColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: EnquiriesColumns;"
                        class="cursorView"></tr>

                </table>
            </div>
        </div>
    </div>
</div>