<div class="col-md-12" mat-dialog-title>
    <div class="main-head">
        <h1>{{formTitle}} webinar
            <span style="
            float: right;
        " mat-button [mat-dialog-close]="false" class="pull-right close-popup"><i class="fa fa-times"></i></span>
        </h1>
    </div>
</div>
<mat-dialog-content>
    <div class="container-fluid" [formGroup]="WebinarForm">

        <div class="row">
            <div class="col-md-12">
                <div class="forms">
                    <div class="row">
                        <div class="col-md-12">
                            <mat-card-content>
                                <mat-form-field class="full-width-input">
                                    <input matInput placeholder="Topic" formControlName="Topic" required>
                                    <mat-error *ngIf="formSubmitAttempt && formControl.Topic.errors ">
                                        Please provide a valid Topic
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="full-width-input">
                                    <input matInput placeholder="Date" formControlName="Date" type="date" required>
                                    <mat-error *ngIf="formSubmitAttempt && formControl.Date.errors ">
                                        Please provide a valid Date
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="full-width-input">
                                    <input matInput type="time" formControlName="Time" required>
                                    <mat-error *ngIf="formSubmitAttempt && formControl.Time.errors ">
                                        Please provide a valid Time
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="full-width-input">
                                    <input matInput placeholder="Hosted By" formControlName="HostedBy" required>
                                    <mat-error *ngIf="formSubmitAttempt && formControl.HostedBy.errors ">
                                        Please provide a valid Hosted By
                                    </mat-error>
                                </mat-form-field>
                                <mat-form-field class="full-width-input">
                                    <textarea matInput placeholder="Host Details" formControlName="HostDetails"
                                        required></textarea>
                                    <mat-error *ngIf="formSubmitAttempt && formControl.HostDetails.errors ">
                                        Please provide a valid Host Details
                                    </mat-error>
                                </mat-form-field>

                                <angular-editor rows="20" [config]="editorConfig" formControlName="Details">
                                </angular-editor>
                                <!-- <mat-form-field class="full-width-input"> -->

                                    <!-- <textarea matInput placeholder="Webinar Details" formControlName="Details" required></textarea>
                                    <mat-error *ngIf="formSubmitAttempt && formControl.Details.errors ">
                                        Please provide a valid Webinar Details
                                    </mat-error> -->
                                <!-- </mat-form-field> -->
                            </mat-card-content>
                        </div>
                        <div class="col-md-12">
                            <mat-error *ngIf="formSubmitAttempt && formControl.HostImage.errors ">
                                Please provide a valid host image
                            </mat-error>
                            <div class="Host-img"
                                [ngClass]="{ 'is-invalid': formSubmitAttempt && formControl.HostImage.errors }">
                                <label [for]="'uploadHostImage'">
                                    <img [src]="HostImageSrc" />
                                    <div *ngIf="datalistService.sessionData">
                                        <div class="edit-image">
                                            <span *ngIf="!addNewHost">
                                                Click To Change Image<br />
                                                <i>Recommended : 620 × 620 pixels</i>
                                            </span>
                                            <span *ngIf="addNewHost">
                                                <i>Recommended : 620 × 260 pixels</i>
                                            </span>
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <input [id]="'uploadHostImage'" type="file" formControlName="HostImage"
                                (change)="onHostSelected($event)" class="image-click" />
                        </div>
                        <div class="col-md-12">
                            <mat-error *ngIf="formSubmitAttempt && formControl.PosterImage.errors ">
                                Please provide a valid poster
                            </mat-error>
                            <div class="Poster-img"
                                [ngClass]="{ 'is-invalid': formSubmitAttempt && formControl.PosterImage.errors }">
                                <label [for]="'uploadPosterImage'">
                                    <img [src]="PosterImageSrc" />
                                    <div *ngIf="datalistService.sessionData">
                                        <div class="edit-image">
                                            <!-- <span>
                                                Click To Change Image<br />
                                            </span> -->
                                        </div>
                                    </div>
                                </label>
                            </div>
                            <input [id]="'uploadPosterImage'" type="file" formControlName="PosterImage"
                                (change)="onPosterSelected($event)" class="image-click" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 text-right">
                            <div class="form-group">
                                <button type="submit" mat-raised-button color="primary" class="mat-info"
                                    (click)="onSubmit()">{{buttonLabel}}</button>&nbsp;&nbsp;
                                <button type="button" mat-raised-button [mat-dialog-close]="false">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>