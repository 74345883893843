<div class="col-md-12" mat-dialog-title>
    <div class="main-head">
        <span mat-button [mat-dialog-close]="false" class="pull-right close-popup" style="float: right;"><i
            class="fa fa-times"></i></span>
        <h1>Event Registration Form
            
        </h1>
    </div>
</div>
<mat-dialog-content>
    <div class="Event-rgstr" *ngIf="EventDetails">
        <section class="profile-section">
            <input type="hidden" class="txt_csrfname" name="csrf_tocken" value="e20bc24cfebe60c206129a507347b664">
    
            <div class="container">
                <div class="row ">
                    <div class="col-md-12">
                        <div class="profile-edit experiance-head profile-box">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="web-form">
                                        <p class="web-p-1">Topic : {{EventDetails.Topic}}</p>
                                    </div>
                                </div>
                            </div>
    
                            <form [formGroup]="RegistrationForm" (ngSubmit)="onSubmit()" autocomplete="off"
                                *ngIf="isActive(EventDetails.EventDate)">
                                <div class="row">
                                    <div class="col-md-4 frm-lyt">
                                        <div class="profile-edit form-group mb-2">
                                            <label>First Name <span>*</span></label>
                                            <input type="text" formControlName="FirstName"
                                                [ngClass]="{ 'is-invalid': submitted && formControl.FirstName.errors }"
                                                class="form-control" placeholder="First Name" required="">
                                        </div>
                                    </div>
                                    <div class="col-md-4 frm-lyt">
                                        <div class="profile-edit form-group mb-2">
                                            <label>Last Name <span>*</span></label>
                                            <input type="text" formControlName="LastName"
                                                [ngClass]="{ 'is-invalid': submitted && formControl.LastName.errors }"
                                                class="form-control" placeholder="Last Name" required="">
                                        </div>
                                    </div>
                                    <div class="col-md-4 frm-lyt">
                                        <div class="form-group mb-2">
                                            <label>Email Address<span>*</span></label>
                                            <input type="email" formControlName="Email"
                                                [ngClass]="{ 'is-invalid': submitted && formControl.Email.errors }"
                                                class="form-control" placeholder="example@example.com" required="">
                                        </div>
                                    </div>
                                    <div class="col-md-4 frm-lyt">
                                        <div class="form-group mb-2">
                                            <label>Phone Number <span>*</span></label>
                                            <input type="text" formControlName="Phone"
                                                [ngClass]="{ 'is-invalid': submitted && formControl.Phone.errors }"
                                                class="form-control" placeholder="Phone Number" required="">
                                        </div>
                                    </div>
    
    
                                    <div class="col-md-8 ">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group gender mb-2">
                                                    <label class="gender">Are you a student?
                                                        <span>*</span></label>
                                                    <div class="age-row">
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="IsStudent"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.IsStudent.errors }"
                                                                    value="Yes" checked="">
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="IsStudent"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.IsStudent.errors }"
                                                                    value="No">
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <div class="form-group gender mb-2" *ngIf="RegistrationForm.value.IsStudent=='Yes'">
                                                    <label class="gender">Course
                                                        <span>*</span></label>
                                                    <div class="age-row">
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="StudentCourse"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.StudentCourse.errors }"
                                                                    value="Plus Two" checked="">
                                                                Plus Two
                                                            </label>
                                                        </div>
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="StudentCourse"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.StudentCourse.errors }"
                                                                    value="Degree">
                                                                Degree
                                                            </label>
                                                        </div>
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="StudentCourse"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.StudentCourse.errors }"
                                                                    value="ITI">
                                                                ITI
                                                            </label>
                                                        </div>
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="StudentCourse"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.StudentCourse.errors }"
                                                                    value="Diploma">
                                                                Diploma
                                                            </label>
                                                        </div>
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="StudentCourse"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.StudentCourse.errors }"
                                                                    value="PG">
                                                                PG
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 ">
                                                <div class="form-group gender mb-2">
                                                    <label class="gender">How did you hear about this Event?
                                                        <span>*</span></label>
                                                    <div class="age-row">
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="HearAboutEvent"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.HearAboutEvent.errors }"
                                                                    value="Social Media" checked="">
                                                                Social Media
                                                            </label>
                                                        </div>
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="HearAboutEvent"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.HearAboutEvent.errors }"
                                                                    value="Teachers">
                                                                Teachers
                                                            </label>
                                                        </div>
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="HearAboutEvent"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.HearAboutEvent.errors }"
                                                                    value="Friend">
                                                                Friend
                                                            </label>
                                                        </div>
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <div class="input-group">
                                                                    <span class="input-group-addon">
                                                                        <input type="radio" class=" hide-radio"
                                                                            formControlName="HearAboutEvent"
                                                                            [ngClass]="{ 'is-invalid': submitted && formControl.HearAboutEvent.errors }"
                                                                            value="Other">Other&nbsp;&nbsp;&nbsp;
                                                                    </span>
                                                                    <input type="text" formControlName="HearAboutEventOther" *ngIf="RegistrationForm.value.HearAboutEvent=='Other'"
                                                                        [ngClass]="{ 'is-invalid': submitted && formControl.HearAboutEventOther.errors }"
                                                                        class="form-control" placeholder="Other">
                                                                </div>
                                                            </label>
    
                                                        </div>
    
    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 frm-lyt">
                                        <div class="profile-edit form-group mb-2">
                                            <label>Please let us know if you have any questions for our speakers ahead of
                                                the Event.
                                                <span></span></label>
                                            <textarea class="address form-control input" formControlName="Questions"
                                                [ngClass]="{ 'is-invalid': submitted && formControl.Questions.errors }" rows="3"
                                                data-form-field="Message" placeholder="Questions"
                                                id="message-form4-4v"></textarea>
                                        </div>
                                    </div>
    
    
                                </div>
                                <br>
                                <br>
    
    
                                <div class="col-md-2 offset-md-4 sub-btn mt-2 mb-5 web-view" style=" margin: auto;">
                                    <button class="btn btn-primary" id="form-btn" type="submit" >Register Now</button>
                                </div>
    
                            </form>
                        </div>
                    </div>
    
    
                </div>
            </div>
        </section>
    </div>
</mat-dialog-content>