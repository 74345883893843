import { Injectable } from '@angular/core';
import { DatalistService } from '../services/datalist.service';

@Injectable({
  providedIn: 'root'
})
export class DatabaseConnectionService {

  constructor(
    public datalistService :DatalistService
  ) { 
    this.datalistService.PHP_API_SERVER = "https://jaibharathmba.com";
    this.datalistService.PHP_API_ADMIN = this.datalistService.PHP_API_SERVER+"/_admin_console";
    this.datalistService.IMGAE_PATH=this.datalistService.PHP_API_SERVER+"/assets/images";
    this.datalistService.DOC_PATH=this.datalistService.PHP_API_SERVER+"/assets/documents";
  }
}