<div class="pop-main" *ngIf="!changeForm">
    <div class="col-md-12" mat-dialog-title>
        <div class="main-head">
            <h1 style="text-align: right;
            font-size: 15px;
            line-height: 1;">
                <span mat-button [mat-dialog-close]="false" class="pull-right close-popup"><i
                        class="fa fa-times"></i></span>
            </h1>
        </div>
    </div>

    <mat-dialog-content>
        <div class="scholar-main">
            <h2 class="scholar-content"></h2>
            <div class="scholar-img">
                <img src="assets/images/home-popup.png" />
            </div>
            <div class="scholar-content">




                <!-- <h1>Get 100% Scholarship Program For all Coaching Classes</h1> -->
                <h1>Mega Jobfair  </h1>
                <!-- <p>
                    Due to Covid Senario. We Are Provide 100h Scholarship for first 100 Students 
                </p> -->
                <p>
                    Registration
                    <br />
                    25+ Corporates
                    <br />
                    1000+ Eligible Candidates
                </p>
                <!-- <div class="seats">
                    <img src="assets/images/scholar-seat.png" />
                </div> -->
                <!-- <div class="reg-btn">
                    <a (click)="RegistrationForm()">
                        <img src="assets/images/scholar-reg.png" />
                    </a>
                </div> -->
                <div class="row">
                    <div class="col-md-12">
                        <ul class="company">
                            <li>
                                Spandana Spoorthy Financial Services
                            </li>
                            <li>
                                L&T Finance
                            </li>
                            <li>
                                Esaf Co operatives
                            </li>
                            <li>
                                Reliant Credits
                            </li>
                            <li>
                                Craysol Technologies
                            </li>
                            <li>
                                SFO Technologies
                            </li>
                            <li>
                                Aabasoft
                            </li>
                            <li>
                                Centreal Multi Trade
                            </li>
                            <li>
                                MyG Mobiles
                            </li>
                            <li>
                                Muthoot Micro Finance
                            </li>
                            <li>
                                Teleperform
                            </li>
                            <li>
                                Samasta Finance
                            </li>
                            <li>
                                Asianet
                            </li>
                            <li>
                                KLM
                            </li>
                            <li>
                                Eureka Forbes
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </mat-dialog-content>
</div>
<app-kmat-mock-registration-popup *ngIf="changeForm"></app-kmat-mock-registration-popup>