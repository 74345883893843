import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from '../../api/services/common.service';
import { DatalistService } from '../../api/services/datalist.service';
import { KmatMockRegistrationPopupComponent } from '../../popup/kmat-mock-registration-popup/kmat-mock-registration-popup.component';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(
    public common: CommonService,
    private matDialog: MatDialog,
    public datalistService: DatalistService,
  ) { }

  ngOnInit(): void {
    // this.KMATMockRegistrationForm()
  }
  KMATMockRegistrationForm() {
    let dialogRef: MatDialogRef<KmatMockRegistrationPopupComponent> = this.matDialog.open(KmatMockRegistrationPopupComponent, {
      width: '400px',
      disableClose: true,
      panelClass: 'custom-dialog-container',
      hasBackdrop: true,
    });
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
      }
    });
  }
}

