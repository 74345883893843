import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
// for SEO
import { Title, Meta } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseOperationService } from '../../api/database/database-operation.service';
import { DatalistService } from '../../api/services/datalist.service';
import { FunctionService } from '../../api/services/function.service';
import { ProceedWarningComponent } from '../../popup/proceed-warning/proceed-warning.component';
import { ViewWebinarRegisterPopupComponent } from '../../popup/webinar/view-webinar-register-popup/view-webinar-register-popup.component';
import { WebinarFormPopupComponent } from '../../popup/webinar/webinar-form-popup/webinar-form-popup.component';
import { WebinarRegisterFormPopupComponent } from '../../popup/webinar/webinar-register-form-popup/webinar-register-form-popup.component';

@Component({
  selector: 'app-webinar',
  templateUrl: './webinar.component.html',
  styleUrls: ['./webinar.component.css']
})
export class WebinarComponent implements OnInit {
  // for SEO
  title = 'Top MBA College in Kerala |JAI BHARATH SCHOOL OF MANAGEMENT STUDIES';
  AllWebinar: Object;
  dateTime = new Date();
  constructor(
    // for SEO
    private titleService: Title,
    private metaTagService: Meta,
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public functionService: FunctionService,
  ) { }

  ngOnInit(): void {
    // for SEO
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'keywords', content: 'top mba college in kerala , top colleges in kerala , top 10 mba colleges in kerala , top mba colleges in kerala accepting kmat score , which mba college gives best placement' },
      { name: 'description', content: 'JAI BHARATH SCHOOL OF MANAGEMENT STUDIES | Become a Global Executive with Internationally Compatible Curriculum. Apply for Admission. Top MBA College in Kerala' },
      { name: 'robots', content: 'index, follow' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { charset: 'UTF-8' }
    ]);

    this.databaseOperationService.getAllWebinar().subscribe(data => {
      this.AllWebinar = data;
    })
  }
  isActive(Date) {
    let date1 = formatDate(this.dateTime, 'yyyy-MM-dd', 'en_US');
    let date2 = formatDate(Date, 'yyyy-MM-dd', 'en_US');
    // this.dateTime
    // let webinarDate=Date.getTime();
    return (date2 >= date1)
  }
  getimage(Poster) {
    return this.datalistService.IMGAE_PATH + '/webinar' + Poster;
  }
  registerWebinar(webinarid) {

    let dialogRef: MatDialogRef<WebinarRegisterFormPopupComponent> = this.matDialog.open(WebinarRegisterFormPopupComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.WebinarId = webinarid;
    return dialogRef.afterClosed().subscribe(result => {
      this.databaseOperationService.getAllWebinar().subscribe(data => { 
        this.AllWebinar = data;
      })
    });
  }
  addWebinar() {

    let dialogRef: MatDialogRef<WebinarFormPopupComponent> = this.matDialog.open(WebinarFormPopupComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.WebinarId = '';
    return dialogRef.afterClosed().subscribe(result => {
      this.databaseOperationService.getAllWebinar().subscribe(data => {
        this.AllWebinar = data;
      })
    });
  }
  editWebinar(WebinarId) {

    let dialogRef: MatDialogRef<WebinarFormPopupComponent> = this.matDialog.open(WebinarFormPopupComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.WebinarId = WebinarId;
    return dialogRef.afterClosed().subscribe(result => {
      this.databaseOperationService.getAllWebinar().subscribe(data => {
        this.AllWebinar = data;
      })
    });
  }
  viewWebinarRegister(WebinarId) {
    let dialogRef: MatDialogRef<ViewWebinarRegisterPopupComponent> = this.matDialog.open(ViewWebinarRegisterPopupComponent, {
      width: '95%',
      height: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.WebinarId = WebinarId;
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.getAllCollege();
      }
    });
  }
  deleteWebinar(allWebinar) {
    let dialogRef: MatDialogRef<ProceedWarningComponent> = this.matDialog.open(ProceedWarningComponent, {
      width: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.datalistService.loaderActive = true;
        this.databaseOperationService.deleteWebinar(allWebinar.WebinarId, allWebinar.Poster, allWebinar.HostedByImage).subscribe((data: any) => {
          this.databaseOperationService.getAllWebinar().subscribe(data => {
            this.datalistService.loaderActive = false;
            this.AllWebinar = data;
          })
        });
        // }
      }
    });
  }
}
