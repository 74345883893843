<div id="main-banner">
    <div class="container-fluid">
        <div id="demo" class="carousel slide" data-ride="carousel">

            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="assets/images/placement/placement-banner.jpg">
                    <div class="carousel-caption">
                        <div class="ban-title">
                            <h1 class="ban-head"> Placements</h1>
                            <h4>Get an industry level skill program with MBA</h4>
                            <h4>Upto 100% scholarship opportunities</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ban-up"></div>
    <div class="main-container">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <img class="col-pic" src="./assets/images/placement/place2.JPG">
                </div>
                <div class="col-md-6">
                    <div class="welcome">
                        <h1 class="prj-head">
                            Placements
                        </h1>
                        <p>
                            The Placement Cell plays a crucial role in locating job opportunities for Under Graduates
                            and

                            Post Graduates passing out from the college by keeping in touch with reputed firms and

                            industrial establishments. The Placement Cell operates round the year to facilitate contacts

                            between companies and graduates. The number of students placed through the campus

                            interviews is continuously rising. On invitation, many reputed industries visit the
                            institute to

                            conduct interviews.
                        </p>
                        <div class="container">
                            <div class="place">
                                <div class="row">
                                    <div class="col-md-4">
                                        <img src="assets/images/placement/place3.jpeg">
                                    </div>
                                    <div class="col-md-4">
                                        <img src="assets/images/placement/place4.jpeg">
                                    </div>
                                    <div class="col-md-4">
                                        <img src="assets/images/placement/place1.jpeg">
                                    </div>
                                </div>
                            </div>
                            <div class="place">
                                <div class="row-img">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <img src="assets/images/placement/place5.jpeg">
                                        </div>
                                        <div class="col-md-4">
                                            <img src="assets/images/placement/place2.jpeg">
                                        </div>
                                        <div class="col-md-4">
                                            <img src="assets/images/activity/mock1.jpeg">
                                        </div>
                                        <!-- <div class="col-md-4">
                                        <img src="assets/images/placement/place2.jpeg">
                                    </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="place">
                                <div class="row-img">
                                    <div class="row">

                                        <div class="col-md-4">
                                            <img src="assets/images/activity/mock2.jpeg">
                                        </div>
                                        <div class="col-md-4">
                                            <img src="assets/images/activity/mock3.jpeg">
                                        </div>
                                        <!-- <div class="col-md-4">
                                        <img src="assets/images/placement/place2.jpeg">
                                    </div> -->
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <br>
        <div class="container">

            <div class="card border-0 card-0">
                <div class="card profile py-3 px-4">
                    <div class="text-center"> <img src="assets/images/placement/hr.jpeg" class="img-fluid profile-pic">
                    </div>
                    <h6 class="mb-0 mt-2">Parvathy S Kumar </h6> <small>2011 -2013 batch</small>
                </div> <img class="img-fluid open-quotes" src="https://i.imgur.com/Hp91vdT.png" width="20" height="20">
                <p class="content mb-0">Education is the passport to the future, for tomorrow belongs to those who
                    prepare for it today"
                    Yes! that is what I can talk about at my college. Jaibharath School of Management Studies made me to
                    think about what I want to be and what I should aim for. The teachers inspired me not only to be
                    just successful but also to be a citizen and make my own mark. Today i can proudly say that i am the
                    HR of my own IT start-up KUDOWORLD TECHBAY PVT LTD. Good education and its system can change who you
                    were to what you want to be.</p> <img class="img-fluid close-quotes ml-auto"
                    src="https://i.imgur.com/iPcHyJK.png" width="20" height="20">
            </div>

            <div class="row">
                <div class="col-md-4">
                    <div class="placement-person">
                        <img src="assets/images/placement/seethalekshmi.jpg">
                        <!-- <h4>Seethalekshmi <br/>Executive Assistant <br/>(Chairman) </h4> -->
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="placement-person">
                        <img src="assets/images/placement/ashly.jpg">
                        <!-- <h4>Ashy Mary Thomas <br/>Finance Officier <br/>(SPI GLobal)</h4> -->
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="placement-person">
                        <img src="assets/images/placement/adshly elais.jpg">
                        <!-- <h4>Adshly Elias <br/>Company secretary cum Hr manager <br/>(UTIZ Global venture PVT ltd)</h4> -->
                    </div>
                </div>

            </div>
            <div class="enq-form">
                <div class="container">
                    <div class="adjust-width">
                        <form [formGroup]="enquiryForm" (ngSubmit)="onSubmit()" autocomplete="off">
                            <h3>Job Fair Registration Form</h3>
                            <p></p>
                            <div class="field" tabindex="1">
                                <label for="username">
                                    <i class="far fa-user"></i>Your Full Name
                                </label>
                                <input formControlName="Name"
                                    [ngClass]="{ 'is-invalid': submitted && formControl.Name.errors }" type="text"
                                    placeholder="e.g. john doe" required>
                            </div>
                            <div class="field" tabindex="2">
                                <label for="email">
                                    <i class="far fa-envelope"></i>Your Email
                                </label>
                                <input formControlName="Email"
                                    [ngClass]="{ 'is-invalid': submitted && formControl.Email.errors }" type="email"
                                    placeholder="email@domain.com" required>
                            </div>
                            <div class="field" tabindex="3">
                                <label for="date">
                                    <i class="far fa-calendar"></i>Your Date of Birth
                                </label>
                                <input formControlName="DOB"
                                    [ngClass]="{ 'is-invalid': submitted && formControl.DOB.errors }" type="date"
                                    placeholder="" required>
                            </div>

                            <div class="field" tabindex="4">
                                <label for="number">
                                    <i class="fas fa-mobile-alt"></i>Your Phone Number
                                </label>
                                <input formControlName="Phone"
                                    [ngClass]="{ 'is-invalid': submitted && formControl.Phone.errors }" type="text"
                                    placeholder="Phone Number" required>
                            </div>
                            <div class="field" tabindex="5">
                                <label for="Qualification">
                                    <i class="fas fa-graduation-cap"></i>Your Qualification
                                </label>
                                <input formControlName="Qualification"
                                    [ngClass]="{ 'is-invalid': submitted && formControl.Qualification.errors }"
                                    type="text" placeholder="Type your Qualification" required>

                            </div>
                            <div class="field" tabindex="6">
                                <label for="Specilization">
                                    <i class="far fa-id-badge"></i>Specilization
                                </label>
                                <input formControlName="Specilization"
                                    [ngClass]="{ 'is-invalid': submitted && formControl.Specilization.errors }"
                                    type="text" placeholder="Specilized In" required>

                            </div>
                            <div class="field" tabindex="7">
                                <label for="Experience">
                                    <i class="fas fa-chart-line"></i>Experience
                                </label>
                                <input formControlName="Experience"
                                    [ngClass]="{ 'is-invalid': submitted && formControl.Experience.errors }" type="text"
                                    placeholder="Your Experience" required>

                            </div>
                            <!-- <div class="field" tabindex="6">
                      <label for="message">
                        <i class="far fa-edit"></i>Your Message
                      </label>
                      <textarea name="message" placeholder="type here" required></textarea>
                    </div> -->
                            <button type="submit">Register Now</button>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="ban-down"></div>

    <div class="container-fluid">
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <h1 class="prj-head">
                    TRAINING
                </h1>
                <p>
                    The students are trained to attain placement through a variety of programmes run by the
                    Placement & Training Cell such as:
                </p>
                <ul>
                    <li>
                        Industry to Institute Programmes – Corporate leaders from leading industries are invited
                        regularly to interact with students
                    </li>
                    <li>
                        Various Training Programmes are organized to train the students in the areas of Aptitude,
                    </li>
                    <li>
                        Technical training programmes are being conducted by Technical experts
                    </li>
                    <li>
                        Language learning programmes to train students in developing their communication skills
                    </li>
                    <li>
                        Training through Mock Interviews for students to perform well in the professional interviews
                        as per the expectations of the corporate world
                    </li>
                    <li>
                        Public Sector Exams Training
                    </li>
                </ul>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3"></div>
            <div class="col-md-6">
                <h1 class="prj-head">
                    Career Development Programs organized by Training and Placement Cell
                </h1>
                <ul>
                    <li>
                        Personality Development Program
                    </li>
                    <li>
                        Communication Skills Program
                    </li>
                    <li>
                        Group Discussion Practice
                    </li>
                    <li>
                        Entrepreneurship Development Program
                    </li>
                    <li>
                        Mock Interview Sessions
                    </li>
                    <li>
                        In plant Training
                    </li>
                    <li>
                        Industry Internship Program

                    </li>
                </ul>
            </div>
        </div>
    </div>
    <!--<div class="instructors">
    <div class="container-fluid">
        <div class="instructors-head">
            <h1 class="prj-head">Our Star Performer</h1>
        </div>
        <ul id="slider-perform" class="content-slider">
            <li>
                <div class="instructors-images">
                    <div class="instruct-box">
                        <div class="img-box">
                             <img src="./assets/images/star.jpg" /> 
                        </div>
                        <div class="instruct-data">
                            <h3 class="head">Full Name</h3>
                            <p class="head">Mechanical Engineering</p>
                            <p class="head">CGPA Score: 9</p>
                            <img src="./assets/images/in.png" class="in-icon" />
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div class="instructors-images">
                    <div class="instruct-box">
                        <div class="img-box">
                             <img src="./assets/images/star.jpg" /> 
                        </div>
                        <div class="instruct-data">
                            <h3 class="head">Full Name</h3>
                            <p class="head">Mechanical Engineering</p>
                            <p class="head">CGPA Score: 9</p>
                            <img src="./assets/images/in.png" class="in-icon" />
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div class="instructors-images">
                    <div class="instruct-box">
                        <div class="img-box">
                             <img src="./assets/images/star.jpg" /> 
                        </div>
                        <div class="instruct-data">
                            <h3 class="head">Full Name</h3>
                            <p class="head">Mechanical Engineering</p>
                            <p class="head">CGPA Score: 9</p>
                            <img src="./assets/images/in.png" class="in-icon" />
                        </div>
                    </div>
                </div>
            </li>
            <li>
                <div class="instructors-images">
                    <div class="instruct-box">
                        <div class="img-box">
                             <img src="./assets/images/star.jpg" /> 
                        </div>
                        <div class="instruct-data">
                            <h3 class="head">Full Name</h3>
                            <p class="head">Mechanical Engineering</p>
                            <p class="head">CGPA Score: 9</p>
                            <img src="./assets/images/in.png" class="in-icon" />
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div class="lSAction partners-controls">
            <a class="lSPrev" id="partnersPrev"><img src="./assets/images/arrow-left.png" /></a>
            <a class="lSNext" id="partnersNext"><img src="./assets/images/arrow-right.png" /></a>
        </div>
    </div>
</div>-->
    <div class="ban-up"></div>
    <div class="placestart-head">
        <div class="container">
            <div class="placement-main">
                <h1 class="prj-head">Our Recruiter</h1>
                <div class="placement-area">
                    <ul id="slider-partners" class="content-slider">
                        <li>
                            <div class="placement-box">
                                <div class="placein-box">
                                    <img src="assets/images/logos/Startup_Village.jpg" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="placement-box">
                                <div class="placein-box">
                                    <img src="assets/images/logos/sap.jpg" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="placement-box">
                                <div class="placein-box">
                                    <img src="assets/images/logos/eme.png" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="placement-box">
                                <div class="placein-box">
                                    <img src="assets/images/logos/bramma.png" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="placement-box">
                                <div class="placein-box">
                                    <img src="assets/images/logos/icon.jpg" />
                                </div>
                            </div>
                        </li>
                        <li>
                            <div class="placement-box">
                                <div class="placein-box">
                                    <img src="assets/images/logos/techno.png" />
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="lSAction partners-controls">
                        <a class="lSPrev" id="partnersPrev"><img src="./assets/images/arrow-left.png" /></a>
                        <a class="lSNext" id="partnersNext"><img src="./assets/images/arrow-right.png" /></a>
                    </div>
                </div>
            </div>
        </div>
    </div>