import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';

@Component({
  selector: 'app-view-grievance',
  templateUrl: './view-grievance.component.html',
  styleUrls: ['./view-grievance.component.css']
})
export class ViewGrievanceComponent implements OnInit {
  AllGrievance=[];

  EnquiriesColumns: string[] = ['SortId', 'NameOfApplicant', 'Class', 'Semester', 'Department', 'RollNo', 'TypeOfGrievance', 'OccurredDate', 'ComplaintDescription', 'RootCause'];
  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,) {
    this.datalistService.loaderActive = true;}

  ngOnInit(): void {
    this.databaseOperationService.getAllGrievance().subscribe((data: any) => {
      this.AllGrievance = data;
      this.datalistService.loaderActive = false;
    });
  }

}
