import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';
import { ViewFacultiesFeedbackPopupComponent } from '../../../popup/faculties/view-faculties-feedback-popup/view-faculties-feedback-popup.component';

@Component({
  selector: 'app-view-faclity-feedback',
  templateUrl: './view-faclity-feedback.component.html',
  styleUrls: ['./view-faclity-feedback.component.css']
})
export class ViewFaclityFeedbackComponent implements OnInit {
  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,
    private titleService: Title) {
    this.datalistService.loaderActive = true;
  }

  Columns: string[] = ['SortId', 'FacultiesName', 'Operation'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ngOnInit(): void {
    this.getAllFaculties()
  }
  getAllFaculties() {
    this.Columns = ['SortId', 'FacultiesName', 'Operation'];
    this.databaseOperationService.getAllFaculties().subscribe((data: any) => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.datalistService.loaderActive = false;
    })
  }
  viewFeedback(FacultiesId){
    let dialogRef: MatDialogRef<ViewFacultiesFeedbackPopupComponent> = this.matDialog.open(ViewFacultiesFeedbackPopupComponent, {
      width: '80%',
      height: '600px',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.FacultiesId = FacultiesId;
  }
}
