import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {

  MyAccount: boolean;
  Security: boolean;
  Enquiries: boolean;
  Subscribers: boolean;
  JobApplications: boolean;
  AllSubscribes = [];
  data: Object;
  subscribersColumns: string[] = ['SortId', 'EmailId', 'SubscribedOn'];
  jobApplicationColumns: string[] = ['SortId', 'Name', 'EmailId', 'Phone', 'Address', 'AppliedOn'];
  EnquiriesColumns: string[] = ['SortId', 'Name', 'Email', 'Message', 'MessageOn'];
  ConsultationColumns: string[] = ['SortId', 'Name', 'EmailId', 'Phone', 'ServiceType', 'Subject', 'Message'];
  AllJob = [];
  AllJobApplication = [];
  AllEnquiries = [];
  formEmailId: any;
  formPassword: any;
  formEmailIdInvalid: boolean;
  formEmailIdSubmitAttempt: boolean;
  formPasswordInvalid: boolean;
  formPasswordSubmitAttempt: boolean;
  GEMSocialMedia = [];
  Consultation: any;
  AllConsultation = [];
  ScopeAssessment: boolean;

  constructor(
    public datalistService: DatalistService,
    public sanitizer: DomSanitizer,
    private matDialog: MatDialog,
    private databaseOperationService: DatabaseOperationService,
    private formBuilder: FormBuilder,
    public functionService: FunctionService,
    private router: Router,
    private titleService: Title) {
    this.titleService.setTitle("Advent Solutions | My account");
    this.setContent('1');
  }

  ngOnInit() {
    // this.getSocialMedia();
  }
  // getRecord(element){
  //   let dialogRef: MatDialogRef<ContactFormDetailsPopupComponent> = this.matDialog.open(ContactFormDetailsPopupComponent, {
  //     width: '60%',
  //     height: 'auto',
  //     maxHeight: 'auto',
  //     disableClose: true,
  //     panelClass: 'custom-dialog-container',
  //     hasBackdrop: true,
  //   });
  //   dialogRef.componentInstance.MessageDetails = element;
  //   return dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       this.getDetails();
  //     }
  //   });
  // }
  
  setContent(step) {
    this.datalistService.loaderActive = true;
    switch (step) {
      case '1':
        this.setStep(true, false, false, false, false, false, false);
        // this.databaseOperationService.getAllSubscribes().subscribe((data: any) => {
        //   this.AllSubscribes = data;
        // })
        // this.databaseOperationService.getAllEnquiries().subscribe((data: any) => {
        //   this.AllEnquiries = data;
        this.datalistService.loaderActive = false;
        // });
        break;

      case '2':
        this.setStep(false, true, false, false, false, false, false);
        this.datalistService.loaderActive = false;

        this.formEmailId = this.formBuilder.group({
          LoginId: sessionStorage.getItem('sessionLoginId'),
          CurrentEmailId: ['', [Validators.required, Validators.email]],
          NewEmailId: ['', [Validators.required, Validators.email]],
        });

        this.formPassword = this.formBuilder.group({
          LoginId: sessionStorage.getItem('sessionLoginId'),
          CurrentPassword: ['', Validators.required],
          NewPassword: ['', Validators.required],
          RetypePassword: ['', Validators.required],
        });
        break;

      case '3':
        this.setStep(false, false, true, false, false, false, false);
        // this.databaseOperationService.getAllEnquiries().subscribe((data: any) => {
        //   this.AllEnquiries = data;
        //   this.datalistService.loaderActive = false;
        // });
        break;

      case '4':
        this.setStep(false, false, false, true, false, false, false);
        // this.databaseOperationService.getAllSubscribes().subscribe((data: any) => {
        //   this.AllSubscribes = data;
        //   this.datalistService.loaderActive = false;
        // })
        break;

      case '5':
        this.setStep(false, false, false, true, false, false, false);
        // this.databaseOperationService.getAllSubscribes().subscribe((data: any) => {
        //   this.AllSubscribes = data;
        //   this.datalistService.loaderActive = false;
        // })
        break;

      case '6':
        this.setStep(false, false, false, false, false, true, false);
        // this.databaseOperationService.getAllConsultation().subscribe((data: any) => {
        //   this.AllConsultation = data;
        //   this.datalistService.loaderActive = false;
        // })
        break;

      case '7':
        this.setStep(false, false, false, false, false, false, true);
        // this.databaseOperationService.getAllConsultation().subscribe((data: any) => {
        //   this.AllConsultation = data;
        //   this.datalistService.loaderActive = false;
        // })
        break;

      default:
        break;
    }
  }
  setStep(MyAccount: boolean, Security: boolean, Enquiries: boolean, Subscribers: boolean, JobApplications: boolean, Consultation: boolean, ScopeAssessment: boolean) {
    this.MyAccount = MyAccount;
    this.Security = Security;
    this.Enquiries = Enquiries;
    this.Subscribers = Subscribers;
    this.JobApplications = JobApplications;
    this.Consultation = Consultation;
    this.ScopeAssessment = ScopeAssessment;
  }
  getDetails() {
    // this.databaseOperationService.getDetails().subscribe((data: any) => {
    //   this.datalistService.SiteDetails = data;
    //   this.datalistService.loaderActive = false;
    // });
  }
  // editContactDetails() {
  //   let dialogRef: MatDialogRef<ContactDetailsPopupComponent> = this.matDialog.open(ContactDetailsPopupComponent, {
  //     width: '60%',
  //     height: 'auto',
  //     maxHeight: 'auto',
  //     disableClose: true,
  //     panelClass: 'custom-dialog-container',
  //     hasBackdrop: true,
  //   });
  //   return dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       this.getDetails();
  //     }
  //   });
  // }
  getJobApplication(JobId) {
    return this.AllJobApplication.filter(element => {
      return element.JobId === JobId;
    });
  }
  onSubmitEmailId() {
    this.datalistService.loaderActive = true;
    this.formEmailIdInvalid = false;
    this.formEmailIdSubmitAttempt = false;
    if (this.formEmailId.valid) {
      try {
        this.formEmailId.value.LoginId = sessionStorage.getItem('sessionLoginId');
        this.databaseOperationService.updateEmailId(this.formEmailId.value).subscribe((data: any) => {
          if (data.result) {
            this.functionService.showProcessMessage("Updated successfully", true);
            sessionStorage.setItem('sessionEmailId', this.formEmailId.value.NewEmailId);
            this.datalistService.sessionEmailId = sessionStorage.getItem('sessionEmailId');
            this.formEmailId.reset();
            this.formEmailId.status = 'VALID';
            this.formEmailId.controls.NewEmailId.status = 'VALID';
            this.formEmailId.controls.CurrentEmailId.status = 'VALID';
            this.formEmailIdInvalid = false;
            this.formEmailIdSubmitAttempt = false;
          }
          else
            this.functionService.showProcessMessage("Incorrect email id", false);
          this.datalistService.loaderActive = false;

        })
      } catch (err) {
        this.formEmailIdInvalid = true;
        this.functionService.showProcessMessage("Please provide valid information", false);
        this.datalistService.loaderActive = false;
      }
    } else {
      this.formEmailIdSubmitAttempt = true;
      this.functionService.showProcessMessage("Please provide valid information", false);
      this.datalistService.loaderActive = false;
    }
  }
  onSubmitPassword() {
    this.datalistService.loaderActive = true;
    this.formPasswordInvalid = false;
    this.formPasswordSubmitAttempt = false;
    if (this.formPassword.valid) {
      try {
        this.formPassword.value.LoginId = sessionStorage.getItem('sessionLoginId');
        this.databaseOperationService.updatePassword(this.formPassword.value).subscribe((data: any) => {
          if (!data) {
            this.functionService.showProcessMessage("Updated successfully", true);
            this.formPassword.reset();
            this.formPassword.status = 'VALID';
            this.formPassword.controls.CurrentPassword.status = 'VALID';
            this.formPassword.controls.NewPassword.status = 'VALID';
            this.formPassword.controls.RetypePassword.status = 'VALID';
            this.formPasswordInvalid = false;
            this.formPasswordSubmitAttempt = false;
          }
          else
            this.functionService.showProcessMessage(data.result, false);
          this.datalistService.loaderActive = false;

        })
      } catch (err) {
        this.formPasswordInvalid = true;
        this.functionService.showProcessMessage("Please provide valid information", false);
        this.datalistService.loaderActive = false;
      }
    } else {
      this.formPasswordSubmitAttempt = true;
      this.functionService.showProcessMessage("Please provide valid information", false);
      this.datalistService.loaderActive = false;
    }
  }
  // downloadEnqeries() {
  //   this.datalistService.loaderActive = true;
  //   this.databaseOperationService.exportEnquiries().subscribe((data: any) => {
  //     this.functionService.showProcessMessage(data['message'], data['result']);
  //     if (data['result']) {
  //       window.location.assign(`${this.functionService.getServerApi()}/documents/${data['filename']}`);
  //     }
  //     this.datalistService.loaderActive = false;
  //   })
  // }
  // downloadSubscribers() {
  //   this.datalistService.loaderActive = true;
  //   this.databaseOperationService.exportSubscribers().subscribe((data: any) => {
  //     this.functionService.showProcessMessage(data['message'], data['result']);
  //     if (data['result']) {
  //       window.location.assign(`${this.functionService.getServerApi()}/documents/${data['filename']}`);
  //     }
  //     this.datalistService.loaderActive = false;
  //   })
  // }
  // addSocialMedia() {
  //   let dialogRef: MatDialogRef<SocialMediaPopupComponent> = this.matDialog.open(SocialMediaPopupComponent, {
  //     width: '400px',
  //     height: 'auto',
  //     maxHeight: 'auto',
  //     disableClose: true,
  //     panelClass: 'custom-dialog-container',
  //     hasBackdrop: true,
  //   });
  //   return dialogRef.afterClosed().subscribe(result => {
  //     this.getSocialMedia();
  //   });
  // }
  // getSocialMedia() {
  //   this.databaseOperationService.getSocialMedia().subscribe((data: any) => {
  //     this.GEMSocialMedia = data;
  //     this.datalistService.loaderActive = false;
  //   });
  // }
  // editSocialMedia(id) {
  //   let dialogRef: MatDialogRef<SocialMediaPopupComponent> = this.matDialog.open(SocialMediaPopupComponent, {
  //     width: '400px',
  //     height: 'auto',
  //     maxHeight: 'auto',
  //     disableClose: true,
  //     panelClass: 'custom-dialog-container',
  //     hasBackdrop: true,
  //   });
  //   dialogRef.componentInstance.MediaId = id;
  //   return dialogRef.afterClosed().subscribe(result => {
  //     this.getSocialMedia();
  //   });
  // }
  // deleteSocialMedia(id) {
  //   let dialogRef: MatDialogRef<ProceedWarningComponent> = this.matDialog.open(ProceedWarningComponent, {
  //     width: 'auto',
  //     disableClose: true,
  //     panelClass: 'custom-dialog-container',
  //     hasBackdrop: true,
  //   });
  //   return dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       this.datalistService.loaderActive = true;
  //       this.databaseOperationService.deleteSocialMedia(id).subscribe((data) => {
  //         if (!data) {
  //           this.getSocialMedia();
  //           this.functionService.showProcessMessage("Social Media deleted successfully", true);
  //         } else {
  //           this.functionService.showProcessMessage("Social Media deleted unsuccessfully", false);
  //           this.datalistService.loaderActive = false;
  //         }
  //       });
  //     }
  //   });
  // }
}
