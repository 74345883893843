import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DatabaseOperationService } from '../../api/database/database-operation.service';
import { DatalistService } from '../../api/services/datalist.service';
import { FunctionService } from '../../api/services/function.service';
// for SEO
import { Title, Meta } from '@angular/platform-browser';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-online-admission',
  templateUrl: './online-admission.component.html',
  styleUrls: ['./online-admission.component.css']
})
export class OnlineAdmissionComponent implements OnInit {
     // for SEO
     title = 'Top MBA College in Kerala |JAI BHARATH SCHOOL OF MANAGEMENT STUDIES';

  onlineAdmisionForm: any;
  formInvalid: boolean;
  submitted: boolean;
  enquiryForm: any;
  SSLCCertificateLoaded: any;
  EquivalentCertificateLoaded: any;
  TransferCertificateLoaded: any;
  SignatureLoaded:any;
  PothoLoaded:any;

  SignatureImg="assets/images/image_upload_placeholder.jpg";
  PhotoImg="assets/images/image_upload_placeholder.jpg"
  constructor(
        // for SEO
        private titleService: Title,
        private metaTagService: Meta,

    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
     // for SEO
     this.titleService.setTitle(this.title);
     this.metaTagService.addTags([
       { name: 'keywords', content: 'top mba college in kerala , top colleges in kerala , top 10 mba colleges in kerala , top mba colleges in kerala accepting kmat score , which mba college gives best placement' },
       { name: 'description', content: 'JAI BHARATH SCHOOL OF MANAGEMENT STUDIES | Become a Global Executive with Internationally Compatible Curriculum. Apply for Admission. Top MBA College in Kerala' },
       { name: 'robots', content: 'index, follow' },      
       { name: 'viewport', content: 'width=device-width, initial-scale=1' },      
       { charset: 'UTF-8' }
     ]);
    this.setForm();
  }
  setForm() {
    this.SignatureLoaded='';
    this.PothoLoaded='';

    var currentDate = new Date()
    var day = currentDate.getDate()
    var month = currentDate.getMonth() + 1
    var year = currentDate.getFullYear()
    this.onlineAdmisionForm = this.formBuilder.group({     
      AdmissionDate: ['', Validators.required],
      CourseApplied:['MBA', Validators.required],      
      CandidateName: ['', Validators.required],
      ExamQualification:['', Validators.required],
      TestName:['', Validators.required],
      TestDate:['', Validators.required],
      RegisterNumber:['', Validators.required],
      TestMarks:['', Validators.required],
      TestRank:['', Validators.required],

      Part1RegisterNumber:['', Validators.required],
      Part1Marks:['', Validators.required],
      Part1maxMarks:['', Validators.required],
      Part1Persentage:['', Validators.required],
      Part2RegisterNumber:['', Validators.required],
      Part2Marks:['', Validators.required],
      Part2maxMarks:['', Validators.required],
      Part2Persentage:['', Validators.required],
      Part3RegisterNumber:['', Validators.required],
      Part3Marks:['', Validators.required],
      Part3maxMarks:['', Validators.required],
      Part3Persentage:['', Validators.required],
      Part4RegisterNumber:['', Validators.required],
      Part4Marks:['', Validators.required],
      Part4maxMarks:['', Validators.required],
      Part4Persentage:['', Validators.required],
      Part5RegisterNumber:['', Validators.required],
      Part5Marks:['', Validators.required],
      Part5maxMarks:['', Validators.required],
      Part5Persentage:['', Validators.required],
      Part6RegisterNumber:['', Validators.required],
      Part6Marks:['', Validators.required],
      Part6maxMarks:['', Validators.required],
      Part6Persentage:['', Validators.required],
      Part7RegisterNumber:[''],
      Part7Marks:[''],
      Part7maxMarks:[''],
      Part7Persentage:[''],
      Part8RegisterNumber:[''],
      Part8Marks:[''],
      Part8maxMarks:[''],
      Part8Persentage:[''],
      TotalRegisterNumber:[''],
      TotalMarks:[''],
      TotalmaxMarks:[''],
      TotalPersentage:[''],
      GrandRegisterNumber:[''],
      GrandMarks:[''],
      GrandmaxMarks:[''],
      GrandPersentage:[''],
      
      
      ExamName:[''],
      UniversityName:[''],
      SemesterMarks:[''],
      SemestermaxMarks:[''],
      SemesterPersentage:[''],
      Address:[''],
      StreetName:[''],
      PostOffice:[''],
      DistrictName:[''],
      PhoneNo:[''],
      MobileNo:[''],
      PinNo:[''],
      Email:['', [Validators.email]],
      Signature:[''],
      Photo:[''],

      PermenentAddress:[''],
      PermenentStreetName:[''],
      PermenentPostOffice:[''],
      PermenentDistrictName:[''],
      PermenentPhoneNo:[''],
      PermenentMobileNo:[''],
      PermenentPinNo:[''],
      PermenentEmail:['', [ Validators.email]],
      Nationality:[''],
      Gender:[''],
      Religion:[''],
      Caste:[''],
      BloodGroup:[''],
      DOB:[''],
      Activities:[''],
      Achivements:[''],
      Scholarships:[''],
      GuardianName:[''],
      GuardianAddress:[''],
      ContactNumber:[''],
      GuardianEmail:['', [ Validators.email]],
      GuardianOccupation:[''],
      GuardianIncome:[''],
      Native:[''],
      QualificationExam:['', Validators.required],
      Board:['', Validators.required],
      RegisterTime:['', Validators.required],
      InstitutionName:['', Validators.required],
      NoChances:['', Validators.required],
      EmployeeDetails:[''],
      SpecialFeature:[''],
      Reseravtion:[''],
      Community:[''],
      ReceiptNo:[''],
      ReceiptDate:[''],
      DDNo:[''],
      Date:[''],
      Amount:[''],
      BankName:[''],
      BranchName:[''],
      
    });
  }
  get formControl() { return this.onlineAdmisionForm.controls; }
  onSubmit() {
    this.formInvalid = false;
    this.submitted = false;
    if (this.onlineAdmisionForm.valid) {
      this.datalistService.loaderActive = true;
      this.databaseOperationService.saveOnlineAdmisionForm(this.onlineAdmisionForm.value).subscribe((data: any) => {
        let Id = data.OnlineAdmissionId;
        this.databaseOperationService.sendOnlineAdmisionConfirmation(this.onlineAdmisionForm.value.Email).subscribe((data)=>{
          console.log(data);
        })
        this.uploadFormFiles(this.SignatureLoaded, Id, 'Signature')
        this.uploadFormFiles(this.PothoLoaded, Id, 'Photo')
        // this.uploadImage(this.SSLCCertificateLoaded, Id, 'SSLCCertificate');
        // this.uploadImage(this.EquivalentCertificateLoaded, Id, 'EquivalentCertificate');
        // this.uploadImage(this.TransferCertificateLoaded, Id, 'TransferCertificate');
        this.setForm();
        this.functionService.showProcessMessage("Our Counsellor Will Connect With You ", true);
        this.datalistService.loaderActive = false;
      })
    } else {
      this.submitted = true;
      this.functionService.showProcessMessage("Please provide valid information", false);
      this.datalistService.loaderActive = false;
    }
  }
  onSSLCCertificateSelected(event) {
    this.SSLCCertificateLoaded = event.target.files[0];
  }
  onEquivalentCertificateSelected(event) {
    this.EquivalentCertificateLoaded = event.target.files[0];
  }
  onTransferCertificateSelected(event) {
    this.TransferCertificateLoaded = event.target.files[0];
  }
  onSignatureSelected(event) {
    this.SignatureLoaded = event.target.files[0];
    if(this.SignatureLoaded.size > 30000){
      this.SignatureLoaded ="";
      Swal.fire({
        title: 'Image size is too high',
        icon: 'warning',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      })
    }
  }
  onPhotoSelected(event) {
    this.PothoLoaded = event.target.files[0];
    if(this.PothoLoaded.size > 100000){
      this.PothoLoaded ="";
      Swal.fire({
        title: 'Image size is too high',
        icon: 'warning',
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      })
    }
  }
  uploadImage(fileLoaded, Id, FieldName) {
    let formData = new FormData();
    if (fileLoaded)
      formData.append('Image', fileLoaded, fileLoaded.name);
    this.databaseOperationService.saveOnlineAdmissionDocument(formData, Id, FieldName).subscribe((data: any) => {

    });
  }
  uploadFormFiles(fileLoaded, Id, FieldName) {
    let formData = new FormData();
    if (fileLoaded)
      formData.append('Image', fileLoaded, fileLoaded.name);
      this.databaseOperationService.saveOnlineAdmissionDocument(formData, Id, FieldName).subscribe((data: any) => {

      });
  }
  
}