import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { DatalistService } from '../../api/services/datalist.service';

@Component({
  selector: 'app-image-crop-popup',
  templateUrl: './image-crop-popup.component.html',
  styleUrls: ['./image-crop-popup.component.css']
})
export class ImageCropPopupComponent implements OnInit {
  imageChangedEvent: any = '';
  croppedImage: any = '';
  fileToReturn: File;
  data: any;
  event: any;
  height: number;
  width: number;

  constructor(
    public httpClient: HttpClient,
    private matDialog: MatDialog,
    public globalDatalistService: DatalistService,
    public dialog: MatDialogRef<ImageCropPopupComponent>,) { }

  ngOnInit(): void {
    this.imageChangedEvent = this.event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.fileToReturn = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,
    )
    return this.fileToReturn;
  }
  upload() {
    this.dialog.close({'file':this.fileToReturn,'imageTemp':this.croppedImage});
  }
  close(){
    this.dialog.close(false);
  }
  base64ToFile(data, filename) {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }
  imageLoaded() {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
}
