<div _ngcontent-gmi-c28="" class="banner"><img _ngcontent-gmi-c28="" src="assets/images/grience-banner.jpg"></div>
<section class="content">
    <!-- page heading -->
    <div class="page-heading ">
        <h2>Grievance Redressal Cell</h2>
        <p>It is sensible to air a grievance instead of staying it bottled up. Protection of human rights is important
            for the all-around development of an individual’s temperament. To understand the first wants of the scholars
            and employees and to secure civil liberties for everyone, a Grievance Redressal Cell is already operating in
            the faculty since ordinal June 2009, consistent with tips of state, JBSMS has been ingrained. JBSMS is
            meant to seek out solutions for issues like harassment – any reasonably physical or mental harassment,
            complaints relating to room teaching – room management, completion of info, teaching ways, etc, if once they
            arise. JBSMS convenes conferences sporadically and takes steps to redress the grievance. The operation of
            the cell is to appear into the complaints lodged by any student of the faculty and choose their benefit. The
            Grievance Cell is additionally authorized to appear in matters of harassment. Anyone with a real grievance
            might approach the department members nose to nose, or in consultation with the officer-in-charge of
            Students' Grievance Cell. Just in case, if the person is unwilling to seem in self, grievances could also be
            written at the letterbox/ suggestion box. Grievances may be also sent through e-mail to the member/officer
            in charge of the Students' Grievance Cell.</p>
    </div>

    <!-- page heading -->
    <div class="page-heading ">
        <h2>Grievance Objectives </h2>
        <p>JBSMS has been created within the faculty to resolve problems associated with student’s issues, develop a
            responsive and responsible perspective among all the stakeholders so as to take care of a harmonious
            instructional atmosphere in the Institute. Following are the necessary grievance objectives of JBSMS:</p>
        <ul>
            <li>To develop associate structure framework to resolve Grievances of scholars and different stakeholders.
            </li>
            <li> To supply scholars accessible for immediate, problem free recourse to own their Grievances redressed.
            </li>
            <li>To enlighten the scholars on their duties and responsibilities to access edges due beneath the policies.
            </li>
            <li>To determine structured interactions with students to elicit info on their expectations.</li>
            <li>To spot general flaws within the style and administration of varied general insurance merchandise and to
                hunt solutions on that.</li>
            <li>To institute an observance mechanism to supervise the functioning of the Grievance Redressal Policy.
            </li>
            <li>Encouraging the scholars to specify their grievances / issues freely and albeit, with no worry of being
                exploited.</li>
            <li>Suggestion / criticism Box are put in within the body block, a place for writing their grievances and
                their suggestions for informing the lecturers / administration within the faculty.</li>
            <li>Ragging in any kind is strictly prohibited in and out of the doors of the campus. Any violation of
                ragging and disciplinary rules ought to be desperately delivered to the notice of the Principal.</li>

        </ul>
    </div>
    <!-- page heading -->
    <div class="page-heading ">
        <h2>Grievance Form </h2>
        <p class="breadcrumb">
            <a href="#">Home</a> / Grievance Committee
        </p>
    </div>
    <form [formGroup]="GrievanceForm" (ngSubmit)="onSubmit()" autocomplete="off">
        <div class="startformlisting grievanceform">

            <div class="row">
                <div class="col-md-6">
                    <label>Name of Applicant*</label>
                    <input type="text" formControlName="NameOfApplicant" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && formControl.NameOfApplicant.errors }"
                        required="required">
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <label>Class*</label>
                    <input type="text" formControlName="Class" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && formControl.Class.errors }" required="required">
                </div>
                <div class="col-md-3">
                    <label>Semester*</label>
                    <input type="text" formControlName="Semester" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && formControl.Semester.errors }" required="required">
                </div>
                <div class="col-md-3">
                    <label>Department*</label>
                    <select name="" formControlName="Department" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && formControl.Department.errors }" required="required">
                        <option value="">Select</option>
                        <option value="Master of Business Administration">Master of Business Administration</option>
                        <!-- <option value="Mechanical Engineering">Mechanical Engineering</option>
                        <option value="Civil Engineering">Civil Engineering</option>
                        <option value="Computer Science & Engineering">Computer Science & Engineering</option>
                        <option value="Electronics & Communication Engineering">Electronics & Communication Engineering
                        </option>
                        <option value="Electrical & Electronics Engineering">Electrical & Electronics Engineering
                        </option>
                        <option value="Polytechnic Civil Engineering">Polytechnic Civil Engineering</option>
                        <option value="Polytechnic Electrical & Electronics Engineering">Polytechnic Electrical &
                            Electronics Engineering</option>
                        <option value="Polytechnic Mechanical Engineering">Polytechnic Mechanical Engineering</option>
                        <option value="Polytechnic Architecture Engineering">Polytechnic Architecture Engineering
                        </option>
                        <option value="Polytechnic Automobile Engineering">Polytechnic Automobile Engineering</option> -->
                    </select>
                </div>
                <div class="col-md-3">
                    <label>Roll No*</label>
                    <input type="text" formControlName="RollNo" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && formControl.RollNo.errors }" required="required">
                </div>

            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>Type Of Grievance*</label>
                    <select name="" formControlName="TypeOfGrievance" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && formControl.TypeOfGrievance.errors }"
                        required="required">
                        <option value="">Select</option>
                        <option value="Grievance 1">Grievance 1</option>
                        <option value="Grievance 2">Grievance 2</option>
                        <option value="Grievance 3">Grievance 3</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label>Event Occurred Date*</label>
                    <input type="date" formControlName="OccurredDate" class="form-control"
                        [ngClass]="{ 'is-invalid': submitted && formControl.OccurredDate.errors }" required="required">
                </div>
                <div class="col-md-12">
                    <label>Complaint Description</label>
                    <textarea placeholder="" class="form-control text-box" cols="30" rows="5"
                        formControlName="ComplaintDescription"
                        [ngClass]="{ 'is-invalid': submitted && formControl.ComplaintDescription.errors }"></textarea>
                </div>
                <div class="col-md-12">
                    <label> Root Cause</label>
                    <textarea placeholder="" class="form-control text-box" cols="30" rows="5"
                        formControlName="RootCause"
                        [ngClass]="{ 'is-invalid': submitted && formControl.RootCause.errors }"></textarea>
                </div>
                <!-- <div class="col-md-6">
                    <label> Type Security Code*</label>
                    <input type="text">
                    
                </div> -->
                <div class="col-md-4">
                    <button class="btn">Submit</button>
                </div>


            </div>
        </div>
    </form>
</section>