<div class="main-head">
    <div class="head-line">
        <h1>Manage Notification</h1>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <a (click)="addNotification()" mat-raised-button color="primary" class="sm"><i class="fa fa-plus"
                    aria-hidden="true"></i></a>
        </div>
        <br />
        <br />
        <div class="col-md-12">
            <div class="manage-tbl">
                <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                    <ng-container matColumnDef="SortId">
                        <mat-header-cell *matHeaderCellDef width="80px"> No. </mat-header-cell>
                        <mat-cell *matCellDef="let element;let j = index">
                            {{ (j+1) + (myPaginator.pageIndex * myPaginator.pageSize) }}</mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Title">
                        <mat-header-cell *matHeaderCellDef> Title</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            {{element.Title}}

                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Link">
                        <mat-header-cell *matHeaderCellDef> Link</mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a href="{{element.Link}}" target="_blank">
                                {{element.Link}}
                            </a>
                        </mat-cell>
                    </ng-container>
                    <ng-container matColumnDef="Operation">
                        <mat-header-cell *matHeaderCellDef width="50px"> Operation </mat-header-cell>
                        <mat-cell *matCellDef="let element">
                            <a (click)="editNotification(element.SiteNotificationId)" class="sm" mat-raised-button
                                color="primary">
                                <i class="fa fa-edit" aria-hidden="true"></i>
                            </a>&nbsp;&nbsp;&nbsp;
                            <a (click)="delete_Notification(element.SiteNotificationId)" class="sm" mat-raised-button
                                color="warn">
                                <i class="fa fa-trash"></i>
                            </a>
                        </mat-cell>
                    </ng-container>
                    <mat-header-row *matHeaderRowDef="Columns"></mat-header-row>
                    <mat-row *matRowDef="let row; columns: Columns;"></mat-row>
                </mat-table>
                <mat-paginator #myPaginator [length]="25" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
                </mat-paginator>

            </div>
        </div>
    </div>
</div>