<section id="contact" class="contact">
    <div class="banner">

        <div class="container-fluid">
        <div class="row">
            <div class="col-md-4">
                <div class="box-form">
                        <form [formGroup]="formRecruiterConnect" (ngSubmit)="onSubmit()" autocomplete="off">
                        <div class="contact-innerbox">
                            <div class="row">
                                <div class="col-md-12">
                                    <h1>Quick Connect</h1>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <input type="text" placeholder="Name" formControlName="Name"
                                        [ngClass]="{ 'is-invalid': submitted && formControl.Name.errors }" class="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <input type="email" placeholder="Official Email Id" formControlName="EmailId"
                                        [ngClass]="{ 'is-invalid': submitted && formControl.EmailId.errors }"
                                            class="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <input type="text" placeholder="Mobile"  formControlName="Phone"
                                        [ngClass]="{ 'is-invalid': submitted && formControl.Phone.errors }" class="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <input type="text" placeholder="Organization Name" formControlName="OrganizationName"
                                        [ngClass]="{ 'is-invalid': submitted && formControl.OrganizationName.errors }"
                                            class="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <input type="text" placeholder="Job Title" formControlName="JobTitle"
                                        [ngClass]="{ 'is-invalid': submitted && formControl.JobTitle.errors }" class="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <input type="text" placeholder="City" formControlName="City"
                                        [ngClass]="{ 'is-invalid': submitted && formControl.City.errors }" class="form-control" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group sb">
                                        <input type="submit" class="btn btn-primary pull-right" value="REQUEST INFO" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
            </div>
            <div class="col-md-8">
                <div class="center">
                    <h1 class="">Fresher's Recruitment solution</h1>
                    <div class="parag">
                        <p>"Right skill Right attitude</p>
                        <p class="right-par">For your Right place"</p>
                        <p></p>
                    </div>
                </div>
            </div>

        </div>
        </div>
    </div>
</section>
<div content-jb>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="jb-head">
                    Talent Assessment System</h1>
                <p class="jb-p">
                    In the recent competitive world, where there is a huge pool of candidates, getting the right
                    employee with the right skill set is a massive job. Almost every candidate edges out his/her
                    competitor by just a fraction. Judging candidates’ capabilities and gathering the best workforce
                    with the required technical, cognitive, and behavioral skills is a challenge by itself. Recruiters
                    usually prefer to conduct unbiased recruitment tests giving them a clear picture of the technical
                    and the soft skills of the candidates.</p>
                <p class="jb-p"> Of lately, criteria for career opportunities have drastically changed. Change has
                    happened in areas such as technology and work environment, work culture,</p>

                <p class="jb-p"> and emotional and behavioral of candidates, affecting the overall selection process.
                    Keeping in mind the constant change that is happening every moment, the IT Professionals Welfare
                    Association has joined hands with Jaibharat College to come up with a Job Fitment Analysis, skill
                    development, and overall work culture training for students.
                </p>
                <p class="jb-p">The talent assessment center aims at making the recruitment procedure simpler than ever
                    before, be it in any number. We are a team of psychometricians, statisticians, and industry leaders
                    who are researching and developing the most effective digital assessment structure for various roles
                    in any industry or organization. We create a Talent Pool, segregated based on such assessments, and
                    present them for recruitment drives for various companies. We have helped leading companies in IT/
                    ITES, Pharmacy, Manufacturing, BFSI, and Automobiles, and other industries to get the apt workforce.
                </p>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    <div class="solution_wrapper">
        <div class="row">
            <div class="col-sm-12">
                <div class="region region-triptych-first">
                    <div id="block-block-121" class="block block-block">


                        <div class="content">
                            <div class="row" style="background-image: linear-gradient(to right, #0e4890, #ffac00);">
                                <div class="col-md-5" style="padding:0;">
                                    <div class="sec-title">
                                        <div class="benf">
                                            <h2 class="title_sub mobml30 mlfix1366" style="margin-left:18.8%">Benefits
                                            </h2>
                                        </div>
                                    </div>
                                    <div class="sec-metric">
                                        <div class="big-t">

                                            <ul>
                                                <li>
                                                    <span class="fas fa-plus-square"></span>
                                                    <span>
                                                        Increase the valudity of
                                                        recruitment
                                                        decision by up to 40%
                                                    </span>
                                                </li>
                                                <li>
                                                    <span class="fas fa-plus-square"></span>
                                                    <span>
                                                        Enhance of employee capability level to meet current and future
                                                        business needs
                                                    </span>
                                                </li>
                                                <li>
                                                    <span class="fas fa-plus-square"></span>
                                                    <span>
                                                        Ensure consistency in recruitment by taking away human bias
                                                    </span>
                                                </li>
                                                <li>
                                                    <span class="fas fa-plus-square"></span>
                                                    <span>
                                                        Objective and formalize the interview process
                                                    </span>
                                                </li>
                                                <li>
                                                    <span class="fas fa-plus-square"></span>
                                                    <span>
                                                        Increase the sucess in the role
                                                    </span>
                                                </li>
                                                <li>
                                                    <span class="fas fa-plus-square"></span>
                                                    <span>
                                                        Reduced the risk of a bad hire
                                                    </span>
                                                </li>
                                                <li>
                                                    <span class="fas fa-plus-square"></span>
                                                    <span>
                                                        Access to additional information on candidates
                                                    </span>
                                                </li>

                                            </ul>


                                            <!--<div class="subtitle mobml30 mlfix1366" style="margin-left:18.8%; margin-bottom:40px;">assessment <span>delivered</span></div>-->
                                            <!--<div class="mleft mobml30 mlfix1366" style="margin-left:18.8%">
                                                <div class="mbox2 mobml0 mobmt20" style="padding-bottom:20px;">35%
                                                    <br><span>Reduction in attrition</span>
                                                </div>
                                                <div class="mbox2 mobml0 mobmt20" style="padding-bottom:20px;">30%
                                                    <br><span>Improvement in employee engagement</span>
                                                </div>
                                                <div class="mbox2 mt20 mobml0" style="padding-bottom:20px;">
                                                    40%<br><span>Faster turnaround time</span></div>
                                                <div style="clear:both;"></div>
                                            </div>-->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-7" style="padding:0;">
                                    <div class="hire-title">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img class="hire-img" src="assets/images/logos/jb1.png">
                                                    </div>
                                                    <div class="col-md-9">
                                                        <h5> <b>IN-DEPTH EXPERTISE</b></h5>
                                                        <p class="content-box">A pioneer - highly experienced in testing
                                                            and assessments for
                                                            recruitment needs</p>

                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img class="hire-img" src="assets/images/logos/jb2.png">
                                                    </div>
                                                    <div class="col-md-9">
                                                        <h5><b> END-TO-END ASSESSMENT </b></h5>
                                                        <p class="content-box">Unique suite of recruitment assessment
                                                            services to assess domain
                                                            knowledge, specialized skill sets, cognitive ability and
                                                            personality
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img class="hire-img" src="assets/images/logos/jb3.png">
                                                    </div>
                                                    <div class="col-md-9">
                                                        <h5><b>SEAMLESS
                                                                ASSESSMENT DELIVERY</b></h5>
                                                        <p class="content-box">Intelligent assessment automation
                                                            platforms for smooth assessment
                                                            delivery on a large scale</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img class="hire-img" src="assets/images/logos/jb4.png">
                                                    </div>
                                                    <div class="col-md-9">
                                                        <h5><b>RESEARCH-BASED ASSESSMENT</b></h5>
                                                        <p class="content-box"> Dedicated in-house test content research
                                                            and development team for
                                                            developing proven assessment and test methodologies</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <img class="hire-img" src="assets/images/logos/jb5.png">
                                                    </div>
                                                    <div class="col-md-9">
                                                        <h5><b>CERTIFIED PROCESSES</b></h5>
                                                        <p class="content-box">ISO 9001:2008 & 27001:2013 certified
                                                            process for testing and
                                                            assessment</p>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="jb-contain">
    <div class="container">

        <div class="row">

            <div class="col-md-12">

                <div class="jb-title">

                    <h1 class="head-jb"> Job Fitment Analysis System?</h1>
                    <ul>
                        <li>More than 50% of Recruiters say they can’t make good hires because of lengthy hiring
                            procedures.</li>
                        <li>A majority of Fortune 500 executives don’t believe that their companies recruit highly
                            talented people.</li>
                        <li>Managers and senior executives who believe their current acquisition and retention
                            strategies will work.</li>
                        <li>Almost three-fourths of hiring managers say that attracting top talents is their greatest
                            challenge.</li>
                        <li>Half of the employers who currently open positions and are not able to find a qualified
                            candidate.</li>
                        <li>Numerous employers have open positions for 12 weeks or longer.</li>
                        <li>We target at transforming a candidate to be job ready, in all areas, to be a successful and
                            valuable employee to any employer. Welcome to all companies, irrespective of the industry of
                            function. We present you a very talented pool of well balanced candidates.
                        </li>

                    </ul>


                </div>

            </div>
        </div>
    </div>

</div>