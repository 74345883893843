import { Component, OnInit } from '@angular/core';
// for SEO
import { Title, Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
   // for SEO
   title = 'Top MBA College in Kerala |JAI BHARATH SCHOOL OF MANAGEMENT STUDIES';

  constructor(
      // for SEO
      private titleService: Title,
      private metaTagService: Meta,
  ) { }

  ngOnInit( ): void {
      // for SEO
      this.titleService.setTitle(this.title);
      this.metaTagService.addTags([
        { name: 'keywords', content: 'top mba college in kerala , top colleges in kerala , top 10 mba colleges in kerala , top mba colleges in kerala accepting kmat score , which mba college gives best placement' },
        { name: 'description', content: 'JAI BHARATH SCHOOL OF MANAGEMENT STUDIES | Become a Global Executive with Internationally Compatible Curriculum. Apply for Admission. Top MBA College in Kerala' },
        { name: 'robots', content: 'index, follow' },      
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },      
        { charset: 'UTF-8' }
      ]);
  }

}
