import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';
import { ViewCareerPopupComponent } from '../../../popup/career/view-career-popup/view-career-popup.component';

@Component({
  selector: 'app-view-career',
  templateUrl: './view-career.component.html',
  styleUrls: ['./view-career.component.css']
})
export class ViewCareerComponent implements OnInit {
  AllCareer=[];

  EnquiriesColumns: string[] = ['SortId', 'Name', 'Email','Phone','PostFor', 'CreatedDate','Operation'];
  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,) {
    this.datalistService.loaderActive = true;}

  ngOnInit(): void {
    this.databaseOperationService.getAllCareer().subscribe((data: any) => {
      this.AllCareer = data;
      this.datalistService.loaderActive = false;
    });
  }
  viewCareer(CareerId){

    let dialogRef: MatDialogRef<ViewCareerPopupComponent> = this.matDialog.open(ViewCareerPopupComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.CareerId = CareerId;
    return dialogRef.afterClosed().subscribe(result => {
    });
  }
}
