import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatabaseOperationService } from '../../api/database/database-operation.service';
import { DatalistService } from '../../api/services/datalist.service';
import { FunctionService } from '../../api/services/function.service';
// for SEO
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-students-feedback',
  templateUrl: './students-feedback.component.html',
  styleUrls: ['./students-feedback.component.css']
})
export class StudentsFeedbackComponent implements OnInit {
   // for SEO
   title = 'Top MBA College in Kerala |JAI BHARATH SCHOOL OF MANAGEMENT STUDIES';

  formFeedBackDetails: any;
  formInvalid: boolean;
  submitted: boolean;
  AllCollege: any;
  AllCourse: any;
  AllFaculties: any;

  constructor(
      // for SEO
      private titleService: Title,
      private metaTagService: Meta,

    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,
    // private titleService: Title
    ) {
    this.datalistService.loaderActive = true;
  }


  ngOnInit() {
    // for SEO
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'keywords', content: 'top mba college in kerala , top colleges in kerala , top 10 mba colleges in kerala , top mba colleges in kerala accepting kmat score , which mba college gives best placement' },
      { name: 'description', content: 'JAI BHARATH SCHOOL OF MANAGEMENT STUDIES | Become a Global Executive with Internationally Compatible Curriculum. Apply for Admission. Top MBA College in Kerala' },
      { name: 'robots', content: 'index, follow' },      
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },      
      { charset: 'UTF-8' }
    ]);
    this.databaseOperationService.getAllCollege().subscribe((data: any) => {
      this.AllCollege = data;
    });
    this.setForm();
  }
  getAllCourse(CollegeId) {
    this.databaseOperationService.getAllCourse(CollegeId).subscribe((data: any) => {
      this.AllCourse = data;
      this.datalistService.loaderActive = false;
    })
  }
  changeCollege(event) {
    this.datalistService.loaderActive = true;
    this.getAllCourse(event.target.value)
  }
  getAllFaculities(CourseId) {
    this.databaseOperationService.getAllFacultiesByCourse(CourseId).subscribe((data: any) => {
      this.AllFaculties = data;
      this.datalistService.loaderActive = false;
    })
  }
  changeCourse(event) {
    this.datalistService.loaderActive = true;
    this.getAllFaculities(event.target.value)
  }
  setForm() {
    this.formFeedBackDetails = this.formBuilder.group({
      FeedbackId: [],
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      Collage: ['', Validators.required],
      Courses: ['', Validators.required],
      Faculties: ['', Validators.required],
      WasWellPrepared: ['', Validators.required],
      InterestingAndInformative: ['', Validators.required],
      GoodExplaining: ['', Validators.required],
      EffectiveInLeading: ['', Validators.required],
      ReceptiveToStudentQuestion: ['', Validators.required],
      StimulatedInterestInSubject: ['', Validators.required],
      StimulatedDiscussionOnSubject: ['', Validators.required],
      StimulatedMeToThink: ['', Validators.required],
      GoodRapport: ['', Validators.required],
      AvailableToQuestionInOfficeHours: ['', Validators.required],
      OtherComment: ['', Validators.required],
    });
    this.datalistService.loaderActive = false;
  }
  get formControl() { return this.formFeedBackDetails.controls; }
  onSubmit() {
    console.log(this.formFeedBackDetails.value);
    this.datalistService.loaderActive = true;
    this.formInvalid = false;
    this.submitted = false;
    if (this.formFeedBackDetails.valid) {
      this.databaseOperationService.saveFeedBackForm(this.formFeedBackDetails.value).subscribe((data: any) => {
        this.functionService.showProcessMessage("Message sent successfully", true);

        this.setForm();
        this.datalistService.loaderActive = false;
      })
    } else {
      this.submitted = true;
      this.functionService.showProcessMessage("Please provide valid information", false);
      this.datalistService.loaderActive = false;
    }
  }

}
