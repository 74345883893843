import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';

@Component({
  selector: 'app-enqury-form-popup',
  templateUrl: './enqury-form-popup.component.html',
  styleUrls: ['./enqury-form-popup.component.css']
})
export class EnquryFormPopupComponent implements OnInit {
  Course: string;
  enquiryForm: any;
  formInvalid: boolean;
  submitted: boolean;

  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    private formBuilder: FormBuilder,
    public dialog: MatDialogRef<EnquryFormPopupComponent>,
    ) { }

  ngOnInit(): void {
    this.enquiryForm = this.formBuilder.group({
      Name: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      Phone: ['', Validators.required],
      Course: [this.Course],
      State: ['', Validators.required],
      City: ['', Validators.required],
    });
  }
  get formControl() { return this.enquiryForm.controls; }
  onSubmit() {
    this.datalistService.loaderActive = true;
    this.formInvalid = false;
    this.submitted = false;
    if (this.enquiryForm.valid) {
      // this.databaseOperationService.sendContactForm(this.enquiryForm.value).subscribe((data: any) => {
      // });
      this.databaseOperationService.saveCourseEnquiryForm(this.enquiryForm.value).subscribe((data: any) => {
        this.enquiryForm = this.formBuilder.group({
          Name: ['', Validators.required],
          Email: ['', [Validators.required, Validators.email]],
          Phone: ['', Validators.required],
          Course: [this.Course],
          State: ['', Validators.required],
          City: ['', Validators.required],
        });
        this.dialog.close(true);
        this.functionService.showProcessMessage("Our Counsellor Will Connect With You ", true);
        this.datalistService.loaderActive = false;
      })
    } else {
      this.submitted = true;
      this.functionService.showProcessMessage("Please provide valid information", false);
      this.datalistService.loaderActive = false;
    }
  }
}
