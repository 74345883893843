
<div class="container">
    <div class="enquir-form">
        <div class="row">
            <div class="col-md-12">
                
            </div>

        </div>

        <form>
            <h1 class="sign-in">SIGN IN</h1>
           
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <input type="text"  class="form-control control-box" placeholder="Enter your Email" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <input type="text"  class="form-control control-box"
                            placeholder="Enter your password" />
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <div class="are-you">
                           <p>Are You ?</p>
                        </div>
                        <div class="select-one">
                        <input type="radio" name="type"/>
                        <span>Student</span> 
                        <input type="radio" name="type"/>
                        <span>Staff</span> 
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="submit-btn">
                        <button class="btn btn-primary">SIGN IN</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
