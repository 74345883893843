import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatalistService {
  tabActive="";
  PHP_API_SERVER: string;
  PHP_API_ADMIN: string;
  IMGAE_PATH: string;
  DOC_PATH: string;
  messageAutoPopupData: string;
  messageAutoPopuSuccess= false;
  sessionUserData= false;
  cart_count: string;
  sessionData=false;
  urlPattern = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
  mobileNumberPattern = "^((\\+971-?)|0)?[0-9]$";
  anyMobileNumberPattern = /^\(?([+|0-9]{1})\)?([0-9]{8})?([0-9]{1})?([0-9]{1})?([0-9]{1})?([0-9]{1})?([0-9]{1})?([0-9]{1})?([0-9]{1})?([0-9]{1})$/;
  numberPattern=/[^0-9\.]/g;
  loaderActive= false;
  uploadActive= false;
  uploadStatus=0;
  uploadPrimaryMessage:string;
  uploadSecondaryMessage:string;
  SiteDetails: any;
  sessionEmailId: string;
  extraTimeLoadWarning= false;
  AllGallery: any;
  isOpenNoti=true;
  constructor() { 
    if(sessionStorage.getItem('sessionEmailId')){
      this.sessionData=true;
    }
  }
}
