import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';

@Component({
  selector: 'app-view-faculties-feedback-popup',
  templateUrl: './view-faculties-feedback-popup.component.html',
  styleUrls: ['./view-faculties-feedback-popup.component.css']
})
export class ViewFacultiesFeedbackPopupComponent implements OnInit {
  FacultiesId: string;
  FacultiesDetail: any;
  AllOtherCommentsByFaculties: any;

  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,) {
    this.datalistService.loaderActive = true;
  }

  ngOnInit(): void {
    this.databaseOperationService.getFacultiesFeedbackById(this.FacultiesId).subscribe((data: any) => {
      this.FacultiesDetail = data;
      this.datalistService.loaderActive = false;
    })
    this.databaseOperationService.getAllOtherCommentsByFaculties(this.FacultiesId).subscribe((data: any) => {
      this.AllOtherCommentsByFaculties = data;
    })
  }
  updateColor(progress) {
    if (progress < 26) {
      return 'poor';
    } else if (progress > 25 && progress < 51) {
      return 'average';
    } else if (progress > 50 && progress < 76) {
      return 'good';
    } else {
      return 'v-good';
    }
  }
}
