import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';

@Component({
  selector: 'app-faculties-form-popup',
  templateUrl: './faculties-form-popup.component.html',
  styleUrls: ['./faculties-form-popup.component.css']
})
export class FacultiesFormPopupComponent implements OnInit {
  FacultiesId: string;
  AllCollege = [];
  FacultiesDetail: any;
  AllCourse = [];
  FacultiesForm: any;
  submitted: boolean;
  formTitle: string;
  buttonLabel: string;

  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,
    public dialog: MatDialogRef<FacultiesFormPopupComponent>,
    private titleService: Title) {
    this.datalistService.loaderActive = true;
  }

  ngOnInit(): void {

    this.FacultiesForm = this.formBuilder.group({
      FacultiesId: [''],
      FacultiesName: ['', Validators.required],
      CollegeId: ['', Validators.required],
      CourseId: ['', Validators.required],
    });
    this.databaseOperationService.getAllCollege().subscribe((data: any) => {
      this.AllCollege = data;
    });
    this.submitted = false;
    this.formTitle = 'Add';
    this.buttonLabel = 'Submit';
    if (this.FacultiesId) {
      this.formTitle = 'Edit';
      this.buttonLabel = 'Update';
      this.databaseOperationService.getFacultiesDetailById(this.FacultiesId).subscribe((data: any) => {
        this.FacultiesDetail = data;
        this.getAllCourse(this.FacultiesDetail.CollegeId)
      });
    } else {
      this.datalistService.loaderActive = false;
    }

  }
  getAllCourse(CollegeId) {
    this.databaseOperationService.getAllCourse(CollegeId).subscribe((data: any) => {
      this.AllCourse = data;
      this.FacultiesForm.patchValue(this.FacultiesDetail);
      this.datalistService.loaderActive = false;
    })
  }
  changeCollege(event) {
    this.getAllCourse(event.target.value)
  }
  get formControl() { return this.FacultiesForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.FacultiesForm.invalid) {
      this.functionService.showProcessMessage("Provide vaild information", false);
      return;
    }

    this.save();
  }
  save() {
    this.datalistService.loaderActive = true;
    this.databaseOperationService.saveFaculties(this.FacultiesForm.value).subscribe(data => {
      this.datalistService.loaderActive = false;
      if (data) {
        if (!this.FacultiesForm.value.FacultiesId) {
          this.functionService.showProcessMessage("New Faculties Added Successfully", true);
        } else {
          this.functionService.showProcessMessage("Faculties Updated Successfully", true);
        }
        this.dialog.close(true);
      } else {
        this.functionService.showProcessMessage("An Error Occurred", false);
      }
    })
  }
}
