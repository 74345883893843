import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';

@Component({
  selector: 'app-course-form-popup',
  templateUrl: './course-form-popup.component.html',
  styleUrls: ['./course-form-popup.component.css']
})
export class CourseFormPopupComponent implements OnInit {
  CourseId: string;
  CourseForm: any;
  submitted: boolean;
  formTitle: string;
  buttonLabel: string;
  CollegeId: string;

  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,
    public dialog: MatDialogRef<CourseFormPopupComponent>,
  ) { }

  ngOnInit(): void {

    this.CourseForm = this.formBuilder.group({
      CourseName: ['', Validators.required],
      CourseId: [''],
      CollegeId: [this.CollegeId],
    });
    this.submitted = false;
    this.formTitle = 'Add';
    this.datalistService.loaderActive = true;
    this.buttonLabel = 'Submit';
    if (this.CourseId) {
      this.formTitle = 'Edit';
      this.buttonLabel = 'Update';
      this.databaseOperationService.getCourseDetailById(this.CourseId).subscribe((data: any) => {
        data = data;
        this.CourseForm.patchValue(data);
        this.datalistService.loaderActive = false;
      })
    }else{
      this.datalistService.loaderActive = false;
    }
  }
  get formControl() { return this.CourseForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.CourseForm.invalid) {
      this.functionService.showProcessMessage("Provide vaild information", false);
      return;
    }

    this.save();
  }
  save() {
    this.datalistService.loaderActive = true;
    this.databaseOperationService.saveCourse(this.CourseForm.value).subscribe(data => {
      this.datalistService.loaderActive = false;
      if (data) {
        if (!this.CourseForm.value.CourseId) {
          this.functionService.showProcessMessage("New Course Added Successfully", true);
        } else {
          this.functionService.showProcessMessage("Course Updated Successfully", true);
        }
        this.dialog.close(true);
      } else {
        this.functionService.showProcessMessage("An Error Occurred", false);
      }
    })
  }

}
