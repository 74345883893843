<header>
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-6">
        <div class="logo">
          <img src="assets/images/logo.png">
        </div>
      </div>
      <div class="col-lg-6 col-6 ">
        <div class="head-title">
          <h2><a href="#"><span class="website-link">GO TO OUR WEB SITE</span><img src="assets/images/title.png"></a>
          </h2>
        </div>
      </div>

    </div>

  </div>
</header>
<section>
  <div class="main-banner">
    <div class="container">
      <div class="row">
        <div class="col-lg-8">
          <div class="ban-cap">
            <h3>KERALA’S FIRST <b>JOB FITMENT</b> ANALYSIS</h3>
            <h3>PROGRAM FOR <b>MANAGEMENT PROFESSIONALS</b></h3>
            <!-- <h5>Upgrade yourself as a hireable IT Professional. </h5>
              <h5>Now @ <span class="cross">5000 Rs</span> 2500 Rs</h5> -->
            <div class="sub-btn mt-5 mb-5" style=" margin: auto;">
              <button class="btn btn-primary" id="form-btn"
                (click)="commonService.openEnquiryForm('Job Fitment Program')">Book now</button>
            </div>
          </div>
        </div>
        <!-- <div class="col-lg-4">
            <div class="ban-img">
              <img src="assets/images/Asset 1@2x.png">
            </div>
          </div> -->
      </div>
    </div>
    <!-- <div class="numb">
      <p style="padding-top: 15px;">OFFER VALID TILL</p>
      <div class="circle">
        <div class="row">
          <div class="box">
            <div class="col-lg">
              <div class="numberCircle">13</div>
              <p>DAY(S)</p>
            </div>
          </div>
          <div class="box">
            <div class="col-lg">
              <div class="numberCircle">09</div>
              <P>HOUR</P>
            </div>
          </div>
          <div class="box">
            <div class="col-lg">
              <div class="numberCircle">27</div>
              <P>MINUTE</P>
            </div>
          </div>
          <div class="box">
            <div class="col-lg">
              <div class="numberCircle">41</div>
              <P>SECOND</P>
            </div>
          </div>
        </div>
      </div>

    </div> -->
  </div>
</section>
<div class="col-md-12 ">
  <div class="affil">
    <div class=" row caption-logo ">
      <div class="col-lg-5 col-sm-12">
        <h5>Affiliated by: </h5> <span> </span>
      </div>
      <div class="col-lg-7 col-sm-12">
        <div class="emb">
          <img class="" src="assets/images/logo.png" alt="Kerala’s largest IT professional organization">
          <img class="" src="assets/images/bl01.jpg" alt="Kerala’s largest IT professional organization">
          <img class="" src="assets/images/bl02.png" alt="Kerala’s largest IT professional organization">
          <img class="" src="assets/images/bl03.png" alt="Kerala’s largest IT professional organization">
        </div>
      </div>
    </div>
  </div>
</div>
<section>
  <div class="certificate">
    <div class="container">
      <h2><b>Program overview</b></h2>
      <br><br>
      <div class="row">
        <div class="col-lg-6">
          <h5><b>ITPWCS has introduced “JOB FITMENT ANALYSIS” as a tool to assess the pre-recruitment aspects of a potential candidate. This test can assess the candidates’ culture fitness, abilities, and emotional quotient, which are largely considered as a distinctive feature for a job aspirant. It also aids to self-assess the strength and weakness of an individual regarding a particular job profile.
          </b></h5>
          <h5><b>Once you are a premium member of ITPWCS, you are entitled for premium job opportunities, E- Resume program and all other privileges of premium club.
            </b>
          </h5>
        </div>
        <div class="col-lg-6">

          <div class="side-space">
            <ul>
              <h6 class="benefit"><b>Benefits</b></h6>
              <li><img src="assets/images/Asset 6@2x.png"></li>
              <h5><b>Help you to land on a safe and secure job</b> </h5>
              <li><img src="assets/images/Asset 6@2x.png"></li>
              <h5><b>Quicker shortlisting by companies looking for Pre-Assessed Candidates</b> </h5>
              <li><img src="assets/images/Asset 6@2x.png"></li>
              <h5><b>Assessment reports are engendered by HR experts </b> </h5>
              <li><img src="assets/images/Asset 6@2x.png"></li>
              <h5><b>Continuous career support till you placed in your dream job</b> </h5>
              <li><img src="assets/images/Asset 6@2x.png"></li>
              <h5><b>Assessment Completion you can get the certificate for your competence</b>
              </h5>
              <li><img src="assets/images/Asset 6@2x.png"></li>
              <h5><b>Take the Assessment from anywhere and at any time in the world </b> </h5>
              <li><img src="assets/images/Asset 6@2x.png"></li>
              <h5><b>Makes you industry ready and build your confidence to face interviews </b> </h5>
            </ul>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>
<div class="modal fade" id="myModal" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="modal-pic">
          <a href="assets/images/Asset 4@2x.png" data-lightbox="mygallery" data-title="images"><img
              src="assets/images/Asset 4@2x.png " style="width: 100%;" /></a>
        </div>
      </div>
      <!--<div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>-->
    </div>

  </div>
</div>

<div class="modal fade" id="myModal1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>
      <div class="modal-body">
        <div class="modal-pic">
          <a href="assets/images/Asset 5@2x.png" data-lightbox="mygallery" data-title="images"><img
              src="assets/images/Asset 5@2x.png" style="width: 70%;" /></a>
        </div>
      </div>
      <!--<div class="modal-footer">
          <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
        </div>-->
    </div>

  </div>
</div>










<section>
  <div class="content">
    <h2 style="text-align: center;"><b>Unlimited Access to Our Dashboard</b></h2>
    <div class="img-box">
      <div class="container-fluid">
        <div class="bord">
          <div class="row ">
            <div class="col-md-6">
              <img src="assets/images/Asset 9@2x.png" data-toggle="modal" data-target="#myModal11">
            </div>

            <div class="col-md-6">
              <h2>Job Fitment Report </h2>
              <p>Jobfitment report give you a clear direction on your tech jobs </p>
            </div>

          </div>
        </div>
        <div class="bord">
          <div class="row">
            <div class="col-md-6">
              <h2>Industry Membership </h2>
              <p>An industrial mentorship programme tailor-made for the young student tech-aspirants.
                Our aim is to help the graduating students connect with leading industry mentors to gain a
                stronger understanding of the professional workforce expectations, even before they step in.
                This can help assist you in acquiring the best of recommendations and industrial advice which,
                in turn, can help you prepare for the professional world awaiting you. Gain access to an
                experienced industry leader to help you reach your potential and offer you with long-term expert
                advice. At the end of the day, your network is your net worth.</p>
            </div>
            <div class="col-md-6">
              <img src="assets/images/industry-mentorship-new.jpg" data-toggle="modal" data-target="#myModal12">
            </div>
          </div>
        </div>
        <div class="bord">
          <div class="row">
            <div class="col-md-6">

              <img src="assets/images/career-coaching-new.jpg" data-toggle="modal" data-target="#myModal13">
            </div>
            <div class="col-md-6">
              <h2>Career Coach </h2>
              <!-- <p>You can get all your most suitable jobs are available all over india. and apply all of these in a single
            click </p> -->
              <p>Your career decision is going to be the number one choice making of your life. Our 1 tot
                1 career guidance counselling is to help you discover your interest and true potential. According
                to the new career patterns, there is a constantly increasing need to learn continuously and to
                chase after multiple careers. All you have to spend just about 60 minutes with the finest and
                utmost meticulously up-skilled career guidance counsellors to decode your assessment and
                help you conclude your top career choices. Get to know more about various educational
                requirements and work-skill requirements along with their future scope. Our dedicated career
                counselling team are well-experienced in providing you with the right guidance on how to
                choose and pursue the best possible opportunity, as per your existing job/ degree, throughout
                the course of time. Plan your career scientifically with the help of our Certified Career Strategy
                Counsellors. Schedule your 1 on 1 career guidance counselling with us .</p>
            </div>

          </div>
        </div>
        <div class="bord">
          <div class="row">

            <div class="col-md-6">
              <h2>E-Resume</h2>
              <p>According to the Talent Edge 2020 survey report, the resume submitted by the 92% of
                the freshers are fake. This has led to the companies to look beyond much further than just the
                resume. An interactive resume game would catapult the genius ahead of everything else.
                Creating your resume would no longer be a big deal. It would help you engage yourself
                throughout in achieving yours goals. The old humdrum tale of resume making would no longer
                prevail. We focus on getting back at the high unemployment rates caused by the uneven talent
                market using our polished talent edge survey. We offer you the best ever resume with multi-
                disciplinary design using gamified resume preparation. This gives you a unique identity in a
                resume to help you gain the trust of your targeted companies which, in turn, gets you the job.Engage
                yourself to gain access to this one-stop
                destination and hit the progression bar all way
                from basic to the expert level.</p>
            </div>
            <div class="col-md-6">
              <img src="assets/images/Asset 7@2x.png" data-toggle="modal" data-target="#myModal14">
            </div>



          </div>
        </div>
        <div class="bord">
          <div class="row">
            <div class="col-md-6">
              <img src="assets/images/Asset 8@2x.png" data-toggle="modal" data-target="#myModal15">
            </div>
            <div class="col-md-6">
              <h2>AI Based Internship Finder</h2>
              <p>Ultimately, your quintessential goal is to get a foot in the door at a firm where you’re
                looking forward to build further in your career. Not every college or institute aims at training you
                specifically to meet the needs of the professional world. Around 80% of the companies in our
                country are mediocre and therefore lack a trainee unit. Such companies have no way other than
                to hire the ones, who has already been a part of an internship, to their yet-to-fill vacancies. The
                professional game has levelled up to an internship-based hiring.

                Ace up your hunt to identify your knowledge, potential and skills with our Internship Hunt
                programme. We help you find both free and paid internships that plays an essential role to get
                you ahead of your peers who, unfortunately, had to work for a job which was completely out of
                their field of study. This Internship Hunt programme can be the stepping stone to the beginning
                of your career. The more accurate internships you find, the higher your chance of landing you
                the job. </p>
            </div>

          </div>
        </div>
        <div class="bord">
          <div class="row">
            <div class="col-md-6">
              <h2>AI Based Job Portal </h2>
              <!-- <p>You can get all your most suitable jobs are available all over india. and apply all of these in a single
            click </p> -->
              <p>Our AI based job portal focus on matching the most-deserving opportunity to the most-
                deserving talent thereby stepping up the hiring process in its entirety. We offer an effective and
                efficient procedure of talent acquisition making it more intelligence-driven. This is the first ever
                AI based job portal introduced in Kerala. We have developed this job portal to get you the best
                job with the help of our step-by-step process of building you right from assisting you in finding
                you a right choice of career to pick the best of around 1000+ companies round the globe. Our
                motto is to get the best talent to be matched right to the best opportunity based on their merit
                and not their background. This makes way to demonstrate how you would carry forth a job even
                before you get the job. You can now effortlessly find out your career opportunity with best job
                search and hiring tools.</p>
            </div>
            <div class="col-md-6">

              <img src="assets/images/Asset 8@2x.png" data-toggle="modal" data-target="#myModal16">
            </div>
          </div>
        </div>
      </div>




      <!-- <section>
    <div class="certificate">
      <div class="container-fluid">
        <div class="certi-box">
          <h2><b>Verified Certificate</b></h2>
          <ul>
            <li><img src="assets/images/Asset 6@2x.png"></li>
            <h5><b>Certificate issues by IT Professionals Welfare and charitable Society</b> </h5>
            <li><img src="assets/images/Asset 6@2x.png"></li>
            <h5><b>Certificates are Globally Recognized</b> </h5>
            <li><img src="assets/images/Asset 6@2x.png"></li>
            <h5><b>Each and every certificate can be digitally verifiable through the QR code</b> </h5>
            <li><img src="assets/images/Asset 6@2x.png"></li>
            <h5><b>Detailed assessment report for the employers through our QR code system</b> </h5>
          </ul>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="cert1">
              <img src="assets/images/Asset 4@2x.png" data-toggle="modal" data-target="#myModal">
            </div>
          </div>
          <div class="col-md-6">
            <div class="cert2"><img src="assets/images/Asset 5@2x.png" data-toggle="modal" data-target="#myModal1">
            </div>
          </div>
        </div>
      </div>
    </div>


  </section> -->

      <section>
        <div class="main-title">
          <h3>Confused about joining??</h3>
          <h4>Register for our free webinar and get details of the program</h4>
          <h3>and learn how to uplift your career</h3>
          <br>
          <form [formGroup]="bookForm" (ngSubmit)="onSubmit()" autocomplete="off">
            <div class="row">
              <div class="col-md-12 frm-lyt">
                <div class="form-group mb-2">

                  <input type="text" formControlName="Name"
                    [ngClass]="{ 'is-invalid': submitted && formControl.Name.errors }" class="form-control-1"
                    placeholder="Your Name" required>
                </div>
              </div>
              <div class="col-md-12 frm-lyt">
                <div class="form-group mb-2">

                  <input type="text" formControlName="Phone"
                    [ngClass]="{ 'is-invalid': submitted && formControl.Name.errors }" class="form-control-1"
                    placeholder=" Phone number" required>
                </div>
              </div>
              <div class="col-md-12 frm-lyt">
                <div class="form-group mb-2">

                  <input type="email" formControlName="Email"
                    [ngClass]="{ 'is-invalid': submitted && formControl.Name.errors }" class="form-control-1"
                    placeholder="Your Email id" required>
                </div>
              </div>
              <div class=" sub-btn mt-2 mb-5" style=" margin: auto;">
                <button class="btn btn-primary" id="form-btn" type="submit">Sign up</button>
              </div>
            </div>
          </form>
        </div>
      </section>

      <div class="container">
        <div id="accordion">
          <div class="row">
            <div class="col-md-7">
              <h4><b>Frequently Asked Questions (FAQ)</b></h4>
              <br>
              <div id="accordion">
                <div class="card">
                  <div class="card-header-style">
                    <a class="card-link" data-toggle="collapse" href="#collapseOne">
                      What is Jobfitment Analysis? <svg width="2em" height="2em" viewBox="0 0 16 16"
                        class="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path fill-rule="evenodd"
                          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                    </a>
                  </div>
                  <div id="collapseOne" class="collapse" data-parent="#accordion">
                    <div class="card-body">
                      ITPWCS Jobfitment Analysis Test. Gradually this is becoming an industry standard for freshers.
                      Jobfitment Analysis test assesses the Persons Culture Fitness and Abilities , Emotional Quitient
                      of a
                      candidate for an IT jobs. its generally required by the companies at the time of recruitment.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header-style">
                    <a class="collapsed card-link" data-toggle="collapse" href="#collapseTwo">
                      How Jobfitment Analsysis is helpful to Freshers? <svg width="2em" height="2em" viewBox="0 0 16 16"
                        class="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path fill-rule="evenodd"
                          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                    </a>
                  </div>
                  <div id="collapseTwo" class="collapse" data-parent="#accordion">
                    <div class="card-body">
                      ITPWCS helps a Freshers to self-assess themselves as where they stand against the job requirements
                      of
                      the their particular industries. Jobfitment assessment also provides scores and grades for each
                      module
                      attempted and highlights the required improvement area.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header-style">
                    <a class="collapsed card-link" data-toggle="collapse" href="#collapseThree">
                      Is there any payment to appear for Jobfitment Analysis Test? <svg width="2em" height="2em"
                        viewBox="0 0 16 16" class="bi bi-plus-circle" fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path fill-rule="evenodd"
                          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                    </a>
                  </div>
                  <div id="collapseThree" class="collapse" data-parent="#accordion">
                    <div class="card-body">
                      Yes, there is payment to appear for Jobfitment Analysis , Benefits Page
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header-style">
                    <a class="collapsed card-link" data-toggle="collapse" href="#collapsefour">
                      Which streams or degree can be Assessed? <svg width="2em" height="2em" viewBox="0 0 16 16"
                        class="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path fill-rule="evenodd"
                          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                    </a>
                  </div>
                  <div id="collapsefour" class="collapse" data-parent="#accordion">
                    <div class="card-body">
                      A student from any stream of education can take Jobfitment Analysis Test.
                      Student from Degree, Diploma, ITI, Polytechnic having any branch like: Computers, Electronics and
                      Tele
                      communication , Management , Finance , etc. can appear for jobfitment Analysis test but each
                      graduate
                      have their own exam, to know more informations please contact +91 6282228991 / Mail to
                      Info@itpwcs.i

                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header-style">
                    <a class="collapsed card-link" data-toggle="collapse" href="#collapsefive">
                      What should I prepare for Jobfitness? <svg width="2em" height="2em" viewBox="0 0 16 16"
                        class="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path fill-rule="evenodd"
                          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                    </a>
                  </div>
                  <div id="collapsefive" class="collapse" data-parent="#accordion">
                    <div class="card-body">
                      Jobfitment Analysis checks your skills on Culture Fitness, Abilities, Situational Analysis on your
                      domain (like Computer Science, Management,, Finance etc.).
                    </div>
                  </div>
                </div>
                <div class="card mar">
                  <div class="card-header-style">
                    <a class="collapsed card-link" data-toggle="collapse" href="#collapsesix">
                      Will I get placement assistance after Jobfitment?<svg width="2em" height="2em" viewBox="0 0 16 16"
                        class="bi bi-plus-circle" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                          d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                        <path fill-rule="evenodd"
                          d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                    </a>
                  </div>
                  <div id="collapsesix" class="collapse" data-parent="#accordion">
                    <div class="card-body">
                      Yes, placement assistance will be provided to Jobfitment analysis assessed candidates through the
                      Dedicated Dashboard
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <img src="assets/images/faq.png" class="jobfit">
            </div>
          </div>
        </div>
      </div>


      <div class="testiminial-main">
        <div class="container">
          <h4>TESTIMONIALS</h4>
          <div class="testimonial">
            <ul id="testimonial">
              <li>
                <div class="testimonial-item">
                  <div class="tstm-img">
                    <img src="https://itpwcs.in/uploads/testimonials/taxscan.png" data-no-retina="">
                  </div>
                  <p>I believe that the strength of a company is the employees. So each and every recruitment is a great
                    task
                    for me but it's a time killing process. But still, I am able to recruit the students without any
                    task and
                    that's because of you.</p>
                  <div class="testm-info">
                    <h5>Abdullah Karuthedakam</h5>
                    <p>CEO Taxscan Edutech PVT LTD</p>
                  </div>
                </div>
              </li>
              <li>
                <div class="testimonial-item">
                  <div class="tstm-img">
                    <img src="https://itpwcs.in/uploads/testimonials/taxscan.png" data-no-retina="">
                  </div>
                  <p>I believe that the strength of a company is the employees. So each and every recruitment is a great
                    task
                    for me but it's a time killing process. But still, I am able to recruit the students without any
                    task and
                    that's because of you.</p>
                  <div class="testm-info">
                    <h5>Abdullah Karuthedakam</h5>
                    <p>CEO Taxscan Edutech PVT LTD</p>
                  </div>
                </div>
              </li>

            </ul>

            <div class="lSAction advant-controls">
              <a class="lSPrev" id="advantPrev"><img src="./assets/images/arrow-left.png" /></a>
              <a class="lSNext" id="advantNext"><img src="./assets/images/arrow-right.png" /></a>
            </div>
          </div>
        </div>
      </div>
      <br>
      <br>




      <div class="container-fluid">
        <div class="sec-last">
          <h2 class="foot-title"><b>Our Success Stories</b></h2>
          <div class="row">
            <div class="col-lg-6 col-sm-12 right">
              <div class="row">
                <div class="col-lg-12 col-sm-12 ">
                  <div class="yellow">
                    <img src="assets/images/logo.png ">
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="menu-img-1"><img src="assets/images/ft2.jpg"></div>
                </div>
                <div class="col-lg-6">
                  <div class="menu-img-2"><img src="assets/images/ft3.jpg"></div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-sm-12 same">
              <div class="row">
                <div class="col-lg-6 col-sm-6">
                  <img src="assets/images/ft4.jpg" class="f1">
                </div>
                <div class="col-lg-6 col-sm-6">
                  <div class="row">
                    <div class="col-lg-12">
                      <img src="assets/images/ft5.jpg " class="person">
                    </div>
                    <div class="col-lg-12">
                      <img src="assets/images/ft6.jpg " class="person">
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>