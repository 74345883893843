import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { DatabaseOperationService } from '../../api/database/database-operation.service';
import { DatalistService } from '../../api/services/datalist.service';
import { FunctionService } from '../../api/services/function.service';
import { CommonService } from '../../api/services/common.service';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  QuickEnquiryForm: any;
  submitted: boolean;

  constructor(
    public commonService: CommonService,
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,) { }

  ngOnInit(): void {
    this.restForm();
  }
  get formControl() { return this.QuickEnquiryForm.controls; }
  onSubmit(){
    this.submitted = true;
    if (this.QuickEnquiryForm.invalid) {
      this.functionService.showProcessMessage("Provide vaild information", false);
      return;
    }

    this.datalistService.loaderActive = true;
    this.databaseOperationService.sendQuickEnquiryForm(this.QuickEnquiryForm.value).subscribe((data: any) => {})
    this.databaseOperationService.saveQuickEnquiryForm(this.QuickEnquiryForm.value).subscribe((data: any) => {
      // this.restForm();
      // this.functionService.showProcessMessage("Our Counsellor Will Connect With You ", true);
      // this.datalistService.loaderActive = false;
      console.log(data);
      this.submitted = true;
      if(data){
        Swal.fire({
          title: 'Thank you for your registration!',
          icon: 'success',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        })
        this.datalistService.loaderActive = false;
      }
      else{
        // Swal.fire("Please provide valid information")
        this.submitted = false;
        this.functionService.showProcessMessage("Please provide valid information", false);
        this.datalistService.loaderActive = false;
      }
    
    })
    // this.functionService.showProcessMessage("Submitted Successfully", true);
    
  }

  restForm() {
    this.submitted = false;
    this.QuickEnquiryForm = this.formBuilder.group({  
      Name: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      Mobile: ['', Validators.required],
      Program: ['', Validators.required],
      Message: [''],
    });
  }
}
