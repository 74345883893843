<div class="course-banner">
    <div id="demo" class="carousel slide wide-banner" data-ride="carousel">
        <div class="carousel-inner">

            <div class="carousel-item active">
                <div class="mech-over"></div>
                <img src="./assets/images/inf.jpg">
                <div class="carousel-caption">
                    <div class="course-caption">
                        <h1 class="part1"> INFORMATION </h1>
                        <h1 class="part2">SYSTEM </h1>

                        <p class="p-tag">The 2 year full time MBA program of Mahatma Gandhi University, </p>

                        <a type="button" class="btn btn-view" data-toggle="modal" data-target="#myModal">
                            Read more..
                        </a>

                        <button class="caption-btn"
                            (click)="commonService.openEnquiryForm('Information System')">Enquiry Now</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="demo" class="carousel slide mobile-banner" data-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="mech-over"></div>
                <img src="./assets/images/inf-mob.jpg">
                <div class="carousel-caption">
                    <div class="course-caption">
                        <h1 class="part1"> INFORMATION </h1>
                        <h1 class="part2">SYSTEM </h1>
                        <p class="p-tag">The 2 year full time MBA program of Mahatma Gandhi University,approved by All
                            India Council for Technical Education (AICTE) </p>

                        <a type="button" class="btn btn-view" data-toggle="modal" data-target="#myModal">
                            Read more..
                        </a>
                        <button class="caption-btn"
                            (click)="commonService.openEnquiryForm('Information System')">Enquiry Now</button>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <!-- The Modal -->
    <div class="modal" id="myModal">
        <div class="modal-dialog modal-dialog-scrollable">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h1 class="modal-title"></h1>
                    <button type="button" class="close" data-dismiss="modal">×</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <h3> Information System </h3>
                    <p class="read-modal"> The 2 year full time MBA program of Mahatma Gandhi University, approved by
                        All India Council for Technical Education (AICTE) is offered under semester system. The
                        objectives of MBA program are the following: </p>
                    <ul>
                        <h5> Objectives</h5>
                        <li>To develop young MBA aspirants into professional managers who can contribute to the growth
                            of business and industry in India and the world</li>
                        <li>To develop astute leaders who can efficiently and effectively manage business amidst of
                            environmental turbulences </li>
                        <li>To nurture entrepreneurial skills among young generation and make them effective change
                            agents</li>
                        <li>To contribute towards better management practices in the country and the world by offering
                            quality management education</li>
                    </ul>



                </div>

                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>

            </div>
        </div>
    </div>


    <!-- <div class="course-info">
        <div class="container-fluid">
            <div class="info-details">
                <div class="row">
                    <div class="col-md-3 col-6">
                        <div class="info-box">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="assets/images/info-i1.png" />
                                </div>
                                <div class="col-md-8">
                                    <div class="infobox-data">
                                        <p>Course Duration</p>
                                        <h2>4 Years</h2>
                                    </div>
                                </div>
                            </div>
                           
                            
                        </div>
                    </div>
                    <div class="col-md-3 col-6">
                        <div class="info-box">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="assets/images/info-i2.png" />
                                </div>
                                <div class="col-md-8">
                                <div class="infobox-data">
                                    <p>Available Seats</p>
                                    <h2>90</h2>
                                </div>
                            </div>
                        </div>
                           
                        </div>
                    </div>
                    <div class="col-md-3 col-6">
                        <div class="info-box">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="assets/images/info-i3.png" />
                                </div>
                                <div class="col-md-8">
                                    <div class="infobox-data">
                                        <p>College Code</p>
                                        <h2>JBT</h2>
                                    </div>
                                </div>
                            </div>
            
                        </div>
                    </div>
                    <div class="col-md-3 col-6">
                        <div class="info-box">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="assets/images/info-i4.png" />
                                </div>
                            
                            <div class="col-md-8">
                                <div class="infobox-data">
                                    <p>Students Completed</p>
                                    <h2>1500</h2>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
</div>

<div class="ban-up"></div>
<div class="main-container">
    <div class="container">
        <div class="back-details">
            <div class="row">

                <div class="col-md-12">
                    <div class="program">
                        <h1 class="prgm-overview">
                            Information System Overview
                        </h1>
                        <div class="content-limit" [ngClass]="{'content-full':more}">
                            <!--<p >An emphasis in marketing provides students with critical, detail-oriented thinking, and strong analytical skills. Marketing emphasizes creativity, but the ability to analyse and interpret data is equally important. Organizational skills are critical for complex projects. Strong oral and written communication skills are also desirable. </p>
                        <p>
                        Graduates typically find positions in manufacturing, medical technology, financial services, and retail industries. Marketing research and marketing services/advertising positions are also taken. We have identified three tracks to help you organize your coursework and enterprise selection if you are interested in marketing.
                    </p><p> Here in Jai Bharath School Of Management Studies mainly   based on the MG university Syllabus, that   structure   the equips those interested in this field with the skill set needed to understand, interpret and apply marketing concepts in live corporate scenarios. The students learn the practical applications of marketing principles through case studies, seminars, workshops, guest lectures and research projects.</p>-->



                            <!-- <ul>
                                <li>Information manager</li>
                                <li>Digital manager</li>
                                <li>System manager</li>
                                <li>IT head</li>
                                <li>Software manager</li>
                            </ul> -->
                            <!-- <p>MBA in Information System train students to incorporate different types of information
                                systems into business operations to meet different goals. An emphasis in Information
                                System provides students with critical, detail-oriented thinking, and strong analytical
                                skills. It emphasizes creativity, but the ability to analyse and interpret data is
                                equally important. Organizational skills are critical for complex projects. Strong oral
                                and written communication skills are also desirable.</p>
                            <p>Graduates typically find positions in manufacturing, medical technology, financial
                                services, and retail industries. We have identified three tracks to help you organize
                                your coursework and enterprise selection if you are interested in marketing. </p>
                            <p>Jai Bharath School of Management Studies mainly based on MG University Syllabus,
                                structure the equips for those interested in this field with the skill set needed to
                                understand, interpret and apply information system concepts in live corporate scenarios.
                                The students learn the practical applications of Information System principles through
                                case studies, seminars, workshops, guest lectures and research projects.</p> -->

                            <p>Our priority is in crafting business minded professionals who can manage the mounting
                                flood of digital information and someone to deal with complex system. We take our
                                students through network configuration system development which help graduates to form
                                an advanced knowledge of information system. We focus on training students to determine
                                appropriate information systems solutions using current and emerging technologies. We
                                aim in building students who have proficiency in understanding and managing the most
                                critical issues of technology investment.</p>
                        </div>
                        <!-- <button class="btn-btn" (click)="readMore();" *ngIf="!more">
                            Read more <i class="fa fa-chevron-right"></i>
                        </button> -->
                    </div>

                </div>
                <div class="col-md-12">
                    <div class="key-high">
                        <div class="row">
                            <div class="col-md-12">
                                <h2>Key Highlights</h2>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3 col-12">
                                <img class="key-pic" src="./assets/images/Asset 13.png">

                                <p>
                                    Get an MBA Degree <br>From MGU
                                </p>
                            </div>
                            <div class="col-md-3 col-12">
                                <img class="key-pic" src="./assets/images/Asset 14.png">

                                <p>
                                    Experience 2 Years industry<br>level immersion Program
                                </p>
                            </div>
                            <div class="col-md-3 col-12">
                                <img class="key-pic" src="./assets/images/Asset 15.png">

                                <p>
                                    Mentorship from<br>Industry leadres
                                </p>
                            </div>
                            <div class="col-md-3 col-12">
                                <img class="key-pic" src="./assets/images/Asset 16.png">

                                <p>
                                    Advance Your career<br>with 360° carrier support
                                </p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>





<div class="syllabus">
    <div class="container">
        <div class="syl-content">
            <div class="row">
                <div class="col-md-6">
                    <h1 class="syllabus-head">Syllabus</h1>
                    <p class="syl-para">
                        We follow the prescribed syllabus of MGU, quality study materials are prepared by our faculty in
                        the form of videos, case studies, and projects. To know more click on the link
                    </p>
                </div>
                <div class="col-md-6">
                    <div class="syllabus-data">
                        <button class="sy-btn" (click)="commonService.openEnquiryForm('Information System')">Enquiry
                            Now</button>
                        <!-- <button class="sy-btn" data-toggle="modal" data-target="#enquiryModel">Enquiry Now</button> -->



                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="top-subject">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="sub-head">
                    <h3 class="sub-h3">Top Subject You Will Learn</h3>
                    <p class="sub-para">

                        System analysis & design,

                        Electronic commerce & internet marketing,

                        Enterprise resource planning,

                        Business process reengineering,

                        Software quality management,

                        Multimedia management,

                        Management support system,

                        Software project management,

                        Database management system,

                        Planning and implementing it strategies,

                    </p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="sub-head">
                    <h3 class="sub-h3">Advance Your career</h3>
                    <p class="sub-para">

                        <!-- Export managers and executives, International business consultant, Consultant, International
                        Business Development Manager, International Marketing Manager, International Finance Manager,
                        Global Business Manager, Export coordinator, Account Manager, Brand Manager, Management
                        Consultant, Marketing Manager, Project Managers, Material Manager, Supplier Manager, Investment
                        Banking Manager, Computer and Information Systems Manager, Data science and Analytics Manager,
                        IT Project Manager, Software Manager and Chief Information Officer. -->

                        Business Application Developer, Business Forecaster, Systems Analyst, Database Administrator,
                        Business Intelligence Analyst, Systems Developer, IT Consultant, Database Analyst, Information
                        Systems Manager, etc

                    </p>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="sub-head">
                    <h3 class="sub-h3">Who Is This Program For ?</h3>
                    <p class="sub-para">Young professional minds who seek Masters in Business Administration.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="sub-head">
                    <h3 class="sub-h3">Minimum Eligilibity</h3>
                    <p class="sub-para">Bachelor’s Degree with minimum 45% marks from a recognized college or
                        university.</p>
                </div>

            </div>
        </div>

    </div>
</div>

<div class="ban-up"></div>

<!--<div class="pic-details">
    <div class="container">
        <div class="pic-array">
            <div class="row">
                <div class="col-md-6">
                    <div class="keam-pic">
                        <div class="row">
                            <div class="col-6 ">
                                <div class="keam-img">
                                    <img class="img-keam" src="assets/images/Asset 22.png">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="keam-para">
                                    <h5 class="keam-h5">Prepare for <br>Keam Exam ?</h5>
                                </div>
                                <div class="apply-now">
                                    <button class="btn btn-book" routerLink="/jobfitment">Apply Now</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-md-6">
                    <div class="keam-pic">
                        <div class="row">
                            <div class="col-6">
                                <div class="keam-img">
                                    <img class="img-keam" src="assets/images/Asset-23.png">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="keam-para">
                                    <h5 class="keam-h5">Are you fit <br>for this carrier?</h5>
                                </div>
                                <div class="keam-para">
                                    <h5 class="keam-h5">Job fitment <br>Analysis</h5>
                                </div>
                                <div class="apply-now">
                                    <button class="btn btn-book" >Apply Now</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>-->
<div class="call-new">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="call-content">
                    <div class="call-no">
                        <h1 class="ph-no"><a href="tel:+91 72932 29051">+91 72932 29051</a></h1>
                    </div>
                    <div class="call-data">
                        <p class="paragraph">Still confused about joining ?</p>
                        <p class="pa-graph">talk to do your career counsellor</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>


<div class="ban-down">
</div>

<!-- <div class="labs"> 
    <div class="container">
        <h3>Our Labs</h3>
        <div class="labs-items">
            <ul id="labs-slider" class="labs-slider">
                <li>
                    <div class="labs-box">
                        <img src="assets/images/Lab-icons1.png" />
                        <p>	Fluid <br />Mechanics Lab</p>
                    </div>
                </li>
                <li>
                    <div class="labs-box">
                        <img src="assets/images/Lab-icons2.png" />
                        <p>Computer <br />Programing Lab</p>
                    </div>
                </li>
                <li>
                    <div class="labs-box">
                        <img src="assets/images/Lab-icons3.png" />
                        <p>	Hydraulics<br /> Machinery Lab</p>
                    </div>
                </li>
                <li>
                    <div class="labs-box">
                        <img src="assets/images/Lab-icons3.png" />
                        <p>Strength of<br /> Material Labb</p>
                    </div>
                </li><li>
                    <div class="labs-box">
                        <img src="assets/images/Lab-icons3.png" />
                        <p>CAD Lab</p>
                    </div>
                </li>
                <li>
                    <div class="labs-box">
                        <img src="assets/images/Lab-icons3.png" />
                        <p>Electrical<br /> Technology Lab</p>
                    </div>
                </li>
                <li>
                    <div class="labs-box">
                        <img src="assets/images/Lab-icons3.png" />
                        <p>Heat<br /> Engine Lab</p>
                    </div>
                </li> <li>
                    <div class="labs-box">
                        <img src="assets/images/Lab-icons3.png" />
                        <p>Advance Machine <br />Tools Lab</p>
                    </div>
                </li> <li>
                    <div class="labs-box">
                        <img src="assets/images/Lab-icons3.png" />
                        <p>Measurements <br />Lab</p>
                    </div>
                </li> <li>
                    <div class="labs-box">
                        <img src="assets/images/Lab-icons3.png" />
                        <p>Mechanical<br /> Systems Lab</p>
                    </div>
                </li> <li>
                    <div class="labs-box">
                        <img src="assets/images/Lab-icons3.png" />
                        <p>Electrical<br /> Technology Lab</p>
                    </div>
                </li>
                <li>
                    <div class="labs-box">
                        <img src="assets/images/Lab-icons4.png" />
                        <p>Electronic Circuits & <br />Communication Lab</p>
                    </div>
                </li>
            </ul>
            <div class="lSAction labs-controls">
                <a class="lSPrev" id="labsPrev"><img src="./assets/images/arrow-left.png" /></a>
                <a class="lSNext" id="labsNext"><img src="./assets/images/arrow-right.png" /></a>
            </div>
        </div>
    </div>
</div>-->


<div class="clients">
    <div class="container">
        <h3>Associate Internship Companies</h3>
        <div class="client-items">
            <ul id="client-slider" class="client-slider">
                <li>
                    <img src="assets/images/cl1.png" />
                </li>
                <li>
                    <img src="assets/images/cl2.png" />
                </li>
                <li>
                    <img src="assets/images/cl3.png" />
                </li>
                <li>
                    <img src="assets/images/cl4.png" />
                </li>
                <li>
                    <img src="assets/images/cl5.png" />
                </li>
                <li>
                    <img src="assets/images/cl6.png" />
                </li>
            </ul>
            <div class="lSAction client-controls">
                <a class="lSPrev" id="clientPrev"><img src="./assets/images/arrow-left.png" /></a>
                <a class="lSNext" id="clientNext"><img src="./assets/images/arrow-right.png" /></a>
            </div>
        </div>
    </div>
</div>

<!-- <div class="ban-redup"></div> -->


<div class="certificate">
    <div class="container">
        <div class="certificate-cls">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="certificate-head">Our Certifications</h1>
                </div>
            </div>
            <ul class="nav nav-pills certificate-tabs">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="pill" href="#place1">Course Completion</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="pill" href="#place2">Job Fitment</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="pill" href="#place3">University Certification</a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="place1">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="certificate-data">
                                <h2>Course Completion</h2>
                                <p>
                                    A directly enrolled student will be awarded the course completion after successful
                                    completion of Your MBA. After completing this course a candidate can get the jobs in
                                    any government as well as private sector. This certification can be used for the
                                    secondary certification for private companies.
                                </p>
                                <ol>
                                    <li> Certification from Jai Bharath School of management Studies and AICTE and
                                        UGC.
                                    </li>
                                    <li>Certifications verifiable through our website.</li>
                                    <li>Certification used as your secondary certification if you have any back logs.
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="certificate-img">
                                <img src="./assets/images/mba (1).jpg" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="place2">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="certificate-data">
                                <h2>Job Fitment</h2>
                                <p>
                                    If you have been looking for a “suitable career option after your degree course”,
                                    you have
                                    come to the right place because here, in JAI BHARATH,  you will find a comprehensive
                                    list of professions to choose after a degree. Students who experience job fit are
                                    productive,
                                    happy, and will be a contributing as a perfect employee. It may find you have a
                                    potential A-
                                    player assigned to the wrong seat on the bus. Our job fitment analysis complete
                                    guide will
                                    help you make a well-informed decision and pursue a perfect career.
                                </p>
                                <ol>
                                    <li>The certification used as your fitness certification for your career journey
                                    </li>
                                    <li>Job fitment certification helps to get shortlist faster by companies looking for
                                        pre-
                                        assessed candidates.</li>
                                    <li>Help you landing and safe and secure job.</li>
                                    <li>Certification globally recognised and it can also use abroad</li>
                                    <li>Certification can be verified using QR code</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="certificate-img">
                                <img src="./assets/images/certificates1.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="place3">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="certificate-data">
                                <h2>University Certification</h2>
                                <p>
                                    The certificate for engineering was provided by MG university(Mahthma Gandhi
                                    University)
                                    this is the prominent certificate MBA gets on completing his two year course. This
                                    often
                                    leads to increased earning potential as well. Also, MBA certificates can help
                                    workers stay
                                    up-to-date with changes in their industries.
                                </p>
                                <ol>
                                    <li>
                                        He certificates from MG university.
                                    </li>
                                    <li>
                                        Globally recognised certification
                                    </li>
                                    <li>
                                        You can have used as your primary certification.
                                    </li>
                                    <li>
                                        Certification gives an extra mileage for your career as well as your salary.
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="certificate-img">
                                <img src="./assets/images/mba2.jpg" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>



</div>

<!-- <div class="ban-reddown"></div> -->

<div class="advantage">
    <div class="container">
        <div class="advant-content">
            <div class="advant-head">
                <h1>Our Highlights</h1>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="advant-box">
                        <div class="inr-icons">
                            <span class="lnr lnr-arrow-up"></span>
                        </div>
                        <h2>360 Career Development</h2>
                        <p>
                            An expert driven industry mentorships supplemented with job fitment analysis and also enable
                            for resume building using AI mode.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="advant-box">
                        <div class="inr-icons">
                            <span class="lnr lnr-database"></span>
                        </div>
                        <h2>Financial Support</h2>
                        <p>
                            Our financial aid helps the students and their families by
                            covering higher education expenses, tie up with banks and also provide various scholarship
                            schemes.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="advant-box">
                        <div class="inr-icons">
                            <span class="lnr lnr-laptop"></span>
                        </div>
                        <h2>Placement Services</h2>
                        <p>
                            The Jai Bharath placement portal serves you access to
                            extensive job leads and reference of leading firms.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="advant-box">
                        <div class="inr-icons">
                            <span class="lnr lnr-earth"></span>
                        </div>
                        <h2>Eco-Friendly Campus</h2>
                        <p>
                            We at Jai Bharath take care of all the environment friendly factors to contribute in making
                            an
                            eco-friendly campus
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="advant-box">
                        <div class="inr-icons">
                            <span class="lnr lnr-book"></span>
                        </div>
                        <h2>Multi Displicinary Curriculum</h2>
                        <p>
                            We design various multidisciplinary curriculum for students that suits a specific career
                            path.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="advant-box">
                        <div class="inr-icons">
                            <span class="lnr lnr-location"></span>
                        </div>
                        <h2>Prime Location</h2>
                        <p>
                            We are located at the Mid of Kochi, Idukki and Kottayam so that its very much easy to
                            step into our campus.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>


<!--<div class="ban-up"></div>

<div class="instructors">
    <div class="container-fluid">
        <div class="instructors-head">
            <h1>Our Instructors</h1>
        </div>
        <div class="instructors-images">
            <ul id="slider-instructors" class="content-instructors">
                <li>
                    <div class="instruct-box">
                        <div class="img-box">
                            <!-- <img src="./assets/images/why-1.png" /> 
                        </div>
                        <div class="instruct-data">
                            <h3 class="head">
                                DR. NAZIMUDDIN M S
                                </h3>
                            <p class="head">Dean <br />MSc.Engg,PhD</p>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante
                                tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi
                                vel, viverra dignissim ligula. Phasellus metus leo, consequat et imperdiet fermentum,
                                varius in arcu.
                            </p>
                            <img src="./assets/images/in.png" class="in-icon" />
                        </div>
                    </div>
                </li>
                <li>
                    <div class="instruct-box">
                        <div class="img-box">
                            <!-- <img src="./assets/images/why-1.png" /> 
                        </div>
                        <div class="instruct-data">
                            <h3 class="head">
                                MR. MATHEWS M
                                </h3>
                            <p class="head">HOD <br />M.Tech</p>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante
                                tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi
                                vel, viverra dignissim ligula. Phasellus metus leo, consequat et imperdiet fermentum,
                                varius in arcu.
                            </p>
                            <img src="./assets/images/in.png" class="in-icon" />
                        </div>
                    </div>
                </li>
                <li>
                    <div class="instruct-box">
                        <div class="img-box">
                            <!-- <img src="./assets/images/why-1.png" /> 
                        </div>
                        <div class="instruct-data">
                            <h3 class="head">MR. SANDEEP K.S</h3>
                            <p class="head">Assistant Professor <br />M.Tech"</p>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante
                                tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi
                                vel, viverra dignissim ligula. Phasellus metus leo, consequat et imperdiet fermentum,
                                varius in arcu.
                            </p>
                            <img src="./assets/images/in.png" class="in-icon" />
                        </div>
                    </div>
                </li>
                <li>
                    <div class="instruct-box">
                        <div class="img-box">
                            <!-- <img src="./assets/images/why-1.png" />
                        </div>
                        <div class="instruct-data">
                            <h3 class="head">MR. ALEN JOHN</h3>
                            <p class="head">Assistant Professor <br />M.Tech"</p>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante
                                tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi
                                vel, viverra dignissim ligula. Phasellus metus leo, consequat et imperdiet fermentum,
                                varius in arcu.
                            </p>
                            <img src="./assets/images/in.png" class="in-icon" />
                        </div>
                    </div>
                </li>
                <li>
                    <div class="instruct-box">
                        <div class="img-box">
                            <img src="./assets/images/why-1.png" /> 
                        </div>
                        <div class="instruct-data">
                            <h3 class="head">
                                ROBIN SCARIAH
                                </h3>
                            <p class="head">Assistant Professor <br />M.Tech"</p>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante
                                tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi
                                vel, viverra dignissim ligula. Phasellus metus leo, consequat et imperdiet fermentum,
                                varius in arcu.
                            </p>
                            <img src="./assets/images/in.png" class="in-icon" />
                        </div>
                    </div>
                </li>
                <li>
                    <div class="instruct-box">
                        <div class="img-box">
                            <!-- <img src="./assets/images/why-1.png" /> 
                        </div>
                        <div class="instruct-data">
                            <h3 class="head">MR. JOMY JOSE</h3>
                            <p class="head">Assistant Professor <br />M.Tech"</p>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante
                                tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi
                                vel, viverra dignissim ligula. Phasellus metus leo, consequat et imperdiet fermentum,
                                varius in arcu.
                            </p>
                            <img src="./assets/images/in.png" class="in-icon" />
                        </div>
                    </div>
                </li>
                <li>
                    <div class="instruct-box">
                        <div class="img-box">
                            <!-- <img src="./assets/images/why-1.png" /> 
                        </div>
                        <div class="instruct-data">
                            <h3 class="head">R. RANJITH KUMAR TB</h3>
                            <p class="head">Assistant Professor <br />M.Tech"</p>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante
                                tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi
                                vel, viverra dignissim ligula. Phasellus metus leo, consequat et imperdiet fermentum,
                                varius in arcu.
                            </p>
                            <img src="./assets/images/in.png" class="in-icon" />
                        </div>
                    </div>
                </li>
                <li>
                    <div class="instruct-box">
                        <div class="img-box">
                            <!-- <img src="./assets/images/why-1.png" /> 
                        </div>
                        <div class="instruct-data">
                            <h3 class="head">MR. ELDHOSE M.CHACKAPPAN</h3>
                            <p class="head">Assistant Professor <br />M.Tech"</p>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante
                                tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi
                                vel, viverra dignissim ligula. Phasellus metus leo, consequat et imperdiet fermentum,
                                varius in arcu.
                            </p>
                            <img src="./assets/images/in.png" class="in-icon" />
                        </div>
                    </div>
                </li>
                <li>
                    <div class="instruct-box">
                        <div class="img-box">
                            <!-- <img src="./assets/images/why-1.png" /> 
                        </div>
                        <div class="instruct-data">
                            <h3 class="head">MR. ELDHOSE JACOB</h3>
                            <p class="head">Assistant Professor <br />M.Tech"</p>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante
                                tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi
                                vel, viverra dignissim ligula. Phasellus metus leo, consequat et imperdiet fermentum,
                                varius in arcu.
                            </p>
                            <img src="./assets/images/in.png" class="in-icon" />
                        </div>
                    </div>
                </li>
                <li>
                    <div class="instruct-box">
                        <div class="img-box">
                            <!-- <img src="./assets/images/why-1.png" /> 
                        </div>
                        <div class="instruct-data">
                            <h3 class="head">MR. JIBIN JOSE</h3>
                            <p class="head">Assistant Professor <br />M.Tech"</p>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante
                                tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi
                                vel, viverra dignissim ligula. Phasellus metus leo, consequat et imperdiet fermentum,
                                varius in arcu.
                            </p>
                            <img src="./assets/images/in.png" class="in-icon" />
                        </div>
                    </div>
                </li>

            </ul>
            <div class="lSAction instructors-controls">
                <a class="lSPrev" id="instructorsPrev"><img src="./assets/images/arrow-left.png" /></a>
                <a class="lSNext" id="instructorsNext"><img src="./assets/images/arrow-right.png" /></a>
            </div>
        </div>
    </div>
</div>

<div class="ban-down"></div>-->

<!--<div class="admission">
    <div class="container">
        <div class="admission-head">
            <h1>Admission Process</h1>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="admins-box">
                    <p class="admin-head">Take the online course fitment test</p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante tempus
                        iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi vel, viverra
                        dignissim ligula. Phasellus metus leo, consequat.
                    </p>
                </div>
                <p class="admis-step">Step 1</p>
            </div>
            <div class="col-md-4">
                <div class="admins-box">
                    <p class="admin-head">Take the online course fitment test</p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante tempus
                        iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi vel, viverra
                        dignissim ligula. Phasellus metus leo, consequat.
                    </p>
                </div>
                <p class="admis-step">Step 2</p>
            </div>
            <div class="col-md-4">
                <div class="admins-box">
                    <p class="admin-head">Take the online course fitment test</p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante tempus
                        iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi vel, viverra
                        dignissim ligula. Phasellus metus leo, consequat.
                    </p>
                </div>
                <p class="admis-step">Step 3</p>
            </div>
        </div>
    </div>
</div>-->
<!-- <div class="ban-up"></div> -->


<div class="placestart-head">
    <div class="container">
        <div class="placement-main">
            <!-- <p class="master-par">The Guarantee</p>-->
            <h1 class="prj-head">Our Placement Strategy</h1>
            <div class="placement-area">
                <div class="row">
                    <div class="col-md-2">
                        <div class="placement-box" routerLink="/career">
                            <div class="placein-box">
                                <img src="assets/images/fwdmbahomescreen/placement (6).png">
                                <p>
                                    Job<br> Fitment Report
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="placement-box" routerLink="/career">
                            <div class="placein-box">
                                <img src="assets/images/fwdmbahomescreen/placement (5).png">
                                <p>
                                    Industry<br> Membership
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="placement-box" routerLink="/career">
                            <div class="placein-box">
                                <img src="assets/images/fwdmbahomescreen/placement (4).png">
                                <p>
                                    Career <br /> Coach
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="placement-box" routerLink="/career">
                            <div class="placein-box">
                                <img src="assets/images/fwdmbahomescreen/placement (3).png">
                                <p>
                                    Gamified<br> Resume Preparation
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="placement-box" routerLink="/career">
                            <div class="placein-box">
                                <img src="assets/images/fwdmbahomescreen/placement (2).png">
                                <p>
                                    Internship <br /> Hunter
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="placement-box" routerLink="/career">
                            <div class="placein-box">
                                <img src="assets/images/fwdmbahomescreen/placement (1).png">
                                <p>
                                    AI-based<br> Job Portal
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="ban-down"></div> -->

<div class="place-head">
    <div class="container">
        <div class="placement-main">
            <h1 class="prj-head">Our Graduates Work At</h1>
            <div class="grad-sec">
                <div class="row">
                    <div class=" col-md-3 col-sm-6 ">
                        <div class="grad-body">
                            <img src="assets/images/wlogos/1.png" />
                        </div>
                    </div>
                    <div class=" col-md-3 col-sm-6">
                        <div class="grad-body">
                            <img src="assets/images/wlogos/2.png" />
                        </div>
                    </div>
                    <div class=" col-md-3 col-sm-6">
                        <div class="grad-body">
                            <img src="assets/images/wlogos/3.png" />
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                        <div class="grad-body">
                            <img src="assets/images/wlogos/4.png" />
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                        <div class="grad-body">
                            <img src="assets/images/wlogos/5.png" />
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                        <div class="grad-body">
                            <img src="assets/images/wlogos/6.png" />
                        </div>
                    </div>
                    <div class=" col-md-3 col-sm-6 ">
                        <div class="grad-body">
                            <img src="assets/images/wlogos/7.png" />
                        </div>
                    </div>
                    <div class=" col-md-3 col-sm-6">
                        <div class="grad-body">
                            <img src="assets/images/wlogos/8.png" />
                        </div>
                    </div>
                    <div class=" col-md-3 col-sm-6">
                        <div class="grad-body">
                            <img src="assets/images/wlogos/9.png" />
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                        <div class="grad-body">
                            <img src="assets/images/wlogos/10.png" />
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                        <div class="grad-body">
                            <img src="assets/images/wlogos/11.png" />
                        </div>
                    </div>
                    <div class="col-md-3 col-sm-6">
                        <div class="grad-body">
                            <img src="assets/images/wlogos/12.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<app-testimonial></app-testimonial>