<div class="main-head">
  <div class="head-line">
    <h1>My
      Account</h1>
  </div>
</div>
<div class="container-admin">
  <div class="container admin">
    <div class="row">
      <div class="col-md-12">


        <div class="mat-drawer-sidenav-content">
          <div class="btn-nav">
            <button mat-raised-button (click)="setContent('1')" [ngClass]="{'active':MyAccount}"><i class="fa fa-user"
                aria-hidden="true"></i> &nbsp;My
              Account</button>
            <button mat-raised-button (click)="setContent('2')" [ngClass]="{'active':Security}"><i class="fa fa-shield"
                aria-hidden="true"></i>
              &nbsp;Security</button>

          </div>
          <div class="drawer-content">
            <div class="innerbox">
              <div class="row" *ngIf="MyAccount">
                <div class="col-lg-12">
                  <div class="contact-innerbox ">
                    <button mat-raised-button color="info" class="btn btn-info pull-right"
                      (click)="editContactDetails()">Edit</button>
                    <span *ngIf="datalistService.SiteDetails">
                      <h1>ADDRESS</h1>
                      <p [innerHTML]="datalistService.SiteDetails.Address">
                      </p>
                      <p *ngIf="datalistService.SiteDetails.Phone1">
                        <i class="fa fa-phone "></i>Phone: {{datalistService.SiteDetails.Phone1}}<span
                          *ngIf="datalistService.SiteDetails.Phone2">,
                          {{datalistService.SiteDetails.Phone2}}</span>
                      </p>
                      <p *ngIf="datalistService.SiteDetails.Email1">
                        <i class="fa fa-envelope "></i> Email: {{datalistService.SiteDetails.Email1}}<span
                          *ngIf="datalistService.SiteDetails.Email2">, {{datalistService.SiteDetails.Email2}}</span>
                      </p>
                      <p *ngIf="datalistService.SiteDetails.WhatsApp">
                        <i class="fa fa-whatsapp"></i> {{datalistService.SiteDetails.WhatsApp}}
                      </p>
                      <!-- <p>
                          <i class="fa fa-clock-o "></i> {{datalistService.SiteDetails.WorkingTime}}
                        </p> -->
                    </span>
                  </div>
                  <div class="contact-innerbox ">
                    <button mat-raised-button color="info" class="btn btn-info pull-right"
                      (click)="addSocialMedia()">Add</button>
                    <p>Social Media</p>
                    <ul class="social-media">
                      <li *ngFor="let GEMSocialMedia of GEMSocialMedia">
                        <a><i [innerHTML]="GEMSocialMedia.Icon"></i> {{GEMSocialMedia.MediaType}}</a>
                        <span (click)="editSocialMedia(GEMSocialMedia.MediaId)"><i class="fa fa-pencil"
                            aria-hidden="true"></i></span>
                        <span (click)="deleteSocialMedia(GEMSocialMedia.MediaId)"><i class="fa fa-trash"
                            aria-hidden="true"></i></span>
                      </li>
                    </ul>
                    <p *ngIf="!GEMSocialMedia.length" class="text-center">No data found</p>
                  </div>
                </div>

                <!-- <div class="col-md-12 ">
                    <div class="contact-innerbox ">
  
                      <div class="location">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14441.699915346793!2d55.2696486!3d25.1888869!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe3a1c444dfdd282f!2sGEM%20James%20Chartered%20Accountants!5e0!3m2!1sen!2sin!4v1590858650939!5m2!1sen!2sin"
                          width="100% " height="650 " frameborder="0 " style="border:0; " allowfullscreen=" "
                          aria-hidden="false " tabindex="0 "></iframe>
                      </div>
                    </div>
                  </div> -->
              </div>
              <div class="row" *ngIf="Security">
                <div class="col-md-12">

                  <mat-accordion>
                    <mat-expansion-panel>
                      <mat-expansion-panel-header class="Security">
                        <mat-panel-title>
                          <i class="fa fa-user"></i>&nbsp;&nbsp;&nbsp;Change email id
                        </mat-panel-title>
                        <mat-panel-description>
                          It's a good idea to use a unique email id
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <form [formGroup]="formEmailId" (ngSubmit)="onSubmitEmailId()">
                        <mat-card>
                          <mat-card-content>
                            <mat-error *ngIf="formEmailIdInvalid">
                              Please fill the required field
                            </mat-error>
                            <mat-form-field class="full-width-input">
                              <input matInput placeholder="Current Email Id" formControlName="CurrentEmailId" required>
                              <mat-error>
                                Please provide a valid email id
                              </mat-error>
                            </mat-form-field>
                          </mat-card-content>
                          <mat-card-content>
                            <mat-error *ngIf="formEmailIdInvalid">
                              Please fill the required field
                            </mat-error>
                            <mat-form-field class="full-width-input">
                              <input matInput placeholder="New Email Id" formControlName="NewEmailId" required>
                              <mat-error>
                                Please provide a valid email id
                              </mat-error>
                            </mat-form-field>
                          </mat-card-content>
                          <mat-card-content>
                            <div class="text-left">
                              <button type="submit" mat-raised-button color="primary" cdkFocusInitial>Save
                                changes</button>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </form>
                    </mat-expansion-panel>

                    <mat-expansion-panel>
                      <mat-expansion-panel-header class="Security">
                        <mat-panel-title>
                          <i class="fa fa-lock"></i>&nbsp;&nbsp;&nbsp;Change password
                        </mat-panel-title>
                        <mat-panel-description>
                          It's a good idea to use a strong password that you don't use elsewhere
                        </mat-panel-description>
                      </mat-expansion-panel-header>
                      <form [formGroup]="formPassword" (ngSubmit)="onSubmitPassword()">
                        <mat-card>
                          <mat-card-content>
                            <mat-error *ngIf="formPasswordInvalid">
                              Please fill the required field
                            </mat-error>
                            <mat-form-field class="full-width-input">
                              <input type="password" matInput placeholder="Current password"
                                formControlName="CurrentPassword" required>
                              <mat-error>
                                Please provide a valid password
                              </mat-error>
                            </mat-form-field>
                          </mat-card-content>
                          <mat-card-content>
                            <mat-error *ngIf="formPasswordInvalid">
                              Please fill the required field
                            </mat-error>
                            <mat-form-field class="full-width-input">
                              <input type="password" matInput placeholder="New password" formControlName="NewPassword"
                                required>
                              <mat-error>
                                Please provide a valid password
                              </mat-error>
                            </mat-form-field>
                          </mat-card-content>
                          <mat-card-content>
                            <mat-error *ngIf="formPasswordInvalid">
                              Please fill the required field
                            </mat-error>
                            <mat-form-field class="full-width-input">
                              <input type="password" matInput placeholder="Retype new" formControlName="RetypePassword"
                                required>
                              <mat-error>
                                Please provide a valid password
                              </mat-error>
                            </mat-form-field>
                          </mat-card-content>
                          <mat-card-content>
                            <div class="text-left">
                              <button type="submit" mat-raised-button color="primary" cdkFocusInitial>Save
                                changes</button>
                            </div>
                          </mat-card-content>
                        </mat-card>
                      </form>
                    </mat-expansion-panel>

                  </mat-accordion>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>