

    <!-- ##### Google Maps Start ##### -->
    <div class="map-area mt-30">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d31428.55941049882!2d76.454109!3d10.052303!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x49fce9b2cf23f181!2sJai%20Bharath%20College%20of%20Management%20and%20Engineering%20Technology!5e0!3m2!1sen!2sin!4v1614061690472!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
    <!-- ##### Google Maps End ##### -->

    <!-- ##### Contact Area Start ##### -->
    <section class="contact-area">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="contact-content-area">
                        <div class="row">
                            <div class="col-12 col-md-4">
                                <div class="contact-content contact-information">
                                    <h4>ADDRESS</h4>
                                    <p>Jai Bharath College, JB Campus</p>
                                    <p>Arackappady, Vengola P.O</p>
                                    <p>Perumbavoor , Cochin</p>
                                    <p>Kerala 683 556 India</p>
                                </div>
                            </div>
                            
                            <div class="col-12 col-md-4">
                                <div class="contact-content contact-information">
                                    <h4>EMAIL</h4>
                                    <p>admissions@jaibharathmba.com</p>
                                    
                                </div>
                            </div>
                            <div class="col-12 col-md-4">
                                <div class="contact-content contact-information">
                                    <h4>PHONE</h4>
                                    <p>Phone:+91 72932 29051</p>
                                    <p>  Fax: 0484 2655001</p>
                                    <p>Mobile: 9447 188 020</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- ##### Contact Area End ##### -->

    <!-- ##### Contact Form Area Start ##### -->
    <div class="contact-form section-padding-0-100">
        <div class="container">
            <div class="row">
                <!-- Section Heading -->
                <div class="col-12">
                    <div class="section-heading">
                        <h2>Contact Us</h2>
                        <!--<p>Your email address will not be published. Required fields are marked.</p>-->
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12">
                    <!-- Contact Form Area -->
                    <div class="contact-form-area">
                        <form [formGroup]="formContactDetails" (ngSubmit)="onSubmit()" autocomplete="off">
                            <div class="row">
                                <div class="col-12 col-lg-4">
                                    <div class="form-group">
                                        <label for="contact-name">Full Name*:</label>
                                        <input type="text" class="form-control" formControlName="Name"
                                        [ngClass]="{ 'is-invalid': submitted && formControl.Name.errors }" placeholder="Full Name">
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <div class="form-group">
                                        <label for="contact-email">Email*:</label>
                                        <input type="email" class="form-control" formControlName="Email"
                                        [ngClass]="{ 'is-invalid': submitted && formControl.Email.errors }" placeholder="Enter your email id">
                                    </div>
                                </div>
                                <div class="col-12 col-lg-4">
                                    <div class="form-group">
                                        <label for="contact-number">Phone*:</label>
                                        <input type="text" class="form-control" formControlName="Phone"
                                        [ngClass]="{ 'is-invalid': submitted && formControl.Phone.errors }" placeholder="Enter your phone number">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label for="message">Details of admission test*:</label>
                                        <textarea class="form-control" formControlName="Message"
                                        [ngClass]="{ 'is-invalid': submitted && formControl.Message.errors }" cols="30" rows="10" placeholder="Write your details"></textarea>
                                    </div>
                                </div>
                                <div class="col-12 text-center">
                                    <button type="submit" class="btn crose-btn mt-15">Submit Now</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ##### Contact Form Area End ##### -->
