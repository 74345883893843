<div class="mt-5">
	<div class="container">
		<p><span>Jaibharath Group of Institution invites applications</span> for the posts of Principals,
			Assist. Principals, Director, Professors, and Associate/Assistant Professors in following disciplines:</p>
		<ul>
			<li>Engineering & Information Technology: Mechanical Computer Science & Engineering, Computer Application,
				Civil, Electrical, Electronics Communication</li>
			<li> Management & Commerce: Finance, Marketing, International Business, HR, Commerce</li>
			<li>Humanities & Social Sciences: English, Economics, Psychology, Hindi, Sanskrit, Geography. Medical &
				Allied Sciences: Physiotherapy for BPT, MPT (Preferred Specializations: Ortho/Sports) Medical Lab
				Technology, Nursing, Anatomy, Physiology)</li>
		</ul>
		<p><strong>Applications also invited for the Following jobs also:</strong></p>
		<p>Training & Placement Officer, Finance Officer, Librarian, Librarian, Deputy/Assistant, Digital Marketing
			Specialist, Sports Officer, Hostel Warden (Boys & Girls), Academic Coordinators, Admission Counsellors, Lab
			Assistant, Music, Dance & Foreign Languages Teachers.</p>
		<form [formGroup]="CareerForm" (ngSubmit)="onSubmit()" autocomplete="off">
			<div class="startformlisting careerform">
				<h4>Personal Information</h4>
				<div class="row">
					<div class="col-md-6">
						<div class="col-lg-12">
							<label>Full Name *</label>

							<input type="text" formControlName="Name"
								[ngClass]="{ 'is-invalid': submitted && formControl.Name.errors }" required="required">
						</div>
						<div class="col-lg-12">
							<label>Email Address *</label>

							<input type="email" formControlName="Email"
								[ngClass]="{ 'is-invalid': submitted && formControl.Email.errors }" required="required">
						</div>
						<div class="col-lg-12">
							<label>State of Residence *</label>

							<input type="text" formControlName="City"
								[ngClass]="{ 'is-invalid': submitted && formControl.City.errors }" required="required"
								placeholder="City" class="two-box">


							<input type="text" formControlName="State"
								[ngClass]="{ 'is-invalid': submitted && formControl.State.errors }" required="required"
								placeholder="State" class="two-box">


						</div>
					</div>
					<div class="col-md-6">
						<div class="col-lg-12">
							<label>Date Of Birth *</label>

							<input type="date" formControlName="DOB"
								[ngClass]="{ 'is-invalid': submitted && formControl.DOB.errors }" required="required">
						</div>
						<div class="col-lg-12">
							<label>Mobile Number *</label>

							<input type="text" formControlName="Phone"
								[ngClass]="{ 'is-invalid': submitted && formControl.Phone.errors }" required="required">
						</div>
					</div>
				</div>
				<h4>Application Details</h4>
				<div class="row">
					<div class="col-md-6">
						<div class="col-lg-12">
							<label>Post Applying for * </label>

							<select formControlName="PostFor"
								[ngClass]="{ 'is-invalid': submitted && formControl.PostFor.errors }">
								<option value="">select</option>
								<option value="Pro-VC">Pro-VC</option>
								<option value="Professor/Dean">Professor/Dean</option>
								<option value="Associate Professor">Associate Professor</option>
								<option value="Assistant Professor">Assistant Professor</option>
								<option value="Lecturer">Lecturer</option>

								<option value="audi">Non-Teaching Position</option>
							</select>
						</div>
						<div class="col-lg-12">
							<label>Specialization</label>

							<input type="text" formControlName="Specialization"
								[ngClass]="{ 'is-invalid': submitted && formControl.Specialization.errors }"
								required="required">
						</div>

					</div>
					<div class="col-md-6">
						<div class="col-lg-12">
							<label>School Applying for * </label>

							<select formControlName="SchoolFor"
								[ngClass]="{ 'is-invalid': submitted && formControl.SchoolFor.errors }">
								<option value="">select</option>
								<option value="Engineering & Information Technology">Engineering & Information
									Technology</option>
								<option value="Agriculture">Agriculture</option>
								<option value="Tourism and Hospitality">Tourism and Hospitality</option>
								<option value="Management & Commerce">Management & Commerce</option>
								<option value="Education">Education</option>
								<option value="Fashion Designing">Fashion Designing</option>
								<option value="Rehabilitation">Rehabilitation</option>
								<option value="Humanities & Social Science">Humanities & Social Science</option>
								<option value="Ayurvedic Medical College & Hospital">Ayurvedic Medical College &
									Hospital</option>
								<option value="Pharmacy">Pharmacy</option>
								<option value="Unani Medical College & Hospital">Unani Medical College & Hospital
								</option>
								<option value="Medical & Allied Sciences">Medical & Allied Sciences</option>
								<option value="University Polytechnic">University Polytechnic</option>
								<option value="Basic & Applied Sciences">Basic & Applied Sciences</option>
								<option value="Others">Others</option>
							</select>
						</div>
					</div>
				</div>
				<h4>Professional Details</h4>

				<div class="row">
					<div class="col-md-12">
						<h5>Qualifications</h5>
					</div>
					<div class="col-lg-12">
						<div class="over-f">
							<table>
								<tbody>
									<tr>
										<td class="dwnlodhead">
											<div class="head-tit">PROGRAM</div>
										</td>
										<td class="dwnlodhead">
											<div class="head-tit">COLLEGE/UNIVERSITY/BOARD</div>
										</td>
										<td class="dwnlodhead">
											<div class="head-tit">COURSE OF STUDY/STREAM</div>
										</td>
										<td class="dwnlodhead" colspan="2">
											<div class="head-tit">YEAR OF PASSING</div>
										</td>
									</tr>
									<tr *ngFor="let ProfessionalDetaills of ProfessionalDetaillsFormControl.controls; let i = index"
										[formGroup]="ProfessionalDetaills">
										<td>
											<div class="text-align">
												<div *ngIf="!ProfessionalDetaills.value.IsNew">
													{{ProfessionalDetaills.value.Program}}
												</div>
												<div *ngIf="ProfessionalDetaills.value.IsNew">
													<input type="text" formControlName="Program" class="qualifications"
														[ngClass]="{ 'is-invalid': submitted && ProfessionalDetaills.controls.Program.errors }">
												</div>
											</div>
										</td>
										<td>
											<div>
												<input type="text" formControlName="College" class="qualifications"
													[ngClass]="{ 'is-invalid': submitted && ProfessionalDetaills.controls.College.errors }">
											</div>
										</td>
										<td>
											<div>
												<input type="text" formControlName="Course" class="qualifications"
													[ngClass]="{ 'is-invalid': submitted && ProfessionalDetaills.controls.Course.errors }">
											</div>
										</td>
										<td>
											<div>
												<select formControlName="Year" class="qualifications"
													[ngClass]="{ 'is-invalid': submitted && ProfessionalDetaills.controls.Year.errors }">
													<option value="" selected="" disabled="">Select</option>
													<option value="{{year}}"
														*ngFor="let year of functionService.getYearList();">{{year}}
													</option>
												</select>
											</div>
										</td>
										<td>
											<div class="text-right">
												<button class="btn btn-success btn_add"
													*ngIf="ProfessionalDetaillsFormControl.controls.length==(i+1)"
													(click)="addProfessionalDetaills()"><i
														class="fa fa-plus"></i></button>
												&nbsp;&nbsp;
												<button class="btn btn-danger btn_add"
													*ngIf="ProfessionalDetaills.value.IsNew"
													(click)="removeProfessionalDetaills(i)"><i
														class="fa fa-minus"></i></button>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<h5>Experience</h5>
					</div>
					<div class="col-lg-12">
						<div class="over-f">
							<table>
								<tbody>
									<tr>
										<td class="dwnlodhead">
											<div class="head-tit">EXPERIENCE TYPE</div>
										</td>
										<td class="dwnlodhead">
											<div class="head-tit">NAME OF THE ORGANIZATION</div>
										</td>
										<td class="dwnlodhead">
											<div class="head-tit">DESIGNATION</div>
										</td>
										<td class="dwnlodhead">
											<div class="head-tit">SALARY DRAWN</div>
										</td>
										<td class="dwnlodhead">
											<div class="head-tit">START DATE</div>
										</td>
										<td class="dwnlodhead">
											<div class="head-tit">END DATE</div>
										</td>
										<td class="dwnlodhead">
											<div class="head-tit">IS THIS YOUR LATEST JOB?</div>
										</td>
										<td class="dwnlodhead"></td>
									</tr>
									<tr *ngFor="let Experience of ExperienceFormControl.controls; let i = index"
										[formGroup]="Experience">
										<td>
											<div>
												<select formControlName="ExperienceType" class="qualifications"
													[ngClass]="{ 'is-invalid': submitted && Experience.controls.ExperienceType.errors }">
													<option value="" selected="" disabled="">Select</option>
													<option value="Teaching Experience">Teaching Experience</option>
													<option value="Industry Experience">Industry Experience</option>
												</select>
											</div>
										</td>
										<td>
											<input type="text" formControlName="OrganizationName" class="qualifications"
												[ngClass]="{ 'is-invalid': submitted && Experience.controls.OrganizationName.errors }"
												required="required" placeholder="">
										</td>


										<td>
											<input type="text" formControlName="Designation" class="qualifications"
												[ngClass]="{ 'is-invalid': submitted && Experience.controls.Designation.errors }"
												required="required" placeholder="">
										</td>

										<td>
											<input type="text" formControlName="SalaryDrawn" class="qualifications"
												[ngClass]="{ 'is-invalid': submitted && Experience.controls.SalaryDrawn.errors }"
												required="required" placeholder="">
										</td>
										<td>
											<input type="date" formControlName="StartDate" class="qualifications"
												[ngClass]="{ 'is-invalid': submitted && Experience.controls.StartDate.errors }"
												required="required" placeholder="">
										</td>
										<td>
											<input type="date" formControlName="EndDate" class="qualifications"
												[ngClass]="{ 'is-invalid': submitted && Experience.controls.EndDate.errors }"
												required="required" placeholder="">
										</td>
										<td>
											<div>
												<select formControlName="LatestJob" class="qualifications"
													[ngClass]="{ 'is-invalid': submitted && Experience.controls.LatestJob.errors }">
													<option value="" selected="" disabled="">Select</option>
													<option value="Yes">Yes</option>
													<option value="No">No</option>
												</select>
											</div>
										</td>
										<td>
											<div class="text-right">
												<button class="btn btn-success btn_add"
													*ngIf="ExperienceFormControl.controls.length==(i+1)"
													(click)="addExperience()"><i class="fa fa-plus"></i></button>
												<button class="btn btn-danger btn_add"
													*ngIf="ExperienceFormControl.controls.length>(i+1)"
													(click)="removeExperience(i)"><i class="fa fa-minus"></i></button>
											</div>
										</td>

									</tr>


								</tbody>
							</table>
						</div>
					</div>

				</div>
				<div class="pad-adjust">
					<div class="row">
						<div class="col-md-12">
							<h5>Research/Others</h5>
						</div>
						<div class="col-lg-10">
							<p class="small-par">Number of Research Papers published/presented in reputed
								national/international journals (please provide details in your resume)</p>
						</div>
						<div class="col-md-2">
							<input type="text" formControlName="NoOfResearch" class="qualifications"
								[ngClass]="{ 'is-invalid': submitted && formControl.NoOfResearch.errors }">
						</div>
					</div>
					<div class="row">

						<div id="dynamic_fields2">
							<div class="row" *ngFor="let OthersForm of OthersFormControl.controls; let i = index"
								[formGroup]="OthersForm">
								<div class="col-md-2">
									<label>Others</label>
								</div>
								<div class="col-md-9">
									<input type="text" formControlName="Others" class="qualifications"
										[ngClass]="{ 'is-invalid': submitted && OthersForm.controls.Others.errors }">
								</div>
								<div class="col-md-1">
									<div class="text-center">
										<button class="btn btn-success btn_add"
											*ngIf="OthersFormControl.controls.length==(i+1)" (click)="addOthers()"><i
												class="fa fa-plus"></i></button>
										<button class="btn btn-danger btn_add"
											*ngIf="OthersFormControl.controls.length>(i+1)" (click)="removeOthers(i)"><i
												class="fa fa-minus"></i></button>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-lg-6">
							<label>Salary Expected</label>

							<input type="text" formControlName="SalaryExpected" class="qualifications"
								[ngClass]="{ 'is-invalid': submitted && formControl.SalaryExpected.errors }"
								required="required">
						</div>

					</div>
					<div class="row">
						<div class="col-md-6">
							<input type="file" [id]="'uploadCoverLetter'" accept="application/pdf, application/vnd.ms-excel" formControlName="CoverLetter"
								(change)="onCoverLetterSelected($event)" class="item-image-click">
							<div class="item-image-box">
								<label>Cover Letter :</label>
								<label [for]="'uploadCoverLetter'" class="holder"
									[ngClass]="{ 'is-invalid': submitted && formControl.CoverLetter.errors }">
									<img src="assets/images/image_upload_placeholder.jpg" alt="" width="100%"
										height="auto" />
									<br />
									<span *ngIf="CoverLetterLoaded">{{CoverLetterLoaded.name}}</span>
								</label>
							</div>
							<!-- <label>Cover Letter</label>
	
									<input type="file" name="fileToUpload" id="fileToUpload"> -->
						</div>
						<div class="col-md-6">
							<div class="col-lg-12">
								<input type="file" [id]="'uploadYourResume'" accept="application/pdf, application/vnd.ms-excel" formControlName="YourResume"
									(change)="onYourResumeSelected($event)" class="item-image-click">
								<div class="item-image-box">
									<label>Upload Your Resume :</label>
									<label [for]="'uploadYourResume'" class="holder"
										[ngClass]="{ 'is-invalid': submitted && formControl.YourResume.errors }">
										<img src="assets/images/image_upload_placeholder.jpg" alt="" width="100%"
											height="auto" />
										<br />
										<span *ngIf="YourResumeLoaded">{{YourResumeLoaded.name}}</span>
									</label>

								</div>
								<!-- <div class="resume-box">
										<label>Upload Your Resume</label>
										<input type="file" name="fileToUpload" id="fileToUpload">
									</div> -->

							</div>
						</div>


						<div class="col-lg-12">
							<div class="text-align">
								<input class="input-submit" id="submit-contact" value="Apply Now" type="submit">
							</div>
						</div>
					</div>
				</div>


			</div>



		</form>



	</div>
</div>