<div class="col-md-12" mat-dialog-title>
    <div class="main-head">
        <h1>Webinar Registration Form
            <span mat-button [mat-dialog-close]="false" class="pull-right close-popup" style="float: right;"><i
                    class="fa fa-times"></i></span>
        </h1>
    </div>
</div>
<mat-dialog-content>
    <div class="webinar-rgstr" *ngIf="WebinarDetails">
        <section class="profile-section">
            <input type="hidden" class="txt_csrfname" name="csrf_tocken" value="e20bc24cfebe60c206129a507347b664">

            <div class="container">
                <div class="row ">
                    <div class="col-md-12">
                        <div class="profile-edit experiance-head profile-box">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="web-form">
                                        <p class="web-p-1">Topic : {{WebinarDetails.Topic}}</p>
                                    </div>
                                </div>
                            </div>

                            <form [formGroup]="RegistrationForm" (ngSubmit)="onSubmit()" autocomplete="off"
                                *ngIf="isActive(WebinarDetails.Date)">
                                <div class="row">
                                    <div class="col-md-4 frm-lyt">
                                        <div class="profile-edit form-group mb-2">
                                            <label>First Name <span>*</span></label>
                                            <input type="text" formControlName="FirstName"
                                                [ngClass]="{ 'is-invalid': submitted && formControl.FirstName.errors }"
                                                class="form-control" placeholder="First Name" required="">
                                        </div>
                                    </div>
                                    <div class="col-md-4 frm-lyt">
                                        <div class="profile-edit form-group mb-2">
                                            <label>Last Name <span>*</span></label>
                                            <input type="text" formControlName="LastName"
                                                [ngClass]="{ 'is-invalid': submitted && formControl.LastName.errors }"
                                                class="form-control" placeholder="Last Name" required="">
                                        </div>
                                    </div>
                                    <div class="col-md-4 frm-lyt">
                                        <div class="form-group mb-2">
                                            <label>Email Address<span>*</span></label>
                                            <input type="email" formControlName="Email"
                                                [ngClass]="{ 'is-invalid': submitted && formControl.Email.errors }"
                                                class="form-control" placeholder="example@example.com" required="">
                                        </div>
                                    </div>
                                    <div class="col-md-4 frm-lyt">
                                        <div class="form-group mb-2">
                                            <label>Phone Number <span>*</span></label>
                                            <input type="text" formControlName="Phone"
                                                [ngClass]="{ 'is-invalid': submitted && formControl.Phone.errors }"
                                                class="form-control" placeholder="Phone Number" required="">
                                        </div>
                                    </div>


                                    <div class="col-md-8 ">
                                        <div class="row">
                                            <div class="col-md-4">
                                                <div class="form-group gender mb-2">
                                                    <label class="gender">Are you a student?
                                                        <span>*</span></label>
                                                    <div class="age-row">
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="IsStudent"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.IsStudent.errors }"
                                                                    value="Yes" checked="">
                                                                Yes
                                                            </label>
                                                        </div>
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="IsStudent"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.IsStudent.errors }"
                                                                    value="No">
                                                                No
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-8">
                                                <div class="form-group gender mb-2"
                                                    *ngIf="RegistrationForm.value.IsStudent=='Yes'">
                                                    <label class="gender">Course
                                                        <span>*</span></label>
                                                    <div class="age-row">
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="StudentCourse"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.StudentCourse.errors }"
                                                                    value="Plus Two" checked="">
                                                                Plus Two
                                                            </label>
                                                        </div>
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="StudentCourse"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.StudentCourse.errors }"
                                                                    value="Degree">
                                                                Degree
                                                            </label>
                                                        </div>
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="StudentCourse"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.StudentCourse.errors }"
                                                                    value="ITI">
                                                                ITI
                                                            </label>
                                                        </div>
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="StudentCourse"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.StudentCourse.errors }"
                                                                    value="Diploma">
                                                                Diploma
                                                            </label>
                                                        </div>
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="StudentCourse"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.StudentCourse.errors }"
                                                                    value="PG">
                                                                PG
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div *ngIf="RegistrationForm.value.IsStudent=='No'">
                                                    <div class="form-group gender mb-2">
                                                        <div class="age-row">
                                                            <label class="gender">Company/Guardian
                                                                <span>*</span></label>
                                                            <div class="radio-box">
                                                                <label class="radio-lable" for="">
                                                                    <input type="radio" class=" hide-radio"
                                                                        formControlName="CompanyGuardian"
                                                                        [ngClass]="{ 'is-invalid': submitted && formControl.CompanyGuardian.errors }"
                                                                        value="Company" checked="">
                                                                    Company
                                                                </label>
                                                            </div>
                                                            <div class="radio-box">
                                                                <label class="radio-lable" for="">
                                                                    <input type="radio" class=" hide-radio"
                                                                        formControlName="CompanyGuardian"
                                                                        [ngClass]="{ 'is-invalid': submitted && formControl.CompanyGuardian.errors }"
                                                                        value="Guardian">
                                                                    Guardian
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <br />
                                                    <br />
                                                    <div *ngIf="RegistrationForm.value.CompanyGuardian=='Company'"
                                                        class="row">

                                                        <div class="col-md-6 frm-lyt">
                                                            <div class="profile-edit form-group mb-2">
                                                                <input type="text" formControlName="CompanyName"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.CompanyName.errors }"
                                                                    class="form-control" placeholder="Company Name">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 frm-lyt">
                                                            <div class="profile-edit form-group mb-2">
                                                                <input type="number" formControlName="NoOfParticipant"
                                                                    (change)="addCompanyStaffRow()" minlength="1"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.NoOfParticipant.errors }"
                                                                    class="form-control"
                                                                    placeholder="No Of Participant">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="row"
                                                                *ngFor="let CompanyStaffNameForm of CompanyStaffNameFormControl.controls; let i = index"
                                                                [formGroup]="CompanyStaffNameForm">

                                                                <div class="col-md-12">
                                                                    <input type="text" class="form-control" formControlName="StaffName"
                                                                        [ngClass]="{ 'is-invalid': submitted && CompanyStaffNameForm.controls.StaffName.errors }"
                                                                        placeholder="Participant Name {{i+1}}" />
                                                                </div>
                                                                <br/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="RegistrationForm.value.CompanyGuardian=='Guardian'">

                                                        <div class="col-md-12 frm-lyt">
                                                            <div class="profile-edit form-group mb-2">
                                                                <input type="text" formControlName="GuardianName"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.GuardianName.errors }"
                                                                    class="form-control" placeholder="Guardian Name">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div class="row">
                                            <div class="col-md-12 ">
                                                <div class="form-group gender mb-2">
                                                    <label class="gender">How did you hear about this webinar?
                                                        <span>*</span></label>
                                                    <div class="age-row">
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="HearAboutWebinar"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.HearAboutWebinar.errors }"
                                                                    value="Social Media" checked="">
                                                                Social Media
                                                            </label>
                                                        </div>
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="HearAboutWebinar"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.HearAboutWebinar.errors }"
                                                                    value="Teachers">
                                                                Teachers
                                                            </label>
                                                        </div>
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <input type="radio" class=" hide-radio"
                                                                    formControlName="HearAboutWebinar"
                                                                    [ngClass]="{ 'is-invalid': submitted && formControl.HearAboutWebinar.errors }"
                                                                    value="Friend">
                                                                Friend
                                                            </label>
                                                        </div>
                                                        <div class="radio-box">
                                                            <label class="radio-lable" for="">
                                                                <div class="input-group">
                                                                    <span class="input-group-addon">
                                                                        <input type="radio" class=" hide-radio"
                                                                            formControlName="HearAboutWebinar"
                                                                            [ngClass]="{ 'is-invalid': submitted && formControl.HearAboutWebinar.errors }"
                                                                            value="Other">Other&nbsp;&nbsp;&nbsp;
                                                                    </span>
                                                                    <input type="text"
                                                                        formControlName="HearAboutWebinarOther"
                                                                        *ngIf="RegistrationForm.value.HearAboutWebinar=='Other'"
                                                                        [ngClass]="{ 'is-invalid': submitted && formControl.HearAboutWebinarOther.errors }"
                                                                        class="form-control" placeholder="Other">
                                                                </div>
                                                            </label>

                                                        </div>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12 frm-lyt">
                                        <div class="profile-edit form-group mb-2">
                                            <label>Please let us know if you have any questions for our speakers ahead
                                                of
                                                the webinar.</label>
                                            <textarea class="address form-control input" formControlName="Questions"
                                                [ngClass]="{ 'is-invalid': submitted && formControl.Questions.errors }"
                                                rows="3" data-form-field="Message" placeholder="Questions"
                                                id="message-form4-4v"></textarea>
                                        </div>
                                    </div>


                                </div>
                                <br>
                                <br>


                                <div class="col-md-2 offset-md-4 sub-btn mt-2 mb-5" style=" margin: auto;">
                                    <button class="btn btn-primary" id="form-btn" type="submit">Register</button>
                                </div>

                            </form>
                        </div>
                    </div>


                </div>
            </div>
        </section>
    </div>
</mat-dialog-content>