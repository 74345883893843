import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';
import { WebinarFormPopupComponent } from '../webinar-form-popup/webinar-form-popup.component';

@Component({
  selector: 'app-webinar-register-form-popup',
  templateUrl: './webinar-register-form-popup.component.html',
  styleUrls: ['./webinar-register-form-popup.component.css']
})
export class WebinarRegisterFormPopupComponent implements OnInit {
  WebinarId;
  WebinarDetails: any;
  RegistrationForm: any;
  formInvalid: boolean;
  submitted: boolean;
  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialogRef<WebinarFormPopupComponent>,
    private formBuilder: FormBuilder,) {
    this.datalistService.loaderActive = true;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      // this.WebinarId = params['WebinarId'];
      this.databaseOperationService.getWebinarById(this.WebinarId).subscribe((data: any) => {
        this.WebinarDetails = data;
        this.datalistService.loaderActive = false;
        console.log(this.WebinarDetails);
      });
    })
    this.RegistrationForm = this.formBuilder.group({
      WebinarId: this.WebinarId,
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      Phone: ['', Validators.required],
      IsStudent: ['', Validators.required],
      StudentCourse: [''],
      CompanyGuardian: [''],
      NoOfParticipant: [''],
      CompanyName: [''],
      GuardianName: [''],
      HearAboutWebinar: ['', Validators.required],
      HearAboutWebinarOther: [''],
      Questions: [''],
      ParticipantName: [''],
      CompanyStaffName: new FormArray([]),
    });
  }
  get CompanyStaffNameFormControl() { return this.formControl.CompanyStaffName as FormArray; }
  get formControl() { return this.RegistrationForm.controls; }
  addCompanyStaffRow(){
    let length=this.CompanyStaffNameFormControl.length;
    for (let index = length-1; index>=0 ; index--) {
      this.CompanyStaffNameFormControl.removeAt(index)
    }
    for (let index = 0; index < this.RegistrationForm.value.NoOfParticipant; index++) {
      this.CompanyStaffNameFormControl.push(this.formBuilder.group({
      StaffName: [''],
    }));
      
    }
    
  }
  onSubmit() {
    this.datalistService.loaderActive = true;
    this.formInvalid = false;
    this.submitted = false;
    if (this.RegistrationForm.valid) {
      let ParticipantName="";
      this.RegistrationForm.value.CompanyStaffName.forEach((element,index) => {
        ParticipantName=ParticipantName+(Number(index)+1)+". "+element.StaffName+"<br/>";
      });
      this.RegistrationForm.value.ParticipantName=ParticipantName;
      this.databaseOperationService.sendWebinarConfirmationMail(this.RegistrationForm.value).subscribe((data: any) => {
      });
      this.databaseOperationService.saveWebinarRegister(this.RegistrationForm.value).subscribe((data: any) => {
        this.datalistService.loaderActive = false;
        // this.functionService.showProcessMessage("Registered successfully ", true);
        // this.datalistService.loaderActive = false;
        this.dialog.close(false);
        if (data.WebinarRegisterId) {
          Swal.fire({
            title: 'Saved Successfully',
            icon: 'success',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          })
        }
        else {
          Swal.fire("Please provide valid information")
        }
      })
    } else {
      this.submitted = true;
      this.functionService.showProcessMessage("Please provide valid information", false);
      this.datalistService.loaderActive = false;
    }
  }

  dateTime = new Date();
  isActive(Date) {
    let date1 = formatDate(this.dateTime, 'yyyy-MM-dd', 'en_US');
    let date2 = formatDate(Date, 'yyyy-MM-dd', 'en_US');
    // this.dateTime
    // let webinarDate=Date.getTime();
    return (date2 >= date1)
  }
}
