<div class="testi">
    <div class="container">
        <!-- <p class="prj-title">Reviews & Ratings</p>-->
        <h1 class="prj-head">Listen to our Alumni</h1>
        <div class="testi-items">
            <ul id="testi-slider" class="testi-slider">
                <li>
                    <div class="row">
                        <div class="col-md-7">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="coma">
                                        <img src="assets/images/fwdmbahomescreen/Testimonial (3).png">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="testimonial-content">
                                        <div class="testi-box">
                                            <h3> Here from Alumni</h3>
                                            <p>
                                                Education is the passport to the future, for tomorrow belongs to those
                                                who prepare for it today" Yes, that is what I can talk about my
                                                college.Jai Bharath School of Management Studies made me to think what I
                                                want to be and what I should aim for. The teachers inspired me not only
                                                be just successful ,but also to be a good citizen and make my own mark.
                                                Today I can proudly say that I am the HR of my own IT start up
                                                KUDOWORLDTECHBAY PVT LTD.A good education and it's system can change who
                                                you were to what you want to be


                                            </p>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="testi-social">
                                                        <a href="">
                                                            <img src="assets/images/linkedin.png">
                                                            <p>Linkedin Profile</p>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="testi-name">
                                                        <p>MS.PARVATHY S KUMAR </p>
                                                        <p>2011 -2013 BATCH </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-5">
                            <div class="testimponial-img">
                                <img src="assets/images/al1.jpeg">
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="coma">
                                        <img src="assets/images/fwdmbahomescreen/Testimonial (3).png">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="testimonial-content">
                                        <div class="testi-box">
                                            <h3> Here from Alumni</h3>
                                            <p>
                                                Jai Bharath has provided me an excellent platform to study and excel
                                                during my professional life with a personal touch. I fondly and vividly
                                                remember the opportunities provided to nurture my skills, passion and to
                                                achieve my dreams and become an HR professional. Numerous activities
                                                which includes Lakshya and IGNIS 2020 ,not only given us the memorable
                                                campus life but also learned/experienced multiple facets of Management.
                                                The training, grooming, motivation and inspiration by Faculties have
                                                enabled me to become an Human Resource professional. I thank Jai Bharath
                                                Group of Institutions Management for yeoman service in the field of
                                                Education and r-- wish all the very best for future endeavors.


                                            </p>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="testi-social">
                                                        <a href="">
                                                            <img src="assets/images/linkedin.png">
                                                            <p>Linkedin Profile</p>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="testi-name">
                                                        <p>MR.MINHAZ SUBAIR </p>
                                                        <P>HR SUPERVISOR AT AMAZON </P>
                                                        <p>2018 -2020 BATCH </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-4">
                            <div class="testimponial-img">
                                <img src="assets/images/al2.jpeg">
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="coma">
                                        <img src="assets/images/fwdmbahomescreen/Testimonial (3).png">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="testimonial-content">
                                        <div class="testi-box">
                                            <h3> Here from Alumni</h3>
                                            <p>
                                                Jai Bharath has nurtured me to fulfil my ambition of becoming an HR
                                                professional in MNC.

                                                As I grew up in Idukki district in Kerala, most of my friends and
                                                relatives have advised me to go to Bangalore or outside Kerala for
                                                studying MBA.But I chose Jai Bharath and the institute has offered
                                                pedagogy, which is in line with industry requirements and prepare the
                                                students to take up jobs in MNCs .

                                                I am happy that I valued a few friends' suggestions and most importantly
                                                my heart when I decided to join at Jai Bharath.

                                                I thank all my faculties and management for moulding me a into a
                                                competent HR professional.


                                            </p>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="testi-social">
                                                        <a href="">
                                                            <img src="assets/images/linkedin.png">
                                                            <p>Linkedin Profile</p>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="testi-name">
                                                        <p>MS.NEETHU JOY </p>
                                                        <P>DIRECTOR (HR & MARKETING)</P>
                                                        <p>VJ PROCESSING SOLUTIONS PVT</p>
                                                        <p>2018 -2020 BATCH </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-4">
                            <div class="testimponial-img">
                                <img src="assets/images/al 4.jpg">
                            </div>
                        </div>
                    </div>
                </li>
                <li>
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="coma">
                                        <img src="assets/images/fwdmbahomescreen/Testimonial (3).png">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="testimonial-content">
                                        <div class="testi-box">
                                            <h3> Here from Alumni</h3>
                                            <p>
                                                Jai Bharath MBA has offered me unique opportunities and have guided me
                                                to differentiate myself from others during the recruitment process.
                                                From curriculum perspective, both the cultural and academic activities,
                                                the array of expert speakers sessions, sports etc were truly memorable
                                                during my two year journey at Jai Bharath and will be remembered
                                                forever. I thank Jai Bharath faculties and management for moulding me
                                                into a true professional, also I am indebted to all my batch mates for
                                                supporting and creating a learning ecosystem in all our off and on
                                                campus programs.


                                            </p>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="testi-social">
                                                        <a href="">
                                                            <img src="assets/images/linkedin.png">
                                                            <p>Linkedin Profile</p>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="testi-name">
                                                        <p>Mr.BASIL BOSE </p>
                                                        <P>HUMAN RESOURCES ADMINISTRATOR AT OPPO</P>
                                                        <!-- <p>VJ PROCESSING SOLUTIONS PVT</p> -->
                                                        <p>MBA 2018 -2020 BATCH </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-4">
                            <div class="testimponial-img">
                                <img src="assets/images/al 5.jpeg">
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="lSAction testi-controls">
                <a class="lSPrev" id="testiPrev"><img src="./assets/images/arrow-left.png" /></a>
                <a class="lSNext" id="testiNext"><img src="./assets/images/arrow-right.png" /></a>
            </div>
        </div>
    </div>
</div>