<div class="col-md-12" mat-dialog-title>
    <div class="main-head">
        <h1>{{formTitle}} Faculties name
            <span style="
            float: right;
        " mat-button [mat-dialog-close]="false" class="pull-right close-popup"><i class="fa fa-times"></i></span>
        </h1>
    </div>
</div>
<mat-dialog-content>
    <form [formGroup]="FacultiesForm">
        <div class="container-fluid">

            <div class="row">
                <div class="col-md-12">
                    <div class="forms">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <select (change)="changeCollege($event)" formControlName="CollegeId" class="form-control">
                                        <option value="">Select College</option>
                                        <option *ngFor="let allCollege of AllCollege" value="{{allCollege.CollegeId}}">
                                            {{allCollege.CollegeName}}</option>
                                    </select>
                                    </div>
                            </div>
                            <div class="col-md-12">
                                
                                <div class="form-group">
                                    <select formControlName="CourseId" class="form-control">
                                        <option value="">Select Course</option>
                                        <option *ngFor="let allCourse of AllCourse" value="{{allCourse.CourseId}}">
                                            {{allCourse.CourseName}}</option>
                                    </select>
                                    </div>
                            </div>
                            <div class="col-md-12">
                                <mat-card-content>
                                    <mat-form-field class="full-width-input">
                                        <input matInput placeholder="Faculties Name" formControlName="FacultiesName"
                                            required>
                                        <mat-error>
                                            Please provide a valid Faculties Name
                                        </mat-error>
                                    </mat-form-field>
                                </mat-card-content>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-right">
                                <div class="form-group">
                                    <button type="button" mat-raised-button color="primary" class="mat-info"
                                        (click)="onSubmit()">{{buttonLabel}}</button>&nbsp;&nbsp;
                                    <button type="button" mat-raised-button [mat-dialog-close]="false">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>