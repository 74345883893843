<div class="print-form">
    <button class="btn btn-primary pull-right" (click)="htmltoPDF()">Print</button>
</div>
<div id="parentdiv">
    <div class="main-head">
        <div class="head-line">
            <h1>KMAT Mock Registration</h1>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="manage-tbl">
                    <p class="text-center" *ngIf="!AllKmatMockRegistration.length">No data found</p>
                    <table mat-table [dataSource]="AllKmatMockRegistration" class="mat-elevation-z8" width="100%"
                        *ngIf="AllKmatMockRegistration.length">

                        <ng-container matColumnDef="SortId">
                            <th mat-header-cell *matHeaderCellDef width="30px"> # </th>
                            <td mat-cell *matCellDef="let element;let i=index;">{{i+1}}</td>
                        </ng-container>
                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef width="130px"> Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Email">
                            <th mat-header-cell *matHeaderCellDef width="130px"> Email Id </th>
                            <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Phone">
                            <th mat-header-cell *matHeaderCellDef width="100px"> Contact No. </th>
                            <td mat-cell *matCellDef="let element"> {{element.Phone}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Address">
                            <th mat-header-cell *matHeaderCellDef width="200px"> Address </th>
                            <td mat-cell *matCellDef="let element"> {{element.Address}} </td>
                        </ng-container>

                        <ng-container matColumnDef="CollegeName">
                            <th mat-header-cell *matHeaderCellDef width="190px"> College Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.CollegeName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="CourseName">
                            <th mat-header-cell *matHeaderCellDef width="130px"> Course Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.CourseName}} </td>
                        </ng-container>
                        <ng-container matColumnDef="CourseYear">
                            <th mat-header-cell *matHeaderCellDef width="70px"> Course Year </th>
                            <td mat-cell *matCellDef="let element"> {{element.CourseYear}} </td>
                        </ng-container>

                        <ng-container matColumnDef="CreatedDate">
                            <th mat-header-cell *matHeaderCellDef width="130px"> Messaged On </th>
                            <td mat-cell *matCellDef="let element"> {{element.CreatedDate}}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="EnquiriesColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: EnquiriesColumns;" (click)="getRecord(row)"
                            class="cursorView"></tr>

                    </table>
                </div>
            </div>
        </div>
    </div>
</div>