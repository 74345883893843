<div class="message-auto-popup" *ngIf="functionService.datalistService.messageAutoPopupData">
    <div class="alert alert-success" *ngIf="functionService.datalistService.messageAutoPopuSuccess">
        <div class="row">
            <div class="col-sm-2">
                <i class="fa fa-check" aria-hidden="true"></i>
            </div>
            <div class="col-sm-10">
                {{functionService.datalistService.messageAutoPopupData}}
            </div>
        </div>
    </div>
    <div class="alert alert-danger" *ngIf="!functionService.datalistService.messageAutoPopuSuccess">
        <div class="row">
            <div class="col-sm-2">
                <i class="fa fa-times" aria-hidden="true"></i>
            </div>
            <div class="col-sm-10">
                {{functionService.datalistService.messageAutoPopupData}}
            </div>
        </div>
    </div>
</div>
<div class="loader-fix" *ngIf="functionService.datalistService.loaderActive">
    <div class="loader-spinner">
        <div class="row">
            <div class="col-md-12">
                <mat-spinner></mat-spinner>
            </div>
        </div>
    </div>
</div>
<div class="dropdown dropdown-notification show" *ngIf="datalistService.isOpenNoti">
    <div class="dropdown-menu wd-300 pd-0-force show" x-placement="bottom-start">
     <a (click)="datalistService.isOpenNoti=false;viewNotiLength = 3;" class="close" aria-label="close" title="close">×</a>
         <div class="dropdown-menu-header">
         
             <label>Notifications</label>
             <!-- <a href="">Mark All as Read</a> -->
         </div>
 
         <div class="media-list">
             <span *ngFor="let allNotification of AllNotification; let i = index;">
                 <a href="{{allNotification.Link}}" target="_blank" class="media-list-link read" *ngIf="i<viewNotiLength">
                     <div class="media pd-x-20 pd-y-15">
                      
                         <div class="media-body">
                             <p class="tx-13 mg-b-0"><strong class="tx-medium">{{allNotification.Title}}</strong></p>
                             <span class="tx-12">{{allNotification.CreatedDate | date:'MMM d, yyyy h:m a'}}</span>
                         </div>
                     </div>
                 </a>
             </span>
 
             <div class="media-list-footer" *ngIf="viewNotiLength<AllNotification.length">
                 <a (click)="viewNotiLength=AllNotification.length" class="tx-12"><i class="fa fa-angle-down mg-r-5"></i> Show All Notifications</a>
             </div>
         </div>
     </div>
 </div>

<router-outlet (activate)="onActivate($event)"></router-outlet>