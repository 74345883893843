import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SiteLayoutComponent } from './layout/site-layout/site-layout.component';
import { HomeComponent } from './site/home/home.component';
import { CourseComponent } from './site/course/course.component';
import { AboutComponent } from './site/about/about.component';
import { AboutGroupComponent } from './site/about-group/about-group.component';
import { AboutMouComponent } from './site/about-mou/about-mou.component';
import { LibraryComponent } from './site/library/library.component';
import { PlacementsComponent } from './site/placements/placements.component';
import { ActivitiesComponent } from './site/activities/activities.component';
import { JobfitAnalysisComponent } from './site/jobfit-analysis/jobfit-analysis.component';
import { AntiRaggingComponent } from './site/anti-ragging/anti-ragging.component';
import { CareerComponent } from './site/career/career.component';
import { PrivacyPolicyComponent } from './site/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './site/terms-conditions/terms-conditions.component';
import { ContactComponent } from './site/contact/contact.component';
import { OnlineAdmissionComponent } from './site/online-admission/online-admission.component';
import { NewsEventsComponent } from './site/news-events/news-events.component';
import { from } from 'rxjs';
import { StudentsFeedbackComponent } from './site/students-feedback/students-feedback.component';
import { WebinarComponent } from './site/webinar/webinar.component';
import { WebinarListComponent } from './site/webinar/webinar-list/webinar-list.component';
import { NewsDetailsComponent } from './site/news-details/news-details.component';
import { CarrerWithusComponent } from './site/carrer-withus/carrer-withus.component';

import { HireJbComponent } from './site/hire-jb/hire-jb.component';
import { AdminAuthGuardService } from './guard/admin-auth-guard.service';
import { LoginComponent } from './site/login/login.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { HeaderComponent } from './layout/header/header.component';
import { ViewFaclityFeedbackComponent } from './site/admin/view-faclity-feedback/view-faclity-feedback.component';
import { ViewEnquiryComponent } from './site/admin/view-enquiry/view-enquiry.component';
import { ManageCollegeComponent } from './site/admin/manage-college/manage-college.component';
import { MyAccountComponent } from './site/admin/my-account/my-account.component';
import { ManageFaclityComponent } from './site/admin/manage-faclity/manage-faclity.component';
import { HumanResourceComponent } from './site/courses/human-resource/human-resource.component';
import { OperationManagementComponent } from './site/courses/operation-management/operation-management.component';
import { MarketingComponent } from './site/courses/marketing/marketing.component';
import { InternationalBusinessComponent } from './site/courses/international-business/international-business.component';
import { InformationSystemComponent } from './site/courses/information-system/information-system.component';
import { FinanceComponent } from './site/courses/finance/finance.component';
import { PlacementRptComponent } from './site/placement-rpt/placement-rpt.component';
import { ViewCareerComponent } from './site/admin/view-career/view-career.component';
import { ViewOnlineAdmissionComponent } from './site/admin/view-online-admission/view-online-admission.component';
import { GrievanceComponent } from './site/grievance/grievance.component';
import { StudentPortalComponent } from './site/student-portal/student-portal.component';
import { ViewCourseEnquiryComponent } from './site/admin/view-course-enquiry/view-course-enquiry.component';
import { ViewRecruiterConnectComponent } from './site/admin/view-recruiter-connect/view-recruiter-connect.component';
import { ViewGrievanceComponent } from './site/admin/view-grievance/view-grievance.component';
import { LandingComponent } from './site/landing/landing.component';
import { ManageLandingEnquiryComponent } from './site/admin/manage-landing-enquiry/manage-landing-enquiry.component';
import { ViewKmatMockRegistrationComponent } from './site/admin/view-kmat-mock-registration/view-kmat-mock-registration.component';
import { FacultyProfileComponent } from './site/faculty-profile/faculty-profile.component';
import { ManagNotificationComponent } from './site/admin/manag-notification/manag-notification.component';
import { ViewJobFairComponent } from './site/admin/view-job-fair/view-job-fair.component';
import { GalleryComponent } from './site/gallery/gallery.component';
import { ViewDetailsComponent } from './site/admin/view-online-admission/view-details/view-details.component';




const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'header', component:HeaderComponent  },

  {
    path: '',
    component: SiteLayoutComponent,
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'about', component: AboutComponent },
      { path: 'about-group', component: AboutGroupComponent },
      { path: 'about-mou', component: AboutMouComponent },
      { path: 'course', component: CourseComponent },
      { path: 'library', component: LibraryComponent },
      { path: 'placements', component: PlacementsComponent },
      { path: 'activities', component: ActivitiesComponent },
      { path: 'faculty-profile', component: FacultyProfileComponent},
      { path: 'gallery', component: GalleryComponent},

      { path: 'anti-ragging', component: AntiRaggingComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'terms-conditions', component: TermsConditionsComponent },
      { path: 'contact', component: ContactComponent },
      { path: 'online-admission', component: OnlineAdmissionComponent },
      { path: 'news-events', component: NewsEventsComponent },
      { path: 'students-feedback', component: StudentsFeedbackComponent },
      { path: 'webinar', component: WebinarComponent },
     
      
      
      { path: 'blog-events/:sortId/:URLValue', component: NewsDetailsComponent },
      { path: 'blog-details/:sortId/:URLValue', component: NewsDetailsComponent },
      { path: 'event-details/:sortId/:URLValue', component: NewsDetailsComponent },
      { path: 'carrer-withus', component: CarrerWithusComponent },
      { path: 'hire-jb', component: HireJbComponent },
      { path: 'human-resource', component: HumanResourceComponent },
      { path: 'operation-management', component:OperationManagementComponent },
      { path: 'marketing', component: MarketingComponent },
      { path: 'international-business',component: InternationalBusinessComponent},
      { path: 'information-system', component: InformationSystemComponent},
      { path: 'finance', component: FinanceComponent},
      {path: 'placement-rpt',component: PlacementRptComponent},
      { path: 'grievance' ,component: GrievanceComponent},
      { path: 'student-portal' ,component:StudentPortalComponent},
      { path: 'landing' ,component:LandingComponent},
      { path: 'webinar-details/:WebinarId', component: WebinarListComponent },
      
      
      {
        path: '',
        component: AdminLayoutComponent,
        canActivate: [AdminAuthGuardService],
        children: [
          {
            path: 'my-account',
            component: MyAccountComponent
          },
          {
            path: 'faclity-feedback',
            component: ViewFaclityFeedbackComponent
          },
          {
            path: 'enquiry',
            component: ViewEnquiryComponent
          },
          {
            path: 'landing-enquiry',
            component: ManageLandingEnquiryComponent
          },
          {
            path: 'view-recruiter-connect',
            component: ViewRecruiterConnectComponent
          },
          {
            path: 'course-enquiry',
            component: ViewCourseEnquiryComponent
          },
          {
            path: 'manage-faclity',
            component: ManageFaclityComponent
          },
          {
            path: 'manage-college',
            component: ManageCollegeComponent
          },
          {
            path: 'view-career',
            component: ViewCareerComponent
          },
          {
            path: 'view-online-admission',
            component: ViewOnlineAdmissionComponent
          },
          {
            path: 'view-details/:OnlineAdmissionId',
            component: ViewDetailsComponent
          },
          {
            path: 'view-grievance',
            component: ViewGrievanceComponent
          },
          {
            path: 'view-kmat-mock-registration',
            component: ViewKmatMockRegistrationComponent
          },
          {
            path: 'manag-notification',
            component: ManagNotificationComponent
          },
          {
            path: 'view-job-fair',
            component: ViewJobFairComponent
          },
        ]
      },

    ]
  },
  {
    path: 'site-cpanel',
    component: LoginComponent
  },
  { path: 'jobfitment', component: JobfitAnalysisComponent },
  { path: 'career', component: CareerComponent },
  
  

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      useHash: true,
      anchorScrolling: 'enabled'
    }),
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule { }