<div class="main-head" mat-dialog-title>
    <div class="head-line">
        <h1>Feedback<span style="
            float: right;
        " mat-button [mat-dialog-close]="false" class="pull-right close-popup"><i class="fa fa-times"></i></span></h1>
    </div>
</div>
<div class="container feedback-view " *ngIf="FacultiesDetail">
    <div class="row">
        <div class="col-md-6">
            <p>
                <b>Name</b> : {{FacultiesDetail.FacultiesName}}
            </p>
            <p>
                <b>College Name</b> : {{FacultiesDetail.CollegeName}}
            </p>
            <p>
                <b>Course Name</b> : {{FacultiesDetail.CourseName}}
            </p>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-md-12">
            <div class="form-group feedback-radio">
                <ol>
                    <li>
                        <div class="row">
                            <div class="col-md-6">
                                Was well-Prepared
                            </div>
                            <div class="col-md-6">
                                <mat-progress-bar mode="determinate"
                                    [class]="updateColor(FacultiesDetail.WasWellPrepared/(FacultiesDetail.TotalFeedback*4)*100)"
                                    value="{{FacultiesDetail.WasWellPrepared/(FacultiesDetail.TotalFeedback*4)*100}}">
                                </mat-progress-bar>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="row">
                            <div class="col-md-6">
                                Gave interesting and informative classes
                            </div>
                            <div class="col-md-6">
                                <mat-progress-bar mode="determinate"
                                    [class]="updateColor(FacultiesDetail.InterestingAndInformative/(FacultiesDetail.TotalFeedback*4)*100)"
                                    value="{{FacultiesDetail.InterestingAndInformative/(FacultiesDetail.TotalFeedback*4)*100}}">
                                </mat-progress-bar>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="row">
                            <div class="col-md-6">
                                Was good at explaining things
                            </div>
                            <div class="col-md-6">
                                <mat-progress-bar mode="determinate"
                                    [class]="updateColor(FacultiesDetail.GoodExplaining/(FacultiesDetail.TotalFeedback*4)*100)"
                                    value="{{FacultiesDetail.GoodExplaining/(FacultiesDetail.TotalFeedback*4)*100}}">
                                </mat-progress-bar>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="row">
                            <div class="col-md-6">
                                Was effective in leading the class
                            </div>
                            <div class="col-md-6">
                                <mat-progress-bar mode="determinate"
                                    [class]="updateColor(FacultiesDetail.EffectiveInLeading/(FacultiesDetail.TotalFeedback*4)*100)"
                                    value="{{FacultiesDetail.EffectiveInLeading/(FacultiesDetail.TotalFeedback*4)*100}}">
                                </mat-progress-bar>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="row">
                            <div class="col-md-6">
                                Was receptive to students’ question
                            </div>
                            <div class="col-md-6">
                                <mat-progress-bar mode="determinate"
                                    [class]="updateColor(FacultiesDetail.ReceptiveToStudentQuestion/(FacultiesDetail.TotalFeedback*4)*100)"
                                    value="{{FacultiesDetail.ReceptiveToStudentQuestion/(FacultiesDetail.TotalFeedback*4)*100}}">
                                </mat-progress-bar>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="row">
                            <div class="col-md-6">
                                Stimulated interest in the subject
                            </div>
                            <div class="col-md-6">
                                <mat-progress-bar mode="determinate"
                                    [class]="updateColor(FacultiesDetail.StimulatedInterestInSubject/(FacultiesDetail.TotalFeedback*4)*100)"
                                    value="{{FacultiesDetail.StimulatedInterestInSubject/(FacultiesDetail.TotalFeedback*4)*100}}">
                                </mat-progress-bar>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="row">
                            <div class="col-md-6">
                                Stimulated discussion on the subject
                            </div>
                            <div class="col-md-6">
                                <mat-progress-bar mode="determinate"
                                    [class]="updateColor(FacultiesDetail.StimulatedDiscussionOnSubject/(FacultiesDetail.TotalFeedback*4)*100)"
                                    value="{{FacultiesDetail.StimulatedDiscussionOnSubject/(FacultiesDetail.TotalFeedback*4)*100}}">
                                </mat-progress-bar>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="row">
                            <div class="col-md-6">
                                Stimulated me to think and Learn
                            </div>
                            <div class="col-md-6">
                                <mat-progress-bar mode="determinate"
                                    [class]="updateColor(FacultiesDetail.StimulatedMeToThink/(FacultiesDetail.TotalFeedback*4)*100)"
                                    value="{{FacultiesDetail.StimulatedMeToThink/(FacultiesDetail.TotalFeedback*4)*100}}">
                                </mat-progress-bar>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="row">
                            <div class="col-md-6">
                                Had a good rapport with the class
                            </div>
                            <div class="col-md-6">
                                <mat-progress-bar mode="determinate"
                                    [class]="updateColor(FacultiesDetail.GoodRapport/(FacultiesDetail.TotalFeedback*4)*100)"
                                    value="{{FacultiesDetail.GoodRapport/(FacultiesDetail.TotalFeedback*4)*100}}">
                                </mat-progress-bar>
                            </div>
                        </div>
                    </li>

                    <li>
                        <div class="row">
                            <div class="col-md-6">
                                Was available to answer question in office hours
                            </div>
                            <div class="col-md-6">
                                <mat-progress-bar mode="determinate"
                                    [class]="updateColor(FacultiesDetail.AvailableToQuestionInOfficeHours/(FacultiesDetail.TotalFeedback*4)*100)"
                                    value="{{FacultiesDetail.AvailableToQuestionInOfficeHours/(FacultiesDetail.TotalFeedback*4)*100}}">
                                </mat-progress-bar>
                            </div>
                        </div>
                    </li>

                </ol>
            </div>
        </div>
        <div class="col-md-12 feedback-comment">
        <h3>Other Comments</h3>
            <p *ngFor="let AllOtherCommentsByFaculties of AllOtherCommentsByFaculties">
                <b>{{AllOtherCommentsByFaculties.FirstName}} {{AllOtherCommentsByFaculties.LastName}}</b>
                <br/>
                {{AllOtherCommentsByFaculties.OtherComment}}
            </p>
            <br/>
            <br/>
        </div>
    </div>
</div>