<div id="main-banner">
    <div class="container-fluid">
        <div id="demo" class="carousel slide" data-ride="carousel">

            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="assets/images/placement/place-banner.jpg">
                    <div class="carousel-caption">
                        <div class="ban-title">
                            <h1 class="ban-head"> Placement Report</h1>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="plcement-rpt">
    <div class="container">
        <div class="pls-cls">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="placement-head">Placement Report</h1>
                </div>
            </div>
            <ul class="nav nav-pills placement-tabs">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="pill" href="#year1">2017-2018</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="pill" href="#year2">2018-2019</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="pill" href="#year3">2019-2020</a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="year1">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">
                                <div class="row">
                                    <div class="col-md-2"></div>
                                    <div class="col-md-8">
                                        <canvas baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels"
                                            [options]="options" [colors]="chartColors" [chartType]="doughnutChartType">
                                        </canvas>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                                <div class="row">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Management</th>
        
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="pack">Average package</td>
                                                    <td>4 LPA</td>
        
                                                </tr>
                                                <tr>
                                                    <td class="pack">Highest package</td>
                                                    <td>6.7 LPA</td>
        
                                                </tr>
                                                <tr>
                                                    <td class="pack">Median package</td>
                                                    <td>4.5 LPA</td>
        
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6">
                                        <div class="place-percent">
                                            <h4>Percentage of Students Placed</h4>
                                            <h6 class="sub-bar">Management</h6>
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="70"
                                                    aria-valuemin="0" aria-valuemax="100" style="width:70%">
                                                    <span class="first"> Marketing</span> <span class="second"> 70%</span>
                                                </div>
                                            </div>
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="70"
                                                    aria-valuemin="0" aria-valuemax="100" style="width:58%">
        
                                                    <span class="first">  Human Resource</span> <span class="second">58%</span>
                                                </div>
                                            </div>
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="70"
                                                    aria-valuemin="0" aria-valuemax="100" style="width:58%">
        
                                                    <span class="first">MBA Finance</span> <span class="second">67%</span>
                                                </div>
                                            </div>
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="70"
                                                    aria-valuemin="0" aria-valuemax="100" style="width:60%">
        
                                                    <span class="first"> IB</span> <span class="second">60%</span>
                                                </div>
                                            </div>
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="70"
                                                    aria-valuemin="0" aria-valuemax="100" style="width:64%">
        
                                                    <span class="first"> Information System</span> <span class="second">64%</span>
                                                </div>
                                            </div>
        
                                        </div>
                                    </div>
                                    <div class="col-md-3"></div>
                                    
                                </div>
                               

                            </div>
                            <div class="row">
                                <div class="col-md-2"></div>
                                <div class="col-md-8">
                                    <div class="placement-points">
                                        <ul class="points">
                                            <li class="li-point"><span class="lnr lnr-apartment"></span>Total no of companies
                                                visiting in 2017-2018 </li>
                                            <li class="li-pnt"><span class="lnr lnr-arrow-right-circle"></span>90 companies
                                                Including Congnizent , TCS</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-2"></div>
                            </div>
                           
                        </div>

                    </div>
                </div>
                <div class="tab-pane fade" id="year2">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">

                                <div class="row">
                                    <div class="col-md-2"></div>
                                    <div class="col-md-8">
                                        <canvas baseChart [data]="doughnutChartData2" [labels]="doughnutChartLabels2"
                                            [options]="options" [colors]="chartColors" [chartType]="doughnutChartType">
                                        </canvas>
                                    </div>
                                </div>
                                <br/>
                                <br/>
<div class="row">
    <div class="col-md-3"></div>
    <div class="col-md-6">
        <table class="table tb">
            <thead>
                <tr>
                    <th></th>
                    <th>Management</th>

                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="pack">Average package</td>
                    <td>3.5 LPA</td>

                </tr>
                <tr>
                    <td class="pack">Highest package</td>
                    <td>7.1 LPA</td>

                </tr>
                <tr>
                    <td class="pack">Median package</td>
                    <td>5.5 LPA</td>

                </tr>
            </tbody>
        </table>

    </div>
    <div class="col-md-3"></div>
</div>
                      <div class="row">
                          <div class="col-md-3"></div>
                          <div class="col-md-6">
                            <div class="place-percent">
                                <h4>Percentage of Students Placed</h4>
                                <h6 class="sub-bar">Management</h6>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="70"
                                        aria-valuemin="0" aria-valuemax="100" style="width:67%">
                                        <span class="first"> Marketing</span> <span class="second"> 67%</span>
                                    </div>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="70"
                                        aria-valuemin="0" aria-valuemax="100" style="width:54%">

                                        <span class="first">  Human Resource</span> <span class="second">54%</span>
                                    </div>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="70"
                                        aria-valuemin="0" aria-valuemax="100" style="width:59%">

                                        <span class="first">MBA Finance</span> <span class="second">59%</span>
                                    </div>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="70"
                                        aria-valuemin="0" aria-valuemax="100" style="width:78%">

                                        <span class="first"> IB</span> <span class="second">78%</span>
                                    </div>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" aria-valuenow="70"
                                        aria-valuemin="0" aria-valuemax="100" style="width:67%">

                                        <span class="first"> Information System</span> <span class="second">67%</span>
                                    </div>
                                </div>

                            </div>

                        </div> 
                          </div>
                          <div class="col-md-3"></div>
                      
                      </div>      
                           <div class="row">
                               <div class="col-md-2"></div>
                               <div class="col-md-8">
                                <div class="placement-points">
                                    <ul class="points">
                                        <li class="li-point"><span class="lnr lnr-apartment"></span>Total no of companies
                                            visiting in 2019-2020 </li>
                                        <li class="li-pnt"><span class="lnr lnr-arrow-right-circle"></span>90 companies
                                            Including IBM , Wipro</li>
                                    </ul>
                                </div>
                               </div>
                               <div class="col-md-2"></div>
                           </div>    
                           
                        </div>

                    </div>
                </div>
                <div class="tab-pane fade" id="year3">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="container">

                                <div class="row">
                                    <div class="col-md-2"></div>
                                    <div class="col-md-8">
                                        <canvas baseChart [data]="doughnutChartData3" [labels]="doughnutChartLabels3"
                                            [options]="options" [colors]="chartColors" [chartType]="doughnutChartType">
                                        </canvas>
                                    </div>
                                </div>
                                <br/>
                                <br/>
                                <div class="row">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>Management</th>
        
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="pack">Average package</td>
                                                    <td>2.7 LPA</td>
        
                                                </tr>
                                                <tr>
                                                    <td class="pack">Highest package</td>
                                                    <td>5.9 LPA</td>
        
                                                </tr>
                                                <tr>
                                                    <td class="pack">Median package</td>
                                                    <td>4.00 LPA</td>
        
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                                <div class="row">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-6">
                                        <div class="place-percent">
                                            <h4>Percentage of Students Placed</h4>
                                            <h6 class="sub-bar">Management</h6>
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="70"
                                                    aria-valuemin="0" aria-valuemax="100" style="width:80%">
                                                    <span class="first"> Marketing</span> <span class="second"> 80%</span>
                                                </div>
                                            </div>
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="70"
                                                    aria-valuemin="0" aria-valuemax="100" style="width:75%">
        
                                                    <span class="first"> Human Resource</span> <span class="second">75%</span>
                                                </div>
                                            </div>
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="70"
                                                    aria-valuemin="0" aria-valuemax="100" style="width:72%">
        
                                                    <span class="first">MBA Finance</span> <span class="second">72%</span>
                                                </div>
                                            </div>
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="70"
                                                    aria-valuemin="0" aria-valuemax="100" style="width:80%">
        
                                                    <span class="first"> IB</span> <span class="second">80%</span>
                                                </div>
                                            </div>
                                            <div class="progress">
                                                <div class="progress-bar" role="progressbar" aria-valuenow="70"
                                                    aria-valuemin="0" aria-valuemax="100" style="width:78%">
        
                                                    <span class="first"> Information System</span> <span class="second">78%</span>
                                                </div>
                                            </div>
        
                                        </div>
        
                                    </div>
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                               <div class="row">
                                   <div class="col-md-2"></div>
                                   <div class="col-md-8">
                                    <div class="placement-points">
                                        <ul class="points">
                                            <li class="li-point"><span class="lnr lnr-apartment"></span>Total no of companies
                                                visiting in 2017-2018 </li>
                                            <li class="li-pnt"><span class="lnr lnr-arrow-right-circle"></span>75 companies
                                                Including infosys , wipro etc</li>
                                        </ul>
                                    </div>
                                   </div>
                                   <div class="col-md-2"></div>
                               </div> 
                          
                        </div>

                    </div>
                </div>

            </div>
            <div class="log-rpstn">
                <div class="container">
                    <div class="logo-style">
                        <div class="row">
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log1.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log51.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log2.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log3.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log4.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log5.jpg">
                            </div>
                        </div>
                    </div>
                    <div class="logo-style-second">
                        <div class="row">
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log6.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log7.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log8.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log9.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log10.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log11.jpg">
                            </div>
                        </div>
                    </div>
                    <div class="logo-style-third">
                        <div class="row">
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log12.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log13.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log14.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log15.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log16.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log17.jpg">
                            </div>
                        </div>
                    </div>
                    <div class="logo-style-fourth">
                        <div class="row">
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log18.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log19.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log20.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log21.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log22.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log23.jpg">
                            </div>
                        </div>
                    </div>
                    <div class="logo-style-fifth">
                        <div class="row">
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log24.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log25.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log26.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log27.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log28.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log29.jpg">
                            </div>
                        </div>
                    </div>
                    <div class="logo-style-sixth">
                        <div class="row">
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log30.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log31.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log32.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log33.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log34.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log35.jpg">
                            </div>
                        </div>
                    </div>
                    <div class="logo-style-seventh">
                        <div class="row">
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log36.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log37.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log38.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log39.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log40.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log41.jpg">
                            </div>
                        </div>
                    </div>
                    <div class="logo-style-seventh">
                        <div class="row">
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log42.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log43.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log44.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log45.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log46.jpg">
                            </div>
                            <div class="col-md-2 col-4">
                                <img class="log" src="assets/images/log-img/log47.jpg">
                            </div>
                           
                        </div>
                        <div class="logo-style-seventh">
                            <div class="row">
                                
                                <div class="col-md-2 col-4">
                                    <img class="log" src="assets/images/log-img/log48.jpg">
                                </div>
                                <div class="col-md-2 col-4">
                                    <img class="log" src="assets/images/log-img/log50.jpg">
                                </div> 
                                <div class="col-md-2 col-4">
                                    <img class="log" src="assets/images/log-img/log52.jpg">
                                </div>
                                <div class="col-md-2 col-4">
                                    <img class="log" src="assets/images/log-img/log53.jpg">
                                </div> 
                                <div class="col-md-2 col-4">
                                    <img class="log" src="assets/images/log-img/log54.jpg">
                                </div>
                                <div class="col-md-2 col-4">
                                    <img class="log" src="assets/images/log-img/log55.jpg">
                                </div>  
                                              
                                
                            </div>
                    </div>
              
                <div class="logo-style-seventh">
                    <div class="row">
                        <div class="col-md-2 col-4">
                            <img class="log" src="assets/images/log-img/log56.jpg">
                        </div>
                        <div class="col-md-2 col-4">
                            <img class="log" src="assets/images/log-img/log57.jpg">
                        </div>
                        <div class="col-md-2 col-4">
                            <img class="log" src="assets/images/log-img/log58.jpg">
                        </div>
                        <div class="col-md-2 col-4">
                            <img class="log" src="assets/images/log-img/log59.jpg">
                        </div>  
                        <div class="col-md-2 col-4">
                            <img class="log" src="assets/images/log-img/log60.jpg">
                        </div>
                        <div class="col-md-2 col-4">
                            <img class="log" src="assets/images/log-img/log61.jpg">
                        </div>                
                         
                    </div>
                </div>
                <div class="logo-style-seventh">
                    <div class="row">
                        <div class="col-md-2 col-4 offset-md-4">
                            <img class="log" src="assets/images/log-img/log62.jpg">
                        </div>
                        <div class="col-md-2 col-4">
                            <img class="log" src="assets/images/log-img/log63.jpg">
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>