<div class="text-center logo">
    <a (click)="close()">
        <img  [src]="datalistService.IMGAE_PATH+'/log.jpg'" class="img-responsive">
    </a>
</div>
<mat-card>
    <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <h2>Log In</h2>
            <mat-error *ngIf="loginInvalid">
                The email id and password were not recognised
            </mat-error>
            <mat-form-field class="full-width-input">
                <input matInput placeholder="Email Id" formControlName="Email" required>
                <mat-error>
                    Please provide a valid email address
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-input">
                <input matInput type="password" placeholder="Password" formControlName="Password" required>
                <mat-error>
                    Please provide a valid password
                </mat-error>
            </mat-form-field>
            <div class="form-group">
                <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="customControlInline"
                        formControlName="RememberMe" value="1">
                    <label class="custom-control-label pull-left" for="customControlInline">Remember me</label>
                    <!-- <a href="" class="forgot-pass">
                        <label class="pull-right">Forgot password</label>
                    </a> -->
                </div>
            </div>
            <button type="submit" mat-raised-button color="primary">Login</button>
        </form>
    </mat-card-content>
</mat-card>