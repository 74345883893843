import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
declare var $: any;
// for SEO
import { Title, Meta } from '@angular/platform-browser';
import { DatabaseOperationService } from '../../api/database/database-operation.service';
import { DatalistService } from '../../api/services/datalist.service';
import { FunctionService } from '../../api/services/function.service';

@Component({
  selector: 'app-placements',
  templateUrl: './placements.component.html',
  styleUrls: ['./placements.component.css']
})
export class PlacementsComponent implements OnInit {
   // for SEO
   title = 'Top MBA College in Kerala |JAI BHARATH SCHOOL OF MANAGEMENT STUDIES';
  enquiryForm: any;
  formInvalid: boolean;
  submitted: boolean;

  constructor(
    // for SEO
    private titleService: Title,
    private metaTagService: Meta,
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    // for SEO
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'keywords', content: 'top mba college in kerala , top colleges in kerala , top 10 mba colleges in kerala , top mba colleges in kerala accepting kmat score , which mba college gives best placement' },
      { name: 'description', content: 'JAI BHARATH SCHOOL OF MANAGEMENT STUDIES | Become a Global Executive with Internationally Compatible Curriculum. Apply for Admission. Top MBA College in Kerala' },
      { name: 'robots', content: 'index, follow' },      
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },      
      { charset: 'UTF-8' }
    ]);
    this.enquiryForm = this.formBuilder.group({
      Name: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      Phone: ['', Validators.required],
      DOB: ['', Validators.required],
      Qualification: ['', Validators.required],
      Specilization: ['', Validators.required],
      Experience: ['', Validators.required],
    });
  }
  get formControl() { return this.enquiryForm.controls; }
  onSubmit() {
    this.datalistService.loaderActive = true;
    this.formInvalid = false;
    this.submitted = false;
    if (this.enquiryForm.valid) {
      // this.databaseOperationService.sendContactForm(this.enquiryForm.value).subscribe((data: any) => {
      // });
      this.databaseOperationService.saveJobFairEnquiryForm(this.enquiryForm.value).subscribe((data: any) => {
        this.enquiryForm = this.formBuilder.group({
          Name: ['', Validators.required],
          Email: ['', [Validators.required, Validators.email]],
          Phone: ['', Validators.required],
          DOB: ['', Validators.required],
          Qualification: ['', Validators.required],
          Specilization: ['', Validators.required],
          Experience: ['', Validators.required],
        });
        this.functionService.showProcessMessage("Our Counsellor Will Connect With You ", true);
        this.datalistService.loaderActive = false;
      })
    } else {
      this.submitted = true;
      this.functionService.showProcessMessage("Please provide valid information", false);
      this.datalistService.loaderActive = false;
    }
  }


  ngAfterViewInit() {
    this.activeSilder()
  }
  activeSilder(){
    var _this = this;
    $(document).ready(function() {
      


      var partnersslide=(<any>$('#slider-partners')).lightSlider({
        loop:true,
        item:6,
        speed:600,
        auto:true,
        keyPress:true,
        controls:false,
        slideMove:1,
        pager:false,
        
        responsive : [
          {
              breakpoint:800,
              settings: {
                  item:3,
                  slideMove:1,
                  slideMargin:6,
                }
          },
          {
              breakpoint:480,
              settings: {
                  item:2,
                  slideMove:1,
                }
          }
      ]
      });
      $('#partnersPrev').on('click', function () {
        partnersslide.goToPrevSlide();
      });
      $('#partnersNext').on('click', function () {
        partnersslide.goToNextSlide();
      });

      var partnersslide=(<any>$('#slider-perform')).lightSlider({
        loop:true,
        item:4,
        speed:600,
        auto:true,
        keyPress:true,
        controls:false,
        slideMove:1,
        pager:false,
        
        responsive : [
          {
              breakpoint:800,
              settings: {
                  item:3,
                  slideMove:1,
                  slideMargin:6,
                }
          },
          {
              breakpoint:480,
              settings: {
                  item:2,
                  slideMove:1,
                }
          }
      ]
      });
      $('#partnersPrev').on('click', function () {
        partnersslide.goToPrevSlide();
      });
      $('#partnersNext').on('click', function () {
        partnersslide.goToNextSlide();
      });

      $( window ).resize();
    });
  }

}
