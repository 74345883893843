<div class="ragg-head">
    <div class="anti-head">
        <h1 class="head-ragg">ANTI RAGGING CELL</h1>
    </div>
</div>
<div class="anti-ragg">
    <div class="container">
        <div class="antiragging-part1">
            <div class="col-head">
                <h4 class="sub-head">ANTI RAGGING CELL</h4>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <img class="anti-ragging" src="./assets/images/anti-raging-200x200.png">
                </div>
                <div class="col-md-9">
                    <p class="parag1">
                        The College has a vigilant committee against instances of ragging. The committee takes measures to repeatedly remind students of the consequences of ragging, and ensures that parents and new students are made aware of their rights. Anti -Ragging Committee is constituted in this college to prevent ragging and to take anti-ragging measures as per the guidelines issued by the Supreme Court of India and UGC.
                    </p>
                </div>
            </div>
            <div class="commitee">
                <div class="row">
                    <div class="commite-head">
                        <h5 class="commite">MEMBERS OF THE COMMITTEE</h5>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4">
                                <p>Manager</p>
                            </div>
                            <div class="col-md-4">
                                <p>Er. A M Kharim </p>
                            </div>
                            <div class="col-md-4">
                                <p>+91 9447188020</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <p>Director</p>
                            </div>
                            <div class="col-md-4">
                                <p>Dr.Pradeep Kumar</p>
                            </div>
                            <div class="col-md-4">
                                <p>+91 72932 29051</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h5 class="commite">THE KERALA PROHIBITION OF RAGGING ACT, 1998*</h5>
                    <div class="fusion-text">
                        <p>(Act 10 of 1998)</p>
                        <p>An Act to Prohibit ragging in educational institutions in the State of Kerala.</p>
                        <p>Preamble.- WHEREAS it is expedient to prohibit ragging in educational institutions in
                            the State of Kerala.</p>
                        <p>BE it enacted in the Forty-Ninth Year of the Republic of India as follows:-</p>
                        <ol start="3">
                            <li><em>Short title, extent and Commencement</em>.(1) This Act may be called the
                                Kerala Prohibition of Ragging Act, 1998.<br>
                                (2) It extends the whole of the State of Kerala.<br>
                                (3) It shall be deemed to have come into force on the 23rd day of October, 1997.
                            </li>
                            <li><em>Definition</em>.-In this Act, unless the context otherwise required,-<br>
                                (a) ‘head of the educational institution’ means the Principal or the Headmaster
                                or the person responsible for the management of that educational
                                institution;<br>
                                (b) ‘Ragging’ means doing of any act, by disorderly conduct, to a student of an
                                educational institution, which causes or is likely to cause physical or
                                psychological harm or raising apprehension or fear or shame or embarrassment to
                                that student and includes-<br>
                                (i) teasing, abusing or paying practical jokes on, or causing hurt to, such
                                student; or<br>
                                (ii) asking a student to do any act or perform something which such student will
                                not, in the ordinary course willingly, do.</li>
                            <li><em>Prohibition of ragging</em>.- Ragging within or without any educational
                                institution is prohibited.</li>
                            <li><em>Penalty for ragging</em>.- Whoever commits, participates in, abets or
                                propagates ragging within, or without, any educational institution shall, on
                                conviction, be punished with imprisonment for a term which may extent to two
                                years and shall also be liable to a fine which may extent to ten thousand
                                rupees.</li>
                            <li><em>Dismissal of student</em>.- Any student convicted of an offence under
                                section 4 shall be dismissed from the educational institution and such student
                                shall not be admitted in any other educational institution for a period of three
                                years from the date of order of such dismissal.</li>
                            <li><em>Suspension of student</em>.<br>
                                (1) Whenever any student or , as the case may be, the parents or guardian, or a
                                teacher of an educational institution complaints, in writing, of ragging to the
                                head of the educational institution, the head of that educational institution
                                shall, without prejudice to the foregoing provisions, within seven days of the
                                receipt of the complaint, enquire into the matter mentioned in the complaint and
                                if, prima facie, it is found true, suspend the student who is accused of the
                                offence, and shall, immediately, forward the complaint to the police station
                                having jurisdiction over the area in which the educational institution is
                                situate, for further action.<br>
                                (2) Where, on enquiry by the head of the educational institution, it is proved
                                that there is no substance prima facie in the complaint received under
                                sub-section (1), he shall intimate the fact, in writing, to the complainant.
                            </li>
                            <li><em>Deemed abetment</em>.- If the head of the educational institution fails or
                                neglects to take action in the manner specified in section 6 when a complaint of
                                ragging is made, such person shall be deemed to have abetted the offence of
                                ragging and shall, on conviction, be punished as provided for in section 4.</li>
                            <li><em>Power to make rules</em>.-<br>
                                (1) The Government may, by notification in the Gazette, make rules for carrying
                                out all or any of the purposes of this Act.<br>
                                (2) Every rule made under this Act shall be laid, as soon as may be after it is
                                made, before the Legislative Assembly, while it is in session for a total period
                                of fourteen days, which may be comprised in one session or in two successive
                                sessions, and if before the expiry of the session in which it is so laid, or the
                                session immediately following, the Legislative Assembly makes any modification
                                in the rule or decides that the rule should not be made, the rule shall,
                                thereafter, have effect only in such modified form or be of no effect, as the
                                case may be, so, however that any such modification or annulment shall be
                                without prejudice to the validity of anything previously done under that rule.
                            </li>
                            <li><em>Repeal and saving</em>.-<br>
                                (1) The Kerala prohibition of Ragging Ordinance, 1998 (2 of 1998) is hereby
                                repealed.<br>
                                (2) Notwithstanding such repeal, anything done or deemed to have been done or
                                any action taken or deemed to have been taken under the said Ordinance shall be
                                deemed to have been done or taken under this Act.</li>
                        </ol>
                    </div>
                </div>
            </div>
            <!-- <div class="report">
                <div class="row">
                    <div class="col-md-12">
                        <button class="report-button">REPORT 2018-2019</button>
                    </div>
                </div>
            </div> -->

        </div>
    </div>
</div>
