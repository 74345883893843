<h2 mat-dialog-title>Warning</h2>

<mat-dialog-content>

    <p> Are you sure you want to proceed </p>
</mat-dialog-content>

<div class="row">
    <button mat-button [mat-dialog-close]="true" mat-raised-button color="primary"
        class="col-sm-6 btn-view">Yes</button>
    <button mat-button [mat-dialog-close]="false" class="col-sm-6 btn-default">No</button>
</div>