import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { DatabaseOperationService } from '../../api/database/database-operation.service';
import { CommonService } from '../../api/services/common.service';
import { DatalistService } from '../../api/services/datalist.service';
import { FunctionService } from '../../api/services/function.service';
declare var $: any;

// for SEO
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.css']
})
export class CareerComponent implements OnInit {
    // for SEO
    title = 'Top MBA College in Kerala |JAI BHARATH SCHOOL OF MANAGEMENT STUDIES';

  bookForm: any;
  formInvalid: boolean;
  submitted: boolean;

  constructor(
     // for SEO
     private titleService: Title,
     private metaTagService: Meta,

    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    public functionService: FunctionService,
    private formBuilder: FormBuilder,
    public commonService: CommonService,) { }

  ngOnInit(): void {
     // for SEO
     this.titleService.setTitle(this.title);
     this.metaTagService.addTags([
       { name: 'keywords', content: 'top mba college in kerala , top colleges in kerala , top 10 mba colleges in kerala , top mba colleges in kerala accepting kmat score , which mba college gives best placement' },
       { name: 'description', content: 'JAI BHARATH SCHOOL OF MANAGEMENT STUDIES | Become a Global Executive with Internationally Compatible Curriculum. Apply for Admission. Top MBA College in Kerala' },
       { name: 'robots', content: 'index, follow' },      
       { name: 'viewport', content: 'width=device-width, initial-scale=1' },      
       { charset: 'UTF-8' }
     ]);
     
    this.bookForm = this.formBuilder.group({
      Name: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      Phone: ['', Validators.required],
      Course: ['Webinar'],
    });
  }

  get formControl() { return this.bookForm.controls; }
  onSubmit() {
    this.datalistService.loaderActive = true;
    this.formInvalid = false;
    this.submitted = false;
    if (this.bookForm.valid) {
      // this.databaseOperationService.sendContactForm(this.bookForm.value).subscribe((data: any) => {
      // });
      this.databaseOperationService.saveCourseEnquiryForm(this.bookForm.value).subscribe((data: any) => {
        this.bookForm = this.formBuilder.group({
          Name: ['', Validators.required],
          Email: ['', [Validators.required, Validators.email]],
          Phone: ['', Validators.required],
          Course: ['Webinar'],
        });
        this.functionService.showProcessMessage("Our Counsellor Will Connect With You ", true);
        this.datalistService.loaderActive = false;
      })
    } else {
      this.submitted = true;
      this.functionService.showProcessMessage("Please provide valid information", false);
      this.datalistService.loaderActive = false;
    }
  }
  ngAfterViewInit() {
    this.activeSilder()
  }
  activeSilder(){
    var _this = this;
    $(document).ready(function() {
      var logosslide=(<any>$('#testimonial')).lightSlider({
        loop:true,
        item:1,
        speed:600,
        auto:true,
        keyPress:true,
        controls:false,
        slideMove:1,
        pager:false,
        
        responsive : [
          {
              breakpoint:800,
              settings: {
                  item:2,
                  slideMove:1,
                  slideMargin:6,
                }
          },
          {
              breakpoint:480,
              settings: {
                  item:1,
                  slideMove:1,
                }
          }
      ]
      });
      $('#advantPrev').on('click', function () {
        logosslide.goToPrevSlide();
      });
      $('#advantNext').on('click', function () {
        logosslide.goToNextSlide();
      });
    });
  }

}
