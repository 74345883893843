
<div class="main-container">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <img class="col-pic" src="./assets/images/about-group.jpeg">
                
            </div>
            <div class="col-md-6">
                <div class="welcome">
                    <h1 class="heading">
                        About Group
                    </h1>

                    <p>Jai Bharath Educational Foundation was established in the year 2001 as a charitable trust with a
                        registered office at Thrikkakkara, Ernakulam, Kerala, with a focused objective of bringing about intellectual awakening of people and for preparing manpower capable of contributing effectively towards national development. Jai Bharath Educational Foundation is one of the largest networks of educational institutions in Kerala. Within a short span, this group of institutions managed by the
                        JBEF has established its reputation for academic excellence, rich infrastructural facilities, and the potential for further growth. Today we are dedicated to serving our society in diverse fields by the establishment of the following institutes Ernakulam District.
                    </p>
                    <ul>
                        <li>Jai Bharath College of Management and Engineering Technology,Perumbavoor</li>
                        <li>Jai Bharath School of Management Studies, Perumbavoor</li>
                        <li>Jai Bharath Arts & Science College Perumbavoor</li>
                        <li>KMM college of management and technology, Thrikkakara</li>
                        <li>KMM College of Arts & Science,Thrikkakara</li>
                        <li>KMM Women’s College, Thrikkakara</li>
                        <li>RU college of management and technology,Kunnathunadu</li>
                        <li>Jai Bharath Training College, Edappally Toll Gate</li>
                            
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>





 <div class="new-range range-inner">
        <div class="container">
            <div class="row">
                <div class="sectional-area-list">
                      <div class="col-md-12">
                          <h3 class="prj-head">Our Colleges</h3>
                          <p></p>
                          <hr>
                       </div>
                       <div class="row">
                       <div class="col-lg-4 col-md-4 col-sm-4 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                           <a href="product-detail.html">
                               <div class="mats-outer">
                                   <div class="mats-img-part">
                                       <img src="./assets/images/colleges/jaibharath arts ans science.jpg">
                                   </div>
                                   <div class="mats-text-part text-center">
                                       
                                       <h3>jaibharath arts ans science</h3>
                                   </div>
                               </div>
                           </a>
                       </div>
                     <div class="col-lg-4 col-md-4 col-sm-4 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                           <a href="product-detail.html">
                               <div class="mats-outer">
                                   <div class="mats-img-part">
                                       <img src="./assets/images/colleges/jaibharath management studies.png">
                                   </div>
                                   <div class="mats-text-part text-center">
                                       
                                       <h3>jaibharath management studies</h3>
                                   </div>
                               </div>
                           </a>
                       </div>
                     <div class="col-lg-4 col-md-4 col-sm-4 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                           <a href="product-detail.html">
                               <div class="mats-outer">
                                   <div class="mats-img-part">
                                       <img src="./assets/images/colleges/KMM collage.jpg">
                                   </div>
                                   <div class="mats-text-part text-center">
                                       
                                       <h3>KMM college</h3>
                                   </div>
                               </div>
                           </a>
                       </div>
                       <div class="col-lg-4 col-md-4 col-sm-4 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                           <a href="product-detail.html">
                               <div class="mats-outer">
                                   <div class="mats-img-part">
                                       <img src="./assets/images/colleges/KMM womens Collage.jpg">
                                   </div>
                                   <div class="mats-text-part text-center">
                                       
                                       <h3>KMM womens College</h3>
                                   </div>
                               </div>
                           </a>
                       </div>
                       <div class="col-lg-4 col-md-4 col-sm-4 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                           <a href="product-detail.html">
                               <div class="mats-outer">
                                   <div class="mats-img-part">
                                       <img src="./assets/images/colleges/kmm-college-of-arts-and-science-thrikkakara-cochin-campus.jpg">
                                   </div>
                                   <div class="mats-text-part text-center">
                                       
                                       <h3>kmm college of arts and science thrikkakara cochin campus</h3>
                                   </div>
                               </div>
                           </a>
                       </div>
                       <div class="col-lg-4 col-md-4 col-sm-4 wow fadeInUp" style="visibility: visible; animation-name: fadeInUp;">
                           <a href="product-detail.html">
                               <div class="mats-outer">
                                   <div class="mats-img-part">
                                       <img src="./assets/images/colleges/RU Collage.jpg">
                                   </div>
                                   <div class="mats-text-part text-center">
                                       
                                       <h3>RU College</h3>
                                   </div>
                               </div>
                           </a>
                       </div>
                   </div>
                </div>
            </div>
        </div>
       </div>





<div class="chairman">
    <div class="container">
        <div class="about-chairman">
            <h1 class="chair-head">About our Chairman</h1>
            <div class="row">
                <div class="col-md-6">
                    <div class="chaiman-img">
                        <img src="assets/images/chairman.jpg" />
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="chairman-content">
                        <p class="ch-dataitem">A.M. Kharim</p>
                        <p class="ch-data"> B.Sc.(Engg), MIE</p>
                        <p class="ch-data">  Mob: +919447188020 jaibharatengg@gmail.com</p>
                        <p class="ch-data">Chairman & Managing Trustee</p>
                        <p class="ch-data">Jaibharath Educational Foundation Thrikkakara – Kochi-21.</p>
                        <p class="ch-data">Tel: 0484-2655379</p>
                      <p>
                            Mr. A.M. Kharim is a Motivational and committed Education Specialist more than 35 years expe- rience in developing and delivering knowledgeable educational guidance. Highly adept at navi- gating various institutions and offering appropriate support. Excels in gaining community involve- ment for the betterment of educational programmes. Through out his career he proved that not only a successful person but also a great philantharopist. He is a key factor in establishing stra- tegic relationships with key businesses and individual decision-makers to generate dynamic and top-producing partnerships, enhancing business development and boosting the bottom line with the touch of humanity and transperancy for Jaibharath Eductional Foundeation since more than 15 years and still continuing. </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<!-- 
<div class="ban-down"></div>

<div class="vision-mission">
  <div class="container">
      <div class="row">
          <div class="col-md-6">
              <div class="mv-box">
                  <div class="Part-Icon"> <img class="img-mission" src='assets/images/d.png' alt="Mission Icon"> </div>
              
                  <div class="mv-content">
                      <h1>Mission</h1>
                      <p>
                          Cras molestie sapien dui, rutrum maximus eros ullamcorper vel. Vivamus sed vulputate
                          metus, quis lacinia magna. Nulla in felis hendrerit, dapibus lorem vitae, tempus felis.
                          Curabitur fermentum eleifend justo, eu tempor leo accumsan in. Nulla elementum
                          laoreet est, sit amet pellentesque metus faucibus vestibulum.
                      </p>
                  </div>
              </div>
          </div>
          <div class="col-lg-6">
              <div class="mv-box">
                  <div class="Part-Icon"> <img class="img-vision"src="assets/images/d1.png" alt="Mission Icon"> </div>
              
                  <div class="mv-content">
                      <h1>Vision</h1>
                      <p>
                          Cras molestie sapien dui, rutrum maximus eros ullamcorper vel. Vivamus sed vulputate
                          metus, quis lacinia magna. Nulla in felis hendrerit, dapibus lorem vitae, tempus felis.
                          Curabitur fermentum eleifend justo, eu tempor leo accumsan in. Nulla elementum
                          laoreet est, sit amet pellentesque metus faucibus vestibulum.
                      </p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="ban-up"></div> -->

<!-- <div class="instructors">
  <div class="container-fluid">
      <div class="instructors-head">
          <h1>Our Instructors</h1>
      </div>
      <div class="instructors-images">
          <ul id="slider-instructors" class="content-instructors">
              <li>
                  <div class="instruct-box">
                      <div class="img-box">
                          <img src="./assets/images/why-1.png" />
                      </div>
                      <div class="instruct-data">
                          <h3 class="head">Full Name</h3>
                          <p class="head">Designation</p><p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi vel, viverra dignissim ligula. Phasellus metus leo, consequat et imperdiet fermentum, varius in arcu. 
                          </p>
                          <img src="./assets/images/in.png" class="in-icon" />
                      </div>
                  </div>                    
              </li>
              <li>
                  <div class="instruct-box">
                      <div class="img-box">
                          <img src="./assets/images/why-1.png" />
                      </div>
                      <div class="instruct-data">
                          <h3 class="head">Full Name</h3>
                          <p class="head">Designation</p><p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi vel, viverra dignissim ligula. Phasellus metus leo, consequat et imperdiet fermentum, varius in arcu. 
                          </p>
                          <img src="./assets/images/in.png" class="in-icon" />
                      </div>
                  </div>                    
              </li>
              <li>
                  <div class="instruct-box">
                      <div class="img-box">
                          <img src="./assets/images/why-1.png" />
                      </div>
                      <div class="instruct-data">
                          <h3 class="head">Full Name</h3>
                          <p class="head">Designation</p><p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi vel, viverra dignissim ligula. Phasellus metus leo, consequat et imperdiet fermentum, varius in arcu. 
                          </p>
                          <img src="./assets/images/in.png" class="in-icon" />
                      </div>
                  </div>                    
              </li>
          </ul>
          <div class="lSAction instructors-controls">
              <a class="lSPrev" id="instructorsPrev"><img src="./assets/images/arrow-left.png" /></a>
              <a class="lSNext" id="instructorsNext"><img src="./assets/images/arrow-right.png" /></a>
          </div>
      </div>
  </div>
</div> -->


