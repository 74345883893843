<div class="col-md-12" mat-dialog-title>
    <div class="main-head">
        <h1>{{formTitle}} Notification name
            <span style="
            float: right;
        " mat-button [mat-dialog-close]="false" class="pull-right close-popup"><i
                    class="fa fa-times"></i></span>
        </h1>
    </div>
</div>
<mat-dialog-content>
    <div class="container-fluid" [formGroup]="NotificationForm">

        <div class="row">
            <div class="col-md-12">
                <div class="forms">
                    <div class="row">
                        <div class="col-md-12">
                                <mat-card-content> 
                                    <mat-form-field class="full-width-input">
                                        <input matInput placeholder="Title" formControlName="Title"
                                            required>
                                        <mat-error>
                                            Please provide a valid Title
                                        </mat-error>
                                    </mat-form-field>
                                </mat-card-content>
                                <mat-card-content>
                                    <mat-form-field class="full-width-input">
                                        <input matInput placeholder="Link" formControlName="Link"
                                            required>
                                        <mat-error>
                                            Please provide a valid Link
                                        </mat-error>
                                    </mat-form-field>
                                </mat-card-content>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-right">
                            <div class="form-group">
                                <button type="submit" mat-raised-button color="primary" class="mat-info"
                                    (click)="onSubmit()">{{buttonLabel}}</button>&nbsp;&nbsp;
                                <button type="button" mat-raised-button [mat-dialog-close]="false">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>