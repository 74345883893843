<div class="banner" *ngIf="common.flink==1">
	<img src="assets/images/library/lb-banner.jpg">
</div>
<div class="banner" *ngIf="common.flink==2">
	<img src="assets/images/library/Hostel.jpg">
</div>
<div class="banner" *ngIf="common.flink==3">
	<img src="assets/images/library/transport-banner.jpg">
</div>
<div class="banner" *ngIf="common.flink==4">
	<img src="assets/images/library/Canteen.jpg">
</div>
<div class="banner" *ngIf="common.flink==5">
	<img src="assets/images/library/atm-ban.jpg">
</div>
<div class="banner" *ngIf="common.flink==6">
	<img src="assets/images/library/Auditorium.jpg">
</div>
<div class="bg-side">
	<div class="container">
		<div class=" wrapper d-flex align-items-stretch">
			<nav id="sidebar" class="order-last" class="img">
				<div class="custom-menu">
					<!--<button type="button" id="sidebarCollapse" class="btn btn-primary">
	        </button>-->
				</div>
				<div class="">
					<h1><a href="index.html" class="logo">Facilities</a></h1>
					<ul class="list-unstyled components mb-5">
						<li class="active">
							<a (click)="common.facility(1);"><i class="fas fa-angle-double-right mr-2"></i> Library</a>
						</li>
						<li>
							<a (click)="common.facility(2);"><i class="fas fa-angle-double-right mr-2"></i> Hostel </a>
						</li>
						<li>
							<a (click)="common.facility(3);"><i class="fas fa-angle-double-right mr-2"></i>
								Transportation</a>
						</li>
						<li>
							<a (click)="common.facility(4);"><i class="fas fa-angle-double-right mr-2"></i> Canteen and
								cafeteria</a>
						</li>
						<li>
							<a (click)="common.facility(5);"><i class="fas fa-angle-double-right mr-2"></i> ATM</a>
						</li>
						<li>
							<a (click)="common.facility(6);"><i
									class="fas fa-angle-double-right mr-2"></i>Auditorium</a>
						</li>

					</ul>




				</div>

			</nav>

			<!-- Page Content  -->
			<div id="content" class="p-4 p-md-5 pt-5">
				<ng-container *ngIf="common.flink==1">
					<h2 class="mb-4">Library </h2>
					<p>
						As Albert Einstein has said, “the only thing you absolutely have to know, is the location of the
						library”. And we provide you with the most amazing Business School library and a knowledge
						center. This House of wisdom comes with the best facilities for students, faculty, and
						nonteaching staff. It has also acquired the title of the state- art –library during the course
						of two years. The central library aims at acquiring propagation of information and knowledge in
						all fields of education among academia. The library has an open stack system that allows the
						users to have access to the library's entire collection. The library has an ample amount of
						books, periodicals, journals, and CDS Services offered here. The services provided by the
						library also include Reading facilities, Reference, Circulation, Reservation, Photocopying,
						Computerised information search, and Internet Browsing Access for technical journals.

					</p>
					<!-- <p>The Central Library and knowledge centre is one of the most important academic facilities
						provided to the students, faculty and non-teaching staff. Within a short span of four years, the
						library of Jai Bharath School of Management Studies  has shown dynamic growth
						by becoming a state-of-the-art library in the field. The central aim of the library is to
						facilitate the acquisition and dissemination of information and knowledge in all areas of
						education among the academic community. Our library follows the open stack system that allows
						users for direct access to the library collection. We have ample amount of books, periodicals,
						journals and CDS Services offered here. The Library offers the following services: Reading
						facilities, Reference, Circulation, Reservation, Photocopying, Computerised information search
						and Internet Browsing Access for technical journals.</p> -->


					<div class="container">
						<div class="row">
							<div class="col-md-4">
								<div class="lib-img">
									<img src="assets/images/library/lb1.jpeg">
								</div>
							</div>
							<div class="col-md-4">
								<div class="lib-img">
									<img src="assets/images/library/lb2.jpeg">
								</div>
							</div>
							<div class="col-md-4">
								<div class="lib-img">
									<img src="assets/images/library/lb3.jpeg">
								</div>
							</div>
							<div class="col-md-4">
								<div class="lib-img">
									<img src="assets/images/library/lb4.jpeg">
								</div>
							</div>
							<div class="col-md-4">
								<div class="lib-img">
									<img src="assets/images/library/lb5.jpg">
								</div>
							</div>
							<div class="col-md-4">
								<div class="lib-img">
									<img src="assets/images/library/lb6.jpg">
								</div>
							</div>
							<div class="col-md-4">
								<div class="lib-img">
									<img src="assets/images/library/lb7.jpg">
								</div>
							</div>
						</div>
					</div>
					<div>
						<!--<img src="assets/images/library/Library1.jpg">-->

					</div>
				</ng-container>
				<ng-container *ngIf="common.flink==2">
					<h2 class="mb-4">Hostel</h2>
					<p>
						We offer you a place where you feel like home. The in-campus hostel facilities are provided for
						both boys and girls. We have wardens and resident tutors who offers adequate security and good
						discipline. We also facilitate moral, emotional support and educational guidance to our
						students. We care to provide an environment where they can learn with fully furnished, well
						designed, large and airy rooms. Ensuring 24 hours of running water. Mess facilities are well
						supervised and students get wholesome, nutritious food of their choice. Homely ambience combines
						with rich educational functions to define the environs of the college hostels.

					</p>
					<!-- <p>An ideal hostel must be , in essence, a home away from home. Our College has separate in-campus
						hostel facilities for both girls and boys. The hostels have wardens and resident tutors.The
						teachers
						who act as resident wardens of the hostels provide moral and emotional support and educational
						guidance to the inmates.. The rooms are fully furnished, well designed, large and airy with the
						best
						in conveniences. 24 hours running water is ensured. Mess facilities are well supervised and
						students
						get wholesome, nutritious food of their choice. Homely ambience combines with rich educational
						functions to define the environs of the college hostels.</p> -->
					<div class="container">
						<div class="row">

							<div class="col-md-4">
								<div class="hostel_pic">
									<img src="assets/images/hstl/h2.JPG">
								</div>
							</div>
							<div class="col-md-4">
								<div class="hostel_pic">
									<img src="assets/images/hstl/h4.jpeg">
								</div>
							</div>
							<div class="col-md-4">
								<div class="hostel_pic">
									<img src="assets/images/hstl/h5.jpeg">
								</div>

							</div>
							<div class="col-md-4">
								<div class="hostel_pic">
									<img src="assets/images/hstl/h3.jpeg">
								</div>
							</div>
							<div class="col-md-4">
								<div class="hostel_pic">
									<img src="assets/images/hstl/h6.jpg">
								</div>
							</div>
							<div class="col-md-4">
								<div class="hostel_pic">
									<img src="assets/images/hstl/h7.jpg">
								</div>
							</div>
							<div class="col-md-4 offset-md-2">
								<div class="hostel_pic">
									<img src="assets/images/hstl/h8.jpg">
								</div>

							</div>
							<div class="col-md-4">
								<div class="hostel_pic">
									<img src="assets/images/hstl/h9.jpg">
								</div>
							</div>
						</div>


					</div>
				</ng-container>
				<ng-container *ngIf="common.flink==3">
					<h2 class="mb-4">Transportation</h2>
					<p>
						Our college has fleet of buses which routes are arranged in a way that students from all part of
						the city can approach the college with ease. The bus fare is reasonable and as per the route
						opted by them. All the day scholars who wish to have a safe and comfortable journey from and to
						home can avail this.

					</p>
					<!-- <p>The College has a fleet of buses. The routes for the buses are organized fitly so that students
						from all parts of the city can easily approach the college. The bus facilities are made
						available to the students at reasonable charges depending upon the routes opted by them. All the
						day scholars who desire to avail the transport facilities of the college can use this.</p> -->
					<div class="container">
						<div class="row">
							<div class="col-md-4">
								<img src="assets/images/trans/pic1.jpg">
							</div>
							<div class="col-md-4">
								<img src="assets/images/trans/pic2.jpg">
							</div>
							<div class="col-md-4">
								<img src="assets/images/trans/pic3.jpg">
							</div>
						</div>


						<div class="col-md-4 offset-md-4">
							<img class="row-img" src="assets/images/trans/pic4.jpg">
						</div>

					</div>
				</ng-container>
				<ng-container *ngIf="common.flink==4">
					<h2 class="mb-4">Canteen and cafeteria</h2>
					<p>
						We have a canteen inside the college campus that aims at providing quality catering services for
						both students and faculty members. It offers the best, the brightest and the most caring people
						to deliver Canteen's delicious food at a moderate rate. They serve both vegetarian and
						non-vegetarian food in a clean and healthy environment.
					</p>
					<!-- <p>A canteen is functioning inside the campus with the objective of providing quality - catering
						facilities for the students as well as the members of the staff, at moderate rates. Both
						vegetarian
						and non-vegetarian foods are served separately in a clean and healthy environment Cleanliness
						cares
						most.</p> -->
					<div class="container">
						<div class="row">
							<div class="col-md-4">
								<img src="assets/images/library/canteen 1.jpeg">
							</div>
							<div class="col-md-4">
								<img src="assets/images/library/canteen 2.jpg">
							</div>
							<div class="col-md-4">
								<img src="assets/images/library/Canteen 3.jpg">
							</div>
						</div>
					</div>
				</ng-container>
				<ng-container *ngIf="common.flink==5">
					<h2 class="mb-4">ATM</h2>
					<p>
						We value your time and safety. South Indian bank have Installed Automated Teller (ATM) Machines
						inside the college campus for the convenience of both students and staff.

					</p>
					<!-- <p>South Indian Bank have installed automated teller machines (ATM) at the Jai Bharath campus.
						This
						ATM caters to the banking needs of both the students and the staff. Also, this saves up their
						valuable time being located inside the campus.</p> -->
					<div class="container">
						<div class="row">
							<div class="col-md-4">
								<img src="assets/images/library/ATM.jpg">
							</div>
						</div>

					</div>
				</ng-container>
				<ng-container *ngIf="common.flink==6">
					<h2 class="mb-4">Auditorium</h2>
					<p>
						The aesthetically arranged multi-purpose auditorium is an excellent support for the important
						meetings, conferences, seminars and presentations. It also avails a secured and technologically
						advance environment that can be used for all cultural and academic events. The Audile designed
						facility has the capacity of 2000 seating. The main auditorium comes with a mini auditorium that
						can accommodate 700 people, along with a seminar hall that can equip over 200 people. The
						arrangement of the auditorium is in a way that the speaker on the stage is brought closer
						towards the spectators. The fully air-conditioned setting along with the best lighting and sound
						systems makes sure that there is enough room for a serene atmosphere complementing an excellent
						presentation. The LCD projector and a vast screen in addition. This makes it apt for the
						placement drives, pre-placements talk, workshops, seminars, conferences, cultural festivals,
						incentives and prize distribution ceremonies as well.

					</p>
					<!-- <p>The aesthetically appointed multi-purpose auditorium is the setting for all the cultural and
						academic events. This acoustically designed facility has a capacity to seat 2000 people. The
						arrangement of the auditorium is in a way that the speaker on the stage is brought closer
						towards the spectators. The fully air-conditioned setting along with the best lighting and sound
						systems makes sure that there is enough room for a serene atmosphere complementing an excellent
						presentation. Apart from these, we have the LCD projector and a vast screen in addition. This
						makes it apt for the placement drives, pre-placements talks, workshops, seminars, conferences,
						cultural festivals, incentives and prize distribution ceremonies as well. The main auditorium is
						complemented with a mini auditorium that can accommodate 700 people, along with a seminar hall
						that can equip over 200 people.</p> -->
					<div class="container">
						<div class="row">
							<div class="col-md-4">
								<img src="assets/images/auditorium/a1.jpg">
							</div>
							<div class="col-md-4">
								<img src="assets/images/auditorium/a2.jpg">
							</div>
							<div class="col-md-4">
								<img src="assets/images/auditorium/a3.jpg">
							</div>
						</div>
					</div>
				</ng-container>


			</div>

		</div>
		<div class="container-fluid" *ngIf="common.flink==6">
			<div class="row">
				<div class="col-md-3">
					<div class="img-box">
						<img src="assets/images/auditorium/a11.jpg">
						<p>Mini Seminar Hall</p>
					</div>
				</div>
				<div class="col-md-3">
					<div class="img-box">
						<img src="assets/images/auditorium/a12.jpg">
						<p>Mini Auditorium</p>
					</div>
				</div>
				<div class="col-md-3">
					<div class="img-box">
						<img src="assets/images/auditorium/a13.jpg">
						<p>Main Auditorium</p>
					</div>
				</div>
				<div class="col-md-3">
					<div class="img-box">
						<img src="assets/images/auditorium/a14.jpg">
						<p>Mini Conference Room</p>
					</div>
				</div>
			</div>
		</div>


		<div class="advantage">
			<div class="container">
				<div class="advant-content">
					<div class="advant-head">
						<h1>Our Highlights</h1>
					</div>
					<div class="row">
						<div class="col-md-4">
							<div class="advant-box">
								<div class="inr-icons">
									<span class="lnr lnr-arrow-up"></span>
								</div>
								<h2>360 career development</h2>
								<p class="advant">
									An expert driven industry mentorships supplemented with job fitment analysis and
									also enable for resume building using AI mode.
								</p>
							</div>
						</div>
						<div class="col-md-4">
							<div class="advant-box">
								<div class="inr-icons">
									<span class="lnr lnr-database"></span>
								</div>
								<h2>Financial Support</h2>
								<p class="advant">
									Our financial aid helps the students and their families by
									covering higher education expenses, tie up with banks and also provide various
									scholarship
									schemes.
								</p>
							</div>
						</div>
						<div class="col-md-4">
							<div class="advant-box">
								<div class="inr-icons">
									<span class="lnr lnr-laptop"></span>
								</div>
								<h2>Placement Services</h2>
								<p class="advant">
									The Jai Bharath placement portal serves you access to
									extensive job leads and reference of leading firms.
								</p>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-md-4">
							<div class="advant-box">
								<div class="inr-icons1">
									<span class="lnr lnr-earth"></span>
								</div>
								<h2>Eco-Friendly Campus</h2>
								<p class="advant">
									We at Jai Bharath take care of all the environment friendly factors to contribute in
									making an
									eco-friendly campus
								</p>
							</div>
						</div>
						<div class="col-md-4">
							<div class="advant-box">
								<div class="inr-icons1">
									<span class="lnr lnr-book"></span>
								</div>
								<h2>Multi Displicinary Curriculum</h2>
								<p class="advant">
									We design various multidisciplinary curriculum for students that suits a specific
									career
									path.
								</p>
							</div>
						</div>
						<div class="col-md-4">
							<div class="advant-box">
								<div class="inr-icons1">
									<span class="lnr lnr-location"></span>
								</div>
								<h2>Prime Location</h2>
								<p class="advant">
									We are located at the Mid of Kochi, Idukki and Kottayam so that its very much easy
									to
									step into our campus.
								</p>
							</div>
						</div>
					</div>

				</div>
			</div>
		</div>


	</div>
</div>