import { Component, OnInit } from '@angular/core';
declare var $: any;
// for SEO
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css']
})
export class TestimonialComponent implements OnInit {
   // for SEO
   title = 'Top MBA College in Kerala |JAI BHARATH SCHOOL OF MANAGEMENT STUDIES';

  constructor(
     // for SEO
     private titleService: Title,
     private metaTagService: Meta,
  ) { }

  ngOnInit(): void {
    // for SEO
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'keywords', content: 'top mba college in kerala , top colleges in kerala , top 10 mba colleges in kerala , top mba colleges in kerala accepting kmat score , which mba college gives best placement' },
      { name: 'description', content: 'JAI BHARATH SCHOOL OF MANAGEMENT STUDIES | Become a Global Executive with Internationally Compatible Curriculum. Apply for Admission. Top MBA College in Kerala' },
      { name: 'robots', content: 'index, follow' },      
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },      
      { charset: 'UTF-8' }
    ]);
    this.activeSilder();
  }

  activeSilder() {

    $(document).ready(function () {

      var testislide = (<any>('#testi-slider')).lightSlider({
        loop: true,
        item: 1,
        speed: 600,
        pause: 5000,
        auto: true,
        keyPress: true,
        controls: false,
        slideMove: 1,
        pager: false,

        responsive: [
          {
            breakpoint: 800,
            settings: {
              item: 1,
              slideMove: 1,
              slideMargin: 6,
            }
          },
          {
            breakpoint: 480,
            settings: {
              item: 1,
              slideMove: 1,
            }
          }
        ]
      });
      $('#labsPrev').on('click', function () {
        testislide.goToPrevSlide();
      });
      $('#labsNext').on('click', function () {
        testislide.goToNextSlide();
      });
    });
  }
}
