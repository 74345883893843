import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';

@Component({
  selector: 'app-view-career-popup',
  templateUrl: './view-career-popup.component.html',
  styleUrls: ['./view-career-popup.component.css']
})
export class ViewCareerPopupComponent implements OnInit {
  CareerId: any;
  Career: any;
  AllCareerExperience: any;
  AllCareerOthersDetails: any;
  AllCareerProfessionalDetaills: any;

  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,) {
    this.datalistService.loaderActive = true;}

  ngOnInit(): void {
    let getCareerById = this.databaseOperationService.getCareerById(this.CareerId);
    let getAllCareerExperience = this.databaseOperationService.getAllCareerExperience(this.CareerId);
    let getAllCareerOthersDetails = this.databaseOperationService.getAllCareerOthersDetails(this.CareerId);
    let getAllCareerProfessionalDetaills = this.databaseOperationService.getAllCareerProfessionalDetaills(this.CareerId);
    forkJoin([getCareerById, getAllCareerExperience,getAllCareerOthersDetails,getAllCareerProfessionalDetaills]).subscribe((Observable: any) => {
      this.Career = Observable[0];
      this.AllCareerExperience = Observable[1];
      this.AllCareerOthersDetails = Observable[2];
      this.AllCareerProfessionalDetaills = Observable[3];
      this.datalistService.loaderActive = false;
      console.log(Observable);
    })
  }

}

