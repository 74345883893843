import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';
import { FacultiesFormPopupComponent } from '../../../popup/faculties/faculties-form-popup/faculties-form-popup.component';
import { ProceedWarningComponent } from '../../../popup/proceed-warning/proceed-warning.component';

@Component({
  selector: 'app-manage-faclity',
  templateUrl: './manage-faclity.component.html',
  styleUrls: ['./manage-faclity.component.css']
})
export class ManageFaclityComponent implements OnInit {
  
  data: any;
  FacultiesId: any;
  CourseId: any;
  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,
    private titleService: Title) {
    this.datalistService.loaderActive = true;
  }

  Columns: string[] = ['SortId', 'FacultiesName', 'Operation'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ngOnInit(): void {
    this.getAllFaculties(this.CourseId)
  }
  getAllFaculties(CourseId) {
    this.Columns = ['SortId', 'FacultiesName', 'Operation'];
    this.databaseOperationService.getAllFaculties().subscribe((data: any) => {
      
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.datalistService.loaderActive = false;
    })
  }
  addFaculties() {

    let dialogRef: MatDialogRef<FacultiesFormPopupComponent> = this.matDialog.open(FacultiesFormPopupComponent, {
      width: '300px',
      height: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.FacultiesId = '';
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllFaculties(this.CourseId);
      }
    });
  }
  editFaculties(FacultiesId) {
    let dialogRef: MatDialogRef<FacultiesFormPopupComponent> = this.matDialog.open(FacultiesFormPopupComponent, {
      width: '300px',
      height: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.FacultiesId = FacultiesId;
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllFaculties(this.CourseId);
      }
    });
  }
  delete_Faculties(FacultiesId) {
    let dialogRef: MatDialogRef<ProceedWarningComponent> = this.matDialog.open(ProceedWarningComponent, {
      width: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.datalistService.loaderActive = true;
        this.databaseOperationService.deleteFaculties(FacultiesId).subscribe((data: any) => {
          this.getAllFaculties(this.CourseId);
          this.functionService.showProcessMessage("Faculties Deleted Successfully", true);
          this.datalistService.loaderActive = false;
        });
        // }
      }
    });
  }
}
