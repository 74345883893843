import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog,MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseOperationService } from '../../api/database/database-operation.service';
import { DatalistService } from '../../api/services/datalist.service';
import { FunctionService } from '../../api/services/function.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
// for SEO
import { Meta } from '@angular/platform-browser';
import { ProceedWarningComponent } from '../../popup/proceed-warning/proceed-warning.component';
import { formatDate } from '@angular/common';
import { EventRegisterFormPopupComponent } from '../../popup/events/event-register-form-popup/event-register-form-popup.component';
import { ViewEventRegisterPopupComponent } from '../../popup/events/view-event-register-popup/view-event-register-popup.component';


@Component({
  selector: 'app-news-details',
  templateUrl: './news-details.component.html',
  styleUrls: ['./news-details.component.css']
})
export class NewsDetailsComponent implements OnInit {
    // for SEO
    title = 'Top MBA College in Kerala |JAI BHARATH SCHOOL OF MANAGEMENT STUDIES';

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
  };
  detailSubscribe: any;
  BlogId: any;
  BlogDetails: any;
  imageSrc: string;
  addNewBlog: boolean;
  editOldBlog: boolean;
  formButtonTitle: string;
  formBlog: any;
  editBlogForm: boolean;
  formInvalid: boolean;
  formSubmitAttempt: boolean;
  fileLoaded: any;
  cancelDisable: boolean;
  sortId: any;
  URLValue: any;

  AllBlog: any;
  AllBlogEvent=[];
  AllEvent: any;
  constructor(
      // for SEO
      private titleService: Title,
      private metaTagService: Meta,

    public datalistService: DatalistService,
    private formBuilder: FormBuilder,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public functionService: FunctionService,
    // private titleService: Title
    ) {
    this.datalistService.loaderActive = true;
  }

  ngOnInit() {
      // for SEO
      this.titleService.setTitle(this.title);
      this.metaTagService.addTags([
        { name: 'keywords', content: 'top mba college in kerala , top colleges in kerala , top 10 mba colleges in kerala , top mba colleges in kerala accepting kmat score , which mba college gives best placement' },
        { name: 'description', content: 'JAI BHARATH SCHOOL OF MANAGEMENT STUDIES | Become a Global Executive with Internationally Compatible Curriculum. Apply for Admission. Top MBA College in Kerala' },
        { name: 'robots', content: 'index, follow' },      
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },      
        { charset: 'UTF-8' }
      ]);

    this.detailSubscribe = this.route.params.subscribe(params => {
      this.sortId = params['sortId'];
      this.URLValue = params['URLValue'];
      // this.titleService.setTitle("Advent Solutions | blog | " + this.URLValue);
      this.databaseOperationService.getTableRowId('blog', this.sortId, this.URLValue).subscribe((data: any) => {
        if (data) {
          this.BlogId = data.BlogId;
          this.getBlogDetails();
        } else {
          this.cancelDisable = true; this.addBlog();
          this.datalistService.loaderActive = false;
        }
      });
    });
  }

  
  deleteBlog(ServiceId, ImageName) {
    let dialogRef: MatDialogRef<ProceedWarningComponent> = this.matDialog.open(ProceedWarningComponent, {
      width: 'auto',
      disableClose: true,
      panelClass: 'custom-dialog-container',
      hasBackdrop: true,
    });
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.datalistService.loaderActive = true;
        this.databaseOperationService.deleteBlog(ServiceId, ImageName).subscribe((data) => {

          if (!data) {
            this.getAllBlog();
            this.functionService.showProcessMessage("Blog deleted successfully", true);
            this.router.navigate(['/news-events']);
          } else {
            this.functionService.showProcessMessage("Blog deleted unsuccessfully", false);
            this.datalistService.loaderActive = false;
            this.router.navigate(['/news-events']);
          }
        });
      }
    });
  }
  getAllBlog(){
    
    this.databaseOperationService.getAllBlog().subscribe((data:any) => {
      this.AllBlogEvent = data;
      this.AllBlog = this.AllBlogEvent.filter(
        AllBlogEvent => AllBlogEvent.Type === "1");
        this.AllEvent = this.AllBlogEvent.filter(
          AllBlogEvent => AllBlogEvent.Type === "2");
      this.datalistService.loaderActive = false;
    })
  }


  getBlogDetails() {
    this.databaseOperationService.getBlogById(this.BlogId).subscribe((data: any) => {
      this.BlogDetails = data;
      this.imageSrc = "";
      if (this.BlogDetails && this.BlogDetails.BlogImage) {
        this.imageSrc = this.datalistService.IMGAE_PATH + '/blog' + this.BlogDetails.BlogImage;
      } else {
        this.imageSrc = this.datalistService.IMGAE_PATH + '/image_upload_placeholder.jpg';
      }
      this.datalistService.loaderActive = false;
    });
  }
  addBlog() {
    this.editBlogForm = true;
    this.addNewBlog = true;
    this.formButtonTitle = "Submit";
    this.imageSrc = this.datalistService.IMGAE_PATH + '/image_upload_placeholder.jpg';
    this.formBlog = this.formBuilder.group({
      BlogId: [''],
      BlogTitle: ['', Validators.required],
      AuthorName: ['', Validators.required],
      Type: ['', Validators.required],
      BlogContent: [''],
      BlogImage: [''],
      Image: ['', Validators.required],
      EventDate:[''],
    });
  }
  editBlog() {
    this.editBlogForm = true;
    this.formButtonTitle = "Update";
    this.formBlog = this.formBuilder.group({
      BlogId: [''],
      BlogTitle: ['', Validators.required],
      Type: ['', Validators.required],
      AuthorName: ['', Validators.required],
      BlogContent: [''],
      BlogImage: [''],
      Image: [''],
      EventDate:[''],
    });
    this.formBlog.patchValue(this.BlogDetails);
  }
  cancelForm() {
    this.editBlogForm = false;
    this.addNewBlog = false;
    if (this.BlogDetails && this.BlogDetails.BlogImage) {
      this.imageSrc = this.datalistService.IMGAE_PATH + '/blog' + this.BlogDetails.BlogImage;
    } else {
      this.imageSrc = this.datalistService.IMGAE_PATH + '/image_upload_placeholder.jpg';
    }
    // if (this.BlogId) { this.functionService.back(); }
  }
  get formControl() { return this.formBlog.controls; }
  submitBlog() {
    this.datalistService.loaderActive = true;
    this.formInvalid = false;
    this.formSubmitAttempt = false;
    if (this.formBlog.valid) {
      try {
        this.databaseOperationService.saveBlog(this.formBlog.value).subscribe((data: any) => {
          this.BlogId = data.BlogId;
          this.uploadImage(this.BlogId, '');
          this.cancelForm();
          if (this.formBlog.value.BlogId) {
            this.functionService.showProcessMessage("Blog updated successfully", true);
          }
          else {
            this.functionService.showProcessMessage("New blog added successfully", true);
          }
        })
      } catch (err) {
        this.formInvalid = true;
        this.functionService.showProcessMessage("Please provide valid information", false);
        this.datalistService.loaderActive = false;
      }
    } else {
      this.functionService.showProcessMessage("Please provide valid information", false);
      this.formSubmitAttempt = true;
      this.datalistService.loaderActive = false;
    }
  }
  uploadImage(BlogId: any, OldImage: any) {
    let formData = new FormData();
    if (this.fileLoaded)
      formData.append('Image', this.fileLoaded, this.fileLoaded.name);
    this.databaseOperationService.saveBlogImage(formData, BlogId, OldImage).subscribe((data: any) => {
      if (OldImage) {
        this.functionService.showProcessMessage("Image uploaded successfully", true);
      } else {
        this.databaseOperationService.getBlogById(BlogId).subscribe((data: any) => {
          if(data.Type==1){
            this.router.navigate(['/blog-details', data.SortId, data.URLValue]);
          }else if(data.Type==2){
            this.router.navigate(['/event-details', data.SortId, data.URLValue]);
          }
        });
      }
      this.fileLoaded = "";
      this.getBlogDetails();
    });
  }
  onBlogSelected(event, BlogId) {
    // let dialogRef = this.functionService.imageCrop(event, 300, 540);
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     this.imageSrc = result.imageTemp;
    //     this.fileLoaded = result.file;
    //     if (BlogId) {
    //       this.uploadImage(BlogId, this.BlogDetails.BlogImage);
    //     } else {
    //       this.datalistService.loaderActive = false;
    //     }
    //   } else {
    //     this.fileLoaded = "";
    //     this.imageSrc = "";
    //   }
    // });
    this.datalistService.loaderActive = true;
    this.fileLoaded = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.imageSrc = event.target.result;
      }
      if (BlogId) {
        this.uploadImage(BlogId, this.BlogDetails.BlogImage);
      } else {
        this.datalistService.loaderActive = false;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  
  registerEvent(Eventid) {

    let dialogRef: MatDialogRef<EventRegisterFormPopupComponent> = this.matDialog.open(EventRegisterFormPopupComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.EventId = Eventid;
    return dialogRef.afterClosed().subscribe(result => {
    });
  }
  dateTime = new Date();
  isActive(Date) {
    let date1 = formatDate(this.dateTime, 'yyyy-MM-dd', 'en_US');
    let date2 = formatDate(Date, 'yyyy-MM-dd', 'en_US');
    // this.dateTime
    // let EventDate=Date.getTime();
    return (date2 >= date1)
  }
  viewEventRegister(EventId) {
    let dialogRef: MatDialogRef<ViewEventRegisterPopupComponent> = this.matDialog.open(ViewEventRegisterPopupComponent, {
      width: '95%',
      height: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.EventId = EventId;
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // this.getAllCollege();
      }
    });
  }
}
