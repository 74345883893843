<div *ngIf="College; then CollegeTemplate else CourseTemplate">
    Here is never showing
</div>
<ng-template #CollegeTemplate>
    <div class="main-head">
        <div class="head-line">
            <h1>Manage College</h1>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <a (click)="addCollege()" mat-raised-button color="primary" class="sm"><i class="fa fa-plus"
                        aria-hidden="true"></i></a>
            </div>
            <br />
            <br />
            <div class="col-md-12">
                <div class="manage-tbl">
                    <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <ng-container matColumnDef="SortId">
                            <mat-header-cell *matHeaderCellDef width="80px"> No. </mat-header-cell>
                            <mat-cell *matCellDef="let element;let j = index">
                                {{ (j+1) + (myPaginator.pageIndex * myPaginator.pageSize) }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="CollegeName">
                            <mat-header-cell *matHeaderCellDef> College</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <a (click)="setCourse(element.CollegeId)"><u>{{element.CollegeName}}</u></a>

                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="Operation">
                            <mat-header-cell *matHeaderCellDef width="50px"> Operation </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <a (click)="editCollege(element.CollegeId)" class="sm" mat-raised-button
                                    color="primary">
                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                </a>&nbsp;&nbsp;&nbsp;
                                <a (click)="delete_College(element.CollegeId)" class="sm" mat-raised-button
                                    color="warn">
                                    <i class="fa fa-trash"></i>
                                </a>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="Columns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: Columns;"></mat-row>
                    </mat-table>
                    <mat-paginator #myPaginator [length]="25" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
                    </mat-paginator>

                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #CourseTemplate>
    <div class="main-head">
        <div class="head-line">
            <h1>Manage Course</h1>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <a (click)="setCollege()" mat-raised-button class="sm">
                    <i class="material-icons">keyboard_backspace</i>
                </a>
                &nbsp;
                &nbsp;
                <a (click)="addCourse()" mat-raised-button color="primary" class="sm"><i class="fa fa-plus"
                        aria-hidden="true"></i></a>
            </div>
            <br />
            <br />
            <div class="col-md-12">
                <div class="manage-tbl">
                    <mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <ng-container matColumnDef="SortId">
                            <mat-header-cell *matHeaderCellDef width="80px"> No. </mat-header-cell>
                            <mat-cell *matCellDef="let element;let j = index">
                                {{ (j+1) + (myPaginator.pageIndex * myPaginator.pageSize) }}</mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="CourseName">
                            <mat-header-cell *matHeaderCellDef> Course</mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                {{element.CourseName}}

                            </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="Operation">
                            <mat-header-cell *matHeaderCellDef width="50px"> Operation </mat-header-cell>
                            <mat-cell *matCellDef="let element">
                                <a (click)="editCourse(element.CourseId)" class="sm" mat-raised-button color="primary">
                                    <i class="fa fa-edit" aria-hidden="true"></i>
                                </a>&nbsp;&nbsp;&nbsp;
                                <a (click)="delete_Course(element.CourseId)" class="sm" mat-raised-button color="warn">
                                    <i class="fa fa-trash"></i>
                                </a>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="Columns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: Columns;"></mat-row>
                    </mat-table>
                    <mat-paginator #myPaginator [length]="25" [pageSize]="5" [pageSizeOptions]="[5, 10, 25, 100]">
                    </mat-paginator>

                </div>
            </div>
        </div>
    </div>
</ng-template>