<div class="custom-banner">
    <div class="fixed-banner">
    <div class="container">
        <div class="banner-content">
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="box">
                        <h2 class="ao-home-title">
                            Build Your <strong>Dream Career</strong> With Our
                            <span>World-Class</span>
                            <span> Programs</span>
                        </h2>
                        <div class="form-group exp-btn">
                            <a href="" class="btn cta"> Explore Our Programs</a>
                        </div>
                        <div class="form-group">
                            <div class="search-box">
                                <input class="div-control form-control-sm " type="text" placeholder="Search Programs"
                                    aria-label="Search">
                                <i class="fas fa-search sa"></i>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="col-md-6 col-sm-12 pad">
                
                            <div class="radio-">
                                <img src="./assets/images/landing-background.png" alt="Top mba college in kerala,
                    top colleges in kerala for mba,
                    top 10 mba colleges in india,
                    Top Ranked MBA Colleges in India,
                    Best school of management studies india">
                            </div>
                        </div>
                       
                        </div>
                    </div> 

                </div>
                </div>
            </div>


<div class="enquiry" id="top-icon">
    <div class="quote-content">
        <div class="enquiry-box">
            <h2 class="quick-head">Quick Enquiry</h2>
            <p>For more details, please write to us</p>
            <form [formGroup]="QuickEnquiryForm" (ngSubmit)="onSubmit()" autocomplete="off">
                <div class="form-group">
                    <input formControlName="Name" [ngClass]="{ 'is-invalid': submitted && formControl.Name.errors }"
                        type="text" class="form-control" placeholder="Name">
                </div>
                <div class="form-group">
                    <input formControlName="Email" [ngClass]="{ 'is-invalid': submitted && formControl.Email.errors }"
                        type="email" class="form-control" placeholder="Email">
                </div>
                <div class="form-group">
                    <input formControlName="Mobile" [ngClass]="{ 'is-invalid': submitted && formControl.Mobile.errors }"
                        type="number" class="form-control" placeholder="Mobile">
                </div>
                <div class="form-group">
                    <select formControlName="Program"
                        [ngClass]="{ 'is-invalid': submitted && formControl.Program.errors }" id=""
                        class="form-control">
                        <option value="BBA (3 Years)"> BBA (3 Years)</option>
                        <option value="MBA (2 Years)"> MBA (2 Years)</option>
                        <option selected="selected" value="">--Program Interested In--</option>

                    </select>
                </div>
                <div class="form-group">
                    <textarea formControlName="Message"
                        [ngClass]="{ 'is-invalid': submitted && formControl.Message.errors }" rows="2" cols="20" id=""
                        class="form-control" placeholder="Message"></textarea>
                </div>
                <div class="form-group">
                    <input type="submit" value="Register Now"
                        class="btn btn-warning btn-lg fg-black btn-block bg-yellow ft-SegoePro-CondBold"
                        style="width: 100%;">
                </div>
            </form>

        </div>
    </div>

</div>
<section>
    <div class="about">
        <div class="container">
            <h1 class="heading">Jai Bharath school Of Management Studies</h1>
            <div class="underline">
                <img src="./assets/images/why-underline.png" alt="Top mba college in kerala,
           top colleges in kerala for mba,
           top 10 mba colleges in india,
           Top Ranked MBA Colleges in India,
           Best school of management studies india">
            </div>
            <p>Jai Bharath School of Management Studies is one of the best B School in the state of Kerala founded in
                2005 with an endeavour of developing scholars who wish to peruse the dream of becoming an entrepreneur.
                The Institute comes under Jai Bharath Educational Foundation which was established in the year 2001 as a
                charitable trust with a registered office at Thrikkakkara, Ernakulam, Kerala. The foundations key
                objective is to bring out intellectual awakening in people and also to prepare manpower who are capable
                of contributing effectively towards national development. Jai Bharath Educational Foundation is one of
                the largest networks of educational institutions in Kerala. The Institution affiliated to the
                foundations are Jai Bharath College of Management and Engineering Technology, Jai Bharath Arts & Science
                College, KMM college of management and technology, KMM College of Arts & Science, RU college of management cochin and technology and Jai Bharath Training College.</p>

        </div>
    </div>
</section>
<section>
    <div class="mba-proginfowp">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="mbaprog-contehd">
                        <h2>MBA 4.0 Program </h2>
                        <p class="lead">The Industry Integrated specializations to understand the Real Business World.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4"></div>
                <div class="col-md-4">
                    <div class="mba-prginbxin">
                        <ul>
                            <li>
                                <i class="fa fa-check"></i>
                                <strong>Marketing</strong>
                            </li>
                            <li>
                                <i class="fa fa-check"></i>
                                <strong>Human Resource</strong>
                            </li>
                            <li>
                                <i class="fa fa-check"></i>
                                <strong>International Business</strong>
                            </li>
                            <li>
                                <i class="fa fa-check"></i>
                                <strong>Information System</strong>
                            </li>
                            <li>
                                <i class="fa fa-check"></i>
                                <strong>Finance</strong>
                            </li>
                            <li>
                                <i class="fa fa-check"></i>
                                <strong>Operation Management</strong>
                            </li>
                        </ul>
                        <div class="absoimg-progabt">
                            <img src="./assets/images/program-ico.png" alt="Top mba college in kerala,
           top colleges in kerala for mba,
           top 10 mba colleges in india,
           Top Ranked MBA Colleges in India,
           Best school of management studies india">
                        </div>
                    </div>
                </div>
                <div class="col-md-4"></div>
            </div>
        </div>
    </div>
</section>
<div class="program-wrap">
    <div class="container splonebg1 ">
        <h2 class="head2blue">INDUSTRY ALIGNED <span>MBA 4.0</span> PROGRAM:</h2>
        <div class="row">
            <div class="col-md-8">
                <h4 class="program-head">Program OverView:</h4>
                <p> Jai Bharath school Of Management Studies offers an “INDUSTRY-ALIGNED MBA Program” designed to groom
                    tomorrow’s Leaders aligned to the
                    needs of INDUSTRY 4.0 that is truly designed & delivered the way the industry expects to meet its
                    challenges, Approved by AICTE, our internationally acclaimed bespoke curriculum is designed to
                    prepare students for tomorrows world. The additional courses not only give all-round knowledge but
                    make them relevant and ready for action to the industry.</p>
            </div>
            <div class="col-md-4">
                <img src="./assets/images/industry4.0.png" class="img-thumbnail" alt="Top mba college in kerala,
                top colleges in kerala for mba,
                top 10 mba colleges in india,
                Top Ranked MBA Colleges in India,
                Best school of management studies india">
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h4 class="program-head">OBJECTIVES:</h4>
                <p>This Program 4.0 aims at realizing the following objectives</p>
                <ul>
                    <li>To bridge the gap between university curriculum and the latest industry requirements.</li>
                    <li>To inculcate student requirements that are beyond knowledge given in the curriculum in areas
                        such as Interpersonal skills, creativity, lateral thinking, communication, people management.
                    </li>
                    <li>To provide competitive edge to the students and make them professionals to compete even
                        in the international market.</li>

                </ul>
                <a (click)="commonService.scrollPosition('top-icon')" class="info-btn btn">Apply Now</a>

            </div>
        </div>
    </div>
</div>
<div class="certificate">
    <div class="container">
        <div class="certificate-cls">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="certificate-head">Our Certifications</h1>
                </div>
            </div>
            <ul class="nav nav-pills certificate-tabs">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="pill" href="#place1">Course Completion</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="pill" href="#place2">Job Fitment</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="pill" href="#place3">University Certification</a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="place1">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="certificate-data">
                                <h2>Course Completion</h2>
                                <p>
                                    A directly enrolled student will be awarded the course completion after successful
                                    completion of Your MBA. After completing this course a candidate can get the jobs in
                                    any government as well as private sector. This certification can be used for the
                                    secondary certification for private companies.
                                </p>
                                <ol>
                                    <li> Certification from Jai Bharath School of management Studies and AICTE and
                                        UGC.
                                    </li>
                                    <li>Certifications verifiable through our website.</li>
                                    <li>Certification used as your secondary certification if you have any back logs.
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="certificate-img">
                                <img src="./assets/images/mba (1).jpg" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="place2">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="certificate-data">
                                <h2>Job Fitment</h2>
                                <p>
                                    If you have been looking for a “suitable career option after your degree course”,
                                    you have
                                    come to the right place because here, in Jai Bharath,  you will find a comprehensive
                                    list of professions to choose after a degree. Students who experience job fit are
                                    productive,
                                    happy, and will be a contributing as a perfect employee. It may find you have a
                                    potential A-
                                    player assigned to the wrong seat on the bus. Our job fitment analysis complete
                                    guide will
                                    help you make a well-informed decision and pursue a perfect career.
                                </p>
                                <ol>
                                    <li>The certification used as your fitness certification for your career journey
                                    </li>
                                    <li>Job fitment certification helps to get shortlist faster by companies looking for
                                        pre-
                                        assessed candidates.</li>
                                    <li>Help you landing and safe and secure job.</li>
                                    <li>Certification globally recognised and it can also use abroad</li>
                                    <li>Certification can be verified using QR code</li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="certificate-img">
                                <img src="./assets/images/certificates1.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="place3">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="certificate-data">
                                <h2>University Certification</h2>
                                <p>
                                    The certificate for engineering was provided by MG university(Mahthma Gandhi
                                    University)
                                    this is the prominent certificate MBA gets on completing his two year course. This
                                    often
                                    leads to increased earning potential as well. Also, MBA certificates can help
                                    workers stay
                                    up-to-date with changes in their industries.
                                </p>
                                <ol>
                                    <li>
                                        He certificates from MG university.
                                    </li>
                                    <li>
                                        Globally recognised certification
                                    </li>
                                    <li>
                                        You can have used as your primary certification.
                                    </li>
                                    <li>
                                        Certification gives an extra mileage for your career as well as your salary.
                                    </li>
                                </ol>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="certificate-img">
                                <img src="./assets/images/mba2.jpg" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>



</div>
<div class="">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="semester-content">
                    <h3>First Semester</h3>
                    <ul>
                        <li>Orientation Program </li>
                        <li>Fresher's Day</li>
                        <li> Industrial visit to Bangalore</li>
                        <li>Advance excel certification</li>
                        <li>Business English Communication Certification</li>


                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="semester-content">
                    <h3>Second Semester</h3>
                    <ul>
                        <li>Out Bound Training (3-4 days)</li>
                        <li>National intercollege Management Fest</li>
                        <li>Industrial Visit to Chennai. </li>
                        <li>Global Certification.</li>
                        <li>Internship at MNC</li>
                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="semester-content">
                    <h3>Third Semester</h3>
                    <ul>
                        <li>CSR activity camp(3-4 days) </li>
                        <li>Industrial Visit</li>
                        <li>Automation anyware certification. </li>
                        <li>lntercollege sports meet </li>
                        <li>Business Analyties </li>


                    </ul>
                </div>
            </div>
            <div class="col-md-6">
                <div class="semester-content">
                    <h3>Fourth Semester</h3>
                    <ul>
                        <li>International/National Tour </li>
                        <li>Placement Orientationby Industry experts(3-4 days)</li>
                        <li>Grand Finale</li>


                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="highlights">
    <div class="container">
        <div class="row">
            <div class="col-md-7">
                <ul>
                    <li>
                        <i class="fa fa-angle-double-right"></i>
                        <strong>20 Years</strong> of Excellence in Management Education.
                    </li>
                    <li>
                        <i class="fa fa-angle-double-right"></i>
                        Grooming ‘Tomorrow’s Leaders aligned to the needs of <strong>industry 4.0,</strong> through its
                        bespoke Curriculum.
                    </li>
                    <li>
                        <i class="fa fa-angle-double-right"></i>
                        Best-in-class faculty with a blend of PhDs and Industry experienced professionals.
                    </li>
                    <li>
                        <i class="fa fa-angle-double-right"></i>
                        Global connect through partnerships and programs with top business schools all around the world.
                    </li>
                    <li>
                        <i class="fa fa-angle-double-right"></i>
                        Internationally acclaimed practice driven initiatives that have served as case studies in
                        business schools globally: <strong>Research Incubation, Social Immersion, Personality
                            Enhancement,
                            Industry Internships and Corporate Mentoring.</strong>
                    </li>
                    <li>
                        <i class="fa fa-angle-double-right"></i>
                        100% placements with the best MNCs and unicorns globally.
                    </li>
                    <li>
                        Only Business school ranked as <strong>Best B</strong> School in Industry Interface.
                    </li>


                </ul>
            </div>
            <div class="col-md-5">
                <img src="./assets/images/place2.jpeg" class="img-thumbnail2" alt="Top mba college in kerala,
                top colleges in kerala for mba,
                top 10 mba colleges in india,
                Top Ranked MBA Colleges in India,
                Best school of management studies india">
            </div>
        </div>
    </div>
</div>

<div class="advantage">
    <div class="container">
        <div class="advant-content">
            <!-- <div class="advant-head">
                <h1>Our Highlights</h1>
            </div> -->
            <div class="mbaprog-contehd">
                <h2>Our Highlights</h2>
                <!-- <p class="lead">The Industry Integrated specializations to understand the Real Business World.
                </p> -->
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="advant-box">
                        <div class="inr-icons">
                            <span class="lnr lnr-arrow-up"></span>
                        </div>
                        <h2>360 Career Development</h2>
                        <p>
                            An expert driven industry mentorships supplemented with job fitment analysis and also
                            enable for resume building using AI mode.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="advant-box">
                        <div class="inr-icons">
                            <span class="lnr lnr-database"></span>
                        </div>
                        <h2>Financial Support</h2>
                        <p>
                            Our financial aid helps the students and their families by
                            covering higher education expenses, tie up with banks and also provide various
                            scholarship
                            schemes.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="advant-box">
                        <div class="inr-icons">
                            <span class="lnr lnr-laptop"></span>
                        </div>
                        <h2>Placement Services</h2>
                        <p>
                            The Jai Bharath placement portal serves you access to
                            extensive job leads and reference of leading firms.
                        </p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="advant-box">
                        <div class="inr-icons">
                            <span class="lnr lnr-earth"></span>
                        </div>
                        <h2>Eco-Friendly Campus</h2>
                        <p>
                            We at Jai Bharath take care of all the environment friendly factors to contribute in
                            making an
                            eco-friendly campus
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="advant-box">
                        <div class="inr-icons">
                            <span class="lnr lnr-book"></span>
                        </div>
                        <h2>Multi Displicinary Curriculum</h2>
                        <p>
                            We design various multidisciplinary curriculum for students that suits a specific career
                            path.
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="advant-box">
                        <div class="inr-icons">
                            <span class="lnr lnr-location"></span>
                        </div>
                        <h2>Prime Location</h2>
                        <p>
                            We are located at the Mid of Kochi, Idukki and Kottayam so that its very much easy to
                            step into our campus.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<div class="mbaprog-contehd">
    <h2>Our Skill Partner</h2>
    <!-- <p class="lead">The Industry Integrated specializations to understand the Real Business World.
    </p> -->
</div>
<div class="grad-sec">
    <div class="row">

        <div class="  col-4">
            <div class="grad-body">
                <img src="assets/images/logos/IBM_logo.png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
            </div>
        </div>
        <div class="  col-4">
            <div class="grad-body">
                <img src="assets/images/logos/automation-anywhere-vector-logo.png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
            </div>
        </div>
        <div class="  col-4">
            <div class="grad-body">
                <img src="assets/images/logos/sim.jpg" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
            </div>
        </div>
        <div class=" col-4 ">
            <div class="grad-body">
                <img src="assets/images/logos/cambridgepng.png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
            </div>
        </div>

        <div class="  col-4">
            <div class="grad-body">
                <img src="assets/images/logos/gidimo.png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
            </div>
        </div>
        <div class=" col-4">
            <div class="grad-body">
                <img src="assets/images/logos/nergy.png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
            </div>
        </div>
        <div class="  col-4 ">
            <div class="grad-body">
                <img src="assets/images/logos/microsoft-log.png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
            </div>
        </div>
        <div class="  col-4">
            <div class="grad-body">
                <img src="assets/images/logos/logo_egal_download.jpg" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
            </div>
        </div>
        <div class="  col-4">
            <div class="grad-body">
                <img src="assets/images/logos/Tally_Logo.png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
            </div>
        </div>
        <div class="  col-4">
            <div class="grad-body">
                <img src="assets/images/logos/mepec.png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
            </div>
        </div>

        <div class="  col-4">
            <div class="grad-body">
                <img src="assets/images/logos/sap1.png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
            </div>
        </div>


    </div>
</div>