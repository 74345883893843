<div class="col-md-12" mat-dialog-title>
    <div class="main-head">
        <h1>Event Registeration
            <span style="
            float: right;
        " mat-button [mat-dialog-close]="false" class="pull-right close-popup"><i class="fa fa-times"></i></span>
        </h1>
    </div>
</div>
<div class="container">
    <div class="enquir-form">
        <div class="row">
            <div class="col-md-12">

                <div class="manage-tbl">
                    <p class="text-center" *ngIf="!AllKmatMockRegistration.length">No data found</p>
                    <div class="row" *ngIf="AllKmatMockRegistration.length">
                        <div class="col-md-12">
                            <a href="{{functionService.getAdminApi()}}/_blog/downloadAllEventRegisteration.php?EventId={{EventId}}" target="_blank" mat-raised-button color="primary">Download</a>
                            &nbsp;&nbsp;

                            <table mat-table [dataSource]="AllKmatMockRegistration" class="mat-elevation-z8"
                                width="100%">

                                <ng-container matColumnDef="SortId">
                                    <th mat-header-cell *matHeaderCellDef> # </th>
                                    <td mat-cell *matCellDef="let element"> - </td>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <th mat-header-cell *matHeaderCellDef> Name </th>
                                    <td mat-cell *matCellDef="let element"> {{element.FirstName}} - {{element.LastName}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Email">
                                    <th mat-header-cell *matHeaderCellDef> Email Id </th>
                                    <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
                                </ng-container>

                                <ng-container matColumnDef="Phone">
                                    <th mat-header-cell *matHeaderCellDef> Contact No. </th>
                                    <td mat-cell *matCellDef="let element"> {{element.Phone}} </td>
                                </ng-container>
                                <ng-container matColumnDef="IsStudent">
                                    <th mat-header-cell *matHeaderCellDef> Is Student </th>
                                    <td mat-cell *matCellDef="let element"> {{element.IsStudent}}
                                        <br />{{element.StudentCourse}}
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="HearAboutEvent">
                                    <th mat-header-cell *matHeaderCellDef> Hear About Event From </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span
                                            *ngIf="element.HearAboutEvent!='Other'">{{element.HearAboutEvent}}</span>
                                        <span
                                            *ngIf="!element.HearAboutEvent=='Other'">{{element.HearAboutEventOther}}</span>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="Questions">
                                    <th mat-header-cell *matHeaderCellDef> Questions </th>
                                    <td mat-cell *matCellDef="let element">
                                        <p style="width: 200px;">{{element.Questions}}</p>
                                    </td>
                                </ng-container>

                                <ng-container matColumnDef="CreatedDate">
                                    <th mat-header-cell *matHeaderCellDef> Messaged On </th>
                                    <td mat-cell *matCellDef="let element"> {{element.CreatedDate}}
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="EnquiriesColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: EnquiriesColumns;" (click)="getRecord(row)"
                                    class="cursorView"></tr>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>