<div class="main-container">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <img class="col-pic" src="./assets/images/Hmepage.png" alt="Top mba college in kerala,
                top colleges in kerala for mba,
                top 10 mba colleges in india,
                Top Ranked MBA Colleges in India,
                Best school of management studies india">
            </div>
            <div class="col-md-6">
                <div class="welcome">
                    <h1 class="heading">
                        About Us
                    </h1>

                    <p>
                        Jai Bharath School of Management Studies is one of the best B School in the state of Kerala
                        founded in 2004 with an endeavor of developing scholars who wish to peruse the dream of becoming
                        an entrepreneur. The Institute comes under Jai Bharath Educational Foundation which was
                        established in the year 2001 as a charitable trust with a registered office at Thrikkakkara,
                        Ernakulam, Kerala. The foundations key objective is to bring out intellectual awakening in
                        people and also to prepare manpower who are capable of contributing effectively towards national
                        development. Jai Bharath Educational Foundation is one of the largest networks of educational
                        institutions in Kerala. The Institution affiliated to the foundations are Jai Bharath College of
                        Management and Engineering Technology, Jai Bharath Arts & Science College, KMM college of
                        management and technology, KMM College of Arts & Science, KMM Women’s College, RU college of
                        management and technology and Jai Bharath Training College.
                    </p>
                    <p>
                        Jai Bharath School of Management Studies initiated its MBA programmes in the year of 2005. The
                        programmes offered are:
                    </p>
                    <ul>
                        <li>MBA in Marketing </li>
                        <li>MBA in Human Resource</li>
                        <li>MBA in International Business</li>
                        <li>MBA in Information System </li>
                        <li>MBA in Finance</li>
                        <li>MBA in Operations Management</li>
                    </ul>
                    <p>
                        MBA programme offered by us is very unique not only in its contents but also in its delivery.
                        During the course students are introduced to managerial application on Industry 4.0, Artificial
                        Intelligence, Block chain, Data Analytics. The very strength of our institution is qualified and
                        highly competent faculty. This with a well-designed course content and University syllabus make
                        our MBA unique.
                    </p>

                </div>

            </div>
        </div>
    </div>
</div>



<div class="vision-mission">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="mv-box">
                    <div class="Part-Icon"> <img class="img-mission" src='assets/images/d.png' alt=" Mission Icon, Top mba college in kerala,
                        top colleges in kerala for mba,
                        top 10 mba colleges in india,
                        Top Ranked MBA Colleges in India,
                        Best school of management studies india">
                    </div>

                    <div class="mv-content">
                        <h1>Vision</h1>
                        <ul>
                            <li>Grooming world class managers for total problem solutions and growth.</li>
                            <li>To be a premier management Institution committed to academic excellence, moulding
                                students with quick reflexes and ability to think critically and creatively.</li>
                            <li>To build a vibrant and socially sensitive fraternity of scholars, management experts and
                                value based professionals with adaptability to the fast changing, dynamic business and
                                social environment.</li>
                        </ul>

                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="mv-box">
                    <div class="Part-Icon"> <img class="img-vision" src="assets/images/d1.png" alt=" Mission Icon, Top mba college in kerala,
                        top colleges in kerala for mba,
                        top 10 mba colleges in india,
                        Top Ranked MBA Colleges in India,
                        Best school of management studies india">
                    </div>

                    <div class="mv-content">
                        <h1>Mission</h1>
                        <p>
                            Facilitate a diverse and challenging environment where the students can learn, grow and
                            emerge ready to inspire and lead. Empower the students with innovative and techno sawy
                            academics that facilitate excellence in teaching, research, consulting and other
                            professional activities. Establish a closer association with industry and other leading
                            institutions and provide a holistic and transformative education that will match with the
                            global standard.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">

            </div>
            <div class="col-md-6">
                <div class="mv-box-obj">
                    <div class="mv-content">
                        <h1>Objectives</h1>
                        <ul>
                            <li>Nurture professionally competent Managers with morals and ethics.</li>
                            <li>To create entrepreneurs with right mix of principles, tradition and modern technology.
                            </li>
                            <li>Churn out the cream in each student by providing experiential learning. Explore, develop
                                and apply human and technological capabilities for the benefit of the community</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-3">

            </div>
        </div>
    </div>
</div>



<div class="place-head">


    <div class="container">
        <div class="messages">
            <div class="row">
                <div class="col-md-12">
                    <div class="message-box">
                        <h2>Chairman Message</h2>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="img-holdr">
                                    <img class="chairman-pic" src="assets/images/chairman.jpg" alt=" Top mba college in kerala,
                                    top colleges in kerala for mba,
                                    top 10 mba colleges in india,
                                    Top Ranked MBA Colleges in India,
                                    Best school of management studies india">
                                </div>
                            </div>
                            <div class="col-md-9">
                                <h3>Er. A M Kharim <br> B.Sc.(Engg), MIE</h3>
                                <p>
                                    The Governing body believes that it has a special commitment to support each
                                    individual's goals. To this end, the college places emphasis on a world-class
                                    education that prepares students academically, personally, and professionally for
                                    successful careers in their chosen fields. Policies, programs, and activities, which
                                    have been designed to implement this philosophy and purpose statement, are evaluated
                                    periodically and changed, as necessary, to meet the needs of the student body and
                                    the insti¬tution. Our educational approach is process-oriented; our emphasis is on
                                    bridging theory and practice, and in learning through collaboration and
                                    participation. We strive to ensure that the time spent at the college is a time of
                                    growth, discovery, and achievement.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="message-box">
                        <h2>Principal Message</h2>
                        <div class="row">
                            <div class="col-md-9">
                                <h3>Dr.Mathew K A
                                    <br>M.com PhD<br>
                                </h3>

                                <p>Education must provide life-building, man-making, character-making assimilation of
                                    ideas. The ideal of this type of education would be to produce an integrated person
                                    – one who has learned how to improve his intellect, purify his emotions, and stand
                                    firm on moral virtues and unselfishness. Negative thoughts weaken men. If you speak
                                    kind words to children and encourage them, they are bound to improve in time. If you
                                    can give them positive ideas, people will grow up to be men and learn to stand on
                                    their own legs.
                                </p>

                            </div>
                            <div class="col-md-3">
                                <div class="img-holdr">

                                    <img class="chairman-pic" src="assets/images/college_principal.png" alt=" Top mba college in kerala,
                                    top colleges in kerala for mba,
                                    top 10 mba colleges in india,
                                    Top Ranked MBA Colleges in India,
                                    Best school of management studies india">

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-12">
                    <div class="message-box">
                        <h2>Director </h2>
                        <div class="row">
                            <div class="col-md-3">
                                <div class="img-holdr">
                                    <img class="chairman-pic" src="assets/images/director.jpg" alt=" Top mba college in kerala,
                                    top colleges in kerala for mba,
                                    top 10 mba colleges in india,
                                    Top Ranked MBA Colleges in India,
                                    Best school of management studies india">

                                </div>
                            </div>
                            <div class="col-md-9">
                                <h3>Dr.Pradeep Kumar
                                    <br>PhD in Management, Master of Business Administration ( HR)<br>
                                </h3>

                                <p>Prof Pradeep Kumar C is an IIM Alumni brings multifaceted experience from both in
                                    Industry and academia. He is specialized in Strategic Management, Human Resource
                                    Management and Business Analytics. He owns more than ten national and international
                                    publications and presented papers with institutes with national repute. Presently
                                    employed as Director with Jai Bharath School of Management Studies.
                                </p>
                                <!-- <p>Jai Bharath School of Management studies is one of the best B School in the state of
                                    Kerala. Jai Bharath MBA follows outcome based education method. Our curriculum
                                    delivery give thrust on critical thinking, experiential learning apart from
                                    acquisition of knowledge. We believes in molding the students to take up jobs in
                                    rapidly changing global corporate environment.</p>
                                <p>
                                    Newspaper reading, book reviews, case study discussions etc are made compulsory in
                                    the program which enables the students to familiarise with dynamic business
                                    conditions. Exposure on Anyware automation and SAP modules give an understanding on
                                    present global business environment. Research based assignments and presentation of
                                    the same enables the students to acquire and strengthen the requisite skills of a
                                    Manager.<br>Industry expert sessions / interactions and meet CEO are part of
                                    pedagogy, ensure students are well versed with latest changes in the industry.
                                    Students are encouraged to attend national /international cultural fest to make a
                                    way for networking and fostering relations ships across the pear groups. Managerial
                                    application on Industry 4.0, Artificial Intelligence, Block chain, Data Analytics
                                    etc are familiarised in the course of study.<br>The MBA program offered by Jai
                                    Bharath School of Management studies is much more than University curriculum with
                                    all above specific additions make our MBA as a special one.<br>

                                    I welcome MBA aspirants to our program for learning and journey of new excitement.
                                </p> -->
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
    <div class="container">
        <div class="placement-main">
            <h1 class="prj-head">Affiliations</h1>
            <div class="grad-sec">
                <div class="row">

                    <div class=" col-md-2 col-6 offset-md-1">
                        <div class="grad-body">
                            <img src="assets/images/logos/pic1.png" alt=" Top mba college in kerala,
                            top colleges in kerala for mba,
                            top 10 mba colleges in india,
                            Top Ranked MBA Colleges in India,
                            Best school of management studies india" />
                        </div>
                    </div>
                    <div class=" col-md-2 col-6">
                        <div class="grad-body">
                            <img src="assets/images/logos/mgu.jpeg" alt=" Top mba college in kerala,
                            top colleges in kerala for mba,
                            top 10 mba colleges in india,
                            Top Ranked MBA Colleges in India,
                            Best school of management studies india" />
                        </div>
                    </div>
                    <div class=" col-md-2 col-6">
                        <div class="grad-body">
                            <img src="assets/images/logos/pic3.png" alt=" Top mba college in kerala,
                            top colleges in kerala for mba,
                            top 10 mba colleges in india,
                            Top Ranked MBA Colleges in India,
                            Best school of management studies india" />
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6">
                        <div class="grad-body">
                            <img src="assets/images/logos/pic4.png" alt=" Top mba college in kerala,
                            top colleges in kerala for mba,
                            top 10 mba colleges in india,
                            Top Ranked MBA Colleges in India,
                            Best school of management studies india" />
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2 col-6">
                        <div class="grad-body">
                            <img src="assets/images/logos/pic5.png" alt=" Top mba college in kerala,
                            top colleges in kerala for mba,
                            top 10 mba colleges in india,
                            Top Ranked MBA Colleges in India,
                            Best school of management studies india" />
                        </div>
                    </div>
                </div>
            </div>
            <h1 class="prj-head">International Partnership / Accreditations</h1>

            <div class="grad-sec">
                <div class="row">

                    <div class=" col-md-2 col-4">
                        <div class="grad-body">
                            <img src="assets/images/logos/IBM_logo.png" alt=" Top mba college in kerala,
                            top colleges in kerala for mba,
                            top 10 mba colleges in india,
                            Top Ranked MBA Colleges in India,
                            Best school of management studies india" />
                        </div>
                    </div>
                    <div class=" col-md-2 col-4">
                        <div class="grad-body">
                            <img src="assets/images/logos/automation-anywhere-vector-logo.png" alt=" Top mba college in kerala,
                            top colleges in kerala for mba,
                            top 10 mba colleges in india,
                            Top Ranked MBA Colleges in India,
                            Best school of management studies india" />
                        </div>
                    </div>
                    <div class=" col-md-2 col-4">
                        <div class="grad-body">
                            <img src="assets/images/logos/sim.jpg" alt=" Top mba college in kerala,
                            top colleges in kerala for mba,
                            top 10 mba colleges in india,
                            Top Ranked MBA Colleges in India,
                            Best school of management studies india" />
                        </div>
                    </div>
                    <div class="col-md-2 col-4 ">
                        <div class="grad-body">
                            <img src="assets/images/logos/cambridgepng.png" alt=" Top mba college in kerala,
                            top colleges in kerala for mba,
                            top 10 mba colleges in india,
                            Top Ranked MBA Colleges in India,
                            Best school of management studies india" />
                        </div>
                    </div>

                    <div class=" col-md-2 col-4">
                        <div class="grad-body">
                            <img src="assets/images/logos/gidimo.png" alt=" Top mba college in kerala,
                            top colleges in kerala for mba,
                            top 10 mba colleges in india,
                            Top Ranked MBA Colleges in India,
                            Best school of management studies india" />
                        </div>
                    </div>
                    <div class=" col-md-2 col-4">
                        <div class="grad-body">
                            <img src="assets/images/logos/nergy.png" alt=" Top mba college in kerala,
                            top colleges in kerala for mba,
                            top 10 mba colleges in india,
                            Top Ranked MBA Colleges in India,
                            Best school of management studies india" />
                        </div>
                    </div>
                    <div class=" col-md-2 col-4 offset-md-1">
                        <div class="grad-body">
                            <img src="assets/images/logos/microsoft-log.png" alt=" Top mba college in kerala,
                            top colleges in kerala for mba,
                            top 10 mba colleges in india,
                            Top Ranked MBA Colleges in India,
                            Best school of management studies india" alt=" Top mba college in kerala,
                            top colleges in kerala for mba,
                            top 10 mba colleges in india,
                            Top Ranked MBA Colleges in India,
                            Best school of management studies india" />
                        </div>
                    </div>
                    <div class=" col-md-2 col-4">
                        <div class="grad-body">
                            <img src="assets/images/logos/logo_egal_download.jpg" alt=" Top mba college in kerala,
                            top colleges in kerala for mba,
                            top 10 mba colleges in india,
                            Top Ranked MBA Colleges in India,
                            Best school of management studies india" />
                        </div>
                    </div>
                    <div class=" col-md-2 col-4">
                        <div class="grad-body">
                            <img src="assets/images/logos/Tally_Logo.png" alt="Top mba college in kerala,
                            top colleges in kerala for mba,
                            top 10 mba colleges in india,
                            Top Ranked MBA Colleges in India,
                            Best school of management studies india" />
                        </div>
                    </div>
                    <div class=" col-md-2 col-4">
                        <div class="grad-body">
                            <img src="assets/images/logos/mepec.png" alt=" Top mba college in kerala,
                            top colleges in kerala for mba,
                            top 10 mba colleges in india,
                            Top Ranked MBA Colleges in India,
                            Best school of management studies india" />
                        </div>
                    </div>

                    <div class=" col-md-2 col-4">
                        <div class="grad-body">
                            <img src="assets/images/logos/sap1.png" alt=" Top mba college in kerala,
                            top colleges in kerala for mba,
                            top 10 mba colleges in india,
                            Top Ranked MBA Colleges in India,
                            Best school of management studies india" />
                        </div>
                    </div>

                    <div class="col-md-3 ">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>




<div class="placestart-head">
    <div class="container">
        <div class="placement-main">
            <h1 class="prj-head">Partners</h1>
            <div class="placement-area">
                <ul id="slider-partners" class="content-slider">
                    <li>
                        <div class="placement-box">
                            <div class="placein-box">
                                <img src="assets/images/logos/Startup_Village.jpg" alt=" Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="placement-box">
                            <div class="placein-box">
                                <img src="assets/images/logos/sap.jpg" alt=" Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="placement-box">
                            <div class="placein-box">
                                <img src="assets/images/logos/eme.png" alt=" Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="placement-box">
                            <div class="placein-box">
                                <img src="assets/images/logos/bramma.png" alt=" Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="placement-box">
                            <div class="placein-box">
                                <img src="assets/images/logos/icon.jpg" alt=" Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="placement-box">
                            <div class="placein-box">
                                <img src="assets/images/logos/techno.png" alt=" Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="lSAction partners-controls">
                    <a class="lSPrev" id="partnersPrev"><img src="./assets/images/arrow-left.png" alt=" Top mba college in kerala,
                        top colleges in kerala for mba,
                        top 10 mba colleges in india,
                        Top Ranked MBA Colleges in India,
                        Best school of management studies india" /></a>
                    <a class="lSNext" id="partnersNext"><img src="./assets/images/arrow-right.png" alt=" Top mba college in kerala,
                        top colleges in kerala for mba,
                        top 10 mba colleges in india,
                        Top Ranked MBA Colleges in India,
                        Best school of management studies india" /></a>
                </div>
            </div>
        </div>
    </div>
</div>