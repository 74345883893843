<!-- ======= Header ======= -->
<header class="header">
  <div class="main-header">
    <nav class="navbar navbar-expand-md navbar-dark">
      <div class="logo-background">
        <div class="logo-background-property">
          <a class="navbar-brand" routerLink="/home"><img src="assets/images/logo.png"></a>
        </div>
      </div>
     
      <button class="navbar-toggler menu-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
        <i class="fa fa-bars" aria-hidden="true"></i>
      </button>
      <div class="main-head">
        <ul class="social_footer_ul">
          <li><a href="https://www.facebook.com/jaibharathsms/">
              <!--<i class="fab fa-facebook-f"></i>--><img src="assets/images/Social (1).png" />
            </a></li>
          <li><a href=""><img src="assets/images/Social (2).png" /></a></li>
          <li><a href="https://instagram.com/jaibharathsms?igshid=11e4docqvqqkn"><img
                src="assets/images/Social (3).png" /></a></li>
          <li><a href=""><img src="assets/images/Social (5).png" /></a></li>
          <li><a href="https://www.linkedin.com/in/jai-bharath-school-of-management-studies-8804601b0/"><img
                src="assets/images/Testimonial (1).png" class="linked" /></a></li>
    
        </ul>
        <div class="collapse navbar-collapse justify-content-end top-head" id="collapsibleNavbar">
        
          <ul class="navbar-nav">
            <li class="nav-item active">
              <a class="nav-link" routerLink="/home"></a>
            </li>
          
           <li class="nav-item active web"> 
             <div class="searchBox">

            <input class="searchInput"type="text" name="" placeholder="Search">
            <button class="searchButton" href="#">
                <i class="material-icons">
                    search
                </i>
            </button>
        </div>
      </li>
            <li class="nav-item active">
              <a class="nav-link" routerLink="./hire-jb">Hire from Jaibharath</a>
            </li>
            <!-- <li class="nav-item active">
              <a class="nav-link" routerLink="./students-feedback">Students Feedback</a>
            </li> -->
            <!-- <li class="nav-item active">
              <a class="nav-link" routerLink="./news-events">News & Events</a>
            </li>
            <li class="nav-item active">
              <a class="nav-link" routerLink="./webinar">Webinar</a>
            </li> -->
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle " id="navbardrope" data-toggle="dropdown" href="#">Events</a>
              <div class="dropdown-menu">
                <a class="nav-link" routerLink="./webinar">Webinar</a>
                <a class="nav-link" routerLink="./news-events">Blog</a>
                <a class="nav-link" routerLink="./news-events">Events</a>

              </div>
            </li>
            <li class="nav-item active">
              <a class="nav-link" routerLink="./carrer-withus">Career</a>
            </li>
            <li class="nav-item active">
              <a class="nav-link" routerLink="./online-admission">Online Admission</a>
            </li>
            <li class="nav-item" *ngIf="datalistService.sessionData" [routerLinkActive]="['active']">
              <a class="nav-link" [routerLink]="['/my-account']">My
                Account</a>
            </li>
            <li class="nav-item" *ngIf="datalistService.sessionData" [routerLinkActive]="['active']">
              <a class="nav-link" (click)="logout()">Logout</a>
            </li>
            <li class="nav-item active">
              <a class="nav-link drop-menu" routerLink="./student-portal">Students Portal</a>
            </li>

          </ul>
        </div>
        <div class="collapse navbar-collapse justify-content-end" id="collapsibleNavbar">
          <ul class="navbar-nav">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle drop-menu course-icon" href="#" id="navbardrop" data-toggle="dropdown">
                Courses
              </a>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#" routerLink="./marketing">MBA In Marketing</a>
                <a class="dropdown-item" href="#" routerLink="./human-resource">MBA In Human Resource</a>
                <a class="dropdown-item" href="#" routerLink="./international-business">MBA In International
                  Business</a>
                <a class="dropdown-item" href="#" routerLink="./information-system">MBA In Information System</a>
                <a class="dropdown-item" href="#" routerLink="./finance">MBA In Finance</a>
                <a class="dropdown-item" href="#" routerLink="./operation-management">MBA In Operations Management</a>

              </div>
            </li>
            <li class="nav-item active">
              <a class="nav-link" routerLink="/home">Home</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle " id="navbardrope" data-toggle="dropdown" href="#">About Us</a>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#" routerLink="./about">About College</a>
                <a class="dropdown-item" href="#" routerLink="./about-group">About Group</a>
                <a class="dropdown-item" href="#" routerLink="./about">Affiliations</a>
                <a class="dropdown-item" href="#" routerLink="./about">Partners</a>
                <a class="dropdown-item" href="#" routerLink="./faculty-profile">Faculty Profile</a>
                <a class="dropdown-item" href="#" routerLink="./gallery">Gallery</a>
                <!-- <a class="dropdown-item" href="#" routerLink="./about">MOU</a> -->

              </div>
            </li>

            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle " id="navbardrope" data-toggle="dropdown" href="#">Placements</a>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#" routerLink="./career">Career Enhancement Program</a>
                <a class="dropdown-item" href="#" routerLink="./placements">Job Fair</a>
                <a class="dropdown-item" href="#" routerLink="./placement-rpt">Placement Report</a>
                <a class="dropdown-item" href="#" routerLink="./placements">Our Recruiter</a>
              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle " id="navbardrope" data-toggle="dropdown" href="#">Facilities</a>
              <div class="dropdown-menu">
                <a class="dropdown-item" href="#" routerLink="./library" (click)="common.facility(1);">Library</a>
                <a class="dropdown-item" href="#" routerLink="./library" (click)="common.facility(2);">Hostel</a>
                <a class="dropdown-item" href="#" routerLink="./library"
                  (click)="common.facility(3);">Transportation</a>
                <a class="dropdown-item" href="#" routerLink="./library" (click)="common.facility(4);">Canteen</a>
                <a class="dropdown-item" href="#" routerLink="./library" (click)="common.facility(5);">ATM</a>
                <a class="dropdown-item" href="#" routerLink="./library" (click)="common.facility(6);">Auditorium</a>

              </div>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle " id="navbardrope" data-toggle="dropdown" href="#">Co-Activities</a>
              <div class="dropdown-menu">
                <p class="drop-head"><b>Academic Activities</b></p>
                <p class="drop-head"><b>Co-Cultural Activities</b></p>
                <!-- <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(1);">Hackathon</a> -->
                <!--<a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(1);">NSS</a>-->
                <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(2);">Faculties
                  Development Program</a>
                <a class="dropdown-item" href="#" routerLink="./activities"
                  (click)="common.activity(3);">Entrepreneurship Development<br> Cell ( EDC )</a>
                <a class="dropdown-item" href="#" routerLink="./activities"
                  (click)="common.activity(4);">Industry-Institute Interaction Cell </a>
                <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(5);">International
                  Conference</a>
                <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(6);">Mock
                  Recruitment Process </a>
                <p class="drop-head"><b>Non-Academic</b></p>
                <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(7);">Farewell</a>
                <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(8);">Annual
                  Function</a>
                <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(9);">Sport
                  Meet</a>
                <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(10);">Ignis</a>
                <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(11);">Festival
                  Celebration</a>
                <a class="dropdown-item" href="#" routerLink="./activities" (click)="common.activity(12);">Freshers
                  day</a>

              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#" routerLink="./contact">Contact Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" (click)="datalistService.isOpenNoti=!datalistService.isOpenNoti" ><i class="fa fa-bell" aria-hidden="true"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    
    <div class="container-fluid">
      <div class="modal fade" id="myModal" role="dialog">
        <div class="modal-dialog">

          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
              <h4 class="modal-title"></h4>
            </div>
            <div class="modal-body">

              <div class="col-md-12" mat-dialog-title>
                <div class="">
                  <h1 class="modal-heading">KMAT Mock Registration Form
                  </h1>
                </div>
              </div>
              <div class="container">
                <div class="enquir-form">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-img">
                        <img src="assets/images/reg-form.png" />
                      </div>
                    </div>

                  </div>

                  <form>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <input type="text" class="form-control control-box" placeholder="Name" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <input type="email" class="form-control control-box" placeholder="Email" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <input type="number" class="form-control control-box" placeholder="Phone" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <input type="text" class="form-control control-box msg-box" placeholder="address" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <input type="text" class="form-control control-box msg-box"
                            placeholder="College Name & Address" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <input type="text" class="form-control control-box" placeholder="Course Name" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <input type="text" class="form-control control-box" placeholder="Course Year" />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <div class="submit-btn">
                          <button class="btn btn-primary">Register</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


      <div id="main-banner">
        <div class="container-fluid">
          <!-- <div id="demo" class="carousel slide" data-ride="carousel">

            <ul class="carousel-indicators">
              <li data-target="#demo" data-slide-to="0" class="active"></li>
              <li data-target="#demo" data-slide-to="1"></li>
              <li data-target="#demo" data-slide-to="2"></li>
            </ul>
          
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img src="la.jpg" alt="Los Angeles">
              </div>
              <div class="carousel-item">
                <img src="chicago.jpg" alt="Chicago">
              </div>
              <div class="carousel-item">
                <img src="ny.jpg" alt="New York">
              </div>
            </div>
          
            <a class="carousel-control-prev" href="#demo" data-slide="prev">
              <span class="carousel-control-prev-icon"></span>
            </a>
            <a class="carousel-control-next" href="#demo" data-slide="next">
              <span class="carousel-control-next-icon"></span>
            </a>
          
          </div> -->
            <div id="myCarousel" class="carousel slide wide-banner" data-ride="carousel" data-interval="4000">
                <ol class="carousel-indicators">
                    <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
                    <li data-target="#myCarousel" data-slide-to="1"></li>
                    <li data-target="#myCarousel" data-slide-to="2" ></li>
                    <li data-target="#myCarousel" data-slide-to="3" ></li>
                    <li data-target="#myCarousel" data-slide-to="4" ></li>
                    <li data-target="#myCarousel" data-slide-to="5" ></li>
                    <li data-target="#myCarousel" data-slide-to="6" ></li>
                    <li data-target="#myCarousel" data-slide-to="7" ></li>


                    <!-- <li data-target="#myCarousel" data-slide-to="2"></li> -->
    
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <a href="" routerLink="/job-fair">
                            <img src="/assets/images/banners/banner12.jpg">
                            
                        </a>    
                    </div>
                    <div class="carousel-item">
                        <a href="" routerLink="/job-fair">
                            <img src="/assets/images/banners/banner13.jpg">
                        </a>
                    </div>
                    <div class="carousel-item">
                      <a href="" routerLink="/job-fair">
                          <img src="/assets/images/banners/banner10.jpg">
                      </a>
                  </div>
                  <div class="carousel-item">
                    <a href="" routerLink="/job-fair">
                        <img src="/assets/images/banners/banner9.jpg">
                    </a>
                </div>


                    <div class="carousel-item">
                      <a href="" routerLink="/job-fair">
                          <img src="/assets/images/banners/banner2.png">
                      </a>
                  </div>


                    
                    <div class="carousel-item" onclick="location.href='https://jaibharathmba.com/sunstone/index.html' ">
                        <a href="" routerLink="https://jaibharathmba.com/sunstone/index.html">
                            <img src="/assets/images/banners/banner3.jpg">
                        </a>
                    </div>
            
                    <div class="carousel-item" onclick="location.href='' ">
                        <a href="" routerLink="">
                            <img src="/assets/images/banners/banner4.jpg">
                        </a>
                    </div>
                                
                    <div class="carousel-item" onclick="location.href='' ">
                        <a href="" routerLink="">
                            <img src="/assets/images/banners/banner5.jpg">
                        </a>
                    </div>

                    <div class="carousel-item" onclick="location.href='' ">
                      <a href="" routerLink="">
                          <img src="/assets/images/banners/banner6.jpg">
                      </a>
                  </div>

             

                <div class="carousel-item" onclick="location.href='' ">
                  <a href="" routerLink="">
                      <img src="/assets/images/banners/banner8.jpg">
                  </a>
              </div>

                </div>
                <a class="carousel-control-prev" href="#demo" data-slide="prev">
                  <span class="carousel-control-prev-icon"></span>
                </a>
                <a class="carousel-control-next" href="#demo" data-slide="next">
                  <span class="carousel-control-next-icon"></span>
                </a>
    
            </div>
        </div>
    </div>
      <!-- <div class="row">
        <div class="col-md-6">
          <div class="header-details">
            <div class="searchBox mob">

              <input class="searchInput"type="text" name="" placeholder="Search">
              <button class="searchButton" href="#">
                  <i class="material-icons">
                      search
                  </i>
              </button>
          </div>
            <div class="head-title">
              <h3 class="head-caption">We offer a variety of courses<br>
                and opportunities In the field of <br>Business Management
              </h3>
            </div>
            <img src="assets/images/entroll.png" (click)="common.openEnquiryForm('Enroll')" class="entroll">
            <div class="tooltip">
            <a (click)="KMATMockRegistrationForm()" class="reg "><img src="assets/images/highlights (5).png"
                class="entroll"><span class="tooltiptext">FREE KMAT MOCK ONLINE COACHING REGISTRATION FORM</span></a>
                </div>
               
                
                
            
            <div class="media">
              <div class="row">
                <div class="col-md-2 col-sm-4">
                  <img src="assets/images/Phone.png">
                </div>
                <div class="col-md-4 col-sm-8">
                  <div class="number">
                    <a href="tel:+91 72932 29051"> +91 72932 29051 </a>
                  </div>
                </div>
                <div class="col-md-2 col-sm-4">
                  <img src="assets/images/Mail.png">
                </div>
                <div class="col-md-4 col-sm-8">
                  <div class="email">
                    <a href=""> info@jaibharathmba.com </a>
                  </div>
                </div>
              </div>
            </div>
            <p class="mgu">Visit On : <a href="https://www.mgu.ac.in/">www.mgu.ac.in</a></p>





          </div>



          <div class="sec-logo">

            <div class="row">
              <div class="col-md-12">
                <div class="program-title">
                  <p>Program Partners</p>
                </div>
              </div>
              <div class="col-md-12">
                <div class="logos-items">
                  <ul class="logogs-slider">
                    <li>
                      <img src="./assets/images/Slider_Logos (1).png">
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>


        </div>
        <div class="col-md-6">
          <div class="header-banner">
            <img src="assets/images/header-banner.png">
          </div>
        </div>
      </div> -->
    </div>
  </div>
</header>