<div class="print-form">
    <a href="https://jaibharathmba.com/_admin_console/_college/downloadJobFair.php" target="_blank" class="btn btn-primary pull-right" >Download</a>
</div>
<div id="parentdiv">
    <div class="main-head">
        <div class="head-line">
            <h1>Job Fair Enquires</h1>
        </div>
    </div>
    <div class="container=fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="manage-tbl">
                    <p class="text-center" *ngIf="!AllEnquiries.length">No data found</p>
                    <table mat-table [dataSource]="AllEnquiries" class="mat-elevation-z8" width="100%"
                        *ngIf="AllEnquiries.length">

                        <ng-container matColumnDef="SortId">
                            <th mat-header-cell *matHeaderCellDef width="60px"> # </th>
                            <td mat-cell *matCellDef="let element;let i=index;"> {{i+1}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Email">
                            <th mat-header-cell *matHeaderCellDef width="300px"> Email Id </th>
                            <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Phone">
                            <th mat-header-cell *matHeaderCellDef> Contact No. </th>
                            <td mat-cell *matCellDef="let element"> {{element.Phone}} </td>
                        </ng-container>

                        <ng-container matColumnDef="DOB">
                            <th mat-header-cell *matHeaderCellDef width="85px"> DOB </th>
                            <td mat-cell *matCellDef="let element"> {{element.DOB}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Qualification">
                            <th mat-header-cell *matHeaderCellDef width="140px"> Qualification </th>
                            <td mat-cell *matCellDef="let element"> {{element.Qualification}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Specilization">
                            <th mat-header-cell *matHeaderCellDef width="150px"> Specilization </th>
                            <td mat-cell *matCellDef="let element"> {{element.Specilization}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Experience">
                            <th mat-header-cell *matHeaderCellDef> Experience </th>
                            <td mat-cell *matCellDef="let element"> {{element.Experience}} </td>
                        </ng-container>

                        <ng-container matColumnDef="EnquiryOn">
                            <th mat-header-cell *matHeaderCellDef> Messaged On </th>
                            <td mat-cell *matCellDef="let element"> {{element.EnquiryOn}}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="EnquiriesColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: EnquiriesColumns;" (click)="getRecord(row)"
                            class="cursorView"></tr>

                    </table>
                </div>
            </div>
        </div>
    </div>
</div>