import { Component, OnInit } from '@angular/core';
declare var $: any;
// for SEO
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-about-group',
  templateUrl: './about-group.component.html',
  styleUrls: ['./about-group.component.css']
})
export class AboutGroupComponent implements OnInit {
   // for SEO
   title = 'Top MBA College in Kerala |JAI BHARATH SCHOOL OF MANAGEMENT STUDIES';
  constructor(
    // for SEO
    private titleService: Title,
    private metaTagService: Meta,
  ) { }

  ngOnInit(): void {
      // for SEO
      this.titleService.setTitle(this.title);
      this.metaTagService.addTags([
        { name: 'keywords', content: 'top mba college in kerala , top colleges in kerala , top 10 mba colleges in kerala , top mba colleges in kerala accepting kmat score , which mba college gives best placement' },
        { name: 'description', content: 'JAI BHARATH SCHOOL OF MANAGEMENT STUDIES | Become a Global Executive with Internationally Compatible Curriculum. Apply for Admission. Top MBA College in Kerala' },
        { name: 'robots', content: 'index, follow' },      
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },      
        { charset: 'UTF-8' }
      ]);
  }
   ngAfterViewInit() {
    this.activeSilder()
  }
  activeSilder(){
    var adventslide=(<any>$('#slider-instructors')).lightSlider({
      loop:true,
      item:3,
      speed:800,
      auto:true,
      keyPress:true,
      controls:false,
      slideMove:1,
      pager:false,
      
      responsive : [
        {
            breakpoint:800,
            settings: {
                item:2,
                slideMove:1,
                slideMargin:6,
              }
        },
        {
            breakpoint:480,
            settings: {
                item:1,
                slideMove:1,
              }
        }
    ]
    });
    $('#instructorsPrev').on('click', function () {
      adventslide.goToPrevSlide();
    });
    $('#instructorsNext').on('click', function () {
      adventslide.goToNextSlide();
    });
    var adventslide=(<any>$('#slider-team')).lightSlider({
      loop:true,
      item:3,
      speed:800,
      auto:true,
      keyPress:true,
      controls:false,
      slideMove:1,
      pager:false,
      
      responsive : [
        {
            breakpoint:800,
            settings: {
                item:2,
                slideMove:1,
                slideMargin:6,
              }
        },
        {
            breakpoint:480,
            settings: {
                item:1,
                slideMove:1,
              }
        }
    ]
    });
    $('#teamPrev').on('click', function () {
      adventslide.goToPrevSlide();
    });
    $('#teamNext').on('click', function () {
      adventslide.goToNextSlide();
    });
    var collageslide=(<any>$('#collage-slider')).lightSlider({
      loop:true,
      item:4,
      speed:600,
      auto:true,
      keyPress:true,
      controls:false,
      slideMove:1,
      pager:false,
      
      responsive : [
        {
            breakpoint:800,
            settings: {
                item:2,
                slideMove:1,
                slideMargin:6,
              }
        },
        {
            breakpoint:480,
            settings: {
                item:1,
                slideMove:1,
              }
        }
    ]
    });
    $('#collageslidePrev').on('click', function () {
      collageslide.goToPrevSlide();
    });
    $('#collageslideNext').on('click', function () {
      collageslide.goToNextSlide();
    });

}
}