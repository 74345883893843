<div class="col-md-12" mat-dialog-title>
    <div class="main-head">
        <h1>Career
            <span mat-button [mat-dialog-close]="false" class="pull-right close-popup" style="float: right;"><i
                    class="fa fa-times"></i></span>
        </h1>
    </div>
</div>
<mat-dialog-content>
    <div class="container-fluid" *ngIf="Career">
        <div class="startformlisting careerform">
            <h4>Personal Information</h4>
            <div class="row">
                <div class="col-md-6">
                    <label>Full Name *</label>

                    {{Career.Name}}
                </div>
                <div class="col-md-6">
                    <label>Date Of Birth *</label>

                    {{Career.DOB}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>Email Address *</label>

                    {{Career.Email}}
                </div>
                <div class="col-md-6">
                    <label>Mobile Number *</label>

                    {{Career.Phone}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>State of Residence *</label>

                    {{Career.City}} - {{Career.State}}
                </div>
            </div>
            <h4>Application Details</h4>
            <div class="row">
                <div class="col-md-6">
                    <label>Post Applying for * </label>
                    {{Career.PostFor}}
                </div>
                <div class="col-md-6">
                    <label>School Applying for * </label>
                    {{Career.SchoolFor}}
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>Specialization</label>
                    {{Career.Specialization}}
                </div>

            </div>
            <h4>Professional Details</h4>

            <div class="row">
                <div class="col-md-12">
                    <h5>Qualifications</h5>
                </div>
                <div class="col-lg-12">
                    <div class="over-f">
                        <table>
                            <tbody>
                                <tr>
                                    <td class="dwnlodhead">
                                        <div class="head-tit">PROGRAM</div>
                                    </td>
                                    <td class="dwnlodhead">
                                        <div class="head-tit">COLLEGE/UNIVERSITY/BOARD</div>
                                    </td>
                                    <td class="dwnlodhead">
                                        <div class="head-tit">COURSE OF STUDY/STREAM</div>
                                    </td>
                                    <td class="dwnlodhead">
                                        <div class="head-tit">YEAR OF PASSING</div>
                                    </td>
                                </tr>
                                <tr *ngFor="let ProfessionalDetaills of AllCareerProfessionalDetaills; let i = index">
                                    <td>
                                        <div class="text-align">
                                            {{ProfessionalDetaills.Program}}

                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {{ProfessionalDetaills.College}}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {{ProfessionalDetaills.Course}}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {{ProfessionalDetaills.Year}}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <h5>Experience</h5>
                </div>
                <div class="col-lg-12">
                    <div class="over-f">
                        <table>
                            <tbody>
                                <tr>
                                    <td class="dwnlodhead">
                                        <div class="head-tit">EXPERIENCE TYPE</div>
                                    </td>
                                    <td class="dwnlodhead">
                                        <div class="head-tit">NAME OF THE ORGANIZATION</div>
                                    </td>
                                    <td class="dwnlodhead">
                                        <div class="head-tit">DESIGNATION</div>
                                    </td>
                                    <td class="dwnlodhead">
                                        <div class="head-tit">SALARY DRAWN</div>
                                    </td>
                                    <td class="dwnlodhead">
                                        <div class="head-tit">START DATE</div>
                                    </td>
                                    <td class="dwnlodhead">
                                        <div class="head-tit">END DATE</div>
                                    </td>
                                    <td class="dwnlodhead">
                                        <div class="head-tit">IS THIS YOUR LATEST JOB?</div>
                                    </td>
                                </tr>
                                <tr *ngFor="let Experience of AllCareerExperience; let i = index">
                                    <td>
                                        <div>
                                            {{Experience.ExperienceType}}
                                        </div>
                                    </td>
                                    <td>
                                        {{Experience.OrganizationName}}
                                    </td>


                                    <td>
                                        {{Experience.Designation}}
                                    </td>

                                    <td>
                                        {{Experience.SalaryDrawn}}
                                    </td>
                                    <td>
                                        {{Experience.StartDate}}
                                    </td>
                                    <td>
                                        {{Experience.EndDate}}
                                    </td>
                                    <td>
                                        <div>
                                            {{Experience.LatestJob}}
                                        </div>
                                    </td>

                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <div class="pad-adjust">
                <div class="row">
                    <div class="col-md-12">
                        <h5>Research/Others</h5>
                    </div>
                    <div class="col-lg-10">Number of Research Papers published/presented in reputed
                            national/international journals 
                    </div>
                    <div class="col-md-2">
                        {{Career.NoOfResearch}}
                    </div>
                </div>
                <div class="row">

                    <div id="dynamic_fields2">
                        <div class="row" *ngFor="let careerOthersDetails of AllCareerOthersDetails; let i = index">
                            <div class="col-md-2">
                                <label>Others</label>
                            </div>
                            <div class="col-md-12">
                                {{careerOthersDetails.OthersDetails}}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-6">
                        <label>Salary Expected</label>
                        {{Career.SalaryExpected}}
                    </div>

                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="item-image-box">
                            <label>Cover Letter :</label>
                            <a href="http://jaibharathmba.com//assets/documents/carrer/{{Career.CoverLetter}}"
                                target="_blank">Click to view</a>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="col-lg-12">
                            <div class="item-image-box">
                                <label>Upload Your Resume :</label>
                                <a href="http://jaibharathmba.com//assets/documents/carrer/{{Career.YourResume}}"
                                    target="_blank">Click to view</a>

                            </div>

                        </div>
                    </div>


                </div>
            </div>


        </div>
    </div>
</mat-dialog-content>