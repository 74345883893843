<div class="facutly">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="fhead">
                    <h1>Our Facutly</h1>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="faclty-list">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/SaeedReswin.png" alt="">
                                <p>
                                    Saeed Reswin <br /> MBA UK
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/Pradheepkumar.jpg" alt="">
                                <p>
                                    Dr. Pradheepkumar C <br /> Director <br /> MBA Ph.D
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/KRVrindaMenonAssociateProfessor.jpg" alt="">
                                <p>
                                    Dr. K R Vrinda Menon <br /> Associate Professor
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/SreejithSukumaranAssistantProfessor.jpg" alt="">
                                <p>
                                    Sreejith Sukumaran <br /> Assistant Professor
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/SeenuBNairAssistantprofessor.jpg" alt="">
                                <p>
                                    Seenu B Nair <br /> Assistant Professor
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/SairaMatthewAssistantProfessor.jpg" alt="">
                                <p>
                                    Saira Matthew <br /> Assistant Professor
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/ArathySreekumarAssistantProfessor.jpg" alt="">
                                <p>
                                    Arathy Sreekumar <br /> Assistant Professor
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/BobyVergheseAssistantProfessor.jpg" alt="">
                                <p>
                                    Boby Verghese <br /> Assistant Professor
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/ThaslimaKS.png" alt="">
                                <p>
                                    Ms. Thaslima K S <br /> Assistant Professor
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="faclty-box">
                                <img src="assets/images/faculty/AnuKunjumonAssistantProfessor.jpg" alt="">
                                <p>
                                    Anu Kunjumon <br /> Assistant Professor
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>