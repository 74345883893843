<div id="main-banner">
    <div class="container-fluid">
        <div id="demo" class="carousel slide" data-ride="carousel">

            <div class="carousel-inner">
                <div class="carousel-item active">
                    <img src="assets/images/news-and-events.jpg">
                    <div class="carousel-caption">
                        <div class="ban-title">
                           
                                    <h1 class="ban-head"> News & Events</h1>
                                   
                                
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="news">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="head-title">
                        <h1 class="head-news">
                         News & Events
                        </h1>
                        <!-- <img class="head-img" src="./assets/images/Sliding Switch B.tech.png"> -->
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="datalistService.sessionData">
                <div class="col-md-12 test-right">
                    <br />
                    <button mat-raised-button class="nav-link active" [routerLink]="['/blog-events','0','new']"><i class="fa fa-plus" aria-hidden="true"></i> Add
                        new</button>
                    <br />
                </div>
            </div>
    
            <!-- Nav pills -->
            <ul class="nav nav-pills news-tabs">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="pill" href="#news1">All</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link " data-toggle="pill" href="#news2">Blogs</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link " data-toggle="pill" href="#news3">Events</a>
                </li>
            </ul>



            <div class="tab-content">
                <div class="tab-pane active" id="news1">
                    <div class="row">
                        <div class="col-md-4 " *ngFor="let allBlog of AllBlogEvent">
                            <div class="row" *ngIf="datalistService.sessionData">
                                <div class="col-md-12 test-right">
                                    <button mat-raised-button color="warn" class="nav-link active" (click)="deleteBlog(allBlog.BlogId,allBlog.BlogImage)"><i class="fa fa-trash" aria-hidden="true"></i></button>
                                </div>
                            </div>
                            <img [src]="datalistService.IMGAE_PATH+'/blog'+allBlog.BlogImage" >
                            <h4 class="news-head">{{allBlog.BlogTitle}}</h4>
                            <p [innerHTML]="functionService.transform(allBlog.BlogContent) | slice:0:220"></p>
                            <div class="read-more">
                                <button *ngIf="allBlog.Type==1" class="btn btn-more all" [routerLink]="['/blog-details',allBlog.SortId,allBlog.URLValue]" >Read more <i class="fa fa-chevron-right"></i></button>
                                <button *ngIf="allBlog.Type==2" class="btn btn-more all" [routerLink]="['/event-details',allBlog.SortId,allBlog.URLValue]" >Read more <i class="fa fa-chevron-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="news2">
                    <div class="row">
                        <div class="col-md-4" *ngFor="let allBlog of AllBlog">
                            <div class="row" *ngIf="datalistService.sessionData">
                                <div class="col-md-12 test-right">
                                    <button mat-raised-button color="warn" class="nav-link active" (click)="deleteBlog(allBlog.BlogId,allBlog.BlogImage)"><i class="fa fa-trash" aria-hidden="true"></i></button>
                                </div>
                            </div>
                            <img [src]="datalistService.IMGAE_PATH+'/blog'+allBlog.BlogImage" >
                            <h4 class="news-head">{{allBlog.BlogTitle}}</h4>
                            <p [innerHTML]="functionService.transform(allBlog.BlogContent) | slice:0:220"></p>
                            <div class="read-more">
                                <button *ngIf="allBlog.Type==1" class="btn btn-more all" [routerLink]="['/blog-details',allBlog.SortId,allBlog.URLValue]" >Read more <i class="fa fa-chevron-right"></i></button>
                                <button *ngIf="allBlog.Type==2" class="btn btn-more all" [routerLink]="['/event-details',allBlog.SortId,allBlog.URLValue]" >Read more <i class="fa fa-chevron-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="news3">
                    <div class="row">
                        <div class="col-md-4 " *ngFor="let allBlog of AllEvent">
                            <div class="row" *ngIf="datalistService.sessionData">
                                <div class="col-md-12 test-right">
                                    <button mat-raised-button color="warn" class="nav-link active" (click)="deleteBlog(allBlog.BlogId,allBlog.BlogImage)"><i class="fa fa-trash" aria-hidden="true"></i></button>
                                </div>
                            </div>
                            <img [src]="datalistService.IMGAE_PATH+'/blog'+allBlog.BlogImage" >
                            <h4 class="news-head">{{allBlog.BlogTitle}}</h4>
                            <p [innerHTML]="functionService.transform(allBlog.BlogContent) | slice:0:220"></p>
                            <div class="read-more">
                                <button *ngIf="allBlog.Type==1" class="btn btn-more all" [routerLink]="['/blog-details',allBlog.SortId,allBlog.URLValue]" >Read more <i class="fa fa-chevron-right"></i></button>
                                <button *ngIf="allBlog.Type==2" class="btn btn-more all" [routerLink]="['/event-details',allBlog.SortId,allBlog.URLValue]" >Read more <i class="fa fa-chevron-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                    
            </div>
        </div>
    </div>
    <div id="tbl">
        <h2>BLITZKRIEG 2K21</h2>
        <h4>Business Plan Contest</h4>
        <div class="tbl-head">
            <p class="left">Teams selected for final presentation </p>
            <p class="right">Final presentation will be held on July 01, </p>
        </div>
        <p></p>
<div class="table-wrapper">
    <table class="fl-table">
        <thead>
        <tr>
            <th>SI.NO</th>
            <th>NAME</th>
            <th>INSTITUTION  </th>
            <th>COURSE & YEAR   </th>
            <!-- <th>Header 5</th> -->
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>
                1
            </td>
            <td>
                Aruna Sasidharan , Jayesh Jayaram , Nafiya PS, Devasurya Muralidharan 
            </td>
            <td>
                Sahrdaya Institute of Managemement Studies
            </td>
            <td>
                MBA /M.COM 2ND YEAR
            </td>
            <!-- <td>Content 1</td> -->
            <!-- <td>Content 1</td> -->
        </tr>
        <tr>
            <td>2</td>
            <td>
                Jayalakshmi, Preeja Babu
            </td>
            <td>
                Bishop Jerome Institute 
            </td>
            <td>
                MBA/M.COM 1ST YEAR
            </td>
            <!-- <td>Content 2</td> -->
        </tr>
        <tr>
            <td>3</td>
            <td>
                Aditi Anoop, Lakshmy KS, Sajeesh Allesh, U.Vanisree
            </td>
            <td>
                Sahrdaya Institute of Management Studies
            </td>
            <td>
                MBA /M.COM 2ND YEAR
            </td>
            <!-- <td>Content 3</td> -->
        </tr>
        <tr>
            <td>4</td>
            <td>Aswin Sivadas, Anjana Prabhakaran, Aiswarya. P, Anaswara .V</td>
            <td>
                Dr.John Mathai Centre
            </td>
            <td>
                MBA /M.COM 2ND YEAR
            </td>
            <!-- <td>Content 4</td> -->
        </tr>
        <tr>
            <td>5</td>
            <td>Shajan Johnson, Harsha Elias
            </td>
            <td>
                TOCH Institute of Science and Technology
            </td>
            <td>
                MBA /M.COM 1st YEAR

            </td>
            <!-- <td>Content 5</td> -->
        </tr>
        <tr>
            <td>6</td>
            <td>Akash Mathew, Sabin Saji Mathew, Akash Prasannan, Amal Dev.M </td>
            <td>
                Mount Zion School of Business Management
            </td>
            <td>
                MBA /M.COM 1st YEAR
            </td>
            <!-- <td>Content 6</td> -->
        </tr>
        <tr>
            <td>7</td>
            <td>Sooraj Jadahav, Jobhitha M.J
            </td>
            <td>
                Kochi Business School
            </td>
            <td>
                MBA/M.COM 1ST YEAR
            </td>
            <!-- <td>Content 7</td> -->
        </tr>
        <!-- <tr>
            <td>Content 8</td>
            <td>Content 8</td>
            <td>Content 8</td>
            <td>Content 8</td>
            <td>Content 8</td>
        </tr>
        <tr>
            <td>Content 9</td>
            <td>Content 9</td>
            <td>Content 9</td>
            <td>Content 9</td>
            <td>Content 9</td>
        </tr>
        <tr>
            <td>Content 10</td>
            <td>Content 10</td>
            <td>Content 10</td>
            <td>Content 10</td>
            <td>Content 10</td>
        </tr> -->
        <tbody>
    </table>
</div>
    </div>
