import { Component } from '@angular/core';
import { DatabaseConnectionService } from './api/database/database-connection.service';
import { DatabaseOperationService } from './api/database/database-operation.service';
import { DatalistService } from './api/services/datalist.service';
import { FunctionService } from './api/services/function.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'college';
  AllNotification = [];
  viewNotiLength = 3;
  onActivate(event) {
      window.scroll(0,0);
      document.body.scrollTop = 0;
      //or document.querySelector('body').scrollTo(0,0)
  }
  constructor(
    public databaseConnectionService : DatabaseConnectionService,
    public databaseOperationService: DatabaseOperationService,
    public functionService: FunctionService,
    public datalistService:DatalistService
  ) {}
  ngOnInit(): void {
    
    this.databaseOperationService.getAllNotification().subscribe((data: any) => {
      this.AllNotification = data;
    });
  }
}
