<div class="course-banner">
    <div id="demo" class="carousel slide wide-banner" data-ride="carousel">        
        <div class="carousel-inner">
            <div class="carousel-item active">
            <img src="./assets/images/Courseslide1.png" >
            <div class="carousel-caption">
                <div class="course-caption">
                    <h1 class="part1"> Computer Science</h1>
                    <h1 class="part2">& Engineering </h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante tempus iaculis. In luctus vulputate suscipit viverra dignissim ligula.</p>
                    <button class="caption-btn">Enquire Now</button>
                </div>
            </div>
            </div>
        </div>        
    </div>
    <div id="demo" class="carousel slide mobile-banner" data-ride="carousel">        
        <div class="carousel-inner">
            <div class="carousel-item active">
            <img src="./assets/images/mobile-banner.jpg" >
            <div class="carousel-caption">
                <div class="course-caption">
                    <h1 class="part1"> Computer Science</h1>
                    <h1 class="part2">& Engineering </h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante tempus iaculis. In luctus vulputate suscipit viverra dignissim ligula.</p>
                    <button class="caption-btn">Enquire Now</button>
                </div>
            </div>
            </div>
        </div>        
    </div>
    <div class="course-info">
        <div class="container-fluid">
            <div class="info-details">
                <div class="row">
                    <div class="col-md-3 col-6">
                        <div class="info-box">
                            <img src="assets/images/info-i1.png" />
                            <div class="infobox-data">
                                <p>Course Duration</p>
                                <h2>4 Years</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-6">
                        <div class="info-box">
                            <img src="assets/images/info-i2.png" />
                            <div class="infobox-data">
                                <p>Available Seats</p>
                                <h2>60</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-6">
                        <div class="info-box">
                            <img src="assets/images/info-i3.png" />
                            <div class="infobox-data">
                                <p>College Code</p>
                                <h2>JBR</h2>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-6">
                        <div class="info-box">
                            <img src="assets/images/info-i4.png" />
                            <div class="infobox-data">
                                <p>Students Completed</p>
                                <h2>1500</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="ban-up"></div>
<div class="main-container">
    <div class="container">
        <div class="back-details">
            <div class="row">

                <div class="col-md-7">
                    <div class="program">
                        <h1 class="prgm-overview">
                            Program Overview
                        </h1>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                            euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
                            minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut
                            aliquip ex ea commodo consequat. Duis autem vel eum iriure dolor in hendrerit in
                            vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis
                            at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril
                            delenit augue duis dolore te feugait nulla facilisi.  Duis autem vel eum iriure dolor in hendrerit in
                            vulputate velit esse molestie consequat, vel illum dolore eu feugiat nulla facilisis
                            at vero eros et accumsan et iusto odio dignissim qui blandit praesent luptatum zzril
                            delenit augue duis dolore te feugait nulla facilisi.
                        </p>
                        <button class="btn-btn">
                            Read more <i class="fa fa-chevron-right"></i>
                        </button>
                    </div>

                </div>
                <div class="col-md-5">
                    <div class="key-high">
                    <div class="row">
                        <div class="col-md-12">
                            <h2>Key Highlights</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3 col-3">
                            <img class="key-pic" src="./assets/images/Asset 13.png">
                        </div>
                        <div class="col-md-9 col-9">
                            <p >
                                Get an B_Tech Degree <br>From Kerala University
                            </p>
                        </div>
                        <div class="col-md-3 col-3">
                            <img class="key-pic" src="./assets/images/Asset 14.png">
                        </div>
                        <div class="col-md-9 col-9">
                            <p >
                              Experience 4 Years industry<br>level immersion Program
                            </p>
                        </div>
                        <div class="col-md-3 col-3">
                            <img class="key-pic" src="./assets/images/Asset 15.png">
                        </div>
                        <div class="col-md-9 col-9">
                            <p >
                             Mentorship from<br>Industry leadres 
                            </p>
                        </div>
                        <div class="col-md-3 col-3">
                            <img class="key-pic" src="./assets/images/Asset 16.png">
                        </div>
                        <div class="col-md-9 col-9">
                            <p >
                               Advance Your carrier<br>with 360° carrier support
                            </p>
                        </div>
                        
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>





<div class="syllabus">
    <div class="container">
        <div class="syl-content">
            <div class="row">
                <div class="col-md-6">
                    <h1 class="syllabus-head">Syllabus</h1>
                    <p class="syl-para">
                        Best-in-Content Leading faculty and Leading industry leadres<br>
                        in the form of videos,case studies and projects
                    </p>
                </div>
                <div class="col-md-6">
                    <div class="syllabus-data">
                        <button class="sy-btn">Enquiry Now</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="top-subject">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="sub-head">
                    <h3 class="sub-h3">Top Subject You Will Learn</h3>
                    <p class="sub-para">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                        euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="sub-head">
                    <h3 class="sub-h3">Advance Your Carrier</h3>
                    <p class="sub-para">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                        euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="sub-head">
                    <h3 class="sub-h3">Who Is This Program For ?</h3>
                    <p class="sub-para">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                        euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                </div>
            </div>
            <div class="col-md-6">
                <div class="sub-head">
                    <h3 class="sub-h3">Minimum Eligilibity</h3>
                    <p class="sub-para">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh
                        euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</p>
                </div>

            </div>
        </div>
      
    </div>
</div>

<div class="ban-up"></div>

<div class="pic-details">
    <div class="container">
        <div class="pic-array">
            <div class="row">
                <div class="col-md-6">
                    <div class="keam-pic">
                        <div class="row">
                            <div class="col-6 ">
                                <div class="keam-img">
                                    <img class="img-keam" src="assets/images/Asset 22.png">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="keam-para">
                                    <h5 class="keam-h5">Prepare for <br>Keam Exam ?</h5>
                                </div>
                                <div class="apply-now">
                                    <button class="btn btn-book">Apply Now</button>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="keam-pic">
                        <div class="row">
                            <div class="col-6">
                                <div class="keam-img">
                                    <img class="img-keam" src="assets/images/Asset-23.png">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="keam-para">
                                    <h5 class="keam-h5">Are you fit  <br>for this carrier?</h5>
                                </div>
                                <div class="keam-para">
                                    <h5 class="keam-h5">Job fitment <br>Analysis</h5>
                                </div>
                                <div class="apply-now">
                                    <button class="btn btn-book">Apply Now</button>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <div class="call-new">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="call-content">
                        <div class="call-no">
                            <h1 class="ph-no">+91 987 654 321</h1>
                        </div>
                        <div class="call-data">
                            <p class="paragraph">still confused about joining ?</p>
                            <p class="pa-graph">talk to do your carrier counsellor</p>
                        </div>
                    </div>
                 
                </div>
            </div>
        </div>
    </div>
    
</div>

<div class="ban-down">
</div>

<div class="labs">
    <div class="container">
        <h3>Our Labs</h3>
        <div class="labs-items">
            <ul id="labs-slider" class="labs-slider">
                <li>
                    <div class="labs-box">
                        <img src="assets/images/Lab-icons1.png" />
                        <p>Networking <br />Lab</p>
                    </div>                    
                </li>
                <li>
                    <div class="labs-box">
                        <img src="assets/images/Lab-icons2.png" />
                        <p>Computer <br />Programing Lab</p>
                    </div>                    
                </li>
                <li>
                    <div class="labs-box">
                        <img src="assets/images/Lab-icons3.png" />
                        <p>Logic <br />Designing Lab</p>
                    </div>                    
                </li>
                <li>
                    <div class="labs-box">
                        <img src="assets/images/Lab-icons4.png" />
                        <p>Electronic Circuits & <br />Communication Lab</p>
                    </div>                    
                </li>
            </ul>
            <div class="lSAction labs-controls">
                <a class="lSPrev" id="labsPrev"><img src="./assets/images/arrow-left.png" /></a>
                <a class="lSNext" id="labsNext"><img src="./assets/images/arrow-right.png" /></a>
            </div>
        </div>
    </div>
</div>


<div class="clients">
    <div class="container">
        <h3>Associate internship companies</h3>
        <div class="client-items">
            <ul id="client-slider" class="client-slider">
                <li>
                    <img src="assets/images/cl1.png" />
                </li>
                <li>
                    <img src="assets/images/cl2.png" />
                </li>
                <li>
                    <img src="assets/images/cl3.png" />
                </li>
                <li>
                    <img src="assets/images/cl4.png" />
                </li>
                <li>
                    <img src="assets/images/cl5.png" />
                </li>
                <li>
                    <img src="assets/images/cl6.png" />
                </li>
            </ul>
            <div class="lSAction client-controls">
                <a class="lSPrev" id="clientPrev"><img src="./assets/images/arrow-left.png" /></a>
                <a class="lSNext" id="clientNext"><img src="./assets/images/arrow-right.png" /></a>
            </div>
        </div>
    </div>
</div>

<div class="ban-redup"></div>

<div class="certificate">
    <div class="container">
        <div class="certificate-cls">
            <div class="row">
                <div class="col-md-12">
                    <h1 class="certificate-head">Our Certifications</h1>
                </div>
            </div>
            <ul class="nav nav-pills certificate-tabs">
                <li class="nav-item">
                    <a class="nav-link active" data-toggle="pill" href="#place1">Course completion</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="pill" href="#place2">Job Fitment</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-toggle="pill" href="#place3">University Certification</a>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane active" id="place1">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="certificate-data">
                                <h2>Job fitment analysis certificate from ITPWCS</h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi vel, viverra dignissim ligula. Phasellus metus leo, consequat et imperdiet fermentum, varius in arcu. Morbi a consectetur quam, ac dictum turpis.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="certificate-img">
                                <img src="./assets/images/certificates1.png" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-pane fade" id="place2">
                </div>
            </div>
    
        </div>
    </div>
</div>

<div class="ban-reddown"></div>

<div class="advantage">
    <div class="container">
        <div class="advant-content">
            <div class="advant-head">
                <h1>Our Advantages</h1>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="advant-box">
    
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="advant-box">
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="advant-box">
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="advant-box">
                        
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="advant-box">
    
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="advant-box">
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="advant-box">
                        
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="advant-box">
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="ban-up"></div>

<div class="instructors">
    <div class="container-fluid">
        <div class="instructors-head">
            <h1>Our Instructors</h1>
        </div>
        <div class="instructors-images">
            <ul id="slider-instructors" class="content-instructors">
                <li>
                    <div class="instruct-box">
                        <div class="img-box">
                            <!-- <img src="./assets/images/why-1.png" /> -->
                        </div>
                        <div class="instruct-data">
                            <h3 class="head">Full Name</h3>
                            <p class="head">Designation</p><p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi vel, viverra dignissim ligula. Phasellus metus leo, consequat et imperdiet fermentum, varius in arcu. 
                            </p>
                            <img src="./assets/images/in.png" class="in-icon" />
                        </div>
                    </div>                    
                </li>
                <li>
                    <div class="instruct-box">
                        <div class="img-box">
                            <!-- <img src="./assets/images/why-1.png" /> -->
                        </div>
                        <div class="instruct-data">
                            <h3 class="head">Full Name</h3>
                            <p class="head">Designation</p><p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi vel, viverra dignissim ligula. Phasellus metus leo, consequat et imperdiet fermentum, varius in arcu. 
                            </p>
                            <img src="./assets/images/in.png" class="in-icon" />
                        </div>
                    </div>                    
                </li>
                <li>
                    <div class="instruct-box">
                        <div class="img-box">
                            <!-- <img src="./assets/images/why-1.png" /> -->
                        </div>
                        <div class="instruct-data">
                            <h3 class="head">Full Name</h3>
                            <p class="head">Designation</p><p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi vel, viverra dignissim ligula. Phasellus metus leo, consequat et imperdiet fermentum, varius in arcu. 
                            </p>
                            <img src="./assets/images/in.png" class="in-icon" />
                        </div>
                    </div>                    
                </li>
            </ul>
            <div class="lSAction instructors-controls">
                <a class="lSPrev" id="instructorsPrev"><img src="./assets/images/arrow-left.png" /></a>
                <a class="lSNext" id="instructorsNext"><img src="./assets/images/arrow-right.png" /></a>
            </div>
        </div>
    </div>
</div>

<div class="ban-down"></div>

<div class="admission">
    <div class="container">
        <div class="admission-head">
            <h1>Admission Process</h1>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="admins-box">
                    <p class="admin-head">Take the online course fitment test</p>
                   <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi vel, viverra dignissim ligula. Phasellus metus leo, consequat. 
                   </p>     
                </div>
                <p class="admis-step">Step 1</p>
            </div>
            <div class="col-md-4">
                <div class="admins-box">
                    <p class="admin-head">Take the online course fitment test</p>
                   <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi vel, viverra dignissim ligula. Phasellus metus leo, consequat. 
                   </p>     
                </div>
                <p class="admis-step">Step 2</p>
            </div>
            <div class="col-md-4">
                <div class="admins-box">
                    <p class="admin-head">Take the online course fitment test</p>
                   <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam auctor sapien ac ante tempus iaculis. In luctus vulputate suscipit. Nullam sem libero, molestie gravida nisi vel, viverra dignissim ligula. Phasellus metus leo, consequat. 
                   </p>     
                </div>
                <p class="admis-step">Step 3</p>
            </div>
        </div>
    </div>
</div>
<div class="ban-up"></div>
<div class="placestart-head">
    <div class="container">
        <div class="placement-main">
            <h1 class="prj-head">Our Placement Process</h1>
            <div class="placement-area">
                <div class="row">
                    <div class="col-md-2">
                        <div class="placement-box">
                            <div class="placein-box">
                                <img src="assets/images/place1.png" />
                                <p>
                                    Step1<br />
                                    Name
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="placement-box">
                            <div class="placein-box">
                                <img src="assets/images/place1.png" />
                                <p>
                                    Step2<br />
                                    Name
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="placement-box">
                            <div class="placein-box">
                                <img src="assets/images/place1.png" />
                                <p>
                                    Step3<br />
                                    Name
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="placement-box">
                            <div class="placein-box">
                                <img src="assets/images/place1.png" />
                                <p>
                                    Step4<br />
                                    Name
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="placement-box">
                            <div class="placein-box">
                                <img src="assets/images/place1.png" />
                                <p>
                                    Step5<br />
                                    Name
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="placement-box">
                            <div class="placein-box">
                                <img src="assets/images/place1.png" />
                                <p>
                                    Step6<br />
                                    Name
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="ban-down"></div>

<div class="place-head">
    <div class="container">
        <div class="placement-main">
            <h1 class="prj-head">Our Graduates Work At</h1>
            <div class="grad-sec">
            <div class="row">
                <div class=" col-md-2 col-sm-4 " >
                    <div class="grad-body">
                          <img src="assets/images/cl1.png" />
                    </div>
                </div>
                <div class=" col-md-2 col-sm-4" >
                   <div class="grad-body">
                          <img src="assets/images/cl2.png" />
                    </div>
                </div>
                <div class=" col-md-2 col-sm-4" >
                   <div class="grad-body">
                          <img src="assets/images/cl3.png" />
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-4" >
                    <div class="grad-body">
                          <img src="assets/images/cl4.png" />
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-4" >
                    <div class="grad-body">
                          <img src="assets/images/cl5.png" />
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-4" >
                    <div class="grad-body">
                          <img src="assets/images/cl6.png" />
                    </div>
                </div>
                </div>
            </div>
                        <div class="grad-sec">
            <div class="row">
                <div class=" col-md-2 col-sm-4 " >
                    <div class="grad-body">
                          <img src="assets/images/cl1.png" />
                    </div>
                </div>
                <div class=" col-md-2 col-sm-4" >
                   <div class="grad-body">
                          <img src="assets/images/cl2.png" />
                    </div>
                </div>
                <div class=" col-md-2 col-sm-4" >
                   <div class="grad-body">
                          <img src="assets/images/cl3.png" />
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-4" >
                    <div class="grad-body">
                          <img src="assets/images/cl4.png" />
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-4" >
                    <div class="grad-body">
                          <img src="assets/images/cl5.png" />
                    </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-4" >
                    <div class="grad-body">
                          <img src="assets/images/cl6.png" />
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>


<div class="testi">
    <div class="container">
        <p class="prj-title">Reviews & Ratings</p>
        <h1 class="prj-head">What Our Learners Have To Say<br>About Us!</h1>
        <div class="testi-items">
            <ul id="testi-slider" class="testi-slider">
                <li>
                    <div class="row">
                        <div class="col-md-9">
                            <div class="testimonial-content">
                                <div class="testi-box">
                                    <p>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc volutpat posuere
                                        suscipit. Donec et ante quis massa laoreet cursus blandit vitae neque. Donec ut
                                        nisi convallis, tempus enim quis, mattis enim. Donec porta quis lorem quis
                                        consectetur. Mauris vulputate auctor leo non varius. Aenean vel orci sed risus
                                        laoreet gravida vel sit amet nulla. Cras ac ipsum vitae magna tincidunt eleifend
                                        eu ac lacus. Phasellus quis justo a diam consectetur convallis ut sed massa.
                                    </p>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="testi-social">
                                                <a href="">
                                                    <img src="assets/images/linkedin.png">
                                                    <p>Linkedin Profile</p>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="testi-name">
                                                <p>-Full name, Designation </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="testimponial-img">
                                <img src="assets/images/testimo-user1.png" />
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="lSAction testi-controls">
                <a class="lSPrev" id="testiPrev"><img src="./assets/images/arrow-left.png" /></a>
                <a class="lSNext" id="testiNext"><img src="./assets/images/arrow-right.png" /></a>
            </div>
        </div>
    </div>
</div>