import { Injectable } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EnquryFormPopupComponent } from '../../popup/course/enqury-form-popup/enqury-form-popup.component';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  flink: number = 1;
  actlink: number = 1;
  constructor(
    private vps: ViewportScroller,
    private matDialog: MatDialog,
  ) { }
  scrollPosition(id){
    this.vps.scrollToAnchor(id);
  }
  facility(link) {
    this.flink = link;
  }
  activity(link) {
    this.actlink = link;
  }
  openEnquiryForm(Course) {
    let dialogRef: MatDialogRef<EnquryFormPopupComponent> = this.matDialog.open(EnquryFormPopupComponent, {
      width: '400px',
      disableClose: true,
      panelClass: 'custom-dialog-container',
      hasBackdrop: true,
    });
    // computer electrical electronic mechanical civil polyArchitecture polyAutomobile polyCivil polyElectrical polyMechanical
    dialogRef.componentInstance.Course = Course;
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {



      }
    });
  }
}
