<section id="contact" class="contact">
  <div _ngcontent-gmi-c28="" class="banner"><img _ngcontent-gmi-c28="" src="assets/images/contact-bnr.jpg"></div>

  <div class="container" data-aos="fade-up">

    <div class="row mt-5" data-aos="fade-up" data-aos-delay="100">
      <div class="col-lg-12">

        <div class="row ">
          <div class="col-md-12">
            <div class="info-box">

              <h3>Admission Form MBA</h3>
              <div class="row ">
                <div class="col-md-12">
                  <h5>Eligibility</h5>
                  <p> A pass in degree examination with 50% marks (60% marks desirable) in aggregate
                    for Arts/Commerce /Science/ Engineering and for post graduates. Final year students
                    are also eligible to apply</p><br>
                  <p> Good score in any Management Aptitude Test- C-MAT / CAT/KMAT (The minimum
                    qualifying cut off marks of KMAT Kerala Entrance Examination shall be 10% (72/720)
                    for General Category and SEBC .The minimum cut off for ) for SC/ST 7.5% (54/720</p><br>
                </div>
                <div class="col-md-6">
                  <h5>Selection </h5>
                  <p>The selection of candidates is purely based on merit. Admission to the MBA
                    programme is determined by the scores obtained by the candidate in the admission
                    test, group discussion, personal interview and a consistent academic record.</p><br>
                </div>
                <div class="col-md-6">
                  <h5>How to Apply</h5>
                  <p>You can apply online from our website, www.jaibharathmba.com
                    Application Fee : Rs. 500/-</p>
                </div>
              </div>
            </div>
          </div>


        </div>

      </div>
    </div>
    <br /> <br />
    <div class="row">
      <div class="col-lg-12">
        <form [formGroup]="onlineAdmisionForm" (ngSubmit)="onSubmit()" autocomplete="off" class="php-email-form">
          <h5>APPLY ONLINE</h5>
          <hr />
          <div class="form-row">
            <div class="col form-group">
              <label>Name of the course applied for</label><br />

              <label><input type="radio" value="MBA"
                  [ngClass]="{ 'is-invalid': submitted && formControl.CourseApplied.errors }"
                  formControlName="CourseApplied">
                <span class="list-item-label">MBA</span></label>
              <label><input type="radio" value="BBA"
                  [ngClass]="{ 'is-invalid': submitted && formControl.CourseApplied.errors }"
                  formControlName="CourseApplied">
                <span class="list-item-label">BBA</span></label>
            </div>

          </div>
          <div class="form-group">
            <label>Admission Date</label>
            <input type="date" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && formControl.AdmissionDate.errors }"
              formControlName="AdmissionDate" />

          </div>
          <div class="form-group">
            <label>Name of the candidate</label>
            <input type="text" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && formControl.CandidateName.errors }"
              formControlName="CandidateName" placeholder="Enter Your Name " />

          </div>
          <div class="form-group">
            <label>Name of the qualifying exam passed</label>
            <select class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.ExamQualification.errors }"
              formControlName="ExamQualification">
              <option value="KMAT">KMAT</option>
              <option value="MAT">MAT</option>
              <option value="CMAT">CMAT</option>
            </select>
            <!-- <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.ExamQualification.errors }" formControlName="ExamQualification"
              placeholder="Enter the qualification exam passed " /> -->
          </div>
          <p>A - Details of admission Test (KMAT/MAT/CMAT)</p>
          <div class="form-row">
            <div class="col-md-3 form-group">
              <label>Name of the test</label>
              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.TestName.errors }" formControlName="TestName"
                placeholder="Enter the test name " />
              <!-- <select class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.TestName.errors }" formControlName="TestName">
                <option value="KMAT">KMAT</option>
                <option value="MAT">MAT</option>
                <option value="CMAT">CMAT</option>
              </select> -->
            </div>
            <div class="col-md-2 form-group">
              <label>Date of the test</label>
              <input type="date" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.TestDate.errors }" formControlName="TestDate"
                placeholder="dd-mm-yy " />

            </div>
            <div class="col-md-3 form-group">
              <label>Test Register Number</label>
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.RegisterNumber.errors }"
                formControlName="RegisterNumber" placeholder="Enter your register no:" />

            </div>
            <div class="col-md-2 form-group">
              <label>Marks Obtained</label>
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.TestMarks.errors }" formControlName="TestMarks"
                placeholder="Enter your marks " />

            </div>
            <div class="col-md-2 form-group">
              <label>Rank If Any</label>
              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.TestRank.errors }" formControlName="TestRank"
                placeholder="Rank achieved " />

            </div>
          </div>

          <P> a) Name of qualifying examination [BA/B.Sc/B.Com/B.Tech etc] Appeared/Passed</P>
          <div class="form-group">
            <input type="text" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && formControl.QualificationExam.errors }"
              formControlName="QualificationExam" type="text" placeholder="Enter the qualifying exam details">

          </div>
          <div class="form-group">
            <label>b) Name of University or Board:</label><br>
            <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.Board.errors }"
              formControlName="Board" placeholder="Enter university / board name " />


          </div>
          <div class="form-group">
            <label>c) Register Bo./Month/Year:</label><br>
            <input type="text" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && formControl.RegisterTime.errors }" formControlName="RegisterTime"
              placeholder=" Register Bo./Month/Year " />


          </div>
          <div class="form-group">
            <label>d) Name of Institution in which studied:</label><br>
            <input type="text" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && formControl.InstitutionName.errors }"
              formControlName="InstitutionName" placeholder="Name of the Institution you studied: " />


          </div>
          <div class="form-group">
            <label>e) Number of chances availed for the qualifying examinations :</label><br>
            <input type="number" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && formControl.NoChances.errors }" formControlName="NoChances"
              placeholder="Enter the no: of chances availed for qualifying exams " />


          </div>
          <p>B - Details of Graduation</p>
          <div class="form-row">
            <div class="col-md-2 form-group">
              <label>Name of Exams</label><br />
              <label>Sem I</label>

            </div>
            <div class="col-md-3 form-group">
              <label>Register Number</label>
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part1RegisterNumber.errors }"
                formControlName="Part1RegisterNumber" placeholder="Enter your register no: " />

            </div>
            <div class="col-md-3 form-group">
              <label>Marks Obtained</label>
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part1Marks.errors }" formControlName="Part1Marks"
                placeholder="Enter your marks:" />

            </div>
            <div class="col-md-2 form-group">
              <label>Maximum Marks</label>
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part1maxMarks.errors }"
                formControlName="Part1maxMarks" placeholder="Enter your Maximum marks " />

            </div>
            <div class="col-md-2 form-group">
              <label>% of Marks</label>
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part1Persentage.errors }"
                formControlName="Part1Persentage" placeholder="Marks Persentage" />

            </div>
          </div>

          <div class="form-row">
            <div class="col-md-2 form-group">
              <label>Sem II</label>
            </div>
            <div class="col-md-3 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part2RegisterNumber.errors }"
                formControlName="Part2RegisterNumber" placeholder="Enter your register no: " />

            </div>
            <div class="col-md-3 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part2Marks.errors }" formControlName="Part2Marks"
                placeholder="Enter your marks:" />

            </div>
            <div class="col-md-2 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part2maxMarks.errors }"
                formControlName="Part2maxMarks" placeholder="Enter your Maximum marks " />

            </div>
            <div class="col-md-2 form-group">

              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part2Persentage.errors }"
                formControlName="Part2Persentage" placeholder="Marks Persentage" />

            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2 form-group">

              <label>Sem III</label>

            </div>
            <div class="col-md-3 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part3RegisterNumber.errors }"
                formControlName="Part3RegisterNumber" placeholder="Enter your register no: " />

            </div>
            <div class="col-md-3 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part3Marks.errors }" formControlName="Part3Marks"
                placeholder="Enter your marks:" />

            </div>
            <div class="col-md-2 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part3maxMarks.errors }"
                formControlName="Part3maxMarks" placeholder="Enter your Maximum marks " />

            </div>
            <div class="col-md-2 form-group">

              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part3Persentage.errors }"
                formControlName="Part3Persentage" placeholder="Marks Persentage" />

            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2 form-group">

              <label>Sem IV</label>


            </div>
            <div class="col-md-3 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part4RegisterNumber.errors }"
                formControlName="Part4RegisterNumber" placeholder="Enter your register no: " />

            </div>
            <div class="col-md-3 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part4Marks.errors }" formControlName="Part4Marks"
                placeholder="Enter your marks:" />

            </div>
            <div class="col-md-2 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part4maxMarks.errors }"
                formControlName="Part4maxMarks" placeholder="Enter your Maximum marks " />

            </div>
            <div class="col-md-2 form-group">

              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part4Persentage.errors }"
                formControlName="Part4Persentage" placeholder="Marks Persentage" />

            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2 form-group">

              <label>Sem V</label>
            </div>
            <div class="col-md-3 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part5RegisterNumber.errors }"
                formControlName="Part5RegisterNumber" placeholder="Enter your register no: " />

            </div>
            <div class="col-md-3 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part5Marks.errors }" formControlName="Part5Marks"
                placeholder="Enter your marks:" />

            </div>
            <div class="col-md-2 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part5maxMarks.errors }"
                formControlName="Part5maxMarks" placeholder="Enter your Maximum marks " />

            </div>
            <div class="col-md-2 form-group">

              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part5Persentage.errors }"
                formControlName="Part5Persentage" placeholder="Marks Persentage" />

            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2 form-group">

              <label>Sem VI</label>
            </div>
            <div class="col-md-3 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part6RegisterNumber.errors }"
                formControlName="Part6RegisterNumber" placeholder="Enter your register no: " />

            </div>
            <div class="col-md-3 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part6Marks.errors }" formControlName="Part6Marks"
                placeholder="Enter your marks:" />

            </div>
            <div class="col-md-2 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part6maxMarks.errors }"
                formControlName="Part6maxMarks" placeholder="Enter your Maximum marks " />

            </div>
            <div class="col-md-2 form-group">

              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part6Persentage.errors }"
                formControlName="Part6Persentage" placeholder="Marks Persentage" />

            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2 form-group">

              <label>Sem VII</label>
            </div>
            <div class="col-md-3 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part7RegisterNumber.errors }"
                formControlName="Part7RegisterNumber" placeholder="Enter your register no: " />

            </div>
            <div class="col-md-3 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part7Marks.errors }" formControlName="Part7Marks"
                placeholder="Enter your marks:" />

            </div>
            <div class="col-md-2 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part7maxMarks.errors }"
                formControlName="Part7maxMarks" placeholder="Enter your Maximum marks " />

            </div>
            <div class="col-md-2 form-group">

              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part7Persentage.errors }"
                formControlName="Part7Persentage" placeholder="Marks Persentage" />

            </div>
          </div>
          <div class="form-row">
            <div class="col-md-2 form-group">

              <label>Sem VIII</label>
            </div>
            <div class="col-md-3 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part8RegisterNumber.errors }"
                formControlName="Part8RegisterNumber" placeholder="Enter your register no: " />

            </div>
            <div class="col-md-3 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part8Marks.errors }" formControlName="Part8Marks"
                placeholder="Enter your marks:" />

            </div>
            <div class="col-md-2 form-group">
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part8maxMarks.errors }"
                formControlName="Part8maxMarks" placeholder="Enter your Maximum marks " />

            </div>
            <div class="col-md-2 form-group">

              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Part8Persentage.errors }"
                formControlName="Part8Persentage" placeholder="Marks Persentage" />

            </div>
          </div>
          <!-- <p>B - Semester System</p>
          <div class="form-row">
            <div class="col-md-3 form-group">
              <label>Name of Exams</label>
              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.ExamName.errors }" formControlName="ExamName" placeholder="Exam Name " />

            </div>
            <div class="col-md-3 form-group">
              <label>University Board</label>
              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.UniversityName.errors }" formControlName="UniversityName" placeholder="Enter Board name" />

            </div>
            <div class="col-md-2 form-group">
              <label>Marks Obtained</label>
              <input type="number" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.SemesterMarks.errors }" formControlName="SemesterMarks" placeholder="your marks " />

            </div>
            <div class="col-md-2 form-group">
              <label>Max Marks</label>
              <input type="number" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.SemestermaxMarks.errors }" formControlName="SemestermaxMarks" placeholder="Max marks " />

            </div>
            <div class="col-md-2 form-group">
              <label>% of Marks</label>
              <input type="number" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.SemesterPersentage.errors }" formControlName="SemesterPersentage" placeholder="% marks " />
            </div>
          </div> -->
          <p>a) Address to which communication are be sent:</p>
          <div class="form-group">
            <label>Door No / House Name</label>
            <input type="text" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && formControl.Address.errors }" formControlName="Address"
              placeholder="Enter your house name " />

          </div>
          <div class="form-group">
            <label> Area/Street/Road</label>
            <input type="text" class="form-control"
              [ngClass]="{ 'is-invalid': submitted && formControl.StreetName.errors }" formControlName="StreetName"
              placeholder="Enter your area/street name" />
          </div>

          <div class="form-row">
            <div class="col-md-6 form-group">
              <label>Post Office</label>
              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.PostOffice.errors }" formControlName="PostOffice"
                placeholder="Post Office Name" />

            </div>
            <div class="col-md-6 form-group">
              <label>District & State</label>
              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.DistrictName.errors }"
                formControlName="DistrictName" placeholder="Enter your District & State " />

            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 form-group">
              <label>Phone No. with area code</label>
              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.PhoneNo.errors }" formControlName="PhoneNo"
                placeholder="Full Phone No " />
            </div>
            <div class="col-md-6 form-group">
              <label>Mobile number</label>
              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.MobileNo.errors }" formControlName="MobileNo"
                placeholder="Enter Mobile No" />

            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 form-group">
              <label>Pin</label>
              <input type="number" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.PinNo.errors }" formControlName="PinNo"
                placeholder="Enter Your Pincode " />
            </div>
            <div class="col-md-6 form-group">
              <label>Email
              </label>
              <input type="email" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Email.errors }" formControlName="Email"
                placeholder="Enter Your Email" />

            </div>
          </div>
          <!-- <p>
            b) Permenent address of the candidate if different from above</p>

          <div class="form-group">
            <label>Door No / House Name</label>
            <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.PermenentAddress.errors }" formControlName="PermenentAddress" placeholder="Enter your house name " />

          </div>
          <div class="form-group">
            <label> Area/Street/Road</label>
            <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.PermenentStreetName.errors }" formControlName="PermenentStreetName"
              placeholder="Enter your area/street name" />
          </div>

          <div class="form-row">
            <div class="col-md-6 form-group">
              <label>Post Office</label>
              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.PermenentPostOffice.errors }" formControlName="PermenentPostOffice" placeholder="Post Office Name" />

            </div>
            <div class="col-md-6 form-group">
              <label>District & State</label>
              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.PermenentDistrictName.errors }" formControlName="PermenentDistrictName"
                placeholder="Enter your District & State " />

            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 form-group">
              <label>Phone No. with area code</label>
              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.PermenentPhoneNo.errors }" formControlName="PermenentPhoneNo" placeholder="Full Phone No " />
            </div>
            <div class="col-md-6 form-group">
              <label>Mobile number</label>
              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.PermenentMobileNo.errors }" formControlName="PermenentMobileNo" placeholder="Enter Mobile No" />

            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 form-group">
              <label>Pin</label>
              <input type="number" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.PermenentPinNo.errors }" formControlName="PermenentPinNo" placeholder="Enter Your Pincode " />
            </div>
            <div class="col-md-6 form-group">
              <label>Email
              </label>
              <input type="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.PermenentEmail.errors }" formControlName="PermenentEmail" placeholder="Enter Your Email" />

            </div>
          </div>
        -->
          <div class="form-row">
            <div class="col-md-6 form-group">
              <label>b) Nationality:</label><br>
              <label><input type="radio" value="indian"
                  [ngClass]="{ 'is-invalid': submitted && formControl.Nationality.errors }"
                  formControlName="Nationality" checked="checked"><span class="list-item-label">Indian</span></label>
              <label><input type="radio" value="others"
                  [ngClass]="{ 'is-invalid': submitted && formControl.Nationality.errors }"
                  formControlName="Nationality" checked="checked"><span class="list-item-label">Others</span></label>

            </div>
            <div class="col-md-6 form-group">
              <label>c) Gender</label><br>
              <label><input type="radio" value="male"
                  [ngClass]="{ 'is-invalid': submitted && formControl.Gender.errors }" formControlName="Gender"><span
                  class="list-item-label">Male</span></label>
              <label><input type="radio" value="female"
                  [ngClass]="{ 'is-invalid': submitted && formControl.Gender.errors }" formControlName="Gender"><span
                  class="list-item-label">Female</span></label>


            </div>
          </div>
          <div class="form-row">
            <div class="col-md-3 form-group">
              <label>d) Religion:</label>
              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Religion.errors }" formControlName="Religion"
                placeholder="Enter your Religion " />

            </div>
            <div class="col-md-3 form-group">
              <label>e) Caste:</label>
              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Caste.errors }" formControlName="Caste"
                placeholder="Enter your caste " />

            </div>
            <div class="col-md-3 form-group">
              <label>f) Blood Group:</label>
              <select class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.BloodGroup.errors }"
                formControlName="BloodGroup">
                <option>O+</option>
                <option>O-</option>
                <option>A+</option>
                <option>A-</option>
                <option>B+</option>
                <option>B-</option>
                <option>AB+</option>
                <option>AB-</option>
              </select>

            </div>
            <div class="col-md-3 form-group">
              <label>h) Date of Birth:</label>
              <input type="date" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.DOB.errors }"
                formControlName="DOB" placeholder="dd-mm-yy " />
            </div>
          </div>

          <div class="form-group">
            <label>Are you native of Kerala?</label><br>
            <label><input type="radio" value="Yes" [ngClass]="{ 'is-invalid': submitted && formControl.Native.errors }"
                formControlName="Native"><span class="list-item-label">Yes</span></label>
            <label><input type="radio" value="No" [ngClass]="{ 'is-invalid': submitted && formControl.Native.errors }"
                formControlName="Native"><span class="list-item-label">No</span></label>


          </div>
          <p>Details of application fee remitted:</p><br>

          <div class="form-row">
            <div class="col-md-6 form-group">
              <label>a) Receipt No (if paid cash)</label>

              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.ReceiptNo.errors }" formControlName="ReceiptNo"
                placeholder="Enter your receipt number" />

            </div>
            <div class="col-md-6 form-group">
              <label>a)Receipt No: & Date(if paid cash) </label>
              <input type="date" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.ReceiptDate.errors }" formControlName="ReceiptDate"
                placeholder="dd-mm-yyyy" />

            </div>
          </div>
          <p>b) DD Particulars(If the payment mode is DD)</p>

          <div class="form-row">
            <div class="col-md-6 form-group">
              <label>DD Number:</label>

              <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.DDNo.errors }"
                formControlName="DDNo" placeholder="Enter your DD number" />

            </div>
            <div class="col-md-6 form-group">
              <label>Date: </label>
              <input type="date" class="form-control" [ngClass]="{ 'is-invalid': submitted && formControl.Date.errors }"
                formControlName="Date" placeholder="dd-mm-yyyy" />

            </div>
            <div class="col-md-6 form-group">
              <label>Amount </label>
              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.Amount.errors }" formControlName="Amount"
                placeholder="Enter the amount paid" />

            </div>
          </div>
          <div class="form-row image-row">
              
						<div class="col-md-6">
							<input type="file" [id]="'signatireupload'" accept=".jpg,.png,.jpeg" formControlName="Signature"
								(change)="onSignatureSelected($event)" class="upload-file item-image-click">
							<div class="item-image-box">
                <label>Signature (Max Size: 30KB)</label>
								<label [for]="'signatireupload'" class="holder"
									[ngClass]="{ 'is-invalid': submitted && formControl.Signature.errors }">
                  <div class="image-palceholder">
                    <img [src]="SignatureImg" alt="" width="100%"
                      height="auto" />
                  </div>
									<span *ngIf="SignatureLoaded">{{SignatureLoaded.name}}</span>
								</label>
                </div>
						</div>  

            <div class="col-md-6">
							<input type="file" [id]="'photoupload'" accept=".jpg,.png,.jpeg" formControlName="Photo"
								(change)="onPhotoSelected($event)" class="upload-file item-image-click">
							<div class="item-image-box">
                <label>Photo (Max Size: 100KB)</label>
								<label [for]="'photoupload'" class="holder"
									[ngClass]="{ 'is-invalid': submitted && formControl.Photo.errors }">
                  <div class="image-palceholder">
                    <img [src]="PhotoImg" alt="" width="100%"
                      height="auto" />
                  </div>
									<span *ngIf="PothoLoaded">{{PothoLoaded.name}}</span>
								</label>
                </div>
						</div>  

          </div>
          <div class="form-row">
            <div class="col-md-6 form-group">
              <label>Name of the bank</label>

              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.BankName.errors }" formControlName="BankName"
                placeholder="Enter the bank name" />

            </div>
            <div class="col-md-6 form-group">
              <label>Name of the branch
              </label>
              <input type="text" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && formControl.BranchName.errors }" formControlName="BranchName"
                placeholder="Enter the branch" />

            </div>

          </div>

          <div class="text-center"><button type="submit">Apply Now</button></div>
        </form>
        <br />
      </div>




    </div>

  </div>
</section><!-- End Contact Section -->