import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { CommonService } from '../../../api/services/common.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';
declare var $: any;
  // for SEO
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-operation-management',
  templateUrl: './operation-management.component.html',
  styleUrls: ['./operation-management.component.css']
})
export class OperationManagementComponent implements OnInit {
    // for SEO
    title = 'Top MBA College in Kerala |JAI BHARATH SCHOOL OF MANAGEMENT STUDIES';

    more: boolean = false;

  constructor(
     // for SEO
     private titleService: Title,
     private metaTagService: Meta,

   public datalistService: DatalistService,
   private databaseOperationService: DatabaseOperationService,
   private matDialog: MatDialog,
   public functionService: FunctionService,
   public commonService: CommonService,
  ) { }

  ngOnInit(): void {
    // for SEO
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'keywords', content: 'top mba college in kerala , top colleges in kerala , top 10 mba colleges in kerala , top mba colleges in kerala accepting kmat score , which mba college gives best placement' },
      { name: 'description', content: 'JAI BHARATH SCHOOL OF MANAGEMENT STUDIES | Become a Global Executive with Internationally Compatible Curriculum. Apply for Admission. Top MBA College in Kerala' },
      { name: 'robots', content: 'index, follow' },      
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },      
      { charset: 'UTF-8' }
    ]);
  }
   readMore() {
    this.more = true;
  }
  ngAfterViewInit() {
    this.activeSilder()
  }

  activeSilder() {

    $(document).ready(function () {
      var labsslide = (<any>$('#labs-slider')).lightSlider({
        loop: true,
        item: 4,
        speed: 600,
        auto: true,
        keyPress: true,
        controls: false,
        slideMove: 1,
        pager: false,

        responsive: [
          {
            breakpoint: 800,
            settings: {
              item: 2,
              slideMove: 1,
              slideMargin: 6,
            }
          },
          {
            breakpoint: 480,
            settings: {
              item: 1,
              slideMove: 1,
            }
          }
        ]
      });
      $('#labsPrev').on('click', function () {
        labsslide.goToPrevSlide();
      });
      $('#labsNext').on('click', function () {
        labsslide.goToNextSlide();
      });

      var clientslide = (<any>$('#client-slider')).lightSlider({
        loop: true,
        item: 6,
        speed: 600,
        auto: true,
        keyPress: true,
        controls: false,
        slideMove: 1,
        pager: false,

        responsive: [
          {
            breakpoint: 800,
            settings: {
              item: 2,
              slideMove: 1,
              slideMargin: 6,
            }
          },
          {
            breakpoint: 480,
            settings: {
              item: 1,
              slideMove: 1,
            }
          }
        ]
      });
      $('#clientPrev').on('click', function () {
        clientslide.goToPrevSlide();
      });
      $('#clientNext').on('click', function () {
        clientslide.goToNextSlide();
      });

      var adventslide = (<any>$('#slider-instructors')).lightSlider({
        loop: true,
        item: 3,
        speed: 600,
        auto: true,
        keyPress: true,
        controls: false,
        slideMove: 1,
        pager: false,

        responsive: [
          {
            breakpoint: 800,
            settings: {
              item: 2,
              slideMove: 1,
              slideMargin: 6,
            }
          },
          {
            breakpoint: 480,
            settings: {
              item: 1,
              slideMove: 1,
            }
          }
        ]
      });
      $('#instructorsPrev').on('click', function () {
        adventslide.goToPrevSlide();
      });
      $('#instructorsNext').on('click', function () {
        adventslide.goToNextSlide();
      });


      var testislide = (<any>$('#testi-slider')).lightSlider({
        loop: true,
        item: 1,
        speed: 600,
        pause: 5000,
        auto: true,
        keyPress: true,
        controls: false,
        slideMove: 1,
        pager: false,

        responsive: [
          {
            breakpoint: 800,
            settings: {
              item: 1,
              slideMove: 1,
              slideMargin: 6,
            }
          },
          {
            breakpoint: 480,
            settings: {
              item: 1,
              slideMove: 1,
            }
          }
        ]
      });
      $('#testiPrev').on('click', function () {
        testislide.goToPrevSlide();
      });
      $('#testiNext').on('click', function () {
        testislide.goToNextSlide();
      });
    });
  }

}
