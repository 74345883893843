import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';
import { CollegeFormPopupComponent } from '../../../popup/college/college-form-popup/college-form-popup.component';
import { CourseFormPopupComponent } from '../../../popup/college/course-form-popup/course-form-popup.component';
import { ProceedWarningComponent } from '../../../popup/proceed-warning/proceed-warning.component';

@Component({
  selector: 'app-manage-college',
  templateUrl: './manage-college.component.html',
  styleUrls: ['./manage-college.component.css']
})
export class ManageCollegeComponent implements OnInit {
  College = true;
  data: any;
  CollegeId: any;
  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,
    private titleService: Title) {
    this.datalistService.loaderActive = true;
  }

  Columns: string[] = ['SortId', 'CollegeName', 'Operation'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ngOnInit(): void {
    this.getAllCollege()
  }

  getAllCollege() {
    this.Columns = ['SortId', 'CollegeName', 'Operation'];
    this.databaseOperationService.getAllCollege().subscribe((data: any) => {
      
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.datalistService.loaderActive = false;
    })
  }
  addCollege() {

    let dialogRef: MatDialogRef<CollegeFormPopupComponent> = this.matDialog.open(CollegeFormPopupComponent, {
      width: '300px',
      height: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.CollegeId = '';
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllCollege();
      }
    });
  }
  editCollege(CollegeId) {
    let dialogRef: MatDialogRef<CollegeFormPopupComponent> = this.matDialog.open(CollegeFormPopupComponent, {
      width: '300px',
      height: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.CollegeId = CollegeId;
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllCollege();
      }
    });
  }
  delete_College(CollegeId) {
    let dialogRef: MatDialogRef<ProceedWarningComponent> = this.matDialog.open(ProceedWarningComponent, {
      width: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.datalistService.loaderActive = true;
        this.databaseOperationService.deleteCollege(CollegeId).subscribe((data: any) => {
          this.getAllCollege();
          this.functionService.showProcessMessage("College Deleted Successfully", true);
          this.datalistService.loaderActive = false;
        });
        // }
      }
    });
  }
  getAllCourse(CollegeId) {
    this.Columns = ['SortId', 'CourseName', 'Operation'];
    this.databaseOperationService.getAllCourse(CollegeId).subscribe((data: any) => {
      
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.datalistService.loaderActive = false;
    })
  }
  addCourse() {

    let dialogRef: MatDialogRef<CourseFormPopupComponent> = this.matDialog.open(CourseFormPopupComponent, {
      width: '300px',
      height: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.CourseId = '';
    dialogRef.componentInstance.CollegeId = this.CollegeId;
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.datalistService.loaderActive = true;
        this.getAllCourse(this.CollegeId);
      }
    });
  }
  editCourse(CourseId) {
    let dialogRef: MatDialogRef<CourseFormPopupComponent> = this.matDialog.open(CourseFormPopupComponent, {
      width: '300px',
      height: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.CourseId = CourseId;
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.datalistService.loaderActive = true;
        this.getAllCourse(this.CollegeId);
      }
    });
  }
  delete_Course(CourseId) {
    let dialogRef: MatDialogRef<ProceedWarningComponent> = this.matDialog.open(ProceedWarningComponent, {
      width: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.datalistService.loaderActive = true;
        this.databaseOperationService.deleteCourse(CourseId).subscribe((data: any) => {
          this.getAllCourse(this.CollegeId);
          this.functionService.showProcessMessage("Course Deleted Successfully", true);
        });
        // }
      }
    });
  }
  setCollege() {
    this.datalistService.loaderActive = true;
    this.College = true
    this.getAllCollege();
  }
  setCourse(CollegeId) {
    this.datalistService.loaderActive = true;
    this.College = false
    this.CollegeId = CollegeId
    this.getAllCourse(this.CollegeId);
  }
}
