import { Component, OnInit } from '@angular/core';
import { ChartType } from 'chart.js';
import { MultiDataSet, Label } from 'ng2-charts';
// for SEO
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-placement-rpt',
  templateUrl: './placement-rpt.component.html',
  styleUrls: ['./placement-rpt.component.css']
})
export class PlacementRptComponent implements OnInit {
   // for SEO
   title = 'Top MBA College in Kerala |JAI BHARATH SCHOOL OF MANAGEMENT STUDIES';


  public doughnutChartLabels: Label[] = ['75 Intership Offers ', '45 Job Offers'];
  public doughnutChartData: MultiDataSet = [
    [75, 45],
  ];
  public doughnutChartLabels2: Label[] = ['90 Intership Offers ', '63 Job Offers'];
  public doughnutChartData2: MultiDataSet = [
    [90, 63],
  ];
  public doughnutChartLabels3: Label[] = ['67 Intership Offers ', '32 Job Offers'];
  public doughnutChartData3: MultiDataSet = [
    [64, 32],
  ];
  public options: any = {
    legend: { position: 'right' }
  }
  public doughnutChartType: ChartType = 'doughnut';
  public chartColors: Array<any> = [
    { // all colors in order
      backgroundColor: ['#303f9f', '#0e219e99']
    }
  ];
  
  constructor(
    // for SEO
    private titleService: Title,
    private metaTagService: Meta,
  ) { }

  ngOnInit(): void {
      // for SEO
      this.titleService.setTitle(this.title);
      this.metaTagService.addTags([
        { name: 'keywords', content: 'top mba college in kerala , top colleges in kerala , top 10 mba colleges in kerala , top mba colleges in kerala accepting kmat score , which mba college gives best placement' },
        { name: 'description', content: 'JAI BHARATH SCHOOL OF MANAGEMENT STUDIES | Become a Global Executive with Internationally Compatible Curriculum. Apply for Admission. Top MBA College in Kerala' },
        { name: 'robots', content: 'index, follow' },      
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },      
        { charset: 'UTF-8' }
      ]);
  }

}
