import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { DatabaseOperationService } from '../../../../api/database/database-operation.service';
import { DatalistService } from '../../../../api/services/datalist.service';
import { FunctionService } from '../../../../api/services/function.service';

@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.css']
})
export class ViewDetailsComponent implements OnInit {
  OnlineAdmissionId: any;
  OnlineAdmission;
  candidateName: any;

  constructor(
    public datalistService: DatalistService,
    private route: ActivatedRoute,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,) {
    this.datalistService.loaderActive = true;
  }
  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.OnlineAdmissionId = params['OnlineAdmissionId'];
      this.databaseOperationService.getOnlineAdmissionById(this.OnlineAdmissionId).subscribe((data: any) => {
        this.OnlineAdmission = data;
        this.datalistService.loaderActive = false;
      });
    });
  }


  getName(name) {
    this.candidateName = name;
    return this.candidateName;
  }
  printPDF() {

    var printContents = document.getElementById("parentdiv").innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    // window.onafterprint = function () {
      window.location.reload();
    // }
    // document.body.innerHTML = originalContents;
  }
  htmltoPDF() {
    // parentdiv is the html element which has to be converted to PDF
    html2canvas(document.querySelector("#parentdiv")).then(canvas => {

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4');

      var position = 0;
      var imgWidth = pdf.internal.pageSize.getWidth();
      var pageHeight = 300;
      var imgHeight = ((canvas.height * imgWidth) / canvas.width) - 30;
      var heightLeft = imgHeight;
      let offsetTop: number = 10;
      if (offsetTop + imgHeight > pdf.internal.pageSize.getHeight()) {
        offsetTop = 10;
        position = offsetTop;
      }

      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight + 15;
        pdf.addPage();
        pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      let Datenow = new Date();
      let fielname = this.candidateName + '_' + Datenow + '.pdf';
      pdf.save(fielname);

    });


  }

}
