import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';

@Component({
  selector: 'app-view-event-register-popup',
  templateUrl: './view-event-register-popup.component.html',
  styleUrls: ['./view-event-register-popup.component.css']
})
export class ViewEventRegisterPopupComponent implements OnInit {
  EventId: string;
  EnquiriesColumns: string[] = ['SortId', 'Name', 'Email','Phone','IsStudent', 'HearAboutEvent','Questions', 'CreatedDate'];
  AllKmatMockRegistration=[];
  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,) {
    this.datalistService.loaderActive = true;}

  ngOnInit(): void {
    this.databaseOperationService.getAllEventRegisteration(this.EventId).subscribe((data: any) => {
      this.AllKmatMockRegistration = data;
      this.datalistService.loaderActive = false;
    });
  }
  downloadRegistration(){
    this.databaseOperationService.downloadAllEventRegisteration(this.EventId).subscribe((data: any) => {
      this.AllKmatMockRegistration = data;
      this.datalistService.loaderActive = false;
    });
  }
}

