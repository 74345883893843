<div class="main-container">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="welcome">
                    <h1 class="heading">
                        JAI BHARATH SCHOOL OF MANAGEMENT STUDIES
                    </h1>
                    <p>
                        Jai Bharath School of Management Studies is an institute committed to provide world-class
                        career-oriented Education and Training. MBA at Jai Bharath is about belonging to a college that
                        strives for excellence in teaching & learning, research & scholarship and community service. It
                        is about having exceptional facilities and faculty that stand behind its students. It is about
                        achieving a rich and balanced teaching experience, inside and outside the classroom.
                    </p>
                    <p>
                        The Institute is committed to maintain a position at the forefront of knowledge and graduate the
                        most talented and qualified entrepreneurs in the region where they are capable of playing an
                        active role in the continuing development of their chosen profession and supporting the needs of
                        the local industry and society. Over the years, Jai Bharath College of Management Studies has
                        become one of the leading promoters of academic institutions in the country under the vision and
                        guidance of eminent personalities from different walks of life.
                    </p>
                    <a routerLink="/about" class="read-m"><img src="./assets/images/read more.png" alt="Top mba college in kerala,
                        top colleges in kerala for mba,
                        top 10 mba colleges in india,
                        Top Ranked MBA Colleges in India,
                        Best school of management studies india"></a>

                </div>

            </div>
            <div class="col-md-6">
                <img class="col-pic" src="./assets/images/Hmepage.png" alt="Top mba college in kerala,
                top colleges in kerala for mba,
                top 10 mba colleges in india,
                Top Ranked MBA Colleges in India,
                Best school of management studies india">
            </div>
        </div>
    </div>
</div>



<div class="course">
    <div class="container">
        <!--<p class="master-par">Master of Business Administration</p>-->
        <div class="row">
            <div class="col-md-12">
                <div class="head-line">
                    <h1 class="head-course">
                        Explore Our Courses
                    </h1>
                    <!-- <img class="head-img" src="./assets/images/Sliding Switch B.tech.png"> -->
                </div>
            </div>
        </div>

        <!-- Nav pills -->
        <ul class="nav nav-pills course-tabs">
            <li class="nav-item">
                <a class="nav-link active" data-toggle="pill" href="#course1">MBA-Dual Specialisation</a>
            </li>
            <!-- <li class="nav-item"> 
                <a class="nav-link" data-toggle="pill" href="#course2">Integrated MBA</a>
            </li>-->
        </ul>
        <p class="explore-cap">
            The 2 year (4 semester) Full time MBA Program of Mahatma Gandhi University, approved by All India council
            for Technical education (AICTE) is offered under semester system. Each student will have the option to
            specialise in any two of the following management subjects,
        </p>

        <!-- Tab panes -->
        <div class="tab-content">
            <div class="tab-pane active" id="course1">
                <div class="row">
                    <div class="col-md-4 course-bg">
                        <div class="row">
                            <div class="col-6">
                                <a routerLink="/marketing"> <img src="./assets/images/Courses (1).png" alt="Top mba college in kerala,
                        top colleges in kerala for mba,
                        top 10 mba colleges in india,
                        Top Ranked MBA Colleges in India,
                        Best school of management studies india"></a>
                            </div>
                            <div class="col-6">
                                <div class="cource-bx">
                                    <div>
                                        <a routerLink="/marketing">
                                            <h5 class="h-mech">Marketing <br> Management</h5>
                                        </a>


                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 course-bg">
                        <div class="row">
                            <div class="col-6">
                                <img src="./assets/images/Courses (2).png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india">
                            </div>
                            <div class="col-6">
                                <div class="cource-bx">
                                    <div>
                                        <a routerLink="/operation-management">
                                            <h5 class="h-mech">Operations <br> Management</h5>
                                        </a>

                                        <!--<p class="course-cap">management</p>-->

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 course-bg">
                        <div class="row">
                            <div class="col-6">
                                <a routerLink="/international-business"> <img src="./assets/images/Courses (3).png" alt="Top mba college in kerala,
                                    top colleges in kerala for mba,
                                    top 10 mba colleges in india,
                                    Top Ranked MBA Colleges in India,
                                    Best school of management studies india"></a>
                            </div>
                            <div class="col-6">
                                <div class="cource-bx">
                                    <div>
                                        <a routerLink="/international-business">
                                            <h5 class="h-mech">International <br> Business</h5>
                                        </a>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



                    <div class="col-md-4 course-bg">
                        <div class="row">
                            <div class="col-6">
                                <a routerLink="/finance"> <img src="./assets/images/Courses (4).png" alt="Top mba college in kerala,
                                    top colleges in kerala for mba,
                                    top 10 mba colleges in india,
                                    Top Ranked MBA Colleges in India,
                                    Best school of management studies india"></a>
                            </div>
                            <div class="col-6">
                                <div class="cource-bx">
                                    <div>
                                        <a routerLink="/finance">
                                            <h5 class="h-mech">Finance<br> Management</h5>
                                        </a>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 course-bg">
                        <div class="row">
                            <div class="col-6">
                                <a routerLink="/human-resource"> <img src="./assets/images/Courses (5).png" alt="Top mba college in kerala,
                                    top colleges in kerala for mba,
                                    top 10 mba colleges in india,
                                    Top Ranked MBA Colleges in India,
                                    Best school of management studies india"></a>
                            </div>
                            <div class="col-6">
                                <div class="cource-bx">
                                    <div>
                                        <a routerLink="/human-resource">
                                            <h5 class="h-mech">Human Resourse <br> Management</h5>
                                        </a>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 course-bg">
                        <div class="row">
                            <div class="col-6">
                                <a routerLink="/information-system"> <img src="./assets/images/Courses (6).png" alt="Top mba college in kerala,
                                    top colleges in kerala for mba,
                                    top 10 mba colleges in india,
                                    Top Ranked MBA Colleges in India,
                                    Best school of management studies india"></a>
                            </div>
                            <div class="col-6">
                                <div class="cource-bx">
                                    <div>
                                        <a routerLink="/information-system">
                                            <h5 class="h-mech">Information <br> Systems</h5>
                                        </a>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>



                </div>
            </div>
            <div class="tab-pane fade" id="course2">


            </div>
        </div>
    </div>
</div>





<!--<div class="pic-details">
    <div class="container">
        <div class="pic-array">
            <div class="row">
                <div class="col-md-4">
                    <div class="book-box">
                        <img src="assets/images/fwdmbahomescreen/highlights (1).png">
                        
                        <h5 class="free-job">Free Fitment Analysing</h5>
                        <p class="book-par">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,   </p>
                        <div class="book-now-im">
                           <img src="assets/images/fwdmbahomescreen/highlights (4).png">
                        </div>
                    </div>
                    
                </div>
                <div class="col-md-4">
                    <div class="book-box">
                        <img src="assets/images/fwdmbahomescreen/highlights (2).png">
                        
                        <h5 class="free-job">Upcoming Webinars</h5>
                        <p class="book-par">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                        <div class="book-now-im">
                           <img src="assets/images/fwdmbahomescreen/highlights (4).png">
                        </div>
                    </div>
                    
                </div>
                <div class="col-md-4">
                    <div class="book-box">
                        <img src="assets/images/fwdmbahomescreen/highlights (3).png">
                        
                        <h5 class="free-job">KMAT Entrance Coaching</h5>
                        <p class="book-par">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                        <div class="book-now-im">
                           <img src="assets/images/fwdmbahomescreen/highlights (4).png">
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</div>-->
<div id="track">
    <div class="container">

        <div class="pic-array">
            <!-- <p class="master-par">Our track record</p>-->
            <div class="row">
                <div class="col-md-12">
                    <div class="head-line">
                        <h1 class="head-course">
                            The Finest Performance By Our Group.
                        </h1>
                        <!-- <img class="head-img" src="./assets/images/Sliding Switch B.tech.png"> -->
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-4">
                    <div class="book-box">
                        <div class="book-box-img">
                            <div class="book-year">60+</div>
                        </div>
                        <h5 class="free-job">Acres Of Green Campus</h5>
                        <!-- <p class="book-par">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,   </p> -->

                    </div>

                </div>
                <div class="col-md-4">
                    <div class="book-box">
                        <div class="book-box-img">
                            <div class="book-year">9</div>
                        </div>

                        <h5 class="free-job">Institutions</h5>
                        <!-- <p class="book-par">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p> -->

                    </div>

                </div>
                <div class="col-md-4">
                    <div class="book-box">
                        <div class="book-box-img">
                            <div class="book-year">8000+</div>
                        </div>

                        <h5 class="free-job">Students</h5>
                        <!-- <p class="book-par">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p> -->

                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="book-box">

                        <div class="book-box-img">
                            <div class="book-year">75000+</div>
                        </div>
                        <h5 class="free-job">Alumni</h5>
                        <!-- <p class="book-par">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p> -->

                    </div>

                </div>
                <div class="col-md-4">
                    <div class="book-box">

                        <div class="book-box-img">
                            <div class="book-year">1000+</div>
                        </div>
                        <h5 class="free-job">Employees</h5>
                        <!-- <p class="book-par">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p> -->

                    </div>

                </div>
                <div class="col-md-4">
                    <div class="book-box">

                        <div class="book-box-img">
                            <div class="book-year">60+</div>
                        </div>
                        <h5 class="free-job">Courses</h5>
                        <!-- <p class="book-par">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p> -->

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<div class="pic-details">
    <div class="container">
        <div class="pt-5 pb-5">
            <div class="row">
                <div class="col-md-6">
                    <div>
                        <img src="assets/images/Viodeo mobleversion (3).png" alt="Top mba college in kerala,
                        top colleges in kerala for mba,
                        top 10 mba colleges in india,
                        Top Ranked MBA Colleges in India,
                        Best school of management studies india">
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="clg-data">
                        <h1 class="check-in">Checkout <br> our campus</h1>
                        <p>Still confused about joining?<br>talk to your career counsellors</p>
                        <hr>
                        <div class="number">
                            <p><a href="tel:+91 72932 29051">+91 72932 29051</a></p>

                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="partners">
    <h1>Acceditations and International Partnership</h1>
    <div class="container-fluid">
        <div class="row">
            <!-- <div class="col-md-6 col-lg-4">
                <h3 class="Partnership-head">Upskill Beyond<br>
                    the classroom,<br>
                    Closer to Industry</h3>
                <img class="hand-pic" src="./assets/images/affiliations.png">

            </div> -->
            <div class="col-md-12">
                <h3 class="aff-clas">Approved By</h3>
                <div class="row">

                    <div class="col-md-1">

                    </div>
                    <div class="col-md-2 col-4">
                        <img class="app-img" src="./assets/images/logos/pic4.png" alt="Top mba college in kerala,
                        top colleges in kerala for mba,
                        top 10 mba colleges in india,
                        Top Ranked MBA Colleges in India,
                        Best school of management studies india">
                    </div>
                    <div class="col-md-2 col-4">
                        <img class="app-img" src="./assets/images/logos/mgu.jpeg" alt="Top mba college in kerala,
                        top colleges in kerala for mba,
                        top 10 mba colleges in india,
                        Top Ranked MBA Colleges in India,
                        Best school of management studies india">
                    </div>
                    <div class="col-md-2 col-4">
                        <img class="app-img" src="./assets/images/logos/pic3.png" alt="Top mba college in kerala,
                        top colleges in kerala for mba,
                        top 10 mba colleges in india,
                        Top Ranked MBA Colleges in India,
                        Best school of management studies india">
                    </div>
                    <div class="col-md-2 col-4">
                        <img class="app-img" src="./assets/images/logos/pic5.png" alt="Top mba college in kerala,
                        top colleges in kerala for mba,
                        top 10 mba colleges in india,
                        Top Ranked MBA Colleges in India,
                        Best school of management studies india">
                    </div>
                    <div class="col-md-2 col-4">
                        <img class="app-img" src="./assets/images/logos/pic1.png" alt="Top mba college in kerala,
                        top colleges in kerala for mba,
                        top 10 mba colleges in india,
                        Top Ranked MBA Colleges in India,
                        Best school of management studies india">
                    </div>
                    <div class="col-md-1">

                    </div>

                </div>
                <h3 class="aff-clas">Our Skill Partners</h3>
                <div class="grad-sec">
                    <div class="row">

                        <div class=" col-md-2 col-4">
                            <div class="grad-body">
                                <img src="assets/images/logos/IBM_logo.png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
                            </div>
                        </div>
                        <div class=" col-md-2 col-4">
                            <div class="grad-body">
                                <img src="assets/images/logos/automation-anywhere-vector-logo.png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
                            </div>
                        </div>
                        <div class=" col-md-2 col-4">
                            <div class="grad-body">
                                <img src="assets/images/logos/sim.jpg" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
                            </div>
                        </div>
                        <div class="col-md-2 col-4 ">
                            <div class="grad-body">
                                <img src="assets/images/logos/cambridgepng.png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
                            </div>
                        </div>

                        <div class=" col-md-2 col-4">
                            <div class="grad-body">
                                <img src="assets/images/logos/gidimo.png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
                            </div>
                        </div>
                        <div class=" col-md-2 col-4">
                            <div class="grad-body">
                                <img src="assets/images/logos/nergy.png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
                            </div>
                        </div>
                        <div class=" col-md-2 col-4 offset-md-1">
                            <div class="grad-body">
                                <img src="assets/images/logos/microsoft-log.png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
                            </div>
                        </div>
                        <div class=" col-md-2 col-4">
                            <div class="grad-body">
                                <img src="assets/images/logos/logo_egal_download.jpg" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
                            </div>
                        </div>
                        <div class=" col-md-2 col-4">
                            <div class="grad-body">
                                <img src="assets/images/logos/Tally_Logo.png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
                            </div>
                        </div>
                        <div class=" col-md-2 col-4">
                            <div class="grad-body">
                                <img src="assets/images/logos/mepec.png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
                            </div>
                        </div>

                        <div class=" col-md-2 col-4">
                            <div class="grad-body">
                                <img src="assets/images/logos/sap1.png" alt="Top mba college in kerala,
                                top colleges in kerala for mba,
                                top 10 mba colleges in india,
                                Top Ranked MBA Colleges in India,
                                Best school of management studies india" />
                            </div>
                        </div>


                    </div>
                </div>


            </div>
        </div>
    </div>
</div>


<div class="clg-advantages">
    <div class="container">
        <!--   <p class="master-par">The Advantages</p>-->
        <div class="row">
            <div class="col-md-12">
                <div class="head-line">
                    <h1 class="head-course">
                        Why Jai Bharath
                    </h1>
                    <!-- <img class="head-img" src="./assets/images/Sliding Switch B.tech.png"> -->
                </div>
            </div>
        </div>
        <div class="pic-array clg-advan">
            <ul id="advantages-slider" class="advantages-slider">
                <li>
                    <div class="book-box">
                        <img src="assets/images/fwdmbahomescreen/Why Jaibharath (1).png" alt="Top mba college in kerala,
                        top colleges in kerala for mba,
                        top 10 mba colleges in india,
                        Top Ranked MBA Colleges in India,
                        Best school of management studies india">

                        <h5 class="free-job">Personalized <br>Industry Mentorship</h5>
                        <!-- <p class="book-par">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p> -->

                    </div>

                </li>
                <li>
                    <div class="book-box">
                        <img src="assets/images/fwdmbahomescreen/Why Jaibharath (2).png" alt="Top mba college in kerala,
                        top colleges in kerala for mba,
                        top 10 mba colleges in india,
                        Top Ranked MBA Colleges in India,
                        Best school of management studies india">

                        <h5 class="free-job">Practical Hands-on<br>Industry Projects</h5>
                        <!-- <p class="book-par">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p> -->

                    </div>
                </li>
                <li>
                    <div class="book-box">
                        <img src="assets/images/fwdmbahomescreen/Why Jaibharath (4).png" alt="Top mba college in kerala,
                        top colleges in kerala for mba,
                        top 10 mba colleges in india,
                        Top Ranked MBA Colleges in India,
                        Best school of management studies india">

                        <h5 class="free-job">Micro Mentoring</h5>
                        <!-- <p class="book-par">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,   </p> -->

                    </div>
                </li>
                <li>
                    <div class="book-box">
                        <img src="assets/images/fwdmbahomescreen/Why Jaibharath (5).png" alt="Top mba college in kerala,
                        top colleges in kerala for mba,
                        top 10 mba colleges in india,
                        Top Ranked MBA Colleges in India,
                        Best school of management studies india">

                        <h5 class="free-job">Industry<br> Intraction</h5>
                        <!-- <p class="book-par">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,   </p> -->

                    </div>
                </li>
                <li>
                    <div class="book-box">
                        <img src="assets/images/fwdmbahomescreen/Why Jaibharath (6).png" alt="Top mba college in kerala,
                        top colleges in kerala for mba,
                        top 10 mba colleges in india,
                        Top Ranked MBA Colleges in India,
                        Best school of management studies india">

                        <h5 class="free-job">Al based<br>Job-Fitment Analysing</h5>
                        <!-- <p class="book-par">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,   </p> -->

                    </div>
                </li>

            </ul>
            <div class="lSAction clgadvantage-controls">
                <a class="lSPrev" id="clgadvantagePrev"><img src="./assets/images/arrow-left.png" alt="Top mba college in kerala,
                    top colleges in kerala for mba,
                    top 10 mba colleges in india,
                    Top Ranked MBA Colleges in India,
                    Best school of management studies india" /></a>
                <a class="lSNext" id="clgadvantageNext"><img src="./assets/images/arrow-right.png" alt="Top mba college in kerala,
                    top colleges in kerala for mba,
                    top 10 mba colleges in india,
                    Top Ranked MBA Colleges in India,
                    Best school of management studies india" /></a>
            </div>

        </div>
    </div>
</div>












<div class="placestart-head">
    <div class="container">
        <div class="placement-main">
            <!-- <p class="master-par">The Guarantee</p>-->
            <h1 class="prj-head">Our Placement Strategy</h1>
            <div class="placement-area">
                <div class="row">
                    <div class="col-md-2">
                        <div class="placement-box" routerLink="/career">
                            <div class="placein-box">
                                <img src="assets/images/fwdmbahomescreen/placement (6).png">
                                <p>
                                    Job<br> Fitment Report
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="placement-box" routerLink="/career">
                            <div class="placein-box">
                                <img src="assets/images/fwdmbahomescreen/placement (5).png">
                                <p>
                                    Industry<br> Membership
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="placement-box" routerLink="/career">
                            <div class="placein-box">
                                <img src="assets/images/fwdmbahomescreen/placement (4).png">
                                <p>
                                    Career <br /> Coach
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="placement-box" routerLink="/career">
                            <div class="placein-box">
                                <img src="assets/images/fwdmbahomescreen/placement (3).png">
                                <p>
                                    Gamified<br> Resume Preparation
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="placement-box" routerLink="/career">
                            <div class="placein-box">
                                <img src="assets/images/fwdmbahomescreen/placement (2).png">
                                <p>
                                    Internship <br /> Hunter
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="placement-box" routerLink="/career">
                            <div class="placein-box">
                                <img src="assets/images/fwdmbahomescreen/placement (1).png">
                                <p>
                                    AI-based<br> Job Portal
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="clients">
        <div class="container">
            <div class="client-items">
                <ul id="client-slider" class="client-slider">
                    <li>
                        <img src="assets/images/cl1.png" />
                    </li>
                    <li>
                        <img src="assets/images/cl2.png" />
                    </li>
                    <li>
                        <img src="assets/images/cl3.png" />
                    </li>
                    <li>
                        <img src="assets/images/cl4.png" />
                    </li>
                    <li>
                        <img src="assets/images/cl5.png" />
                    </li>
                    <li>
                        <img src="assets/images/cl6.png" />
                    </li>
                </ul>
                <div class="lSAction client-controls">
                    <a class="lSPrev" id="clientPrev"><img src="./assets/images/arrow-left.png" /></a>
                    <a class="lSNext" id="clientNext"><img src="./assets/images/arrow-right.png" /></a>
                </div>
            </div>
        </div>
    </div>
</div>




<app-testimonial></app-testimonial>


<section id="full-prj">
    <div class="container">
        <h1 class="prj-head"></h1>
        <div class="blogs">
            <div class="row">
                <div class="col-md-7">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="prj-head-blog">News & Events</h1>
                            <button class="btn btn-view-blog" routerLink="/news-events"> VIEW ALL </button>
                        </div>
                    </div>
                    <span *ngFor="let allBlog of AllBlogEvent;let i=index;">
                        <div class="row" *ngIf="i<3">
                            <div class="col-md-3">
                                <img [src]="datalistService.IMGAE_PATH+'/blog'+allBlog.BlogImage" class="small-img" />
                            </div>
                            <div class="col-md-9">
                                <div class="break-line">
                                    <h5>{{allBlog.BlogTitle}}</h5>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
                <div class="col-md-5" *ngIf="AllWebinar.length">
                    <div class="row">
                        <div class="col-md-12">
                            <h1 class="prj-head-blog">Upcoming Webinar</h1>
                            <button class="btn btn-view-blog" routerLink="/webinar"> VIEW ALL </button>
                        </div>
                    </div>
                    <div class="row" *ngFor="let allWebinar of AllWebinar;let i=index;">
                        <div class="col-md-12" *ngIf="i<2">
                            <div class="img-ho">
                                <img [src]="datalistService.IMGAE_PATH+'/webinar'+allWebinar.Poster" class="big-img" />
                            </div>
                            <div class="break-line">
                                <!-- <p>2020-11-25</p> -->
                                <h4>{{allWebinar.Topic}}</h4>
                                <hr>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <section id="full-prj" *ngIf="AllBlogEvent">
    <div class="container">
        <p class="prj-title">News & Blogs</p>
        <h1 class="prj-head">Our Media Presence</h1>
        <div class="blogs">
            <ul id="blog-slide" class="blog-slide">
                <li *ngFor="let allBlog of AllBlogEvent">
                    <img [src]="datalistService.IMGAE_PATH+'/blog'+allBlog.BlogImage" />
                    <div class="batch">
                        <p class="prj-title-2">{{allBlog.BlogTitle}}</p>
                        <p class="blue" *ngIf="allBlog.Type==1" class="btn btn-more all" [routerLink]="['/blog-details',allBlog.SortId,allBlog.URLValue]" >Read more</p>
                        <p class="blue" *ngIf="allBlog.Type==2" class="btn btn-more all" [routerLink]="['/blog-details',allBlog.SortId,allBlog.URLValue]" >Read more</p>
                    </div>
                </li>
                
            </ul>
            <div class="lSAction blog-controls">
                <a class="lSPrev" id="blogPrev"><img src="./assets/images/arrow-left.png" /></a>
                <a class="lSNext" id="blogNext"><img src="./assets/images/arrow-right.png" /></a>
            </div>
        </div>
    </div>
</section> -->