import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DatabaseOperationService } from '../../api/database/database-operation.service';
import { DatalistService } from '../../api/services/datalist.service';
import { FunctionService } from '../../api/services/function.service';

@Component({
  selector: 'app-kmat-mock-registration-popup',
  templateUrl: './kmat-mock-registration-popup.component.html',
  styleUrls: ['./kmat-mock-registration-popup.component.css']
})
export class KmatMockRegistrationPopupComponent implements OnInit {
  RegistrationForm: any;
  formInvalid: boolean;
  submitted: boolean;

  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    private formBuilder: FormBuilder,
    public dialog: MatDialogRef<KmatMockRegistrationPopupComponent>,) { }

  ngOnInit(): void {
    this.RegistrationForm = this.formBuilder.group({
      Name: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      Phone: ['', Validators.required],
      Address: ['', Validators.required],
      CollegeName: ['', Validators.required],
      CourseName: ['', Validators.required],
      CourseYear: ['', Validators.required],
    });
  }
  get formControl() { return this.RegistrationForm.controls; }
  onSubmit() {
    this.datalistService.loaderActive = true;
    this.formInvalid = false;
    this.submitted = false;
    if (this.RegistrationForm.valid) {
      // this.databaseOperationService.sendContactForm(this.RegistrationForm.value).subscribe((data: any) => {
      // });
      this.databaseOperationService.saveKmatMockRegistrationForm(this.RegistrationForm.value).subscribe((data: any) => {
        this.dialog.close(true);
        this.functionService.showProcessMessage("Our Counsellor Will Connect With You ", true);
        this.datalistService.loaderActive = false;
      })
    } else {
      this.submitted = true;
      this.functionService.showProcessMessage("Please provide valid information", false);
      this.datalistService.loaderActive = false;
    }
  }

}
