import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';

@Component({
  selector: 'app-webinar-form-popup',
  templateUrl: './webinar-form-popup.component.html',
  styleUrls: ['./webinar-form-popup.component.css']
})
export class WebinarFormPopupComponent implements OnInit {
  WebinarForm: any;
  submitted: boolean;
  WebinarId: string;
  formTitle: string;
  buttonLabel: string;
  HostImageSrc: string;
  PosterImageSrc: string;
  WebinarDetails: any;
  fileHostImageLoaded: any;
  filePosterImageSrcLoaded: any;
  formSubmitAttempt: boolean;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: '',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
  };
  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,
    public dialog: MatDialogRef<WebinarFormPopupComponent>,
  ) { }

  ngOnInit(): void {

    this.submitted = false;
    this.formTitle = 'Add';
    this.buttonLabel = 'Submit';
    this.HostImageSrc = "assets/images/homepl5.png";
    this.PosterImageSrc = "assets/images/image_upload_placeholder.jpg";
    this.WebinarForm = this.formBuilder.group({
      WebinarId: [''],
      Topic: ['', Validators.required],
      Date: ['', Validators.required],
      Time: ['', Validators.required],
      Venue: [''],
      HostedBy: ['', Validators.required],
      HostDetails: ['', Validators.required],
      Details: ['', Validators.required],
      HostedByImage: [''],
      HostImage: ['', Validators.required],
      Poster: [''],
      PosterImage: ['', Validators.required],
    });
    if (this.WebinarId) {
      this.datalistService.loaderActive = true;
      this.databaseOperationService.getWebinarById(this.WebinarId).subscribe((data: any) => {
        this.WebinarDetails = data;
        this.WebinarForm = this.formBuilder.group({
          WebinarId: [''],
          Topic: ['', Validators.required],
          Date: ['', Validators.required],
          Time: ['', Validators.required],
          Venue: [''],
          HostedBy: ['', Validators.required],
          HostDetails: ['', Validators.required],
          Details: ['', Validators.required],
          HostedByImage: [''],
          HostImage: [''],
          Poster: [''],
          PosterImage: [''],
        });
        this.formTitle = 'Update';
        this.buttonLabel = 'Update';
        this.HostImageSrc = this.datalistService.IMGAE_PATH + '/webinar' + this.WebinarDetails.HostedByImage;
        this.PosterImageSrc = this.datalistService.IMGAE_PATH + '/webinar' + this.WebinarDetails.Poster;
        this.WebinarForm.patchValue(this.WebinarDetails);
        this.datalistService.loaderActive = false;
      })
    }
  }
  get formControl() { return this.WebinarForm.controls; }
  onSubmit() {
    this.submitted = true;
    this.formSubmitAttempt = true;
    if (this.WebinarForm.invalid) {
      this.functionService.showProcessMessage("Provide vaild information", false);
      return;
    }
    this.databaseOperationService.saveWebinar(this.WebinarForm.value).subscribe((data: any) => {
      this.uploadImage(data.WebinarId, this.WebinarForm.value.HostedByImage, this.WebinarForm.value.Poster);
    })
    // this.save();
  }

  onHostSelected(event) {
    let dialogRef = this.functionService.imageCrop(event, 300, 540);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.HostImageSrc = result.imageTemp;
        this.fileHostImageLoaded = result.file;
      }
    });
  }
  onPosterSelected(event) {
    this.filePosterImageSrcLoaded = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.PosterImageSrc = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

  uploadImage(WebinarId: any, OldHostImage: any, OldPosterImage: any) {
    let formData = new FormData();
    let count = 0;
    if (this.filePosterImageSrcLoaded) {
      formData.append('Image', this.filePosterImageSrcLoaded, this.filePosterImageSrcLoaded.name);
      this.databaseOperationService.saveWebinarPosterImage(formData, WebinarId, OldPosterImage).subscribe((data: any) => {


        this.filePosterImageSrcLoaded = "";
        count++;
        if (count == 2) {
          this.functionService.showProcessMessage("Saved successfully", true);
          this.dialog.close(false);
        }
      });
    } else {
      count++;
    }
    if (this.fileHostImageLoaded) {
      formData.append('Image', this.fileHostImageLoaded, this.fileHostImageLoaded.name);
      this.databaseOperationService.saveWebinarHostImage(formData, WebinarId, OldHostImage).subscribe((data: any) => {

        this.fileHostImageLoaded = "";
        count++;
        if (count == 2) {
          this.functionService.showProcessMessage("Saved successfully", true);
          this.dialog.close(false);
        }
      });
    } else {
      count++;
    }
    if (count == 2) {
      this.functionService.showProcessMessage("Saved successfully", true);
      this.dialog.close(false);
    }

  }
}
