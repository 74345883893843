import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseOperationService } from '../../api/database/database-operation.service';
import { DatalistService } from '../../api/services/datalist.service';
import { FunctionService } from '../../api/services/function.service';
import { ProceedWarningComponent } from '../../popup/proceed-warning/proceed-warning.component';
// for SEO
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-news-events',
  templateUrl: './news-events.component.html',
  styleUrls: ['./news-events.component.css']
})
export class NewsEventsComponent implements OnInit {
  // for SEO
  title = 'Top MBA College in Kerala |JAI BHARATH SCHOOL OF MANAGEMENT STUDIES';

  AllBlog: any;
  AllBlogEvent=[];
  AllEvent: any;

  constructor(
    // for SEO
    private titleService: Title,
    private metaTagService: Meta,

    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public functionService: FunctionService,
    // private titleService:Title
    ) {
      this.datalistService.loaderActive = true;
     }

  ngOnInit(): void {
     // for SEO
     this.titleService.setTitle(this.title);
     this.metaTagService.addTags([
       { name: 'keywords', content: 'top mba college in kerala , top colleges in kerala , top 10 mba colleges in kerala , top mba colleges in kerala accepting kmat score , which mba college gives best placement' },
       { name: 'description', content: 'JAI BHARATH SCHOOL OF MANAGEMENT STUDIES | Become a Global Executive with Internationally Compatible Curriculum. Apply for Admission. Top MBA College in Kerala' },
       { name: 'robots', content: 'index, follow' },      
       { name: 'viewport', content: 'width=device-width, initial-scale=1' },      
       { charset: 'UTF-8' }
     ]);
    this.getAllBlog();
  }
  getAllBlog(){
    
    this.databaseOperationService.getAllBlog().subscribe((data:any) => {
      this.AllBlogEvent = data;
      this.AllBlog = this.AllBlogEvent.filter(
        AllBlogEvent => AllBlogEvent.Type === "1");
        this.AllEvent = this.AllBlogEvent.filter(
          AllBlogEvent => AllBlogEvent.Type === "2");
      this.datalistService.loaderActive = false;
    })
  }
  deleteBlog(ServiceId, ImageName) {
    let dialogRef: MatDialogRef<ProceedWarningComponent> = this.matDialog.open(ProceedWarningComponent, {
      width: 'auto',
      disableClose: true,
      panelClass: 'custom-dialog-container',
      hasBackdrop: true,
    });
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.datalistService.loaderActive = true;
        this.databaseOperationService.deleteBlog(ServiceId, ImageName).subscribe((data) => {

          if (!data) {
            this.getAllBlog();
            this.functionService.showProcessMessage("Blog deleted successfully", true);
          } else {
            this.functionService.showProcessMessage("Blog deleted unsuccessfully", false);
            this.datalistService.loaderActive = false;
          }
        });
      }
    });
  }
}

