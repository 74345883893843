<div class="news-details">
    <div class="container">
        <div class="row" *ngIf="datalistService.sessionData">
            <div class="col-md-12 test-right">
                <br />
                <button mat-raised-button class="nav-link active" (click)="addBlog()"><i class="fa fa-plus"
                        aria-hidden="true"></i> Add new</button>
                <br />
                <br />
            </div>
        </div>
        <div class="news-elements" *ngIf="!editBlogForm">
            <div class="row">

                <div class="col-md-6">
                    <img [src]="imageSrc" class="news-pic" />
                    <div class="text-center"  *ngIf="datalistService.sessionData">
                        <br/>
                            <button mat-raised-button color="primary" class="nav-link active"
                            (click)="viewEventRegister(BlogDetails.BlogId)">
                        View Registeration</button>
                    </div>
                    <div class="find-out-more-btn text-center" *ngIf="BlogDetails.EventDate&&BlogDetails.Type&&isActive(BlogDetails.EventDate)">

                        <br/>
                        <a class="btn crose-btn btn-2" (click)="registerEvent(BlogDetails.BlogId)">Register</a>
                    </div>
                </div>
                <div class="col-md-6" *ngIf="!editBlogForm&&BlogDetails">
                    <div class="row" *ngIf="datalistService.sessionData">
                        <div class="col-md-12 text-right">
                            <button class="edit-content" (click)="editBlog()" mat-raised-button color="primary"><i
                                    class="fa fa-pencil-square-o" aria-hidden="true"></i>
                                Edit</button>
                                <button mat-raised-button color="warn" class="nav-link active" (click)="deleteBlog(BlogDetails.BlogId,BlogDetails.BlogImage)"><i class="fa fa-trash" aria-hidden="true"></i></button>
                        </div>
                    </div>
                    <h2 class="news-head">{{BlogDetails.BlogTitle}}</h2>
                    <p [innerHTML]="BlogDetails.BlogContent">
                    </p>
                    <div class="post-data">
                        <p class="autor"><a>by {{BlogDetails.AuthorName}}</a></p>
                        <p class="data"><a>{{BlogDetails.PostedDate}}</a></p>
                    </div>
                </div>
            </div>
        </div>
        <div class="news-elements" *ngIf="editBlogForm">
            <div class="row">
                <div class="col-md-12">
                    <mat-error *ngIf="formSubmitAttempt && formControl.Image.errors ">
                        Please provide a valid blog image
                    </mat-error>
                    <div class="blog-img" [ngClass]="{ 'is-invalid': formSubmitAttempt && formControl.Image.errors }">
                        <label [for]="'uploadBlogImage'">
                            <img [src]="imageSrc" />
                            <div *ngIf="datalistService.sessionData">
                                <div class="edit-image">
                                    <span *ngIf="!addNewBlog">
                                        <input [id]="'uploadBlogImage'" type="file"
                                            (change)="onBlogSelected($event,BlogDetails.BlogId)" class="image-click">
                                        Click To Change Image<br />
                                        <i>Recommended : 620 × 260 pixels</i>
                                    </span>
                                    <span *ngIf="addNewBlog">
                                        <i>Recommended : 620 × 260 pixels</i>
                                    </span>
                                </div>
                            </div>
                        </label>
                    </div>
                    <div class="blog-data">
                        <br />
                        <br />
                        <form [formGroup]="formBlog" (ngSubmit)="submitBlog()">
                            <mat-card-content>
                                <mat-form-field appearance="fill">
                                    <mat-label>Blog/Event</mat-label>
                                    <select matNativeControl  formControlName="Type" required >
                                        <option value="1">Blog</option>
                                        <option value="2">Event</option>
                                    </select>
                                </mat-form-field>
                            </mat-card-content>
                            <br />
                            <mat-card-content *ngIf="formBlog.value.Type==2">
                                <mat-form-field>
                                    <input matInput type="date" placeholder="Event Date" formControlName="EventDate"
                                        required />
                                    <mat-error>
                                        Please provide a valid Event Date
                                    </mat-error>
                                </mat-form-field>
                            </mat-card-content>
                            <br />
                            <mat-card-content>
                                <mat-form-field>
                                    <input matInput placeholder="Blog Title" formControlName="BlogTitle" required />
                                    <mat-error>
                                        Please provide a valid blog title
                                    </mat-error>
                                </mat-form-field>
                            </mat-card-content>
                            <br />
                            <mat-card-content>
                                <mat-form-field>
                                    <input matInput placeholder="Author Name" formControlName="AuthorName" required />
                                    <mat-error>
                                        Please provide a valid author name
                                    </mat-error>
                                </mat-form-field>
                            </mat-card-content>
                            <angular-editor rows="20" [config]="editorConfig" formControlName="BlogContent">
                            </angular-editor>
                            <span *ngIf="addNewBlog">
                                <input [id]="'uploadBlogImage'" type="file" formControlName="Image"
                                    (change)="onBlogSelected($event,'')" class="image-click" />
                            </span>
                            <div class="text-right submit-div">
                                <button mat-raised-button color="primary">{{formButtonTitle}}</button>
                                <button (click)="cancelForm()" *ngIf="!cancelDisable" mat-raised-button>Cancel</button>
                                <button [routerLink]="['/insight/blog']" *ngIf="cancelDisable"
                                    mat-raised-button>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>