<section id="contact" class="contact">
    <div _ngcontent-gmi-c28="" class="banner"><img _ngcontent-gmi-c28="" src="assets/images/mbas-ban.jpg"></div>

    <div class="container" data-aos="fade-up">

        <div class="row mt-5" data-aos="fade-up" data-aos-delay="100">
            <div class="col-lg-12">
                <form [formGroup]="formFeedBackDetails" (ngSubmit)="onSubmit()" autocomplete="off">
                    <h5>APPLY ONLINE</h5>
                    <div class="form-row">
                        <div class="col form-group">
                            <input type="text" class="form-control" formControlName="FirstName"
                                [ngClass]="{ 'is-invalid': submitted && formControl.FirstName.errors }"
                                placeholder="First Name *" data-rule="minlen:4"
                                data-msg="Please enter at least 4 chars" />
                            <div class="validate"></div>
                        </div>
                        <div class="col form-group">
                            <input type="text" class="form-control" formControlName="LastName"
                                [ngClass]="{ 'is-invalid': submitted && formControl.LastName.errors }"
                                placeholder="Last Name *" data-rule="minlen:4"
                                data-msg="Please  enter at least 4 chars" />
                            <div class="validate"></div>
                        </div>
                    </div>
                    <div class="form-group">
                        <select class="form-control" formControlName="Collage"  (change)="changeCollege($event)"
                            [ngClass]="{ 'is-invalid': submitted && formControl.Collage.errors }"
                            placeholder="collage *">
                            <option value="">Select College</option>
                            <option *ngFor="let allCollege of AllCollege" value="{{allCollege.CollegeId}}">
                                {{allCollege.CollegeName}}</option>
                        </select>
                        <div class="validate"></div>
                    </div>
                    <div class="form-group">
                        <select class="form-control" formControlName="Courses"  (change)="changeCourse($event)"
                            [ngClass]="{ 'is-invalid': submitted && formControl.Courses.errors }"
                            placeholder="Courses *">
                            <option value="">Select Course</option>
                            <option *ngFor="let allCourse of AllCourse" value="{{allCourse.CourseId}}">
                                {{allCourse.CourseName}}</option>
                        </select>
                        <div class="validate"></div>
                    </div>
                    <div class="form-group">
                        <select class="form-control" formControlName="Faculties"
                            [ngClass]="{ 'is-invalid': submitted && formControl.Faculties.errors }"
                            placeholder="Faculties *" >
                            <option value="">Select Faculties</option>
                            <option *ngFor="let allFaculties of AllFaculties" value="{{allFaculties.FacultiesId}}">
                                {{allFaculties.FacultiesName}}</option>
                        </select>
                        <div class="validate"></div>
                    </div>
                    <div class="form-row">
                        <div class="col form-group">
                            <p><span class="bold">Dear Students,</span> <br>
                                We are Request to give your fare and Frank opinion of the Faculty on a 10-point scale.
                                It will help us<br>
                                to improve and maintain the quality of teaching. Your response will remain
                                confidential.<br>
                                <br />

                                Consider the following and fill the appropriate circle:
                            </p>
                        </div>
                    </div>

                    <div class="form-group feedback-radio">
                        <p><span class="bold">Questions:</span> <br><br />
                        <p> 1. ) Was well-Prepared </p>
                        <mat-radio-group aria-label="Select an option" formControlName="WasWellPrepared"
                            [ngClass]="{ 'is-invalid': submitted && formControl.WasWellPrepared.errors }">
                            <mat-radio-button value="1">Low</mat-radio-button>
                            <mat-radio-button value="2">Average</mat-radio-button>
                            <mat-radio-button value="3">Good</mat-radio-button>
                            <mat-radio-button value="4">Very Good</mat-radio-button>
                        </mat-radio-group>

                        <p> 2) Gave interesting and informative classes</p>

                        <mat-radio-group aria-label="Select an option" formControlName="InterestingAndInformative"
                            [ngClass]="{ 'is-invalid': submitted && formControl.InterestingAndInformative.errors }">
                            <mat-radio-button value="1">Low</mat-radio-button>
                            <mat-radio-button value="2">Average</mat-radio-button>
                            <mat-radio-button value="3">Good</mat-radio-button>
                            <mat-radio-button value="4">Very Good</mat-radio-button>
                        </mat-radio-group>
                        <p>3) Was good at explaining things </p>

                        <mat-radio-group aria-label="Select an option" formControlName="GoodExplaining"
                            [ngClass]="{ 'is-invalid': submitted && formControl.GoodExplaining.errors }">
                            <mat-radio-button value="1">Low</mat-radio-button>
                            <mat-radio-button value="2">Average</mat-radio-button>
                            <mat-radio-button value="3">Good</mat-radio-button>
                            <mat-radio-button value="4">Very Good</mat-radio-button>
                        </mat-radio-group>
                        <p>4) Was effective in leading the class</p>

                        <mat-radio-group aria-label="Select an option" formControlName="EffectiveInLeading"
                            [ngClass]="{ 'is-invalid': submitted && formControl.EffectiveInLeading.errors }">
                            <mat-radio-button value="1">Low</mat-radio-button>
                            <mat-radio-button value="2">Average</mat-radio-button>
                            <mat-radio-button value="3">Good</mat-radio-button>
                            <mat-radio-button value="4">Very Good</mat-radio-button>
                        </mat-radio-group>
                        <p>5) Was receptive to students’ question</p>

                        <mat-radio-group aria-label="Select an option" formControlName="ReceptiveToStudentQuestion"
                            [ngClass]="{ 'is-invalid': submitted && formControl.ReceptiveToStudentQuestion.errors }">
                            <mat-radio-button value="1">Low</mat-radio-button>
                            <mat-radio-button value="2">Average</mat-radio-button>
                            <mat-radio-button value="3">Good</mat-radio-button>
                            <mat-radio-button value="4">Very Good</mat-radio-button>
                        </mat-radio-group>
                        <p>6) Stimulated interest in the subject </p>

                        <mat-radio-group aria-label="Select an option" formControlName="StimulatedInterestInSubject"
                            [ngClass]="{ 'is-invalid': submitted && formControl.StimulatedInterestInSubject.errors }">
                            <mat-radio-button value="1">Low</mat-radio-button>
                            <mat-radio-button value="2">Average</mat-radio-button>
                            <mat-radio-button value="3">Good</mat-radio-button>
                            <mat-radio-button value="4">Very Good</mat-radio-button>
                        </mat-radio-group>
                        <p> 7) Stimulated discussion on the subject</p>

                        <mat-radio-group aria-label="Select an option" formControlName="StimulatedDiscussionOnSubject"
                            [ngClass]="{ 'is-invalid': submitted && formControl.StimulatedDiscussionOnSubject.errors }">
                            <mat-radio-button value="1">Low</mat-radio-button>
                            <mat-radio-button value="2">Average</mat-radio-button>
                            <mat-radio-button value="3">Good</mat-radio-button>
                            <mat-radio-button value="4">Very Good</mat-radio-button>
                        </mat-radio-group>
                        <p> 8) Stimulated me to think and Learn </p>

                        <mat-radio-group aria-label="Select an option" formControlName="StimulatedMeToThink"
                            [ngClass]="{ 'is-invalid': submitted && formControl.StimulatedMeToThink.errors }">
                            <mat-radio-button value="1">Low</mat-radio-button>
                            <mat-radio-button value="2">Average</mat-radio-button>
                            <mat-radio-button value="3">Good</mat-radio-button>
                            <mat-radio-button value="4">Very Good</mat-radio-button>
                        </mat-radio-group>
                        <p>9) Had a good rapport with the class </p>

                        <mat-radio-group aria-label="Select an option" formControlName="GoodRapport"
                            [ngClass]="{ 'is-invalid': submitted && formControl.GoodRapport.errors }">
                            <mat-radio-button value="1">Low</mat-radio-button>
                            <mat-radio-button value="2">Average</mat-radio-button>
                            <mat-radio-button value="3">Good</mat-radio-button>
                            <mat-radio-button value="4">Very Good</mat-radio-button>
                        </mat-radio-group>
                        <p> 10) Was available to answer question in office hours </p>

                        <mat-radio-group aria-label="Select an option"
                            formControlName="AvailableToQuestionInOfficeHours"
                            [ngClass]="{ 'is-invalid': submitted && formControl.AvailableToQuestionInOfficeHours.errors }">
                            <mat-radio-button value="1">Low</mat-radio-button>
                            <mat-radio-button value="2">Average</mat-radio-button>
                            <mat-radio-button value="3">Good</mat-radio-button>
                            <mat-radio-button value="4">Very Good</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <div class="form-group">
                        <p><span class="bold">Any Other Comments:</span> <br>
                            <textarea class="form-control" formControlName="OtherComment"
                                [ngClass]="{ 'is-invalid': submitted && formControl.OtherComment.errors }"></textarea>
                        <div class="validate"></div>
                    </div>
                    <div class="col-12 text-center">
                        <div class="form-group">
                            <button type="submit" mat-raised-button color="primary">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</section><!-- End Contact Section -->