import { Injectable, Component } from '@angular/core';
import { DatalistService } from './datalist.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ImageCropPopupComponent } from '../../popup/image-crop-popup/image-crop-popup.component';
import { EnquryFormPopupComponent } from '../../popup/course/enqury-form-popup/enqury-form-popup.component';

@Injectable({
  providedIn: 'root'
})
export class FunctionService {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public datalistService: DatalistService,
    private _snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private location: Location
  ) { }
  getServerApi() {
    return this.datalistService.PHP_API_SERVER;
  }
  getAdminApi() {
    return this.datalistService.PHP_API_ADMIN;
  }
  getImagePath() {
    return this.datalistService.IMGAE_PATH;
  }
  getMailApi() {
    return this.datalistService.PHP_API_SERVER + "/_admin_console/mail";
  }

  getYearList() {
    let yearArray=[];
    var d = new Date();
    var year = d.getFullYear();
    for (let i = year; i >= 1950; i--) {
      yearArray.push(i);
    }
    return yearArray;
  }
  imageCrop(event: any, height: number, width: number) {
    let dialogRef: MatDialogRef<ImageCropPopupComponent> = this.matDialog.open(ImageCropPopupComponent, {
      width: 'auto',
      height: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container-image-crop',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.event = event;
    dialogRef.componentInstance.height = height;
    dialogRef.componentInstance.width = width;
    return dialogRef;
  }
  transform(value: string): any {
    let usefulTags = [];
    usefulTags[0] = `'`;
    return usefulTags.length > 0
      ? value.replace(new RegExp(`<(?!\/?(${usefulTags.join('|')})\s*\/?)[^>]+>`, 'g'), '')
      : value.replace(/<(?:.|\s)*?>/g, '');
  }
  showProcessMessage(message: string, type: boolean) {
    this.datalistService.messageAutoPopupData = message;
    this.datalistService.messageAutoPopuSuccess = type;
    setTimeout(() => {
      this.datalistService.messageAutoPopupData = '';
      this.datalistService.messageAutoPopuSuccess = false;
    },
      6000);
  }
  setExtraTimeLoadWarning() {
    this.datalistService.extraTimeLoadWarning = false;
    setTimeout(() => {
      this.datalistService.extraTimeLoadWarning = true;
      setTimeout(() => {
        this.datalistService.extraTimeLoadWarning = false;
      },
        10000);
    },
      4000);
  }
  userLogout() {
    window.sessionStorage.clear();
    this.router.navigateByUrl('/');
    this.datalistService.sessionUserData = false;
    this.datalistService.cart_count = "0";
  }
  counter(i: number) {
    return new Array(i);
  }
  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        return;
      }
      if (control.value !== matchingControl.value) {
        this.showProcessMessage("Password mismatching", false);
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }
  back() { this.location.back(); }
 
  // openEnquiryForm(Course){
  //   alert(Course);
  //   let dialogRef: MatDialogRef<EnquryFormPopupComponent> = this.matDialog.open(EnquryFormPopupComponent, {
  //     width: '400px',
  //     disableClose: true,
  //     panelClass: 'custom-dialog-container',
  //     hasBackdrop: true,
  //   });
  //   // computer electrical electronic mechanical civil polyArchitecture polyAutomobile polyCivil polyElectrical polyMechanical
  //   dialogRef.componentInstance.Course = Course;
  //   return dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
        
          
      
  //     }
  //   });
  // }
   tConv24(time24) {
    var ts = time24;
    var H = +ts.substr(0, 2);
    var h = (H % 12) || 12;
    h = (h < 10)?(h):h;  // leading 0 at the left for 1 digit hours
    var ampm = H < 12 ? " AM" : " PM";
    ts = h + ts.substr(2, 3) + ampm;
    return ts;
  };
}
