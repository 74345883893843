import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SiteLayoutComponent } from './layout/site-layout/site-layout.component';
import { HomeComponent } from './site/home/home.component';
import { CourseComponent } from './site/course/course.component';
import { AboutComponent } from './site/about/about.component';
import { AboutGroupComponent } from './site/about-group/about-group.component';
import { AboutMouComponent } from './site/about-mou/about-mou.component';
import { LibraryComponent } from './site/library/library.component';
import { PlacementsComponent } from './site/placements/placements.component';
import { ActivitiesComponent } from './site/activities/activities.component';
import { AntiRaggingComponent } from './site/anti-ragging/anti-ragging.component';
import { JobfitAnalysisComponent } from './site/jobfit-analysis/jobfit-analysis.component';
import { CareerComponent } from './site/career/career.component';
import { PrivacyPolicyComponent } from './site/privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './site/terms-conditions/terms-conditions.component';
import { ContactComponent } from './site/contact/contact.component';
import { OnlineAdmissionComponent } from './site/online-admission/online-admission.component';
import { NewsEventsComponent } from './site/news-events/news-events.component';
import { StudentsFeedbackComponent } from './site/students-feedback/students-feedback.component';
import { WebinarComponent } from './site/webinar/webinar.component';
import { NewsDetailsComponent } from './site/news-details/news-details.component';
import { ImageCropPopupComponent } from './popup/image-crop-popup/image-crop-popup.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ProceedWarningComponent } from './popup/proceed-warning/proceed-warning.component';
import { LoginPopupComponent } from './popup/user/login-popup/login-popup.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { CarrerWithusComponent } from './site/carrer-withus/carrer-withus.component';
import { HireJbComponent } from './site/hire-jb/hire-jb.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MatInputModule } from '@angular/material/input';
import { LoginComponent } from './site/login/login.component';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { ViewFaclityFeedbackComponent } from './site/admin/view-faclity-feedback/view-faclity-feedback.component';
import { ManageCollegeComponent } from './site/admin/manage-college/manage-college.component';
import { MyAccountComponent } from './site/admin/my-account/my-account.component';
import { ViewEnquiryComponent } from './site/admin/view-enquiry/view-enquiry.component';
import {MatTableModule} from '@angular/material/table';
import {MatPaginatorModule} from '@angular/material/paginator';
import { CollegeFormPopupComponent } from './popup/college/college-form-popup/college-form-popup.component';
import { CourseFormPopupComponent } from './popup/college/course-form-popup/course-form-popup.component';
import { ManageFaclityComponent } from './site/admin/manage-faclity/manage-faclity.component';
import { FacultiesFormPopupComponent } from './popup/faculties/faculties-form-popup/faculties-form-popup.component';
import {MatSelectModule} from '@angular/material/select';
import { ViewFacultiesFeedbackPopupComponent } from './popup/faculties/view-faculties-feedback-popup/view-faculties-feedback-popup.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { HeaderComponent } from './layout/header/header.component';

import { MarketingComponent } from './site/courses/marketing/marketing.component';
import { HumanResourceComponent } from './site/courses/human-resource/human-resource.component';
import { FinanceComponent } from './site/courses/finance/finance.component';
import { InternationalBusinessComponent } from './site/courses/international-business/international-business.component';
import { InformationSystemComponent } from './site/courses/information-system/information-system.component';
import { ViewCourseEnquiryComponent } from './site/admin/view-course-enquiry/view-course-enquiry.component';
import { ViewRecruiterConnectComponent } from './site/admin/view-recruiter-connect/view-recruiter-connect.component';
import { EnquryFormPopupComponent } from './popup/course/enqury-form-popup/enqury-form-popup.component';
import { ChartsModule } from 'ng2-charts';
import { PlacementRptComponent } from './site/placement-rpt/placement-rpt.component';
import { TestimonialComponent } from './site/testimonial/testimonial.component';
import { ViewOnlineAdmissionComponent } from './site/admin/view-online-admission/view-online-admission.component';
import { ViewOnlineAdmissionPopupComponent } from './popup/online-admission/view-online-admission-popup/view-online-admission-popup.component';
import { ViewCareerComponent } from './site/admin/view-career/view-career.component';
import { ViewCareerPopupComponent } from './popup/career/view-career-popup/view-career-popup.component';
import { GrievanceComponent } from './site/grievance/grievance.component';
import { StudentPortalComponent } from './site/student-portal/student-portal.component';
import { ViewGrievanceComponent } from './site/admin/view-grievance/view-grievance.component';
import { OperationManagementComponent } from './site/courses/operation-management/operation-management.component';
import { LandingComponent } from './site/landing/landing.component';
import { ManageLandingEnquiryComponent } from './site/admin/manage-landing-enquiry/manage-landing-enquiry.component';
import { WebinarListComponent } from './site/webinar/webinar-list/webinar-list.component';
import { KmatMockRegistrationPopupComponent } from './popup/kmat-mock-registration-popup/kmat-mock-registration-popup.component';
import { ViewKmatMockRegistrationComponent } from './site/admin/view-kmat-mock-registration/view-kmat-mock-registration.component';
import { WebinarFormPopupComponent } from './popup/webinar/webinar-form-popup/webinar-form-popup.component';
import { ViewWebinarRegisterPopupComponent } from './popup/webinar/view-webinar-register-popup/view-webinar-register-popup.component';
import { WebinarRegisterFormPopupComponent } from './popup/webinar/webinar-register-form-popup/webinar-register-form-popup.component';
import { FacultyProfileComponent } from './site/faculty-profile/faculty-profile.component';
import { ManagNotificationComponent } from './site/admin/manag-notification/manag-notification.component';
import { NotificationFormPopupComponent } from './popup/notifiction/notification-form-popup/notification-form-popup.component';
import { ViewJobFairComponent } from './site/admin/view-job-fair/view-job-fair.component';
import { ScholarshipHomepopupComponent } from './popup/scholarship-homepopup/scholarship-homepopup.component';
import { GalleryComponent } from './site/gallery/gallery.component';
import { ViewEventRegisterPopupComponent } from './popup/events/view-event-register-popup/view-event-register-popup.component';
import { EventRegisterFormPopupComponent } from './popup/events/event-register-form-popup/event-register-form-popup.component';
import { ViewDetailsComponent } from './site/admin/view-online-admission/view-details/view-details.component';


@NgModule({
  declarations: [
    AppComponent,
    SiteLayoutComponent,
    HomeComponent,
    CourseComponent,
    AboutComponent,
    LibraryComponent,
    PlacementsComponent,
    ActivitiesComponent,
    AntiRaggingComponent,
    JobfitAnalysisComponent,
    CareerComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    ContactComponent,
    OnlineAdmissionComponent,
    NewsEventsComponent,
    StudentsFeedbackComponent,
    WebinarComponent,
    NewsDetailsComponent,
    ImageCropPopupComponent,
    ProceedWarningComponent,
    LoginPopupComponent,
    CarrerWithusComponent,
    HireJbComponent,
    LoginComponent,
    MyAccountComponent,
    AdminLayoutComponent,
    ViewFaclityFeedbackComponent,
    ManageCollegeComponent,
    ViewEnquiryComponent,
    CollegeFormPopupComponent,
    CourseFormPopupComponent,
    ManageFaclityComponent,
    FacultiesFormPopupComponent,
    ViewFacultiesFeedbackPopupComponent,
    HeaderComponent,
    PlacementRptComponent,
    MarketingComponent,
    HumanResourceComponent,
    FinanceComponent,
    InternationalBusinessComponent,
    InformationSystemComponent,
    ViewCourseEnquiryComponent,
    ViewRecruiterConnectComponent,
    EnquryFormPopupComponent,
    TestimonialComponent,
    ViewOnlineAdmissionComponent,
    ViewOnlineAdmissionPopupComponent,
    ViewCareerComponent,
    ViewCareerPopupComponent,
    GrievanceComponent,
    StudentPortalComponent,
    ViewGrievanceComponent,
    OperationManagementComponent,
    LandingComponent,
    ManageLandingEnquiryComponent,
    WebinarListComponent,
    KmatMockRegistrationPopupComponent,
    ViewKmatMockRegistrationComponent,
    WebinarFormPopupComponent,
    ViewWebinarRegisterPopupComponent,
    WebinarRegisterFormPopupComponent,
    FacultyProfileComponent,
    ManagNotificationComponent,
    NotificationFormPopupComponent,
    ViewJobFairComponent,
    ScholarshipHomepopupComponent,
    GalleryComponent,
    ViewEventRegisterPopupComponent,
    EventRegisterFormPopupComponent,
    ViewDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ImageCropperModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSnackBarModule,
    MatDialogModule,
    MatRadioModule,
    MatButtonModule,
    MatFormFieldModule,
    AngularEditorModule,
    MatInputModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatProgressBarModule,
    ChartsModule
  ],
  exports: [MatFormFieldModule, MatInputModule],
  entryComponents: [
    LoginPopupComponent,
    ProceedWarningComponent,
    ImageCropPopupComponent,
    CollegeFormPopupComponent,
    EnquryFormPopupComponent,
    ViewCareerPopupComponent,
    ViewOnlineAdmissionPopupComponent,
    WebinarFormPopupComponent,
    ViewWebinarRegisterPopupComponent,
    NotificationFormPopupComponent,
    EventRegisterFormPopupComponent,
    ViewEventRegisterPopupComponent,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
