<div class="main-head">
    <div class="head-line">
        <h1>Landing Enquires</h1>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="manage-tbl">
                <p class="text-center" *ngIf="!AllEnquiries.length">No data found</p>
                <table mat-table [dataSource]="AllEnquiries" class="mat-elevation-z8" width="100%"
                    *ngIf="AllEnquiries.length">

                    <ng-container matColumnDef="SortId">
                        <th mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element"> - </td>
                    </ng-container>
                    <ng-container matColumnDef="Name">
                        <th mat-header-cell *matHeaderCellDef> Name </th>
                        <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Email">
                        <th mat-header-cell *matHeaderCellDef> Email Id </th>
                        <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Mobile">
                        <th mat-header-cell *matHeaderCellDef> Contact No. </th>
                        <td mat-cell *matCellDef="let element"> {{element.Mobile}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Program">
                        <th mat-header-cell *matHeaderCellDef> Program </th>
                        <td mat-cell *matCellDef="let element"> {{element.Program}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Message">
                        <th mat-header-cell *matHeaderCellDef> Message </th>
                        <td mat-cell *matCellDef="let element">
                            <p [innerHTML]="element.Message">
                            </p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="CreatedDate">
                        <th mat-header-cell *matHeaderCellDef> Messaged On </th>
                        <td mat-cell *matCellDef="let element"> {{element.CreatedDate}}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="EnquiriesColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: EnquiriesColumns;" (click)="getRecord(row)"
                        class="cursorView"></tr>

                </table>
            </div>
        </div>
    </div>
</div>