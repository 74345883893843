<div class="col-md-12" mat-dialog-title>
    <div class="main-head">
        <h1>Online Admission
            <span mat-button [mat-dialog-close]="false" class="pull-right close-popup" style="float: right;"><i
                    class="fa fa-times"></i></span>
        </h1>
        <div class="print-form">
            <button class="btn btn-primary pull-right" (click)="htmltoPDF()">Print</button>
        </div>
    </div>
</div>
<mat-dialog-content>
    <div class="container-fluid" *ngIf="OnlineAdmission" id="parentdiv">
        <div class="admission-result">
            <div class="row">
                <div class="col-md-6">
                    <img src="assets/images/arts_logo.png" style="Width:200px" />
                </div>
                <div class="col-md-6 text-right">
                    <p style="margin: 0;text-align: right;">Jai Bharath College, JB Campus</p>
                    <p style="margin: 0;text-align: right;">Arackappady, Vengola P.O</p>
                    <p style="margin: 0;text-align: right;">Perumbavoor , Cochin</p>
                    <p style="margin: 0;text-align: right;">Kerala 683 556 India</p>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-12">
                    <div class="form-head">
                        <h1><b>Online Admission MBA</b></h1>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>Name of the course applied for</label>

                </div>
                <div class="col-md-6">

                    <p>: {{OnlineAdmission.CourseApplied}}</p>
                </div>
                <div class="col-md-6">
                    <label>Name of the candidate</label>
                </div>
                <div class="col-md-6">
                    <p>: {{getName(OnlineAdmission.CandidateName)}}</p>

                </div>
                <div class="col-md-6">
                    <label>Name of the qualifying exam passed</label>
                </div>
                <div class="col-md-6">
                    <p>: {{OnlineAdmission.ExamQualification}}</p>
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col-md-12 admission-sub-head">
                    <h3>
                        <b>A - Details of admission Test (KMAT/MCAT/CMAT)</b>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-bordered">
                        <tr>
                            <th>Name of the test</th>
                            <th>Date of the test</th>
                            <th>Test Register Number</th>
                            <th>Marks Obtained</th>
                            <th>Rank If Any</th>
                        </tr>
                        <tr>
                            <td>{{OnlineAdmission.TestName}}</td>
                            <td>{{OnlineAdmission.TestDate}}</td>
                            <td>{{OnlineAdmission.RegisterNumber}}</td>
                            <td>{{OnlineAdmission.TestMarks}}</td>
                            <td>{{OnlineAdmission.TestRank}}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 admission-sub-head">
                    <h3>
                        <b>B - Name of qualifying examination [BA/B.Sc/B.Com/B.Tech etc] Appeared/Passed</b>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>a) Name of qualifying examination</label>
                </div>
                <div class="col-md-6">
                    <p>{{OnlineAdmission.QualificationExam}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>b) Name of University or Board</label>
                </div>
                <div class="col-md-6">
                    <p>{{OnlineAdmission.Board}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>c) Register Bo./Month/Year</label>
                </div>
                <div class="col-md-6">
                    <p>{{OnlineAdmission.RegisterTime}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>d) Name of Institution in which studied</label>
                </div>
                <div class="col-md-6">
                    <p>{{OnlineAdmission.InstitutionName}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>e) Number of chances availed for the qualifying examinations</label>
                </div>
                <div class="col-md-6">
                    <p>{{OnlineAdmission.NoChances}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 admission-sub-head">
                    <h3>
                        <b>C - Details of Graduation</b>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-bordered">
                        <tr>
                            <th>Name of Exams</th>
                            <th>Register Number</th>
                            <th>Marks Obtained</th>
                            <th>Maximum Marks</th>
                            <th>% of Marks</th>
                        </tr>
                        <tr>
                            <td>Sem I</td>
                            <td>{{OnlineAdmission.Part1RegisterNumber}}</td>
                            <td>{{OnlineAdmission.Part1Marks}}</td>
                            <td>{{OnlineAdmission.Part1maxMarks}}</td>
                            <td>{{OnlineAdmission.Part1Persentage}}</td>
                        </tr>
                        <tr>
                            <td>Sem II</td>
                            <td>{{OnlineAdmission.Part2RegisterNumber}}</td>
                            <td>{{OnlineAdmission.Part2Marks}}</td>
                            <td>{{OnlineAdmission.Part2maxMarks}}</td>
                            <td>{{OnlineAdmission.Part2Persentage}}</td>
                        </tr>
                        <tr>
                            <td>Sem III</td>
                            <td>{{OnlineAdmission.Part3RegisterNumber}}</td>
                            <td>{{OnlineAdmission.Part3Marks}}</td>
                            <td>{{OnlineAdmission.Part3maxMarks}}</td>
                            <td>{{OnlineAdmission.Part3Persentage}}</td>
                        </tr>
                        <tr>
                            <td>Sem IV</td>
                            <td>{{OnlineAdmission.Part4RegisterNumber}}</td>
                            <td>{{OnlineAdmission.Part4Marks}}</td>
                            <td>{{OnlineAdmission.Part4maxMarks}}</td>
                            <td>{{OnlineAdmission.Part4Persentage}}</td>
                        </tr>
                        <tr>
                            <td>Sem V</td>
                            <td>{{OnlineAdmission.Part5RegisterNumber}}</td>
                            <td>{{OnlineAdmission.Part5Marks}}</td>
                            <td>{{OnlineAdmission.Part5maxMarks}}</td>
                            <td>{{OnlineAdmission.Part5Persentage}}</td>
                        </tr>
                        <tr>
                            <td>Sem VI</td>
                            <td>{{OnlineAdmission.Part6RegisterNumber}}</td>
                            <td>{{OnlineAdmission.Part6Marks}}</td>
                            <td>{{OnlineAdmission.Part6maxMarks}}</td>
                            <td>{{OnlineAdmission.Part6Persentage}}</td>
                        </tr>
                        <tr>
                            <td>Sem VII</td>
                            <td>{{OnlineAdmission.Part7RegisterNumber}}</td>
                            <td>{{OnlineAdmission.Part7Marks}}</td>
                            <td>{{OnlineAdmission.Part7maxMarks}}</td>
                            <td>{{OnlineAdmission.Part7Persentage}}</td>
                        </tr>
                        <tr>
                            <td>Sem VIII</td>
                            <td>{{OnlineAdmission.Part8RegisterNumber}}</td>
                            <td>{{OnlineAdmission.Part8Marks}}</td>
                            <td>{{OnlineAdmission.Part8maxMarks}}</td>
                            <td>{{OnlineAdmission.Part8Persentage}}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <!-- <div class="row">
                <div class="col-md-12 admission-sub-head">
                    <h3>
                        <b>C - Semester System</b>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <table class="table table-bordered">
                        <tr>
                            <th>Name of Exams</th>
                            <th>University Board</th>
                            <th>Marks Obtained</th>
                            <th>Max Marks</th>
                            <th>% of Marks</th>
                        </tr>
                        <tr>
                            <td>{{OnlineAdmission.ExamName}}</td>
                            <td>{{OnlineAdmission.UniversityName}}</td>
                            <td>{{OnlineAdmission.SemesterMarks}}</td>
                            <td>{{OnlineAdmission.SemestermaxMarks}}</td>
                            <td>{{OnlineAdmission.SemesterPersentage}}</td>
                        </tr>
                    </table>
                </div>
            </div> -->
            
            <div class="row">
                <div class="col-md-12 admission-sub-head">
                    <h3>
                        <b>D - Personal Details</b>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <b>a) Address to which communication are be sent:</b>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-4">
                            <label>Door No / House Name</label>
                        </div>
                        <div class="col-md-8">
                            <p>: {{OnlineAdmission.Address}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-4">
                            <label> Area/Street/Road</label>
                        </div>
                        <div class="col-md-8">
                            <p>: {{OnlineAdmission.StreetName}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-4">
                            <label>Post Office</label>
                        </div>
                        <div class="col-md-8">
                            <p>: {{OnlineAdmission.PostOffice}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-4">
                            <label>District & State</label>
                        </div>
                        <div class="col-md-8">
                            <p>: {{OnlineAdmission.DistrictName}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-4">
                            <label>Phone No. with area code</label>
                        </div>
                        <div class="col-md-8">
                            <p>: {{OnlineAdmission.PhoneNo}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-4">
                            <label>Mobile number</label>
                        </div>
                        <div class="col-md-8">
                            <p>: {{OnlineAdmission.MobileNo}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-4">
                            <label>Email</label>
                        </div>
                        <div class="col-md-8">
                            <p>: {{OnlineAdmission.Email}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-4">
                            <label>Pin</label>
                        </div>
                        <div class="col-md-8">
                            <p>: {{OnlineAdmission.PinNo}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <br/><br/><br/><br/>
           <!--  <div class="row">
                <div class="col-md-12">
                    <b>b) Permenent address of the candidate if different from above</b>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Door No / House Name</label>
                        </div>
                        <div class="col-md-6">
                            <p>: {{OnlineAdmission.PermenentAddress}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <label> Area/Street/Road</label>
                        </div>
                        <div class="col-md-6">
                            <p>: {{OnlineAdmission.PermenentStreetName}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Post Office</label>
                        </div>
                        <div class="col-md-6">
                            <p>: {{OnlineAdmission.PermenentPostOffice}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <label>District & State</label>
                        </div>
                        <div class="col-md-6">
                            <p>: {{OnlineAdmission.PermenentDistrictName}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Phone No. with area code</label>
                        </div>
                        <div class="col-md-6">
                            <p>: {{OnlineAdmission.PermenentPhoneNo}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Mobile number</label>
                        </div>
                        <div class="col-md-6">
                            <p>: {{OnlineAdmission.PermenentMobileNo}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Pin</label>
                        </div>
                        <div class="col-md-6">
                            <p>: {{OnlineAdmission.PermenentPinNo}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <label>Email</label>
                        </div>
                        <div class="col-md-6">
                            <p>: {{OnlineAdmission.PermenentEmail}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <hr />-->
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-4">
                            <label>b) Nationality:</label>
                        </div>
                        <div class="col-md-8">
                            <p>: {{OnlineAdmission.Nationality}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-4">
                            <label>c) Gender</label><br>
                        </div>
                        <div class="col-md-8">
                            <p>: {{OnlineAdmission.Gender}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-4">
                            <label>d) Religion:</label>
                        </div>
                        <div class="col-md-8">
                            <p>: {{OnlineAdmission.Religion}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">

                    <div class="row">
                        <div class="col-md-4">
                            <label>e) Caste:</label>
                        </div>
                        <div class="col-md-8">
                            <p>: {{OnlineAdmission.Caste}}</p>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-md-6">

                    <div class="row">
                        <div class="col-md-4">
                            <label>f) Blood Group:</label>
                        </div>
                        <div class="col-md-8">
                            <p>: {{OnlineAdmission.BloodGroup}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-4">
                            <label>g) Date of Birth:</label>
                        </div>
                        <div class="col-md-8">
                            <p>: {{OnlineAdmission.DOB}}</p>
                        </div>
                    </div>
                </div>
            </div>
<!--
            <div class="row">
                <div class="col-md-12">
                    <label>i) Parent/Guardian </label>
                </div>
            </div>
            <hr />
            <div class="row">
                <div class="col-md-6">
                    <label>&nbsp;&nbsp;&nbsp;1) Name </label>
                </div>
                <div class="col-md-6">
                    <p>: {{OnlineAdmission.GuardianName}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>&nbsp;&nbsp;&nbsp;2) Address </label>
                </div>
                <div class="col-md-6">
                    <p>{{OnlineAdmission.GuardianAddress}}</p>
                    <p>Contact Details : {{OnlineAdmission.ContactNumber}}</p>
                    <p>{{OnlineAdmission.GuardianEmail}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>&nbsp;&nbsp;&nbsp;3) Occupation </label>
                </div>
                <div class="col-md-6">
                    <p>: {{OnlineAdmission.GuardianOccupation}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>&nbsp;&nbsp;&nbsp;4) Annual income</label>
                </div>
                <div class="col-md-6">
                    <p>: {{OnlineAdmission.GuardianIncome}}</p>
                </div>
            </div>


-->

            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-4">
                            <label>h) Are you native of Kerala?</label>
                        </div>
                        <div class="col-md-8">
                            <p>{{OnlineAdmission.Native}}</p>
                        </div>
                    </div>
                </div>
            </div> 


            <!-- <div class="row">
                <div class="col-md-12 admission-sub-head">
                    <h3>
                        <b>C - Other Details</b>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>a) Details of exta carriculam activities:</label>
                </div>
                <div class="col-md-6">
                    <p>{{OnlineAdmission.Activities}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>b) Achivements attained:-group</label>
                </div>
                <div class="col-md-6">
                    <p>{{OnlineAdmission.Achivements}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>c) Details of scholarships/ award obtained :</label>
                </div>
                <div class="col-md-6">
                    <p>{{OnlineAdmission.Scholarships}}</p>
                </div>
            </div> -->
            <!-- <div class="row">
                <div class="col-md-12 admission-sub-head">
                    <h3>
                        <b>E - Employment Details</b>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>Are you an employee? Details of your employment?</label>
                </div>
                <div class="col-md-6">
                    <p>{{OnlineAdmission.EmployeeDetails}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 admission-sub-head">
                    <h3>
                        <b>F - Special consideration</b>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>Are you an employee? Details of your employment?</label>
                </div>
                <div class="col-md-6">
                    <p>{{OnlineAdmission.SpecialFeature}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 admission-sub-head">
                    <h3>
                        <b>G - Reservation particulars</b>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>Are you eligible for reseravtion under any category</label>
                </div>
                <div class="col-md-6">
                    <p>{{OnlineAdmission.Reseravtion}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>If yes,specify Community/Category[SEBC/SC/ST/any Other(specify)]</label>
                </div>
                <div class="col-md-6">
                    <p>{{OnlineAdmission.Community}}</p>
                </div>
            </div> -->
            <div class="row">
                <div class="col-md-12 admission-sub-head">
                    <h3>
                        <b>E - Details of application fee remitted</b>
                    </h3>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>a) Receipt No (if paid cash)</label>
                </div>
                <div class="col-md-6">
                    <p>{{OnlineAdmission.ReceiptNo}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>b) Receipt Date(if paid cash)</label>
                </div>
                <div class="col-md-6">
                    <p>{{OnlineAdmission.ReceiptDate}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label>c) DD Particulars(If the payment mode is DD)</label>
                </div>
                <div class="col-md-6">
                    <p>DD Number: {{OnlineAdmission.DDNo}}</p>
                    <p>Date: {{OnlineAdmission.Date}}</p>
                    <p>Amount: {{OnlineAdmission.Amount}}</p>
                    <p>Name of the branch: {{OnlineAdmission.BranchName}}</p>
                    <p>Name of the bank: {{OnlineAdmission.BankName}}</p>
                </div>
            </div>
        </div>
    </div>
</mat-dialog-content>