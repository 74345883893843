import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FunctionService } from '../../api/services/function.service';
import { LoginPopupComponent } from '../../popup/user/login-popup/login-popup.component';
// for SEO
import {  Meta } from '@angular/platform-browser';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
   // for SEO
   title = 'Top MBA College in Kerala |JAI BHARATH SCHOOL OF MANAGEMENT STUDIES';

  constructor(
    // for SEO
    private titleService: Title,
    private metaTagService: Meta,

    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public functionService: FunctionService,
    // private titleService:Title
     ) { 
    this.titleService.setTitle("Advent Solutions | Login"); }

  ngOnInit() {
      // for SEO
      this.titleService.setTitle(this.title);
      this.metaTagService.addTags([
        { name: 'keywords', content: 'top mba college in kerala , top colleges in kerala , top 10 mba colleges in kerala , top mba colleges in kerala accepting kmat score , which mba college gives best placement' },
        { name: 'description', content: 'JAI BHARATH SCHOOL OF MANAGEMENT STUDIES | Become a Global Executive with Internationally Compatible Curriculum. Apply for Admission. Top MBA College in Kerala' },
        { name: 'robots', content: 'index, follow' },      
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },      
        { charset: 'UTF-8' }
      ]);
    let dialogRef: MatDialogRef<LoginPopupComponent> = this.matDialog.open(LoginPopupComponent, {
      width: '400px',
      height: 'auto',
      maxHeight: 'auto',
      disableClose: true,
      panelClass: 'custom-dialog-container',
      hasBackdrop: true,
    });
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        
        // if(!sessionStorage.getItem('sessionEmailId')){
          this.functionService.showProcessMessage("Logged In Successfully", true);
          this.functionService.datalistService.sessionData=true;
        // };
        
        this.router.navigate(['/home']);
      }else{
        this.router.navigate(['/home']);
      }
    });
  }

}
