import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proceed-warning',
  templateUrl: './proceed-warning.component.html',
  styleUrls: ['./proceed-warning.component.css']
})
export class ProceedWarningComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
