<div class="col-md-12" mat-dialog-title>
    <div class="main-head">
        <h1>KMAT Coaching Form
            <span style="
            float: right;
        " mat-button [mat-dialog-close]="false" class="pull-right close-popup"><i class="fa fa-times"></i></span>
        </h1>
    </div>
</div>
<!-- <mat-dialog-content> -->
<div class="container">
  <div class="enquir-form">
    <div class="row">
      <div class="col-md-12">
        <div class="form-img">
          <img src="assets/images/reg-form.png" />
        </div>
      </div>

    </div>

    <form [formGroup]="RegistrationForm" (ngSubmit)="onSubmit()" autocomplete="off">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <input type="text" class="form-control control-box" formControlName="Name"
            [ngClass]="{ 'is-invalid': submitted && formControl.Name.errors }" placeholder="Name" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <input type="email" class="form-control control-box" formControlName="Email"
            [ngClass]="{ 'is-invalid': submitted && formControl.Email.errors }" placeholder="Email" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <input type="number" class="form-control control-box" formControlName="Phone"
            [ngClass]="{ 'is-invalid': submitted && formControl.Phone.errors }" placeholder="Phone" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <input type="text" class="form-control control-box msg-box" formControlName="Address"
            [ngClass]="{ 'is-invalid': submitted && formControl.Address.errors }" placeholder="Address" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <input type="text" class="form-control control-box msg-box" formControlName="CollegeName"
            [ngClass]="{ 'is-invalid': submitted && formControl.CollegeName.errors }"
              placeholder="College Name & Address" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <input type="text" class="form-control control-box" formControlName="CourseName"
            [ngClass]="{ 'is-invalid': submitted && formControl.CourseName.errors }" placeholder="Course Name" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <input type="text" class="form-control control-box" formControlName="CourseYear"
            [ngClass]="{ 'is-invalid': submitted && formControl.CourseYear.errors }" placeholder="Course Year" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="submit-btn">
            <button class="btn btn-primary">Register</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- </mat-dialog-content> -->