import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatabaseOperationService } from '../../api/database/database-operation.service';
import { DatalistService } from '../../api/services/datalist.service';
import { FunctionService } from '../../api/services/function.service';
// for SEO
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-grievance',
  templateUrl: './grievance.component.html',
  styleUrls: ['./grievance.component.css']
})
export class GrievanceComponent implements OnInit {
    // for SEO
    title = 'Top MBA College in Kerala |JAI BHARATH SCHOOL OF MANAGEMENT STUDIES';

  GrievanceForm: any;
  submitted: boolean;

  constructor(
     // for SEO
     private titleService: Title,
     private metaTagService: Meta,

    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,) {
    // this.datalistService.loaderActive = true;
  }

  ngOnInit(): void {
    // for SEO
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'keywords', content: 'top mba college in kerala , top colleges in kerala , top 10 mba colleges in kerala , top mba colleges in kerala accepting kmat score , which mba college gives best placement' },
      { name: 'description', content: 'JAI BHARATH SCHOOL OF MANAGEMENT STUDIES | Become a Global Executive with Internationally Compatible Curriculum. Apply for Admission. Top MBA College in Kerala' },
      { name: 'robots', content: 'index, follow' },      
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },      
      { charset: 'UTF-8' }
    ]);
    
    this.GrievanceForm = this.formBuilder.group({
      NameOfApplicant: ['', Validators.required],
      Class: ['', Validators.required],
      Semester: ['', Validators.required],
      Department: ['', Validators.required],
      RollNo: ['', Validators.required],
      TypeOfGrievance: ['', Validators.required],
      OccurredDate: ['', Validators.required],
      ComplaintDescription: [''],
      RootCause: [''],
      
    });
    // this.datalistService.loaderActive = false;
  }
  get formControl() { return this.GrievanceForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.GrievanceForm.invalid) {
      this.functionService.showProcessMessage("Provide vaild information", false);
      return;
    }

    this.save();
  }
  save() {
    this.datalistService.loaderActive = true;
    this.databaseOperationService.saveGrievance(this.GrievanceForm.value).subscribe(data => {
      this.datalistService.loaderActive = false;
      if (data) {
          this.functionService.showProcessMessage("Grievance Submitted Successfully", true);
      } else {
        this.functionService.showProcessMessage("An Error Occurred", false);
      }
    })
  }

}
// NameOfApplicant Class Semester Department RollNo TypeOfGrievance OccurredDate ComplaintDescription RootCause