<div class="main-head">
    <div class="head-line">
        <h1>Grievance</h1>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="manage-tbl">
                <p class="text-center" *ngIf="!AllGrievance.length">No data found</p>
                <table mat-table [dataSource]="AllGrievance" class="mat-elevation-z8" width="100%"
                    *ngIf="AllGrievance.length">

                    <ng-container matColumnDef="SortId">
                        <th mat-header-cell *matHeaderCellDef> # </th>
                        <td mat-cell *matCellDef="let element"> - </td>
                    </ng-container>        
                    <ng-container matColumnDef="NameOfApplicant">
                        <th mat-header-cell *matHeaderCellDef> Name Of Applicant </th>
                        <td mat-cell *matCellDef="let element"> {{element.NameOfApplicant}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Class">
                        <th mat-header-cell *matHeaderCellDef> Class </th>
                        <td mat-cell *matCellDef="let element"> {{element.Class}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Semester">
                        <th mat-header-cell *matHeaderCellDef> Semester </th>
                        <td mat-cell *matCellDef="let element"> {{element.Semester}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Department">
                        <th mat-header-cell *matHeaderCellDef> Department </th>
                        <td mat-cell *matCellDef="let element"> {{element.Department}} </td>
                    </ng-container>
                    <ng-container matColumnDef="RollNo">
                        <th mat-header-cell *matHeaderCellDef> Roll No </th>
                        <td mat-cell *matCellDef="let element"> {{element.RollNo}} </td>
                    </ng-container>
                    <ng-container matColumnDef="TypeOfGrievance">
                        <th mat-header-cell *matHeaderCellDef> Type Of Grievance </th>
                        <td mat-cell *matCellDef="let element"> {{element.TypeOfGrievance}} </td>
                    </ng-container>
                    <ng-container matColumnDef="OccurredDate">
                        <th mat-header-cell *matHeaderCellDef> Occurred Date </th>
                        <td mat-cell *matCellDef="let element"> {{element.OccurredDate | date :'dd MMM yyyy'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="ComplaintDescription">
                        <th mat-header-cell *matHeaderCellDef> Complaint Description </th>
                        <td mat-cell *matCellDef="let element"> {{element.ComplaintDescription}} </td>
                    </ng-container>
                    <ng-container matColumnDef="RootCause">
                        <th mat-header-cell *matHeaderCellDef> Root Cause </th>
                        <td mat-cell *matCellDef="let element"> {{element.RootCause}} </td>
                    </ng-container>
<!-- 
                    <ng-container matColumnDef="CreatedDate">
                        <th mat-header-cell *matHeaderCellDef> Posted On </th>
                        <td mat-cell *matCellDef="let element"> {{element.CreatedDate | date :'dd MMM yyyy @ h:mm:ss a'}}
                        </td>
                    </ng-container> -->
                    <!-- <ng-container matColumnDef="Operation">
                        <th mat-header-cell *matHeaderCellDef> Operation</th>
                        <td mat-cell *matCellDef="let element" width="50px">
                            <a (click)="viewCareer(element.CareerId)" class="sm" mat-raised-button
                                color="primary">
                                <i class="fa fa-eye" aria-hidden="true"></i>
                            </a>
                        </td>
                    </ng-container> -->

                    <tr mat-header-row *matHeaderRowDef="EnquiriesColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: EnquiriesColumns;"
                        class="cursorView"></tr>

                </table>
            </div>
        </div>
    </div>
</div>