import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';

@Component({
  selector: 'app-view-job-fair',
  templateUrl: './view-job-fair.component.html',
  styleUrls: ['./view-job-fair.component.css']
})
export class ViewJobFairComponent implements OnInit {
  AllEnquiries=[];

  EnquiriesColumns: string[] = ['SortId', 'Name', 'Email','Phone','DOB','Qualification','Specilization','Experience', 'EnquiryOn'];
  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,) {
    this.datalistService.loaderActive = true;}

  ngOnInit(): void {
    this.databaseOperationService.getAllJobFairEnquiry().subscribe((data: any) => {
      this.AllEnquiries = data;
      this.datalistService.loaderActive = false;
    });
  }

  htmltoPDF() {
    // parentdiv is the html element which has to be converted to PDF
    html2canvas(document.querySelector("#parentdiv")).then(canvas => {

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jsPDF('p', 'mm', 'a4');

      var position = 0;
      var imgWidth = pdf.internal.pageSize.getWidth();
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      let offsetTop: number = 10;
      if (offsetTop + imgHeight > pdf.internal.pageSize.getHeight()) {
        offsetTop = 10;
        position = offsetTop;
      }

      pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        console.log(position);
        pdf.addPage();
        pdf.addImage(contentDataURL, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      let Datenow = new Date();
      let fielname = 'Job Fair Enquires_' + Datenow + '.pdf';
      pdf.save(fielname);

    });

    // let printContents = document.getElementById('parentdiv').innerHTML;
    // let originalContents = document.body.innerHTML;

    // document.body.innerHTML = printContents;

    // window.print();

    // document.body.innerHTML = originalContents;

  }
}