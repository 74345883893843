<!-- ##### Upcoming Events Area Start ##### -->
<section class="upcoming-events-area section-padding-0-100">
    <!-- Upcoming Events Heading Area -->

    <!-- Upcoming Events Slide -->
    <div class="upcoming-events-slides-area">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="row" *ngIf="datalistService.sessionData">
                        <div class="col-md-12 test-right">
                            <br />
                            <button mat-raised-button class="nav-link active" (click)="addWebinar()"><i
                                    class="fa fa-plus" aria-hidden="true"></i>
                                Add
                                new</button>
                            <br />
                        </div>
                    </div>
                    <div class="upcoming-slides owl-carousel">

                        <div class="single-slide">
                            <!-- Single Upcoming Events Area -->






                            <div class="single-upcoming-events-area d-flex flex-wrap align-items-center"
                                *ngFor="let allWebinar of AllWebinar">
                                <!--register-->
                                <div class="wrap mob-view"  *ngIf="isActive(allWebinar.Date)">
                                    <button class="button" (click)="registerWebinar(allWebinar.WebinarId)">Register Now</button>
                                  </div>
                                <!-- Thumbnail -->
                                <div class="upcoming-events-thumbnail">
                                    <img src="{{datalistService.IMGAE_PATH+'/webinar'+allWebinar.Poster}}">

                                </div>
                                <!-- Content -->
                                <div class="upcoming-events-content d-flex flex-wrap align-items-center">
                                    <div class="events-text">

                                        <h4> {{allWebinar.Topic}}</h4>
                                        <div class="events-meta">
                                            <a><i class="fa fa-calendar" aria-hidden="true"></i> {{allWebinar.Date| date:'dd-MM-yyyy'}}</a>
                                            <a><i class="fa fa-clock" aria-hidden="true"></i> {{functionService.tConv24(allWebinar.Time)}}</a>
                                            <a><i class="fa fa-map-marker" aria-hidden="true"></i> Online</a>
                                        </div>
                                        <p class="content-limit" [ngClass]="{'content-full':more}" [innerHTML]="functionService.transform(allWebinar.Details) | slice:0:700"></p>
                                        <a [routerLink]="['/webinar-details',allWebinar.WebinarId]" >Read More <i
                                                class="fa fa-angle-double-right"></i></a>
                                        <div class="row" *ngIf="datalistService.sessionData">
                                            <div class="col-md-12 test-right">
                                                <button class="edit-content" (click)="editWebinar(allWebinar.WebinarId)" mat-raised-button
                                                    color="primary"><i class="fa fa-pencil-square-o"
                                                        aria-hidden="true"></i>
                                                    Edit</button>&nbsp;&nbsp;&nbsp;
                                                <button mat-raised-button color="warn"  class="nav-link active"
                                                    (click)="deleteWebinar(allWebinar)"><i
                                                        class="fa fa-trash"
                                                        aria-hidden="true"></i></button>&nbsp;&nbsp;&nbsp;
                                                <button mat-raised-button color="primary" class="nav-link active"
                                                    (click)="viewWebinarRegister(allWebinar.WebinarId)">
                                                View Registeration</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="find-out-more-btn" *ngIf="isActive(allWebinar.Date)">

                                        <!-- <a [routerLink]="['/webinar-details',allWebinar.WebinarId]"
                                            class="btn crose-btn btn-2" >Register</a> -->
                                        <a class="btn crose-btn btn-2" (click)="registerWebinar(allWebinar.WebinarId)">Register</a>
                                    </div>
                                </div>
                            </div>







                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- ##### Upcoming Events Area End ##### -->