import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatabaseOperationService } from '../../api/database/database-operation.service';
import { DatalistService } from '../../api/services/datalist.service';
import { FunctionService } from '../../api/services/function.service';
// for SEO
import {  Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
   // for SEO
   title = 'Top MBA College in Kerala |JAI BHARATH SCHOOL OF MANAGEMENT STUDIES';

  formContactDetails: any;
  formInvalid: boolean;
  submitted: boolean;

  constructor(
     // for SEO
     private titleService: Title,
     private metaTagService: Meta,

    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,
    // private titleService: Title
    ) {
    // this.datalistService.loaderActive = true;
  }

  ngOnInit() {
     // for SEO
     this.titleService.setTitle(this.title);
     this.metaTagService.addTags([
       { name: 'keywords', content: 'top mba college in kerala , top colleges in kerala , top 10 mba colleges in kerala , top mba colleges in kerala accepting kmat score , which mba college gives best placement' },
       { name: 'description', content: 'JAI BHARATH SCHOOL OF MANAGEMENT STUDIES | Become a Global Executive with Internationally Compatible Curriculum. Apply for Admission. Top MBA College in Kerala' },
       { name: 'robots', content: 'index, follow' },      
       { name: 'viewport', content: 'width=device-width, initial-scale=1' },      
       { charset: 'UTF-8' }
     ]);
    this.formContactDetails = this.formBuilder.group({
      Name: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      Phone: ['', Validators.required],
      Message: ['', Validators.required]
    });
  }
  get formControl() { return this.formContactDetails.controls; }
  onSubmit() {
    this.datalistService.loaderActive = true;
    this.formInvalid = false;
    this.submitted = false;
    if (this.formContactDetails.valid) {
      // this.databaseOperationService.sendContactForm(this.formContactDetails.value).subscribe((data: any) => {
      // });
      this.databaseOperationService.saveContactForm(this.formContactDetails.value).subscribe((data: any) => {
        this.functionService.showProcessMessage("Message sent successfully", true);
        this.formContactDetails = this.formBuilder.group({
          Name: ['', Validators.required],
          Email: ['', [Validators.required, Validators.email]],
          Phone: ['', Validators.required],
          Message: ['', Validators.required]
        });
        this.datalistService.loaderActive = false;
      })
    } else {
      this.submitted = true;
      this.functionService.showProcessMessage("Please provide valid information", false);
      this.datalistService.loaderActive = false;
    }
  }

}
