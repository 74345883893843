import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';
import { ViewOnlineAdmissionPopupComponent } from '../../../popup/online-admission/view-online-admission-popup/view-online-admission-popup.component';

@Component({
  selector: 'app-view-online-admission',
  templateUrl: './view-online-admission.component.html',
  styleUrls: ['./view-online-admission.component.css']
})
export class ViewOnlineAdmissionComponent implements OnInit {
  AllOnlineAdmission=[];

  EnquiriesColumns: string[] = ['SortId', 'CandidateName', 'Email','Phone', 'PostedDate','Operation'];
  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,) {
    this.datalistService.loaderActive = true;}

  ngOnInit(): void {
    this.databaseOperationService.getAllOnlineAdmission().subscribe((data: any) => {
      this.AllOnlineAdmission = data;
      this.datalistService.loaderActive = false;
    });
  }
  viewOnlineAdmission(OnlineAdmissionId){

    let dialogRef: MatDialogRef<ViewOnlineAdmissionPopupComponent> = this.matDialog.open(ViewOnlineAdmissionPopupComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.OnlineAdmissionId = OnlineAdmissionId;
    return dialogRef.afterClosed().subscribe(result => {
    });
  }
}
