import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormArray, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatabaseOperationService } from '../../api/database/database-operation.service';
import { DatalistService } from '../../api/services/datalist.service';
import { FunctionService } from '../../api/services/function.service';

// for SEO
import {  Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-carrer-withus',
  templateUrl: './carrer-withus.component.html',
  styleUrls: ['./carrer-withus.component.css']
})
export class CarrerWithusComponent implements OnInit {
   // for SEO
   title = 'Top MBA College in Kerala |JAI BHARATH SCHOOL OF MANAGEMENT STUDIES';

  CareerForm: any;
  ProfessionalDetaills: any;
  Program = [];
  submitted: boolean;
  YourResumeLoaded: any;
  CoverLetterLoaded: any;

  constructor(
     // for SEO
     private titleService: Title,
     private metaTagService: Meta,


    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,
    // private titleService: Title
    ) {
  }

  ngOnInit(): void {
     // for SEO
     this.titleService.setTitle(this.title);
     this.metaTagService.addTags([
       { name: 'keywords', content: 'top mba college in kerala , top colleges in kerala , top 10 mba colleges in kerala , top mba colleges in kerala accepting kmat score , which mba college gives best placement' },
       { name: 'description', content: 'JAI BHARATH SCHOOL OF MANAGEMENT STUDIES | Become a Global Executive with Internationally Compatible Curriculum. Apply for Admission. Top MBA College in Kerala' },
       { name: 'robots', content: 'index, follow' },      
       { name: 'viewport', content: 'width=device-width, initial-scale=1' },      
       { charset: 'UTF-8' }
     ]);
    this.restForm();
  }
  restForm() {
    this.CoverLetterLoaded = "";
    this.YourResumeLoaded = "";
    this.CareerForm = this.formBuilder.group({
      Name: ['', Validators.required],
      Email: ['', Validators.required],
      City: ['', Validators.required],
      State: ['', Validators.required],
      DOB: ['', Validators.required],
      Phone: ['', Validators.required],
      PostFor: ['', Validators.required],
      Specialization: ['', Validators.required],
      SchoolFor: ['', Validators.required],
      ProfessionalDetaills: new FormArray([]),
      Experience: new FormArray([]),
      NoOfResearch: ['', Validators.required],
      SalaryExpected: ['', Validators.required],
      CoverLetter: ['', Validators.required],
      YourResume: ['', Validators.required],
      OthersDetails: new FormArray([]),

    });
    this.Program = [{ Program: 'Senior Secondary' }, { Program: 'UG' }, { Program: 'PG' }, { Program: 'PhD' }];
    this.Program.forEach(element => {
      this.ProfessionalDetaillsFormControl.push(this.formBuilder.group({
        Program: [element.Program],
        IsNew: [false],
        College: ['', Validators.required],
        Course: ['', Validators.required],
        Year: ['', Validators.required],
      }));
    });
    this.addExperience(false);
    this.addOthers(false);
  }
  get formControl() { return this.CareerForm.controls; }
  get ProfessionalDetaillsFormControl() { return this.formControl.ProfessionalDetaills as FormArray; }
  get ExperienceFormControl() { return this.formControl.Experience as FormArray; }
  get OthersFormControl() { return this.formControl.OthersDetails as FormArray; }


  addExperience(IsNew) {
    this.ExperienceFormControl.push(this.formBuilder.group({
      ExperienceType: ['', Validators.required],
      OrganizationName: ['', Validators.required],
      IsNew: [IsNew],
      Designation: ['', Validators.required],
      SalaryDrawn: ['', Validators.required],
      StartDate: ['', Validators.required],
      EndDate: ['', Validators.required],
      LatestJob: ['', Validators.required],
    }));
  }
  removeExperience(index) {
    this.ExperienceFormControl.removeAt(index);
  }
  addProfessionalDetaills() {
    this.ProfessionalDetaillsFormControl.push(this.formBuilder.group({
      Program: ['', Validators.required],
      IsNew: [true],
      College: ['', Validators.required],
      Course: ['', Validators.required],
      Year: ['', Validators.required],
    }));
  }
  removeProfessionalDetaills(index) {
    this.ProfessionalDetaillsFormControl.removeAt(index);
  }
  addOthers(IsNew) {
    this.OthersFormControl.push(this.formBuilder.group({
      Others: ['', Validators.required],
      IsNew: [IsNew],
    }));
  }
  removeOthers(index) {
    this.OthersFormControl.removeAt(index);
  }
  onSubmit() {
    this.submitted = true;
    if (this.CareerForm.invalid) {
      this.functionService.showProcessMessage("Provide vaild information", false);
      return;
    }

    this.datalistService.loaderActive = true;
    this.databaseOperationService.saveCareerForm(this.CareerForm.value).subscribe((data: any) => {
      let Id = data.CareerId;
      this.uploadImage(this.CoverLetterLoaded, Id, 'CoverLetter');
      this.uploadImage(this.YourResumeLoaded, Id, 'YourResume');
      this.restForm();
      this.functionService.showProcessMessage("Our Counsellor Will Connect With You ", true);
      this.datalistService.loaderActive = false;
      this.submitted = false;
    })
    // this.functionService.showProcessMessage("Submitted Successfully", true);

  }
  uploadImage(fileLoaded, Id, FieldName) {
    let formData = new FormData();
    if (fileLoaded)
      formData.append('Image', fileLoaded, fileLoaded.name);
    this.databaseOperationService.saveCareerDocument(formData, Id, FieldName).subscribe((data: any) => {

    });
  }
  onCoverLetterSelected(event) {
    this.CoverLetterLoaded = event.target.files[0];
  }
  onYourResumeSelected(event) {
    this.YourResumeLoaded = event.target.files[0];
  }
}

