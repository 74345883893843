import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';

@Component({
  selector: 'app-manage-landing-enquiry',
  templateUrl: './manage-landing-enquiry.component.html',
  styleUrls: ['./manage-landing-enquiry.component.css']
})
export class ManageLandingEnquiryComponent implements OnInit {
  AllEnquiries: any;

  EnquiriesColumns: string[] = ['SortId', 'Name', 'Email','Mobile','Program', 'Message', 'CreatedDate'];
  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,) {
    this.datalistService.loaderActive = true;}

  ngOnInit(): void {
    this.databaseOperationService.getAllLandingEnquiries().subscribe((data: any) => {
      this.AllEnquiries = data;
      this.datalistService.loaderActive = false;
    });
  }

}
