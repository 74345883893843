<div class="print-form">
    <button class="btn btn-primary pull-right" (click)="htmltoPDF()">Print</button>
</div>
<div id="parentdiv">
    <div class="main-head">
        <div class="head-line">
            <h1>Course Enquires</h1>
        </div>
    </div>
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="manage-tbl" id="parentdiv">
                    <p class="text-center" *ngIf="!AllEnquiries.length">No data found</p>
                    <table mat-table [dataSource]="AllEnquiries" class="mat-elevation-z8" width="100%"
                        *ngIf="AllEnquiries.length">

                        <ng-container matColumnDef="SortId">
                            <th mat-header-cell *matHeaderCellDef> # </th>
                            <td mat-cell *matCellDef="let element; let i=index;"> {{i+1}} </td>
                        </ng-container>
                        <ng-container matColumnDef="Name">
                            <th mat-header-cell *matHeaderCellDef> Name </th>
                            <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Email">
                            <th mat-header-cell *matHeaderCellDef> Email Id </th>
                            <td mat-cell *matCellDef="let element"> {{element.Email}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Phone">
                            <th mat-header-cell *matHeaderCellDef> Contact No. </th>
                            <td mat-cell *matCellDef="let element"> {{element.Phone}} </td>
                        </ng-container>

                        <ng-container matColumnDef="Course">
                            <th mat-header-cell *matHeaderCellDef> Course/Program </th>
                            <td mat-cell *matCellDef="let element"> {{element.Course}} </td>
                        </ng-container>

                        <ng-container matColumnDef="State">
                            <th mat-header-cell *matHeaderCellDef> State </th>
                            <td mat-cell *matCellDef="let element"> {{element.State}} </td>
                        </ng-container>
                        <ng-container matColumnDef="City">
                            <th mat-header-cell *matHeaderCellDef> City </th>
                            <td mat-cell *matCellDef="let element"> {{element.City}} </td>
                        </ng-container>

                        <ng-container matColumnDef="EnquiryOn">
                            <th mat-header-cell *matHeaderCellDef> Messaged On </th>
                            <td mat-cell *matCellDef="let element"> {{element.EnquiryOn}}
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="EnquiriesColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: EnquiriesColumns;" (click)="getRecord(row)"
                            class="cursorView"></tr>

                    </table>
                </div>
            </div>
        </div>
    </div>
</div>