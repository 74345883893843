import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';

@Component({
  selector: 'app-college-form-popup',
  templateUrl: './college-form-popup.component.html',
  styleUrls: ['./college-form-popup.component.css']
})
export class CollegeFormPopupComponent implements OnInit {
  CollegeId: string;
  CollegeForm: any;
  submitted: boolean;
  formTitle: string;
  buttonLabel: string;

  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,
    public dialog: MatDialogRef<CollegeFormPopupComponent>,
  ) { }

  ngOnInit(): void {

    this.CollegeForm = this.formBuilder.group({
      CollegeName: ['', Validators.required],
      CollegeId: [''],
    });
    this.submitted = false;
    this.formTitle = 'Add';
    this.datalistService.loaderActive = true;
    this.buttonLabel = 'Submit';
    if (this.CollegeId) {
      this.formTitle = 'Edit';
      this.buttonLabel = 'Update';
      this.databaseOperationService.getCollegeDetailById(this.CollegeId).subscribe((data: any) => {
        data = data;
        this.CollegeForm.patchValue(data);
        this.datalistService.loaderActive = false;
      })
    }else{
      this.datalistService.loaderActive = false;
    }
  }
  get formControl() { return this.CollegeForm.controls; }
  onSubmit() {
    this.submitted = true;
    if (this.CollegeForm.invalid) {
      this.functionService.showProcessMessage("Provide vaild information", false);
      return;
    }

    this.save();
  }
  save() {
    this.datalistService.loaderActive = true;
    this.databaseOperationService.saveCollege(this.CollegeForm.value).subscribe(data => {
      this.datalistService.loaderActive = false;
      if (data) {
        if (!this.CollegeForm.value.CollegeId) {
          this.functionService.showProcessMessage("New College Added Successfully", true);
        } else {
          this.functionService.showProcessMessage("College Updated Successfully", true);
        }
        this.dialog.close(true);
      } else {
        this.functionService.showProcessMessage("An Error Occurred", false);
      }
    })
  }

}
