<div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <button mat-raised-button (click)="drawer.toggle()">
          <i class="fa fa-bars" aria-hidden="true"></i>
        </button>
        <br />
        <br />
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <mat-drawer-container class=" menu-container" hasBackdrop="false">
          <mat-drawer #drawer mode="over" opened="true">
            <ul>
              <li>
                <button mat-raised-button routerLink="./my-account"  (click)="drawer.toggle();" routerLinkActive="active" >My Account</button>
              </li>
              <li>
                <button mat-raised-button (click)="drawer.toggle()" routerLink="./manage-college"  routerLinkActive="active">College</button>
              </li>
              <li>
                <button mat-raised-button (click)="drawer.toggle()" routerLink="./manage-faclity"  routerLinkActive="active">Faclities</button>
              </li>
              <li>
                <button mat-raised-button  routerLink="./faclity-feedback" routerLinkActive="active" (click)="drawer.toggle()">Student Feedback</button>
              </li>
              <li>
                <button mat-raised-button (click)="drawer.toggle()" routerLink="./enquiry"  routerLinkActive="active">Site Enquiry</button>
              </li>
              <li>
                <button mat-raised-button (click)="drawer.toggle()" routerLink="./course-enquiry"  routerLinkActive="active">Course Enquiry</button>
              </li>
              <li>
                <button mat-raised-button (click)="drawer.toggle()" routerLink="./view-recruiter-connect"  routerLinkActive="active">Recruiter Connect</button>
              </li>
              <li>
                <button mat-raised-button (click)="drawer.toggle()" routerLink="./view-career"  routerLinkActive="active">View Career</button>
              </li>
              <li>
                <button mat-raised-button (click)="drawer.toggle()" routerLink="./view-online-admission"  routerLinkActive="active">View Online Admission</button>
              </li>
              <li>
                <button mat-raised-button (click)="drawer.toggle()" routerLink="./landing-enquiry"  routerLinkActive="active">Landing Enquiry</button>
              </li>
              <li>
                <button mat-raised-button (click)="drawer.toggle()" routerLink="./view-grievance"  routerLinkActive="active">Grievance</button>
              </li>
              <li>
                <button mat-raised-button (click)="drawer.toggle()" routerLink="./view-kmat-mock-registration"  routerLinkActive="active">KMAT Mock Registration</button>
              </li>
              <li>
                <button mat-raised-button (click)="drawer.toggle()" routerLink="./manag-notification"  routerLinkActive="active">Manage Notification</button>
              </li>
              <li>
                <button mat-raised-button (click)="drawer.toggle()" routerLink="./view-job-fair"  routerLinkActive="active">View Job Fair Enquiry</button>
              </li>
            </ul>
  
          </mat-drawer>
          <mat-drawer-content>
            <router-outlet></router-outlet>
          </mat-drawer-content>
        </mat-drawer-container>
      </div>
    </div>
  </div>