import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';
import { NotificationFormPopupComponent } from '../../../popup/notifiction/notification-form-popup/notification-form-popup.component';
import { ProceedWarningComponent } from '../../../popup/proceed-warning/proceed-warning.component';

@Component({
  selector: 'app-manag-notification',
  templateUrl: './manag-notification.component.html',
  styleUrls: ['./manag-notification.component.css']
})
export class ManagNotificationComponent implements OnInit {
  Notification = true;
  data: any;
  SiteNotificationId: any;
  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private router: Router,
    private formBuilder: FormBuilder,
    private titleService: Title) {
    // this.datalistService.loaderActive = true;
  }

  Columns: string[] = ['SortId', 'Title', 'Link', 'Operation'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  ngOnInit(): void {
    this.getAllNotification()
  }

  getAllNotification() {
    // this.Columns = ['SortId', 'Title', 'Operation'];
    this.databaseOperationService.getAllNotification().subscribe((data: any) => {

      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.datalistService.loaderActive = false;
    })
  }
  addNotification() {

    let dialogRef: MatDialogRef<NotificationFormPopupComponent> = this.matDialog.open(NotificationFormPopupComponent, {
      width: '400px',
      height: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.SiteNotificationId = '';
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllNotification();
      }
    });
  }
  editNotification(SiteNotificationId) {
    let dialogRef: MatDialogRef<NotificationFormPopupComponent> = this.matDialog.open(NotificationFormPopupComponent, {
      width: '400px',
      height: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.SiteNotificationId = SiteNotificationId;
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.getAllNotification();
      }
    });
  }
  delete_Notification(SiteNotificationId) {
    let dialogRef: MatDialogRef<ProceedWarningComponent> = this.matDialog.open(ProceedWarningComponent, {
      width: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    return dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.datalistService.loaderActive = true;
        this.databaseOperationService.deleteNotification(SiteNotificationId).subscribe((data: any) => {
          this.getAllNotification();
          this.functionService.showProcessMessage("Notification Deleted Successfully", true);
          this.datalistService.loaderActive = false;
        });
        // }
      }
    });
  }
  setNotification() {
    this.datalistService.loaderActive = true;
    this.Notification = true
    this.getAllNotification();
  }
}
