<!--<div class="banner" *ngIf="common.actlink==1">
    <img src="assets/images/activity/nss-banner.jpg">
</div>-->
<div class="banner" *ngIf="common.actlink==3">
    <img src="assets/images/activity/fresh-banner.jpg">
</div>
<div class="banner" *ngIf="common.actlink==2">
    <img src="assets/images/library/FDP.jpg">
</div>
<div class="banner" *ngIf="common.actlink==4">
    <img src="assets/images/activity/industry-interaction.JPG">
</div>
<div class="banner" *ngIf="common.actlink==5">
    <img src="assets/images/activity/conference.png">
</div>
<div class="banner" *ngIf="common.actlink==6">
    <img src="assets/images/activity/mock.jpg">
</div>
<div class="banner" *ngIf="common.actlink==7">
    <img src="assets/images/activity/nss-banner.jpg">
</div>
<div class="banner" *ngIf="common.actlink==8">
    <img src="assets/images/activity/fest-banner.jpg">
</div>
<div class="banner" *ngIf="common.actlink==9">
    <img src="assets/images/activity/fresh-banner.jpg">
</div>
<div class="banner" *ngIf="common.actlink==10">
    <img src="assets/images/activity/igniz-banner.jpeg">
</div>
<div class="banner" *ngIf="common.actlink==11">
    <img src="assets/images/activity/fresh-banner.jpg">
</div>
<div class="banner" *ngIf="common.actlink==12">
    <img src="assets/images/activity/fresh-banner.jpg">
</div>
<div class="bg-side">
    <div class="container">
        <div class=" wrapper d-flex align-items-stretch">
            <nav id="sidebar" class="order-last" class="img">
                <div class="custom-menu">
                    <!--<button type="button" id="sidebarCollapse" class="btn btn-primary">
	        </button>-->
                </div>
                <div class="">
                    <h1><a href="index.html" class="logo">Cultural Activities</a></h1>
                    <ul class="list-unstyled components mb-5">
                        <h2><a href="index.html" class="logo">Academic</a></h2>
                        <!--<li class="active">
                            <a (click)="common.activity(1);"><i class="fas fa-angle-double-right mr-2"></i>
                                Hackathon</a>
                        </li>-->
                        <!-- <li>
                            <a (click)="common.activity(1);"><i class="fas fa-angle-double-right mr-2"></i> NSS </a>
                        </li>-->
                        <li>
                            <a (click)="common.activity(2);"><i class="fas fa-angle-double-right mr-2"></i> Faculties
                                Development Program</a>
                        </li>
                        <li>
                            <a (click)="common.activity(3);"><i class="fas fa-angle-double-right mr-2"></i>
                                Entrepreneurship Development Cell ( EDC )</a>
                        </li>
                        <li>
                            <a (click)="common.activity(4);"><i class="fas fa-angle-double-right mr-2"></i>
                                Industry-Institute Interaction Cell </a>
                        </li>
                        <li>
                            <a (click)="common.activity(5);"><i class="fas fa-angle-double-right mr-2"></i>
                                International Conference</a>
                        </li>
                        <li>
                            <a (click)="common.activity(6);"><i class="fas fa-angle-double-right mr-2"></i>
                                Mock Recruitment Process</a>
                        </li>
                        <h2><a href="index.html" class="logo">None-Academic</a></h2>
                        <li>
                            <a (click)="common.activity(7);"><i class="fas fa-angle-double-right mr-2"></i> Farewell</a>
                        </li>
                        <li>
                            <a (click)="common.activity(8);"><i class="fas fa-angle-double-right mr-2"></i> Annual
                                Function</a>
                        </li>
                        <li>
                            <a (click)="common.activity(9);"><i class="fas fa-angle-double-right mr-2"></i> Sport
                                Meet</a>
                        </li>
                        <li>
                            <a (click)="common.activity(10);"><i class="fas fa-angle-double-right mr-2"></i>
                                Ignis</a>
                        </li>
                        <li>
                            <a (click)="common.activity(11);"><i class="fas fa-angle-double-right mr-2"></i>Festival
                                Celebration</a>
                        </li>
                        <li>
                            <a (click)="common.activity(12);"><i class="fas fa-angle-double-right mr-2"></i>Freshers
                                day</a>
                        </li>

                    </ul>




                </div>

            </nav>

            <!-- Page Content  -->
            <div id="content" class="p-4 p-md-5 pt-5">
                <!--<ng-container *ngIf="common.actlink==1">
                    <h2 class="mb-4">Hackathon</h2>
                    <p>
                        Jai Bharath College of Management and Engineering Technology (JBCMET) is one of the nodal
                        centers in Kerala of Smart India Hackathon (SIH) along with other 48 colleges consisting of IITs
                        and NITs all over India. It was inaugurated by our Honourable Prime Minister Shri. Narendra
                        Modi. Dr. Ramesh Unnikrishnan (Director, AICTE), Dr.Rajasree M.S (V.C. KTU), Dr. Sasidharan
                        (V.C., CUSAT), Dr. M. Abdul Rahiman (MD C-Apt), Dr. Abdul JabbarAhammed (Nodal officer, AICTE,
                        SAGY) also graced the occasion with their presence. SIH is a nationwide initiative launched by
                        AICTE and MHRD which aims at providing students from the field of Engineering and Technology
                        with a platform to find solutions to some of the pressing problems faced by society which in
                        turn inculcate a culture of product innovation and problem-solving mentality. The online
                        interaction of our Prime Minister Sri. Narendra Modi was a great experience for the students.</p>
                        <div class="container">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="assets/images/activity/hack/hack1.JPG">
                                </div>
                                <div class="col-md-4">
                                    <img src="assets/images/activity/hack/hack2.JPG">
                                </div>
                                <div class="col-md-4">
                                    <img src="assets/images/activity/hack/hack3.JPG">
                                </div>
                            </div>
                        
                            <div class="row">
                                <div class="col-md-4">
                                    <img class="row-img" src="assets/images/activity/hack/hack4.JPG">
                                </div>
                                <div class="col-md-4">
                                    <img class="row-img" src="assets/images/activity/hack/hack5.JPG">
                                </div>
                                <div class="col-md-4">
                                    <img class="row-img" src="assets/images/activity/hack/hack6.JPG">
                                </div>
                            </div>
                        </div>
                   
                </ng-container>-->
                <!--<ng-container *ngIf="common.actlink==1">
                    <h2 class="mb-4"> NSS</h2>
                    <p>The College has a Special Programme Officer for NSS. The main objective of the NSS is to “Serve
                        the downtrodden in the society” and “Personality Development through Community Service”. The NSS
                        unit of JBCMET has carried out blood donation camps, Awareness programmes on ‘Health and
                        Hygiene’, Consumer Rights, AIDS awareness programme, Environmental Protection etc. The NSS
                        volunteers of JBCMET attend many camps and are eligible for grace marks as per the university
                        regulations.</p>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-4">
                                <img src="assets/images/activity/nss/01.jpg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/activity/nss/03.jpg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/activity/nss/02.jpg">
                            </div>
                        </div>
                        <div class="row-img">
                            <div class="row">

                                <div class="col-md-4">

                                </div>
                                <div class="col-md-4">
                                    <img src="assets/images/activity/nss/07.jpg">
                                </div>
                                <div class="col-md-4">

                                </div>
                            </div>
                        </div>

                    </div>
                </ng-container>-->

                <ng-container *ngIf="common.actlink==2">
                    <h2 class="mb-4">Faculties Development Program</h2>
                    <p>
                        In this education scenario with its shifting dynamics faculty need to update their understanding
                        and knowledge from time to time. As a solution for this Jai Bharath School of Management Studies
                        offers Faculty Development Programme. FDP aims at developing a strong reputation for excellence
                        in the professional development of management educators. This focuses to attain eminence in
                        management education, research, and training. During this programme the participants are
                        introduced to the use of contemporary participant-centric pedagogies, teaching methods and also
                        to establish a presence in emerging segments of management education. The distinctive feature of
                        FDP is to provide information and guidelines to conduct management development programmes. The
                        pandemic has changed every sphere of life. Taking the current situation in hand Jai Bharath
                        School of Management Studies is equipping faculties to assimilate the required change. Which
                        ensures in molding students who are capable to manage post covid19 situation.

                    </p>
                    <!-- <p>With the shifting dynamics of the education scenario, the improvisation in the societies’
                        intellectual infrastructure is a major challenge. To address this challenge and to think one
                        step ahead of the rest, Jai Bharath School of Management Studies  focuses on
                        the Faculty Development Programme. As the saying goes “As you sow, so shall you reap”, such
                        programmes are embarked here in order to establish a strong foundation. The motive behind this
                        programme is to reinforce the faculty with more groundbreaking and fascinating techniques to
                        permit the students to grab the best out of the lectures</p> -->
                    <!-- <p>
                        Such programmes provide an insight into the teaching faculty to make room for improvisation and
                        enhancement of their skills enough to be more efficient and effective. The focus is not just
                        confined to enhancing the functional area expertise but also helps in the reinforcement of
                        abilities that makes way for performing meaningful research. Our faculties are affluent to be
                        able to actively participate in the faculty development programmes organized by major
                        conglomerates, inside and outside the campus.
                    </p> -->
                </ng-container>
                <ng-container *ngIf="common.actlink==3">
                    <h2 class="mb-4">
                        Entrepreneurship Development Cell ( EDC )</h2>
                    <p>ED cell was established with a vision of fostering innovation and promoting entrepreneurial
                        skills among students. Hence, an Entrepreneurship Development Cell was constituted in Jai
                        Bharath College of Management and Engineering Technology with a dedicated team of students.
                        Aspiring management students are groomed with the necessary inputs on how to be a successful
                        entrepreneur through workshops and seminars by eminent people from the industry.</p>


                    <!-- <ul>
                        <li class="edc">Our aim is to establish a pre- eminent Cell, focused on teaching students and graduates to think differently, identifying new opportunities and convert their innovative ideas into viable enterprises. </li>
                        <li class="edc">To give blended training for future entrepreneurs.</li>
                        <li class="edc">To promote students to strengthen and encourage their entrepreneurial skills. </li>
                        <li class="edc">To foster students to build their innovative skills. </li>
                       
                    </ul> -->
                    <div class="container">
                        <div class="row">
                            <div class="col-md-4">
                                <img src="assets/images/ec1.jpg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/ec2.jpg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/ec3.jpg">
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="common.actlink==4">
                    <h2 class="mb-4">
                        Industry-Institute Interaction Cell </h2>
                    <p>The Industry-Institute Interaction Cell is another remarkable initiative of the placement cell of
                        Jai Bharath to establish strong relationship with industries and to inculcate Industry based
                        training and learning in the normal learning schedule. It focuses on -</p>


                    <ul>
                        <li class="edc">Organizing workshops, conferences and symposium with the joint participation of
                            the faculty and the industry. </li>
                        <li class="edc">Participating experts from industry in curriculum development.</li>
                        <li class="edc">Arranging visits of staff members and students to various industries. </li>
                        <li class="edc">Visits of faculty to industry for study and discussion or delivering lectures on
                            subjects of mutual interest.
                        </li>
                        <li class="edc">Visiting faculty/experts from industries. </li>
                        <li class="edc">Practical trainingfor students in industries. </li>

                    </ul>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-4">
                                <img src="assets/images/ec1.jpg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/ec2.jpg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/ec3.jpg">
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div class="row-img">
                        <div class="row">
                            <div class="col-md-4">
                                <img src="assets/images/activity/freshers/frsh3.jpg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/activity/freshers/frsh4.jpg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/activity/freshers/frsh2.jpg">
                            </div>
                          
                        </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="common.actlink==5">
                    <h2 class="mb-4">International Conference</h2>
                    <p>

                        The International conference was one of the memorable auspicious occasion that occurred in the
                        history of Jai Bharath school of Management studies.It was the first time that Jai Bharath
                        celebrating an International conference on ADVANCED INNOVATIONS IN SCIENCE AND TECHNOLOGY on
                        February 6th. The lamp was lightened by our chief guest Dr Muhammed Mahadi Bin Abdul Jamil,
                        assistant professor of electrical and engineering from university Tun Hussain Onn, Malaysia. The
                        keynote address was delivered by Dr.jose Antony, professor of social work department, Sree
                        Sankaracharya University of Sanskrit, Kalady. The guest of honour was Professor. Dr Rajit
                        Karunakaran Nair,who is a corporate trainer, mentor, and storyteller and J.Simons,who is a
                        native British teacher, married, and living in Kerala.She delivered a beautiful expert talk
                        about communication. Dr Gireeshan M.G, vice-principal of Jai Bharath,delivered a talk on the
                        importance of research.



                    </p>
                    <p>The main sight of the event was the paper presentation of faculties and students. This event
                        brought out a great opportunity for the faculties and students who have not yet participated in
                        an international conference. The HOD of the MBA department Dr Jerin Joseph presented a paper on
                        the topic "A brief review of cultural diversity among employees and its impact on business
                        organization". she achieved an award of 'Best presenter'. The majority of the students from MBA
                        also participated. There were three teams from MBA department. The topics presented are,”Melting
                        of plastic garbage from today " ,"Celebrity endorsement and its impact on consumer buying
                        behaviour “ and " Mental health in learning place ".One member from each team presented the
                        paper. The main aim of the event was certifications.Our MBA faculties provided an interactive
                        session with our honourable guest J.Simons. The interactive session was so interesting and
                        students got the opportunity to share and clear the anxieties and doubts regarding communication
                    </p>

                    <div class="container">
                        <div class="row">
                            <div class="col-md-4">
                                <img src="assets/images/ec1.jpg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/ec2.jpg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/ec3.jpg">
                            </div>
                        </div>
                    </div>

                </ng-container>
                <ng-container *ngIf="common.actlink==6">
                    <h2 class="mb-4">Mock Recruitment Process </h2>
                    <p>
                        A flag ship event titled Mock Recruitment Process was initiated last year with the following
                        objectives:

                    </p>


                    <ul>
                        <li class="edc">To assess the students’ performance in a real - time recruitment process. </li>
                        <li class="edc">To make the students have a first-hand information about how the recruitment
                            process is conducted.</li>
                        <li class="edc">To identify, the right advice the students with respect to the mistakes
                            committed by them.</li>


                    </ul>
                    <div class="container">
                        <div class="row">
                            <div class="col-md-4">
                                <img src="assets/images/activity/mock1.jpeg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/activity/mock2.jpeg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/activity/mock3.jpeg">
                            </div>
                        </div>
                        <div class="row-img">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="assets/images/activity/mock4.jpeg">
                                </div>
                                <!-- <div class="col-md-4">
                                    <img src="assets/images/activity/mock5.jpeg">
                                </div> -->
                                <div class="col-md-4">
                                    <img src="assets/images/activity/mock6.jpeg">
                                </div>
                            </div>
                        </div>
                    </div>




                </ng-container>

                <ng-container *ngIf="common.actlink==7">
                    <h2 class="mb-4">Farewell</h2>
                    <p>
                        Every farewell brings a surcharged atmosphere inside every campus, Farewell at the terminal
                        point here at Jai Bharath School of Management Studies articulates the sentimental bond it holds
                        up with the students. Every journey has a meeting point and an end but farewell as a final
                        juncture in the academic lifetime conveys an emotional attachment the college has with its
                        students. It is a time when juniors bid adieu to their seniors with best wishes for their
                        future. The day comes with a state-of-the-art reminder of a new journey that will impart them a
                        lord of responsibilities. Taking an oath to keep on the legacy and reputations of this
                        Institution.

                    </p>
                    <!-- <p>Every beginning has an end, and every end is a new beginning. Farewell at the terminal point here
                        at Jai Bharath School of Management Studies articulates the sentimental
                        bond it holds up with the students. Gathering and farewell are two sides of a coin in life which
                        cannot be dodged off.
                    </p> -->
                    <!-- <p>
                        Farewell is the final crossroad where juniors bid adieu to their seniors along with wishing good
                        luck for their future. The occasion of farewell marks the love and respect of the juniors
                        towards their seniors, reminding them how the real journey has begun. This is the day they get
                        to promise to themselves, and not just their parents and friends but the institute itself be
                        proud of their achievements to be made hereafter. This is the occasion that calls for great
                        celebrations and the seniors are set out to protect the legacy of Jai Bharath College of
                        Management Studies.

                    </p> -->
                    <div class="container">
                        <div class="row">
                            <div class="col-md-4">
                                <img src="assets/images/activity/farewel/farewel1.jpg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/activity/farewel/farewel2.jpg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/activity/farewel/farewel3.jpg">
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="common.actlink==8">
                    <h2 class="mb-4">Annual Function</h2>
                    <p>
                        This program leaves a great impact on everyone. The day marks the celebration of student's
                        academic and non-academic achievements. The college after the grand success of the annual day
                        hopes for another event to come which will be another exciting day for both faculty members and
                        the students. This day is dedicated to applauding the young and talented genius that roam around
                        the campus. As a final ceremony, the honorable principal reads out the Annual report,
                        highlighting the accomplishments and progressions made by the college in the preceding academic
                        year.

                    </p>
                    <!-- <p>VICTORY, as the name suggests, is an occasion celebrated here at Jai Bharath College of
                        Management and Engineering Technology serving the intent of acknowledging and felicitating our
                        champions in academics and extra-curricular activities and making them the young prodigy all -
                        rounders. The annual report gets read out by the honourable Principal on this auspicious
                        occasion to accentuate the accomplishments and progressions made by the college in the preceding
                        academic year.</p> -->
                    <div class="container">
                        <div class="row">
                            <div class="col-md-4">
                                <img src="assets/images/activity/annual/annnual1.jpg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/activity/annual/annnual2.jpg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/activity/annual/annnual3.jpg">
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="common.actlink==9">
                    <h2 class="mb-4">Sport Meet</h2>
                    <p>
                        Building sportsman spirit helps in building character, Jai Bharath School of management studies
                        with its outstanding cricket and football ground gives their growing athlete to unwind their
                        talents. This allows the student to participate with great fervor and manifest their athletic
                        skills with an unimpeachable finesse

                    </p>
                    <!-- <p>The cricket and the football ground of
                        Jai Bharath School of Management Studies are a state of the art and provide a great way to
                        unwind and for the overall
                        development of students. Matches are quite a noisy affair as audience cheer for their team as
                        the matches extend into night. As the sun sets, the colour of the sky changes from a light
                        orange to azure blue and the floodlights come on.</p> -->


                    <div class="container">
                        <div class="row">
                            <div class="col-md-4">
                                <img src="assets/images/activity/sports/pic1.jpg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/activity/sports/pic2.jpg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/activity/sports/pic3.jpg">
                            </div>
                        </div>
                        <div class="row-img">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="assets/images/activity/sports/pic4.jpg">
                                </div>
                                <div class="col-md-4">
                                    <img src="assets/images/activity/sports/pic5.jpg">
                                </div>
                                <div class="col-md-4">
                                    <img src="assets/images/activity/sports/pic6.jpg">
                                </div>
                            </div>
                        </div>

                        <div class="row-img">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="assets/images/activity/sports/pic7.jpg">
                                </div>
                                <div class="col-md-4">
                                    <img src="assets/images/activity/sports/pic8.jpg">
                                </div>
                                <div class="col-md-4">
                                    <img src="assets/images/activity/sports/pic9.jpg">
                                </div>
                            </div>
                        </div>
                        <div class="row-img">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="assets/images/activity/sports/pic10.jpg">
                                </div>
                                <div class="col-md-4">
                                    <img src="assets/images/activity/sports/pic11.jpg">
                                </div>
                                <div class="col-md-4">
                                    <img src="assets/images/activity/sports/pic13.jpg">
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="common.actlink==10">
                    <h2 class="mb-4">IGNIS 2020</h2>
                    <p>Don't you think you should also have fun while learning at college? JAI BHARATH SCHOOL OF
                        MANAGEMENT STUDIES organized a college management fest named IGNIS 2020 to encourage students to
                        participate and showcase their talent. A two-day extravaganza from the 13th to 14th February
                        2020 was organized by the MBA students touched the lives of all who were involved and visited
                        the fest. The grand endeavor was turned into realities, under the guidance of Dr.
                        Pradeep(Director), Prof. Pramod, Asst. Prof. Jerin, Asst.Prof Aswathy, Asst.Prof.Thaslima,
                        Asst.Prof.Thansi, Asst.Prof.Sudheesh who was ably assisted by the student coordinators and the
                        core team of IGNIS 2020. With a thunderous lineup of cultural events and competition, IGNIS 2020
                        was a crowd-pleasing to every circle of students. The event witnessed a footfall of
                        approximately 3,000 students. Our fest was rich with enthralling events and games. Dominos, the
                        Best Manager contest, a Finance game named Pecunia, a Marketing game named Ipsum, and an HR game
                        named Tironem. Apart from these main events, there were ten other events, Shutterbug (mobile
                        phone photography), RJ hunt, Corporate walk, 3's football, Spot dance, Treasure Hunt,
                        Valentine's special, fun games, and the performance of Thakara Band. <br>
                        On 13th February- 2020 IGNIS 2020 the college fest commenced with the blessings of God by
                        lighting the lamp by our teachers and the core team. The spectacular Day1 of IGNIS 2020 was
                        started with the inauguration. Then we moved on to the main management events and the fun games.
                        The first three rounds of all the events were done on the first day and we ended the day with a
                        corporate walk. Day 2 started with the final round of main events. Since our second-day program
                        was on February 14, Valentine's special programs were there. The whole crowd witnessed the
                        curtains falling over the beats of the most popular band Thakara in Kerala. This visual treat
                        made the night an unforgettable one! The event was a great success as there was generous support
                        of our sponsors to help fulfill all requirements and top-level facilities for the event. Our
                        sponsors were BMW, IDFC FIRST Bank, FOREMOST, Tabassum Styles and Design, Waffles Bae, SiMAT,
                        DECATHLON. IGNIS 2020 has epitomized the ultimate array of cultural activities on an Institute
                        platform. The two-day event moving from one to another showed the diversity and diligently
                        organized proceedings. As the diverse colors of the rainbow enhanced the magnificence of the sky
                        With their unique flairs, being under the umbrella of IGNIS 2020, the events left their
                        everlasting and impeccable impact on the mind of each and everyone who was a part of the fest.
                        Keeping heart, mind, and soul, our institute bought a treasure of talent. A soul that was
                        vibrant, contagious, and exuberant. It has been a paradise for the young generation to nurture
                        and showcase their cultural and managerial skills.</p>
                    <div>
                        <img src="assets/images/activity/igniz.jpeg">
                    </div>
                </ng-container>
                <ng-container *ngIf="common.actlink==11">
                    <h2 class="mb-4">Festival
                        Celebration</h2>
                    <!-- <p>Jai Bharath School of Management Studies takes pride in orchestrating the
                        maximum number of events under one roof. From co-curricular activities to extra curricular
                        activities, cultural program celebrations to National Festival celebrations, each and every one
                        are rejoiced and relished with great pomp and show at the Jai Bharath campus. Some of the
                        festival celebrations include:</p> -->
                    <p>
                        The vibrant college life is incomplete without a fest and constitutes a major part of the life
                        of a student. Here at Jai Bharath School of management studies makes it their motto to celebrate
                        every event whether it be co-curricular or extra-curricular activities. This etches a mark on
                        the academic calendar and students look forward to the dates. In each month a special set of
                        celebrations is put exclusively for birthday parties. Given below are some of our major
                        celebrations.

                    </p>
                    <ul>
                        <li>Republic Day </li>
                        <li>Independence Day </li>
                        <li>Annual Day </li>
                        <li>Onam </li>
                        <li>Environment day </li>
                        <li>Gandhi Jayanthi </li>
                        <li>National Service Scheme Day </li>
                        <li>Teachers Day </li>
                        <li>Christmas </li>

                    </ul>
                    <!-- <p>
                        Apart from all these, the birthday parties call for a special set of celebrations and are
                        eagerly awaited in each month.
                    </p> -->
                    <div>
                        <img src="assets/images/activity/fest1.jpg">
                    </div>
                </ng-container>
                <ng-container *ngIf="common.actlink==12">
                    <h2 class="mb-4">Freshers day</h2>
                    <p>
                        The purpose of fresher's day is to welcome the newbies to a friendly atmosphere so that they
                        feel free to flaunt their creative impulses and also to boost their confidence. Jai Bharath
                        School of Management Studies celebrates this day with great fervor and enthusiasm as it sets a
                        spark for a fresh journey to one’s academic life, full of new challenges, milestones, and
                        achievements. We also aim at the unimpeachable harmony of both seniors and juniors. This day
                        also brings nostalgia for many and also a day filled with mixed emotions.

                    </p>
                    <!-- <p>
                        Novelty has been intriguing and fascinating to man since time immemorial. The Fresher’s day
                        celebration in Jai Bharath School of Management Studies is pulled off with
                        the intent of welcoming the juniors who brings in the same hope and challenge like that of the
                        new players in a cricket team. The College believes that both the seniors and juniors together
                        in harmony can make a terrific team altogether. Hence, the endeavour to make the newbies feel
                        welcome includes lots of gaiety and fun. It is the time in which the seniors extend a cordial
                        reception to their juniors.

                    </p> -->
                    <div class="container">
                        <div class="row">
                            <div class="col-md-4">
                                <img src="assets/images/activity/freshers/frsh1.jpg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/activity/freshers/frsh2.jpg">
                            </div>
                            <div class="col-md-4">
                                <img src="assets/images/activity/freshers/frsh3.jpg">
                            </div>
                        </div>
                        <div class="row-img">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="assets/images/activity/freshers/frsh4.jpg">
                                </div>
                                <div class="col-md-4">
                                    <img src="assets/images/activity/freshers/frsh5.jpg">
                                </div>
                                <div class="col-md-4">
                                    <img src="assets/images/activity/freshers/frsh6.jpg">
                                </div>
                            </div>
                        </div>
                        <div class="row-img">
                            <div class="row">
                                <div class="col-md-4">
                                    <img src="assets/images/activity/freshers/Freshers-day1.jpg">
                                </div>
                                <div class="col-md-4">
                                    <img src="assets/images/activity/freshers/Freshers-day2.jpg">
                                </div>
                               
                            </div>
                        </div>
                    </div>


                </ng-container>
            </div>
        </div>
        <div class="advantage">
            <div class="container">
                <div class="advant-content">
                    <div class="advant-head">
                        <h1>Our Highlights</h1>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="advant-box">
                                <div class="inr-icons">
                                    <span class="lnr lnr-arrow-up"></span>
                                </div>
                                <h2>360 career development</h2>
                                <p class="advant">
                                    An expert driven industry mentorships supplemented with job fitment analysis and
                                    also enable for resume building using AI mode.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="advant-box">
                                <div class="inr-icons">
                                    <span class="lnr lnr-database"></span>
                                </div>
                                <h2>Financial Support</h2>
                                <p class="advant">
                                    Our financial aid helps the students and their families by
                                    covering higher education expenses, tie up with banks and also provide various
                                    scholarship
                                    schemes.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="advant-box">
                                <div class="inr-icons">
                                    <span class="lnr lnr-laptop"></span>
                                </div>
                                <h2>Placement Services</h2>
                                <p class="advant">
                                    The Jai Bharath placement portal serves you access to
                                    extensive job leads and reference of leading firms.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="advant-box">
                                <div class="inr-icons1">
                                    <span class="lnr lnr-earth"></span>
                                </div>
                                <h2>Eco-Friendly Campus</h2>
                                <p class="advant">
                                    We at Jai Bharath take care of all the environment friendly factors to
                                    contribute in making an
                                    eco-friendly campus
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="advant-box">
                                <div class="inr-icons1">
                                    <span class="lnr lnr-book"></span>
                                </div>
                                <h2>Multi Displicinary Curriculum</h2>
                                <p class="advant">
                                    We design various multidisciplinary curriculum for students that suits a
                                    specific career
                                    path.
                                </p>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="advant-box">
                                <div class="inr-icons1">
                                    <span class="lnr lnr-location"></span>
                                </div>
                                <h2>Prime Location</h2>
                                <p class="advant">
                                    We are located at the Mid of Kochi, Idukki and Kottayam so that its very much
                                    easy to
                                    step into our campus.
                                </p>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    </div>
</div>