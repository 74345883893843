import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
import { WebinarRegisterFormPopupComponent } from '../../../popup/webinar/webinar-register-form-popup/webinar-register-form-popup.component';
@Component({
  selector: 'app-webinar-list',
  templateUrl: './webinar-list.component.html',
  styleUrls: ['./webinar-list.component.css']
})
export class WebinarListComponent implements OnInit {
  WebinarId;
  WebinarDetails: any;
  RegistrationForm: any;
  formInvalid: boolean;
  submitted: boolean;
  constructor(
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    public functionService: FunctionService,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,) {
    this.datalistService.loaderActive = true;
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.WebinarId = params['WebinarId'];
      this.databaseOperationService.getWebinarById(this.WebinarId).subscribe((data: any) => {
        this.WebinarDetails = data;
        this.datalistService.loaderActive = false;
      });
    })

    this.RegistrationForm = this.formBuilder.group({
      WebinarId:this.WebinarId,
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      Email: ['', [Validators.required, Validators.email]],
      Phone: ['', Validators.required],
      IsStudent: ['', Validators.required],
      StudentCourse: [''],
      HearAboutWebinar: ['', Validators.required],
      HearAboutWebinarOther: [''],
      Questions: [''],
    });
  }

  get formControl() { return this.RegistrationForm.controls; }
  onSubmit() {
    this.datalistService.loaderActive = true;
    this.formInvalid = false;
    this.submitted = false;
    if (this.RegistrationForm.valid) {
      this.databaseOperationService.sendWebinarConfirmationMail(this.RegistrationForm.value).subscribe((data: any) => {
      });
      this.databaseOperationService.saveWebinarRegister(this.RegistrationForm.value).subscribe((data: any) => {
        this.datalistService.loaderActive = false;
        // this.functionService.showProcessMessage("Registered successfully ", true);
        // this.datalistService.loaderActive = false;
        if(data.WebinarRegisterId){
          Swal.fire({
            title: 'Saved Successfully',
            icon: 'success',
            showClass: {
              popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp'
            }
          })
        }
        else{
          Swal.fire("Please provide valid information")
        }
      })
    } else {
      this.submitted = true;
      this.functionService.showProcessMessage("Please provide valid information", false);
      this.datalistService.loaderActive = false;
     }
  }

  registerWebinar(webinarid) {

    let dialogRef: MatDialogRef<WebinarRegisterFormPopupComponent> = this.matDialog.open(WebinarRegisterFormPopupComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true,
      panelClass: 'no-padding-dialog-container',
      hasBackdrop: true,
    });
    dialogRef.componentInstance.WebinarId = webinarid;
    return dialogRef.afterClosed().subscribe(result => {
    });
  }
  dateTime = new Date();
  isActive(Date) {
    let date1 = formatDate(this.dateTime, 'yyyy-MM-dd', 'en_US');
    let date2 = formatDate(Date, 'yyyy-MM-dd', 'en_US');
    // this.dateTime
    // let webinarDate=Date.getTime();
    return (date2 >= date1)
  }
}
