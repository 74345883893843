import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseOperationService } from '../../api/database/database-operation.service';
import { DatalistService } from '../../api/services/datalist.service';
import { FunctionService } from '../../api/services/function.service';
declare var $: any;

// for SEO
import { Title, Meta } from '@angular/platform-browser';
import { formatDate } from '@angular/common';
import { ScholarshipHomepopupComponent } from '../../popup/scholarship-homepopup/scholarship-homepopup.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  AllBlogEvent: any;

  // for SEO
  title = 'JAI BHARATH SCHOOL OF MANAGEMENT STUDIES |TOP MBA College In Kerala';
  AllWebinar=[];

  constructor(

    // for SEO
    private titleService: Title,
    private metaTagService: Meta,

    
    public datalistService: DatalistService,
    private databaseOperationService: DatabaseOperationService,
    private matDialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    public functionService: FunctionService,) { }

  ngOnInit(): void {
    // this.scholarshipPop();

    // this.databaseOperationService.getAllWebinar().subscribe((data: any) => {
    //   this.AllWebinar = data.filter(
    //     data => this.isActive(data.Date));
    // })
    // for SEO
    this.titleService.setTitle(this.title);
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Top mba colleges in kerala , top colleges in kerala for mba ,top 10 mba colleges in kerala,top mba colleges in kerala accepting kmat score ,top 5 mba colleges in kerala ,which mba college gives best placement,top mba colleges in kochi,best mba college' },
      { name: 'description', content: 'JAI BHARATH SCHOOL OF MANAGEMENT STUDIES is one of the best Business schools in Kerala. It offers various MBA courses to the students.' },
      { name: 'robots', content: 'index, follow' },      
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },      
      { charset: 'UTF-8' }
    ]);



    this.databaseOperationService.getAllBlog().subscribe((data:any) => {
      this.AllBlogEvent = data;
      this.datalistService.loaderActive = false;
    })
  }



  scholar
  // scholarshipPop(){
  //   let dialogRef: MatDialogRef<ScholarshipHomepopupComponent> = this.matDialog.open(ScholarshipHomepopupComponent, {
  //     width: '500px',
  //     disableClose: true,
  //     panelClass: 'scholarship-dialog-container',
  //     hasBackdrop: true,
  //   });
  //   return dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //     }
  //   });
  // }


  dateTime = new Date();
  isActive(Date) {
    let date1 = formatDate(this.dateTime, 'yyyy-MM-dd', 'en_US');
    let date2 = formatDate(Date, 'yyyy-MM-dd', 'en_US');
    // this.dateTime
    // let webinarDate=Date.getTime();
    return (date2 >= date1)
  }
  ngAfterViewInit() {
    this.activeSilder()
  }
  activeSilder(){
    var _this = this;
    $(document).ready(function() {
      var logosslide=(<any>$('#logogs-slider')).lightSlider({
        loop:true,
        item:5,
        speed:600,
        auto:true,
        keyPress:true,
        controls:false,
        slideMove:1,
        pager:false,
        
        responsive : [
          {
              breakpoint:800,
              settings: {
                  item:2,
                  slideMove:1,
                  slideMargin:6,
                }
          },
          {
              breakpoint:480,
              settings: {
                  item:1,
                  slideMove:1,
                }
          }
      ]
      });
      $('#logosPrev').on('click', function () {
        logosslide.goToPrevSlide();
      });
      $('#logosNext').on('click', function () {
        logosslide.goToNextSlide();
      });

      var adventslide=(<any>$('#slider-advant')).lightSlider({
        loop:true,
        item:3,
        speed:600,
        auto:true,
        keyPress:true,
        controls:false,
        slideMove:1,
        pager:false,
        
        responsive : [
          {
              breakpoint:800,
              settings: {
                  item:2,
                  slideMove:1,
                  slideMargin:6,
                }
          },
          {
              breakpoint:480,
              settings: {
                  item:1,
                  slideMove:1,
                }
          }
      ]
      });
      $('#advantPrev').on('click', function () {
        adventslide.goToPrevSlide();
      });
      $('#advantNext').on('click', function () {
        adventslide.goToNextSlide();
      });

      var clientslide=(<any>$('#client-slider')).lightSlider({
        loop:true,
        item:6,
        speed:600,
        auto:true,
        keyPress:true,
        controls:false,
        slideMove:1,
        pager:false,
        
        responsive : [
          {
              breakpoint:800,
              settings: {
                  item:2,
                  slideMove:1,
                  slideMargin:6,
                }
          },
          {
              breakpoint:480,
              settings: {
                  item:2,
                  slideMove:1,
                }
          }
      ]
      });
      $('#clientPrev').on('click', function () {
        clientslide.goToPrevSlide();
      });
      $('#clientNext').on('click', function () {
        clientslide.goToNextSlide();
      });


      var advantageslide=(<any>$('#advantages-slider')).lightSlider({
        loop:true,
        item:3,
        speed:600,
        auto:true,
        keyPress:true,
        controls:false,
        slideMove:1,
        pager:false,
        
        responsive : [
          {
              breakpoint:800,
              settings: {
                  item:2,
                  slideMove:1,
                  slideMargin:6,
                }
          },
          {
              breakpoint:480,
              settings: {
                  item:2,
                  slideMove:1,
                }
          }
      ]
      });
      $('#clgadvantagePrev').on('click', function () {
        advantageslide.goToPrevSlide();
      });
      $('#clgadvantageNext').on('click', function () {
        advantageslide.goToNextSlide();
      });


      var testislide=(<any>$('#testi-slider')).lightSlider({
        loop:true,
        item:1,
        speed:600,
        pause: 5000,
        auto:true,
        keyPress:true,
        controls:false,
        slideMove:1,
        pager:false,
        
        responsive : [
          {
              breakpoint:800,
              settings: {
                  item:1,
                  slideMove:1,
                  slideMargin:6,
                }
          },
          {
              breakpoint:480,
              settings: {
                  item:1,
                  slideMove:1,
                }
          }
      ]
      });
      $('#testiPrev').on('click', function () {
        testislide.goToPrevSlide();
      });
      $('#testiNext').on('click', function () {
        testislide.goToNextSlide();
      });


      var testislide=(<any>$('#blog-slide')).lightSlider({
        loop:true,
        item:3,
        speed:600,
        pause: 5000,
        auto:true,
        keyPress:true,
        controls:false,
        slideMove:1,
        pager:false,
        
        responsive : [
          {
              breakpoint:800,
              settings: {
                  item:2,
                  slideMove:1,
                  slideMargin:6,
                }
          },
          {
              breakpoint:480,
              settings: {
                  item:1,
                  slideMove:1,
                }
          }
      ]
      });
      $('#blogPrev').on('click', function () {
        testislide.goToPrevSlide();
      });
      $('#blogNext').on('click', function () {
        testislide.goToNextSlide();
      });

      $( window ).resize();
    });
    
  }

}
