import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DatabaseOperationService } from '../../../api/database/database-operation.service';
import { DatalistService } from '../../../api/services/datalist.service';
import { FunctionService } from '../../../api/services/function.service';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.css']
})
export class LoginPopupComponent implements OnInit {
  form: FormGroup;
  public loginInvalid: boolean;
  private formSubmitAttempt: boolean;
  private returnUrl: string;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialogRef<LoginPopupComponent>,
    private databaseOperationService: DatabaseOperationService,
    public functionService: FunctionService,
    public datalistService: DatalistService,
  ) {
  }

  async ngOnInit() {

    this.form = this.fb.group({
      Email: ['', Validators.email],
      Password: ['', Validators.required],
      RememberMe: ['']
    });

    if (localStorage.getItem('auth-access') && localStorage.getItem('auth-access-data')) {
      this.datalistService.loaderActive = true;
      this.databaseOperationService.userPreLogin(localStorage.getItem('auth-access'), localStorage.getItem('auth-access-data')).subscribe((data: any) => {
        if (data&&data.LoginId) {
          if (this.form.value.RememberMe) {
            localStorage.setItem('auth-access', data.EmailAddress);
            localStorage.setItem('auth-access-data', data.AuthAccessToken);
          }
          sessionStorage.setItem('sessionEmailId', localStorage.getItem('auth-access'));
          sessionStorage.setItem('sessionLoginId', data.LoginId);
          this.datalistService.loaderActive = false;
          this.dialog.close(true);
        } else {
          this.loginInvalid = true;
          this.datalistService.loaderActive = false;
        }
      });

    }
  }

  async onSubmit() {
    this.loginInvalid = false;
    this.formSubmitAttempt = false;
    if (this.form.valid) {
      try {
        this.datalistService.loaderActive = true;
        this.databaseOperationService.userLogin(this.form.value).subscribe((data: any) => {
          if (data) {
            if (this.form.value.RememberMe) {
              localStorage.setItem('auth-access', data.EmailAddress);
              localStorage.setItem('auth-access-data', data.AuthAccessToken);
            }
            sessionStorage.setItem('sessionEmailId', data.EmailAddress);
            sessionStorage.setItem('sessionLoginId', data.LoginId);
            this.dialog.close(true);
            this.datalistService.loaderActive = false;
          } else {
            this.loginInvalid = true;
            this.functionService.showProcessMessage("Please provide valid information", false);
            this.datalistService.loaderActive = false;
          }
        });
      } catch (err) {
        this.loginInvalid = true;
        this.functionService.showProcessMessage("Please provide valid information", false);
      }
    } else {
      this.formSubmitAttempt = true;
      this.functionService.showProcessMessage("Please provide valid information", false);
    }
  }

  close() {
    this.dialog.close(false);
  }
}