import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FunctionService } from '../services/function.service';

@Injectable({
  providedIn: 'root'
})
export class DatabaseOperationService {
  getAllJobFairEnquiry() {
    return this.httpClient.get(`${this.functionService.getAdminApi()}/_college/getAllJobFairEnquiry.php`);
  }
  saveJobFairEnquiryForm(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_college/saveJobFairEnquiryForm.php`, { formValue: formValue });
  }
  downloadAllWebinarRegisteration(WebinarId: string) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_webinar/downloadAllWebinarRegisteration.php`, { WebinarId: WebinarId });
  }
  getAllWebinarRegisteration(WebinarId: string) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_webinar/getAllWebinarRegisteration.php`, { WebinarId: WebinarId });
  }
  sendWebinarConfirmationMail(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/mail/sendWebinarConfirmationMail.php`, { formValue: formValue });
  }
  saveWebinarRegister(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_webinar/saveWebinarRegister.php`, { formValue: formValue });
  }
  getWebinarById(WebinarId: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_webinar/getWebinarById.php`, { WebinarId: WebinarId });
  }
  saveWebinarHostImage(fileLoaded: FormData, WebinarId: any, OldImage: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_webinar/saveWebinarHostImage.php?id=${WebinarId}&HostImage=${OldImage}`, fileLoaded);
  }
  saveWebinarPosterImage(fileLoaded: FormData, WebinarId: any, OldImage: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_webinar/saveWebinarPosterImage.php?id=${WebinarId}&PosterImage=${OldImage}`, fileLoaded);
  }
  saveWebinar(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_webinar/saveWebinar.php`, { formValue: formValue });
  }
  deleteWebinar(WebinarId: any, PosterImage: any, HostImage: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_webinar/deleteWebinar.php`, { WebinarId: WebinarId, PosterImage: PosterImage , HostImage: HostImage });
  }
  getAllWebinar() {
    return this.httpClient.get(`${this.functionService.getAdminApi()}/_webinar/getAllWebinar.php`);
  }
  getNotificationDetailById(SiteNotificationId: string) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_notification/getNotificationById.php`, { SiteNotificationId: SiteNotificationId });
  }
  saveNotification(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_notification/saveNotification.php`, { formValue: formValue });
  }
  deleteNotification(SiteNotificationId: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_notification/deleteNotification.php`, { SiteNotificationId: SiteNotificationId });
  }
  getAllNotification() {
    return this.httpClient.get(`${this.functionService.getAdminApi()}/_notification/getAllNotification.php`);
  }
  getAllKmatMockRegistration() {
    return this.httpClient.get(`${this.functionService.getAdminApi()}/_site/getAllKmatMockRegistration.php`);
  }
  saveKmatMockRegistrationForm(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_site/saveKmatMockRegistrationForm.php`, { formValue: formValue });
  }
  sendQuickEnquiryForm(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/mail/sendQuickEnquiryForm.php`, { formValue: formValue });
  }
  getAllLandingEnquiries() {
    return this.httpClient.get(`${this.functionService.getAdminApi()}/_site/getAllLandingEnquiries.php`);
  }
  saveQuickEnquiryForm(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_site/saveQuickEnquiryForm.php`, { formValue: formValue });
  }
  sendOnlineAdmisionConfirmation(Email: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/mail/online-admission-confirmation.php`, { Email: Email });
  }
  getAllGrievance() {
    return this.httpClient.get(`${this.functionService.getAdminApi()}/_grievance/getAllGrievance.php`);
  }
  saveGrievance(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_grievance/saveGrievance.php`, { formValue: formValue });
  }
  getCareerById(CareerId: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_career/getCareerById.php`, { CareerId: CareerId });
  }
  getAllCareerExperience(CareerId: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_career/getAllCareerExperience.php`, { CareerId: CareerId });
  }
  getAllCareerOthersDetails(CareerId: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_career/getAllCareerOthersDetails.php`, { CareerId: CareerId });
  }
  getAllCareerProfessionalDetaills(CareerId: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_career/getAllCareerProfessionalDetaills.php`, { CareerId: CareerId });
  }
  getAllCareer() {
    return this.httpClient.get(`${this.functionService.getAdminApi()}/_career/getAllCareer.php`);
  }
  saveCareerDocument(fileLoaded: FormData, Id: any, FieldName: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_career/saveCareerDocument.php?id=${Id}&FieldName=${FieldName}`, fileLoaded);
  }
  saveCareerForm(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_career/saveCareerForm.php`, { formValue: formValue });
  }
  getOnlineAdmissionById(OnlineAdmissionId: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_online_admission/getOnlineAdmissionById.php`, { OnlineAdmissionId: OnlineAdmissionId });
  }
  getAllOnlineAdmission() {
    return this.httpClient.get(`${this.functionService.getAdminApi()}/_online_admission/getAllOnlineAdmission.php`);
  }
  saveOnlineAdmissionDocument(fileLoaded: FormData, Id: any, FieldName: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_online_admission/saveOnlineAdmissionDocument.php?id=${Id}&FieldName=${FieldName}`, fileLoaded);
  }
  saveOnlineAdmisionForm(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_online_admission/saveOnlineAdmisionForm.php`, { formValue: formValue });
  }
  getAllRecruiterConnect() {
    return this.httpClient.get(`${this.functionService.getAdminApi()}/_college/getAllRecruiterConnect.php`);
  }
  saveRecruiterConnectForm(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_college/saveRecruiterConnectForm.php`, { formValue: formValue });
  }
  getAllCourseEnquiries() {
    return this.httpClient.get(`${this.functionService.getAdminApi()}/_college/getAllCourseEnquiries.php`);
  }
  saveCourseEnquiryForm(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_college/saveCourseEnquiryForm.php`, { formValue: formValue });
  }
  getAllEnquiries() {
    return this.httpClient.get(`${this.functionService.getAdminApi()}/_about/getAllEnqueries.php`);
  }
  getAllOtherCommentsByFaculties(FacultiesId: string) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_faculties/getAllOtherCommentsByFaculties.php`, { FacultiesId: FacultiesId });
  }
  getFacultiesFeedbackById(FacultiesId: string) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_faculties/getFacultiesFeedbackById.php`, { FacultiesId: FacultiesId });
  }
  getAllFacultiesByCourse(CourseId: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_faculties/getAllFacultiesByCourse.php`, { CourseId: CourseId });
  }
  getFacultiesDetailById(FacultiesId: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_faculties/getFacultiesById.php`, { FacultiesId: FacultiesId });
  }
  deleteFaculties(FacultiesId: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_faculties/deleteFaculties.php`, { FacultiesId: FacultiesId });
  }
  saveFaculties(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_faculties/saveFaculties.php`, { formValue: formValue });
  }
  getAllFaculties() {
    return this.httpClient.get(`${this.functionService.getAdminApi()}/_faculties/getAllFaculties.php`);
  }
  getCourseDetailById(CourseId: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_college/getCourseById.php`, { CourseId: CourseId });
  }
  deleteCourse(CourseId: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_college/deleteCourse.php`, { CourseId: CourseId });
  }
  saveCourse(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_college/saveCourse.php`, { formValue: formValue });
  }
  getAllCourse(CollegeId: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_college/getAllCourse.php`, { CollegeId: CollegeId });
  }
  getCollegeDetailById(CollegeId: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_college/getCollegeById.php`, { CollegeId: CollegeId });
  }
  deleteCollege(CollegeId: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_college/deleteCollege.php`, { CollegeId: CollegeId });
  }
  saveCollege(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_college/saveCollege.php`, { formValue: formValue });
  }
  getAllCollege() {
    return this.httpClient.get(`${this.functionService.getAdminApi()}/_college/getAllCollege.php`);
  }
  getFacultiesWithScore(CourseId: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_faculties/getFacultiesWithScore.php`, { CourseId: CourseId });
  }
  getTableRowId(tableName: string, sortId: any, URLValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/getTableRowId.php`, { tableName: tableName, sortId: sortId, URLValue: URLValue });
  }
  sendEventConfirmationMail(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/mail/sendEventConfirmationMail.php`, { formValue: formValue });
  }
  saveEventRegister(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_blog/saveEventRegister.php`, { formValue: formValue });
  }
  getAllEventRegisteration(EventId: string) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_blog/getAllEventRegisteration.php`, { EventId: EventId });
  }
  downloadAllEventRegisteration(EventId: string) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_blog/downloadAllEventRegisteration.php`, { EventId: EventId });
  }
  getBlogById(BlogId: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_blog/getBlogById.php`, { BlogId: BlogId });
  }
  saveBlogImage(fileLoaded: FormData, BlogId: any, OldImage: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_blog/saveBlogImage.php?id=${BlogId}&BlogImage=${OldImage}`, fileLoaded);
  }
  saveBlog(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_blog/saveBlog.php`, { formValue: formValue });
  }
  deleteBlog(BlogId: any, ImageName: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_blog/deleteBlog.php`, { BlogId: BlogId, BlogImage: ImageName });
  }
  getAllBlog() {
    return this.httpClient.get(`${this.functionService.getAdminApi()}/_blog/getAllBlog.php`);
  }
  getAllBlogEvents() {
    return this.httpClient.get(`${this.functionService.getAdminApi()}/_blog/getAllBlog.php`);
  }
  saveFeedBackForm(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_feedback/saveFeedBackForm.php`, { formValue: formValue });
  }
  sendContactForm(formValue: any) {
    return this.httpClient.post(`${this.functionService.getMailApi()}/enquiryMail.php`, { formValue: formValue });
  }
  saveContactForm(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_about/saveContactForm.php`, { formValue: formValue });
  }
  updateEmailId(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_login/updateEmailId.php`, { formValue: formValue });
  }
  updatePassword(formValue: any) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_login/updatePassword.php`, { formValue: formValue });
  }
  userPreLogin(EmailId, Token) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_login/pre_login.php`, { EmailId: EmailId, Token: Token });
  }
  userLogin(userLogin) {
    return this.httpClient.post(`${this.functionService.getAdminApi()}/_login/login.php`, { userLogin: userLogin });
  }
  constructor(
    public httpClient: HttpClient,
    public functionService: FunctionService
  ) { }

}







